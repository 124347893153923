import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { GoogleMap, GoogleMapsModule, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { MapsApiLoaderService } from '@app/shared/maps-api-loader.service';
import { ServiceArea } from '@app/shared/service-area';

import { serviceAreaMapStyles, usCenterLatitude, usCenterLongitude, usRestriction } from './service-area-map-config';

const MAP_ICON_SIZE = 54;

interface MapMarkerConfig {
  position: google.maps.MarkerOptions['position'];
  serviceArea: ServiceArea;
}

@Component({
  selector: 'om-service-area-map',
  templateUrl: './service-area-map.component.html',
  styleUrls: ['./service-area-map.component.scss'],
})
export class ServiceAreaMapComponent implements OnInit, OnChanges {
  @Input() selectedServiceArea: ServiceArea;
  @Input() serviceAreas: ServiceArea[];
  @Input() zoomLevel = 4;
  @Output() markerClick = new EventEmitter<ServiceArea>();
  @ViewChildren(MapMarker) mapMarkers: QueryList<MapMarker>;
  @ViewChild(MapInfoWindow) mapInfoWindow: MapInfoWindow;
  @ViewChild(GoogleMap) googleMap: GoogleMap;

  markerConfigs: MapMarkerConfig[];

  readonly apiLoaded$: Observable<boolean> = this.googleMapLoader.load$();
  googleMapOptions: google.maps.MapOptions;

  readonly MAP_ICON_ACTIVE = '/assets/images/map-pin-active.svg';
  readonly MAP_ICON_INACTIVE = '/assets/images/map-pin-inactive.svg';
  MARKER_ICON_SIZE: google.maps.Size;

  readonly OFFICE_PLURAL_MAPPING = {
    '=1': '1 office',
    other: '# offices',
  };

  constructor(private googleMapLoader: MapsApiLoaderService) {}

  ngOnInit() {
    this.googleMapOptions = {
      center: { lat: usCenterLatitude, lng: usCenterLongitude },
      styles: serviceAreaMapStyles,
      zoom: this.zoomLevel ?? 4,
      restriction: usRestriction,
      streetViewControl: false,
      disableDefaultUI: true,
      zoomControl: true,
    };
    this.apiLoaded$.pipe(take(1)).subscribe(() => {
      this.MARKER_ICON_SIZE = new google.maps.Size(MAP_ICON_SIZE, MAP_ICON_SIZE);
      this.openServiceAreaMarker(this.selectedServiceArea);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.selectedServiceArea?.isFirstChange()) {
      this.openServiceAreaMarker(changes.selectedServiceArea?.currentValue);
    }

    if (changes.serviceAreas?.currentValue?.length > 0) {
      this.markerConfigs = this.mapServiceAreaToMarkerConfigs(changes.serviceAreas?.currentValue);
    }
  }

  handleMarkerClick(mapMarker: MapMarker, serviceArea: ServiceArea) {
    this.selectedServiceArea = serviceArea; // Triggers icon update
    this.markerClick.emit(serviceArea);
    this.mapInfoWindow.open(mapMarker);
  }

  setZoom(zoomLevel: number) {
    this.googleMap?.googleMap.setZoom(zoomLevel);
  }

  private mapServiceAreaToMarkerConfigs(serviceAreas: ServiceArea[]): MapMarkerConfig[] {
    return serviceAreas.map(serviceArea => ({
      serviceArea,
      position: {
        lat: serviceArea.latitude,
        lng: serviceArea.longitude,
      },
    }));
  }

  private openServiceAreaMarker(serviceArea: ServiceArea) {
    // Since the google-maps component runs outside of ngZone, we have to manually trigger change detection to update
    // references to the markers and info window references
    setTimeout(() => {
      if (!serviceArea) {
        this.mapInfoWindow.close();
      } else {
        const serviceAreaMarker = this.mapMarkers.find(marker => marker.getTitle() === serviceArea.name);
        this.mapInfoWindow.open(serviceAreaMarker);
      }
    }, 0);
  }
}

@NgModule({
  imports: [CommonModule, GoogleMapsModule],
  providers: [MapsApiLoaderService],
  declarations: [ServiceAreaMapComponent],
  exports: [ServiceAreaMapComponent],
})
export class ServiceAreaMapModule {}
