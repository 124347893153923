export class PharmacyResponse {
  display_name: string;
  display_address_street: string;
}

export class Pharmacy {
  displayName: string;
  displayAddressStreet: string;

  static fromApiV2(response: PharmacyResponse): Pharmacy {
    const pharmacy = new Pharmacy();

    pharmacy.displayName = response.display_name;
    pharmacy.displayAddressStreet = response.display_address_street;

    return pharmacy;
  }
}
