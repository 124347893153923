import * as Sentry from '@sentry/angular-ivy';

import { environment } from '../../../environments/environment';

declare let global: { CODE_VERSION: string };

export const SentryErrorHandler = Sentry.createErrorHandler({
  showDialog: false,
});

export const initializeSentry = () => {
  if (environment.sentry.enabled) {
    Sentry.init({
      dsn: environment.sentry.dsn,
      environment: environment.sentry.environment,
      release: global.CODE_VERSION,
      tracesSampleRate: 0,
      integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
      ],
    });
  }
};
