import { Pharmacy, PharmacyResponse } from '@app/shared/pharmacy';

export class PatientPharmacyResponse {
  is_preferred: boolean;
  pharmacy: PharmacyResponse;
}

export class PatientPharmacy {
  isPreferred: boolean;
  pharmacy: Pharmacy;

  static fromApiV2(response: PatientPharmacyResponse): PatientPharmacy {
    const patientPharmacy = new PatientPharmacy();

    patientPharmacy.isPreferred = response.is_preferred;
    patientPharmacy.pharmacy = Pharmacy.fromApiV2(response.pharmacy);

    return patientPharmacy;
  }
}
