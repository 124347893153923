import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable, of as observableOf } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AttemptedPathService } from '@app/core/attempted-path.service';
import { AuthService } from '@app/core/auth.service';
import { LegalDocumentsService } from '@app/core/legal-documents';
import { MembershipService } from '@app/core/membership.service';

import { LinksService } from './links.service';

@Injectable({
  providedIn: 'root',
})
export class TosGuardService implements CanActivate {
  constructor(
    private authService: AuthService,
    private attemptedPathService: AttemptedPathService,
    private membershipService: MembershipService,
    private router: Router,
    private legalDocumentsService: LegalDocumentsService,
    private links: LinksService,
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // This guard runs simultaneously with the AuthGuardService, so it cannot assume that the user is authenticated
    return this.authService
      .isLoggedIn$()
      .pipe(switchMap(isLoggedIn => (isLoggedIn ? this.runTosCheck(state.url) : observableOf(false))));
  }

  private runTosCheck(desiredPath: string) {
    return combineLatest([
      this.legalDocumentsService.getForSelf().valueChanges,
      this.membershipService.getMembership(),
    ]).pipe(
      map(([tos, membership]) => {
        const unsignedDocs = tos.filter(doc => !doc.signed);
        if (unsignedDocs.length && membership.isActive) {
          this.attemptedPathService.setAttemptedPath(desiredPath);
          this.router.navigateByUrl(this.links.termsOfService, { replaceUrl: true });
          return false;
        }
        return true;
      }),
    );
  }
}
