import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlCreationOptions, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FeatureFlagSelectors } from './feature-flags/feature-flag.selectors';

/**
 * Guards a route based on the `featureFlag` that is evaluated.
 * Can navigate to `redirectTo`, if specified.
 * Can preserve query params, if specified.
 *
 * @example
 * {
 *   path: 'my-path',
 *   component: MyComponent,
 *   canActivate: [FeatureFlagGuard],
 *   data: {
 *     featureFlag: FeatureFlags.MY_FEATURE_FLAG,
 *     redirectTo: '/'
 *     preserveQueryParams: true
 *   }
 * }
 */
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagGuard implements CanActivate {
  constructor(private router: Router, private featureFlagSelectors: FeatureFlagSelectors) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    let urlTreeOptions: UrlCreationOptions;

    if (route.data.preserveQueryParams) {
      urlTreeOptions = { queryParams: route.queryParams };
    }

    return this.featureFlagSelectors.getFeatureFlag(route.data.featureFlag, false).pipe(
      map(flag => {
        if (!flag && route.data.redirectTo) {
          return this.router.createUrlTree([route.data.redirectTo], urlTreeOptions);
        }
        return flag;
      }),
    );
  }
}
