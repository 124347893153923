<nav class="pt-3">
  <div class="row g-0">
    <div class="col-1 col-md-4 ps-3">
      <button
        class="btn btn-link p-0"
        aria-label="Back button"
        data-cy="back-arrow"
        *ngIf="canGoBack"
        (click)="onBack($event)"
      >
        <om-arrow-left size="large"></om-arrow-left>
      </button>
    </div>
    <div class="col-10 col-md-4"> <om-logo class="brand mx-auto"></om-logo> </div>
  </div>
  <hr class="border mb-0" />
</nav>
