import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import first from 'lodash-es/first';
import map from 'lodash-es/map';
import noop from 'lodash-es/noop';
import { BehaviorSubject, Observable, Subscription, throwError as observableThrowError } from 'rxjs';
import { catchError, map as rxjsMap, tap } from 'rxjs/operators';

import { ApiService } from '../core/api.service';
import { AppointmentSearchState } from './appointment-search-service/appointment-search-state';
import { AppointmentType, BlankAppointmentType } from './appointment-type';
import { DayInventories, ProviderInventories } from './provider-inventories';

export interface RecommendedRemoteResults {
  remoteResults: ProviderInventories[];
  remoteAppointmentType: AppointmentType;
}

@Injectable()
export class AppointmentSearchService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  readonly loading$ = this._loading$.asObservable();

  private _remoteResults$ = new BehaviorSubject<RecommendedRemoteResults>(null);
  readonly remoteResults$: Observable<RecommendedRemoteResults> = this._remoteResults$.asObservable();

  private _results$ = new BehaviorSubject<ProviderInventories[]>(null);
  readonly results$: Observable<ProviderInventories[]> = this._results$.asObservable();

  private _meta$ = new BehaviorSubject<AppointmentSearchMetaData>(null);
  readonly meta$: Observable<AppointmentSearchMetaData> = this._meta$.asObservable();

  private _errors$ = new BehaviorSubject<any>(null);
  readonly errors$: Observable<any> = this._errors$.asObservable();

  private requestSubscription: Subscription;
  searchEndpoint = '/api/v2/patient/appointment_search';

  constructor(private apiService: ApiService) {}

  createNewSearchRequest(searchState: AppointmentSearchState): Observable<any> {
    return this.apiService.post(this.searchEndpoint, searchState.searchParams, false);
  }

  getResults(searchState: AppointmentSearchState): Observable<object> {
    this._loading$.next(true);
    this.unsubscribeFromLastOutstandingRequest();
    const request = this.createNewSearchRequest(searchState).pipe(
      rxjsMap(response => this.mapInventoryResponse(response, searchState)),
      tap((providerList: ProviderInventories[]) => {
        this._results$.next(providerList);
        this._loading$.next(false);
        this._errors$.next(null);
      }),
      catchError((error: HttpErrorResponse) => {
        this._errors$.next(error);
        this._loading$.next(false);
        return observableThrowError(error);
      }),
    );

    // noop in error handler so error is not rethrown without ability to catch
    this.requestSubscription = request.subscribe({ error: noop });
    return request;
  }

  mapInventoryResponse(respJson: any, searchState: AppointmentSearchState): ProviderInventories[] {
    const providerList: ProviderInventories[] = map(respJson.results, ProviderInventories.fromApiV2);
    const remoteRecommendations: ProviderInventories[] = map(
      respJson.recommended_remote_results,
      ProviderInventories.fromApiV2,
    );

    const remoteAppointmentType = respJson.recommended_remote_appointment_type
      ? AppointmentType.fromApiV2(respJson.recommended_remote_appointment_type)
      : new BlankAppointmentType();
    this._remoteResults$.next({ remoteAppointmentType, remoteResults: remoteRecommendations });

    const meta: AppointmentSearchMetaData = new AppointmentSearchMetaData(respJson, remoteRecommendations);
    searchState.searchMetadata = meta;
    this._meta$.next(meta);

    return providerList;
  }

  private unsubscribeFromLastOutstandingRequest(): void {
    if (this.requestSubscription) {
      this.requestSubscription.unsubscribe();
    }
  }
}

export class AppointmentSearchMetaData {
  providerCount: number;
  inventoryCount: number;
  recommendedRemoteResultsProviderCount: number;
  recommendedRemoteResultsInventoryCount: number;
  remoteAppointmentTypeId: number;

  constructor(respJson: Record<string, any>, remoteRecommendations: ProviderInventories[]) {
    const hasRemoteRecommendations =
      remoteRecommendations &&
      remoteRecommendations.length > 0 &&
      remoteRecommendations[0].dayInventories &&
      Object.keys(remoteRecommendations[0].dayInventories).length > 0;
    this.providerCount = respJson && respJson['provider_count'];
    this.inventoryCount = respJson && respJson['inventory_count'];
    this.remoteAppointmentTypeId = respJson.recommended_remote_appointment_type?.id;
    this.recommendedRemoteResultsProviderCount = hasRemoteRecommendations ? 1 : 0;
    this.recommendedRemoteResultsInventoryCount = hasRemoteRecommendations
      ? this.getRemoteInventoryCount(remoteRecommendations[0].dayInventories)
      : 0;
  }

  private getRemoteInventoryCount(inventories: DayInventories) {
    const maxVisibleRemoteInventories = 5;
    const firstDateAvailable = first(Object.keys(inventories));

    return inventories[firstDateAvailable].slice(0, maxVisibleRemoteInventories)
      ? inventories[firstDateAvailable].slice(0, maxVisibleRemoteInventories).length
      : 0;
  }
}
