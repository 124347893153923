import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TrialVideoVisitOnboardingGraphQL } from '@app/membership/gcn-confirmation/trial-video-visit-onboarding-graphql.service';
import { StartToServiceAreaEdgeGraphqlService } from '@app/registration/graph/edges/start-to-service-area-edge-graphql.service';
import { RegistrationEdge } from '@app/registration/graph/graph-navigation.service';
import { ServiceAreaNode } from '@app/registration/graph/nodes/service-area.node';
import { amazonFlowStatus } from '@app/registration/routing/constants';

@Injectable({
  providedIn: 'root',
})
export class StartToServiceAreaEdge implements RegistrationEdge {
  constructor(
    private graphQLService: StartToServiceAreaEdgeGraphqlService,
    private trialVideoVisitOnboardingGraphQLService: TrialVideoVisitOnboardingGraphQL,
  ) {}

  nextNode = ServiceAreaNode;

  private checkTrialVideoVisit$(): Observable<boolean> {
    return this.trialVideoVisitOnboardingGraphQLService.fetch().pipe(
      map(response => {
        const patient = response.data?.patient;
        if (!patient) {
          return false;
        }
        const { hasAmazonPrimeTrial, isRegComplete, completedAmazonFlowStatus } = patient;
        return hasAmazonPrimeTrial && !isRegComplete && completedAmazonFlowStatus === amazonFlowStatus.gcn_trial;
      }),
    );
  }

  private checkServiceArea$(): Observable<boolean> {
    return this.graphQLService.fetch().pipe(map(result => !result.data?.patient?.serviceArea));
  }

  shouldNavigate$(): Observable<boolean> {
    return combineLatest([this.checkTrialVideoVisit$(), this.checkServiceArea$()]).pipe(
      map(([trialVideoVisit, serviceArea]) => trialVideoVisit || serviceArea),
    );
  }
}
