import { Component, HostBinding, Input } from '@angular/core';

const REACTIVE_CLASSES = 'col-xl-6 col-12 pt-xl-10 pt-4 min-vh-100 bg-wild-sand';

@Component({
  selector: 'om-landing-page-value-prop',
  template: `
    <div>
      <h2 class="offset-xl-2 fw-normal mb-xl-5 mb-4 text-center text-xl-start">A modern approach to primary care</h2>
      <div class="col-md-8 col-xl-9 offset-md-2 p-xl-0">
        <div class="d-flex flex-row mb-4">
          <div class="me-4 mt-2">
            <om-svg-stethoscope></om-svg-stethoscope>
          </div>
          <div class="col-xl-8 p-0">
            <p class="lh-175 mb-1">
              <b>Primary care</b><br />
              Get care for any physical or mental health concern - from colds to chronic conditions.
            </p>
          </div>
        </div>

        <div class="d-flex flex-row mb-4">
          <div class="me-4 mt-2">
            <img
              class="list-thumbnail-image"
              src="/assets/images/video-call-on-mobile-device.svg"
              role="presentation"
            />
          </div>
          <div class="col-xl-8 p-0">
            <p class="lh-175 mb-1">
              <b>24/7 virtual care</b><br />
              Video Chat with a provider or message our care team at no cost to you.
            </p>
          </div>
        </div>

        <div class="d-flex flex-row mb-4">
          <div class="me-4 mt-2">
            <img class="list-thumbnail-image" src="/assets/images/calendar.svg" role="presentation" />
          </div>
          <div class="col-xl-8 p-0">
            <p class="lh-175 mb-1">
              <b>Same - or next-day appointments</b><br />
              Get in-person care in One Medical offices and virtual care nationwide.
            </p>
          </div>
        </div>

        <div class="d-flex flex-row mb-4">
          <div class="me-4 mt-2">
            <om-svg-germ></om-svg-germ>
          </div>
          <div class="col-xl-8 p-0">
            <p class="lh-175 mb-1">
              <b>Mental health services</b><br />
              From trouble sleeping and work stress to anxiety and depression, get care for your mental health.
            </p>
          </div>
        </div>

        <div class="d-flex flex-row mb-4">
          <div class="me-4 mt-2">
            <img class="list-thumbnail-image" src="/assets/images/lab-work.svg" role="presentation" />
          </div>
          <div class="col-xl-8 p-0">
            <p class="lh-175 mb-1">
              <b>On-site labs</b><br />
              Drop in to any One Medical office for blood tests, immunizations, vaccines, and more.
            </p>
          </div>
        </div>

        <div class="d-flex flex-row mb-4">
          <div class="me-4 mt-2">
            <img class="list-thumbnail-image" src="/assets/images/pill-bottle.svg" role="presentation" />
          </div>
          <div class="col-xl-8 p-0">
            <p class="lh-175 mb-1">
              <b>Rx renewals</b><br />
              Easily request prescription renewals and get them filled wherever you go, online or in the app.
            </p>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class LandingPageValuePropComponent {
  @Input() @HostBinding('class') class: string = REACTIVE_CLASSES;
}
