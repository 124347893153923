import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

import {
  AutoEnrollMfa,
  AutoEnrollMfaVariables,
} from '@app/shared/mfa-step/__generated__/auto-enroll-mfa.graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class AutoEnrollMfaGraphQLService extends Mutation<AutoEnrollMfa, AutoEnrollMfaVariables> {
  document = gql`
    mutation AutoEnrollMfa($input: AutoEnrollMfaInput!) {
      autoEnrollMfa(input: $input) {
        success
        mutationErrors {
          messages
          path
        }
      }
    }
  `;
}
