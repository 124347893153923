import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'om-svg-hamburger',
  templateUrl: './svg-hamburger.component.html',
})
export class SvgHamburgerComponent implements OnInit {
  @Input() stroke = 'black';

  constructor() {}

  ngOnInit() {}
}
