import { Injectable } from '@angular/core';
import { Query, gql, Apollo } from 'apollo-angular';

export type ResponseChannelName =
  | 'appointmentSearch'
  | 'manualTopic'
  | 'noRecommendation'
  | 'treatMeNow'
  | 'videoVisit';

export type InputChannelName = 'inputText' | 'manualTopic' | 'survey';

export interface IDOnly {
  id: number;
}

export interface AppointmentType {
  id: number;
  description: string;
  displayedDuration: number;
  displayName: string;
  firstVisitAppointmentType: IDOnly;
  isSpecialty: boolean;
  position: number;
  remote: boolean;
}

export interface AppointmentSearch {
  appointmentType: AppointmentType;
}

export interface Meta {
  metadataUuid: string;
  serviceArea: IDOnly;
}

export interface ChannelRouting {
  appointmentSearch: AppointmentSearch | null;
  channel: ResponseChannelName;
  inputText: string | null;
  meta: Meta | null;
  secondaryChannels: string[] | null;
  topic: string | null;
}

export interface Response {
  channelRouting: ChannelRouting;
}

export interface Parameters {
  channel: InputChannelName;
  inputText?: string;
  serviceAreaId?: string;
  topic?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ChannelRoutingGraphQL extends Query<Response, Parameters> {
  constructor(apollo: Apollo) {
    super(apollo);
  }

  document = gql`
    query ChannelRouting($channel: String!, $inputText: String, $serviceAreaId: ID, $topic: String) {
      channelRouting(channel: $channel, inputText: $inputText, serviceAreaId: $serviceAreaId, topic: $topic) {
        appointmentSearch {
          appointmentType {
            id
            description
            displayedDuration
            displayName
            firstVisitAppointmentType {
              id
            }
            isSpecialty
            position
            remote
          }
        }
        channel
        inputText
        meta {
          metadataUuid
          serviceArea {
            id
          }
        }
        secondaryChannels
        topic
      }
    }
  `;
}
