import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

import { ServiceArea, ServiceAreaVariables } from './__generated__/ServiceArea';

@Injectable({
  providedIn: 'root',
})
export class ServiceAreaGraphQL extends Query<ServiceArea, ServiceAreaVariables> {
  document = gql`
    query ServiceArea($id: Int!) {
      serviceArea(id: $id) {
        id
        code
        name
        virtual
      }
    }
  `;
}
