import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { States_states } from '@app/shared/__generated__/States';
import { StatesGraphQLService } from '@app/shared/states-graphql.service';

export interface State extends Omit<States_states, '__typename'> {}

@Injectable()
export class StateService {
  states$: Observable<State[]>;

  constructor(private statesGraphQLService: StatesGraphQLService) {
    this.states$ = this.statesGraphQLService.fetch().pipe(
      map(result => result.data.states),
      shareReplay(1),
    );
  }
}
