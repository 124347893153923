import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

import type { MembershipResult } from './__generated__/membership-graphql.service.types';

@Injectable()
export class MembershipGraphQL extends Query<MembershipResult> {
  document = gql`
    fragment B2bPlan on B2bPlan {
      id
      isCore
      company {
        name
        includesDependent
        verifyDependentInfo
        enterprisePedsRegistrationEnabled
      }
      hasActiveDiscountTypes
    }

    query MembershipResult {
      membership {
        id
        planId
        planType
        plan {
          id
          ... on B2bPlan {
            ...B2bPlan
          }
          ... on StripePlan {
            id
            amount
            amountInDollars
          }
        }
      }
    }
  `;
}
