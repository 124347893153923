import { Injectable } from '@angular/core';

import { GraphNavigationArgs } from '@app/registration/graph/graph-navigation.service';

@Injectable({
  providedIn: 'root',
})
export class GraphNavigationStorageService {
  private readonly storage: Storage | null;
  private readonly ARGS_STORAGE_KEY = 'graph_navigation_args';
  private readonly NAVIGATION_PROGRESS_STORAGE_KEY = 'graph_navigation_in_progress';

  constructor() {
    this.storage = sessionStorage;
  }

  fetchGraphNavigationArgs(): GraphNavigationArgs | null {
    if (!this.storage) {
      return null;
    }

    const args = this.storage.getItem(this.ARGS_STORAGE_KEY);
    return args ? JSON.parse(args) : null;
  }

  fetchGraphNavigationStatus(): boolean {
    if (!this.storage) {
      return false;
    }

    const status = this.storage.getItem(this.NAVIGATION_PROGRESS_STORAGE_KEY);
    return status ? JSON.parse(status) : false;
  }

  storeGraphNavigationData(navigationInProgress: boolean, navigationArgs: GraphNavigationArgs): void {
    if (this.storage) {
      this.storage.setItem(this.NAVIGATION_PROGRESS_STORAGE_KEY, JSON.stringify(navigationInProgress));
      this.storage.setItem(this.ARGS_STORAGE_KEY, JSON.stringify(navigationArgs));
    }
  }

  clearGraphNavigationData(): void {
    if (this.storage) {
      this.storage.removeItem(this.ARGS_STORAGE_KEY);
      this.storage.removeItem(this.NAVIGATION_PROGRESS_STORAGE_KEY);
    }
  }
}
