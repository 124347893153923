import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-svg-family',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5886 5.36013L12.0277 6.86603L10.4669 5.36013C8.58714 3.54662 5.53941 3.54662 3.65986 5.36013C1.78005 7.17363 1.78005 10.1136 3.65986 11.9271L5.22056 13.433L11.8541 19.8325C11.9509 19.926 12.1044 19.926 12.2012 19.8325L18.8347 13.433L20.3957 11.9271C22.2755 10.1136 22.2755 7.17363 20.3957 5.36013C18.5159 3.54662 15.4684 3.54662 13.5886 5.36013Z"
        [attr.stroke]="color"
        stroke-width="1.25"
      />
    </svg>
  `,
})
export class SvgFamilyComponent {
  @Input() color = 'currentColor';

  constructor() {}
}
