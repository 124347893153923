import { Injectable } from '@angular/core';
import { Observable, of as observableOf, zip } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { Provider } from '../shared/provider';
import { User } from '../shared/user';
import { ApiService } from './api.service';
import { UserService } from './user.service';

@Injectable()
export class ProviderService {
  patientProviderTeam$: Observable<Provider[]> = this.userService.user$.pipe(
    first(),
    map(this.getProviderTeamsIdsByUser),
    switchMap(providerIds => this.getProvidersByIds(providerIds)),
  );

  constructor(private userService: UserService, private apiService: ApiService) {}

  getProviderById(providerId: number) {
    return this.apiService
      .get(`/api/v2/patient/providers/${providerId}.json`)
      .pipe(map(provider => Provider.fromApiV2(provider)));
  }

  private getProvidersByIds(providerIds: number[]): Observable<Provider[]> {
    if (providerIds.length < 1) {
      return observableOf([]);
    }
    const providersObsArray = providerIds.map(pid => this.getProviderById(pid));
    return zip(...providersObsArray);
  }

  private getProviderTeamsIdsByUser(user: User) {
    if (user.provider == null || user.provider.id == null) {
      return [];
    }
    return [user.provider.id].concat(
      user.provider.colleagues == null ? [] : user.provider.colleagues.map(provider => provider.id),
    );
  }
}
