import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '@app/core/api.service';
import { AppointmentTypeGraphQL } from '@app/shared/appointment-type-graphql.service';
import { Provider } from '@app/shared/provider';
import { ServiceArea } from '@app/shared/service-area';

import { BookableAppointmentTypes, BookableAppointmentTypesVariables } from './__generated__/BookableAppointmentTypes';
import { FetchAppointmentCancellationReasons } from './__generated__/FetchAppointmentCancellationReasons';
import { Appointment } from './appointment';
import { AppointmentBookingState } from './appointment-booking-state-service';
import { AppointmentCancellationReason } from './appointment-cancellation-reason';
import { AppointmentCancellationReasonsGraphQL } from './appointment-cancellation-reasons-graphql.service';
import { AppointmentDetailsGraphQLService } from './appointment-reschedule-reason/appointment-details-graphql.service';
import { AppointmentType } from './appointment-type';
import { BookableAppointmentTypesGraphQL } from './bookable-appointment-types-graphql.service';
import { ProviderType, ProviderTypeUtil } from './provider-type';

@Injectable()
export class AppointmentService {
  private _specialtyAppointmentsAvailability = new BehaviorSubject<boolean>(false);
  specialtyAppointmentsAvailability$ = this._specialtyAppointmentsAvailability.asObservable();

  constructor(
    private apiService: ApiService,
    private bookableAppointmentTypesGraphQL: BookableAppointmentTypesGraphQL,
    private appointmentCancellationReasonsGraphQL: AppointmentCancellationReasonsGraphQL,
    private appointmentTypeGraphQL: AppointmentTypeGraphQL,
    private appointmentDetailsGraphQL: AppointmentDetailsGraphQLService,
  ) {}

  getCancellationReasons(): Observable<AppointmentCancellationReason[]> {
    return this.appointmentCancellationReasonsGraphQL
      .fetch()
      .pipe(
        map((resp: ApolloQueryResult<FetchAppointmentCancellationReasons>) =>
          resp.data.appointmentCancellationReasons.map(AppointmentCancellationReason.fromGraphQL),
        ),
      );
  }

  getAppointmentTypes(
    providerType?: ProviderType,
    provider?: Provider,
    serviceArea?: ServiceArea,
  ): Observable<AppointmentType[]> {
    const queryParams: BookableAppointmentTypesVariables = {};

    if (providerType != null) {
      if (provider) {
        queryParams.providerId = provider.id.toString();
      }
      if (providerType !== ProviderType.specificProvider) {
        queryParams.appointmentCategory = ProviderTypeUtil.getTypeCategory(providerType);
      }
    }

    if (serviceArea != null) {
      queryParams.serviceAreaId = serviceArea.id.toString();
    }

    return this.bookableAppointmentTypesGraphQL
      .fetch(queryParams, { fetchPolicy: 'network-only' })
      .pipe(
        map((result: ApolloQueryResult<BookableAppointmentTypes>) =>
          result.data.patient.bookableAppointmentTypes.map(apptType => AppointmentType.fromGraphQL(apptType)),
        ),
      );
  }

  getSpecialtyAppointmentAvailability(serviceArea: ServiceArea) {
    const queryParams = {
      appointmentCategory: 'specialty',
      serviceAreaId: serviceArea.id.toString(),
    };
    this.bookableAppointmentTypesGraphQL
      .fetch(queryParams, { fetchPolicy: 'network-only' })
      .pipe(
        map(
          (result: ApolloQueryResult<BookableAppointmentTypes>) =>
            result.data.patient.bookableAppointmentTypes.length !== 0,
        ),
      )
      .subscribe(specialtyAvailability => this._specialtyAppointmentsAvailability.next(specialtyAvailability));
  }

  saveBookingState(appointmentBookingState: AppointmentBookingState) {
    return this.apiService.post('/api/v2/patient/appointments/search', appointmentBookingState.toApiV2());
  }

  resetBookingState(): Observable<unknown> {
    return this.apiService.post('/api/v2/patient/appointments/reset_cache', {});
  }

  getAppointmentType(id: string | number): Observable<AppointmentType> {
    return this.appointmentTypeGraphQL
      .fetch({ id })
      .pipe(map(resp => AppointmentType.fromGraphQL(resp.data.appointmentTypeForPatient)));
  }

  getAppointment(id: string): Observable<Appointment> {
    return this.appointmentDetailsGraphQL
      .fetch({ id })
      .pipe(map(appointmentResponse => Appointment.fromGraphQL(appointmentResponse.data.appointment)));
  }
}
