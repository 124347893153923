import { Injectable } from '@angular/core';
import lodashMap from 'lodash-es/map';
import merge from 'lodash-es/merge';
import pick from 'lodash-es/pick';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { MarkdownService } from '@app/core/markdown.service';
import { Provider } from '@app/shared/provider';

import { ApiService } from '../core/api.service';
import { Task } from './task';

export interface UpdateParams {
  feedback?: string;
  event_type?: string;
  year?: string;
  month?: string;
  otherProvider?: Partial<Provider>; // this used to be object. seems like it should be provider-ish. not much usage to go on. -cj
  message?: string;
}

@Injectable()
export class TaskService {
  private _tasks$ = new Subject<Task[]>();
  tasks$ = this._tasks$.asObservable();

  constructor(private apiService: ApiService, private markdownService: MarkdownService) {}

  getTasks() {
    this.apiService.get('/api/v2/patient/tasks', false).subscribe((tasks: any) => {
      this._tasks$.next(lodashMap(tasks, task => Task.fromApiV2(task, this.markdownService)));
    });
  }

  updateTask(id: number, event_type: 'complete' | 'decline', params: UpdateParams = {}) {
    let postParams: UpdateParams = { event_type: event_type };
    postParams = merge(postParams, pick(params, ['feedback', 'message', 'year', 'month', 'other_provider']));
    return this.apiService
      .patch(`/api/v2/patient/tasks/${id}`, postParams)
      .pipe(map(task => Task.fromApiV2(task, this.markdownService)));
  }

  getTask(id: string) {
    return this.apiService
      .get(`/api/v2/patient/tasks/${id}`)
      .pipe(map(task => Task.fromApiV2(task, this.markdownService)));
  }
}
