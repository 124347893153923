import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-svg-circle-with-exclamation-point',
  template: `
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 0.375C4.68426 0.375 0.375 4.68426 0.375 10C0.375 15.3157 4.68426 19.625 10 19.625C15.3157 19.625 19.625 15.3157 19.625 10C19.625 4.68426 15.3157 0.375 10 0.375ZM1.625 10C1.625 5.37462 5.37462 1.625 10 1.625C14.6254 1.625 18.375 5.37462 18.375 10C18.375 14.6254 14.6254 18.375 10 18.375C5.37462 18.375 1.625 14.6254 1.625 10ZM9.35262 12.688H10.9366V4.672H9.35262V12.688ZM10.9206 15.776C11.1233 15.5627 11.2246 15.3173 11.2246 15.04C11.2246 14.752 11.1233 14.5067 10.9206 14.304C10.718 14.1013 10.462 14 10.1526 14C9.84329 14 9.58729 14.1067 9.38462 14.32C9.18195 14.5227 9.08062 14.768 9.08062 15.056C9.08062 15.3333 9.18195 15.5787 9.38462 15.792C9.59795 15.9947 9.85396 16.096 10.1526 16.096C10.462 16.096 10.718 15.9893 10.9206 15.776Z"
        fill="#982430"
      />
    </svg>
  `,
})
export class SvgCircleWithExclamationPointComponent {
  @Input() color = 'currentColor';

  constructor() {}
}
