<nav
  class="navbar d-flex flex-row justify-content-between align-items-center p-0 m-0 w-100"
  [ngClass]="{ 'navbar-shadow': !showProgressBar }"
>
  <div class="navbar-item d-flex justify-content-center align-items-center">
    <div class="fixed-container fixed-container-left">
      <ng-container *ngTemplateOutlet="shortArrowButton"></ng-container>
    </div>
  </div>
  <div class="navbar-item d-flex justify-content-center align-items-center">
    <ng-container *ngTemplateOutlet="logo"></ng-container>
  </div>
  <div class="navbar-item d-flex justify-content-center align-items-center">
    <div class="fixed-container fixed-container-right">
      <ng-container *ngTemplateOutlet="loginItem"></ng-container>
    </div>
  </div>
</nav>
<om-navbar-progress-bar *ngIf="showProgressBar" [percentComplete]="percentComplete"></om-navbar-progress-bar>

<ng-template #logo>
  <om-logo class="d-inline-block"></om-logo>
</ng-template>

<ng-template #loginItem>
  <button
    class="btn btn-link custom-padding text-decoration-none fw-bold"
    aria-label="Login Button"
    data-cy="login-button"
    *ngIf="showLoginLink"
    (click)="onLogIn($event)"
  >
    Log in
  </button>
</ng-template>

<ng-template #shortArrowButton>
  <button
    class="btn btn-link p-0"
    aria-label="Back button"
    data-cy="back-arrow"
    *ngIf="canGoBack"
    (click)="onBack($event)"
  >
    <om-navbar-arrow-left ariaLabel="back-arrow"></om-navbar-arrow-left>
  </button>
</ng-template>
