<ng-template #modalContent let-dismiss="dismiss">
  <div lass="modal-header p-0 text-center top-rounded">
    <div class="top-rounded">
      <img src="/assets/images/remote-visit.svg" alt="Remote Visit Icon" class="mx-auto d-block mt-5 mb-1" />
    </div>

    <omgui-close-x
      class="close position-absolute p-3"
      height="32"
      width="32"
      stroke="white"
      backgroundOpacity="0.3"
      backgroundFill="#1A1A1A"
      fill="none"
      role="button"
      (buttonClick)="dismiss()"
    ></omgui-close-x>
  </div>

  <div class="mb-3 mx-5 px-2 modal-body text-center">
    <div class="mx-2 mb-4">
      <h2 class="px-4">Remote Visits are billed</h2>
      <p>
        A remote visit is like an in-person visit except it's done virtually. It's billed to your insurance or directly
        to you if you're self-pay.
      </p>
    </div>

    <div class="rounded-4 included-benefits text-emphasis-medium px-3 py-3 mb-4">
      <p class="fw-500">Included membership benefits:</p>
      <p
        >For more urgent needs, use Urgent Video Chat
        <span class="d-block"
          >via
          <a
            class="fw-bold text-decoration-underline"
            aria-label="app store link"
            data-cy="app-store-link"
            tabindex="0"
            (keyup)="dismissAndShowAppModal($event)"
            (click)="dismissAndShowAppModal($event)"
            >our app.</a
          ></span
        ></p
      >
      <p class="m-0">For emergencies, <span class="fw-500">please call 911.</span></p>
    </div>

    <omgui-button aria-label="I understand button" data-cy="acknowledge" (click)="acknowledge()"
      >I understand</omgui-button
    >
  </div>
</ng-template>
