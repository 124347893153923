import { BannerType } from '@omgui/omgui-banner/omgui-banner.component';

import { ContentBlockMetadataStyles } from '../../../__generated__/globalTypes';

export function contentBlockMetadataStyleToBannerStyle(style: ContentBlockMetadataStyles): BannerType {
  return isBannerType(style) ? style : BannerType.InfoLight;
}

function isBannerType(value: string): value is BannerType {
  return Object.values(BannerType).includes(value as BannerType);
}
