import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-svg-notification-settings',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.1887 5.25C13.1887 5.871 12.6847 6.375 12.0637 6.375C11.4427 6.375 10.9387 5.871 10.9387 5.25C10.9387 4.629 11.4427 4.125 12.0637 4.125C12.6847 4.125 13.1887 4.629 13.1887 5.25Z"
        [attr.stroke]="color"
      />
      <path
        d="M13.9387 18C13.9387 19.0358 13.0995 19.875 12.0637 19.875C11.028 19.875 10.1887 19.0358 10.1887 18"
        [attr.stroke]="color"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.4387 6.375C16.167 6.375 16.0012 8.0625 16.9387 11.625C17.8612 15.1305 18.7515 15 18.4387 15.375C18.126 15.75 17.3137 16.875 12.4387 16.875H11.6887C6.81371 16.875 6.00146 15.75 5.68871 15.375C5.37596 15 6.26621 15.1305 7.18871 11.625C8.12621 8.0625 7.96046 6.375 11.6887 6.375H12.4387Z"
        [attr.stroke]="color"
      />
    </svg>
  `,
})
export class SvgNotificationSettingsComponent {
  @Input() color = 'currentColor';

  constructor() {}
}
