import { Component, Input } from '@angular/core';

import { ComponentSize } from '@omgui/component-size';

import { Provider } from '../../shared/provider';

@Component({
  selector: 'om-profile-bubble-with-details',
  templateUrl: './profile-bubble-with-details.component.html',
  styleUrls: ['./profile-bubble-with-details.component.scss'],
})
export class ProfileBubbleWithDetailsComponent {
  @Input() provider: Provider;

  readonly componentSize = ComponentSize;
}
