import { Pipe, PipeTransform } from '@angular/core';

export enum PageState {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export function isLoadingState(state: PageState) {
  return state === PageState.LOADING;
}

export function isSuccessState(state: PageState) {
  return state === PageState.SUCCESS;
}

export function isErrorState(state: PageState) {
  return state === PageState.ERROR;
}

@Pipe({
  name: 'isLoadingState',
})
export class IsLoadingStatePipe implements PipeTransform {
  transform(value: PageState): boolean {
    return isLoadingState(value);
  }
}

@Pipe({
  name: 'isSuccessState',
})
export class IsSuccessStatePipe implements PipeTransform {
  transform(value: PageState): boolean {
    return isSuccessState(value);
  }
}

@Pipe({
  name: 'isErrorState',
})
export class IsErrorStatePipe implements PipeTransform {
  transform(value: PageState): boolean {
    return isErrorState(value);
  }
}
