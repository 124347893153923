import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import { differenceInYears, format, parse } from 'date-fns';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { MODULE_PERSONAL_DETAILS_PAGE } from '@app/core/mixpanel.constants';
import { WhitelistedEmployeePersonalDetails } from '@app/registration/enterprise/__generated__/WhitelistedEmployeePersonalDetails';
import { MembershipType } from '@app/registration/enterprise/membership-type';
import { StepName } from '@app/registration/enterprise/registration-step-name';
import { AlreadyRegisteredError } from '@app/registration/enterprise/whitelisted-employee-errors';

import { EnterpriseRegistration } from '../enterprise-registration';
import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { RegistrationStep } from '../registration-step';
import { WhitelistedEmployeePersonalDetailsGraphQL } from '../whitelisted-employee-personal-details-graphql.service';
import { PersonalDetailsStepComponent } from './personal-details-step.component';

@Injectable()
export class PersonalDetailsConfig extends RegistrationStep {
  GA_LABEL = 'PersonalDetails_Step';
  MODULE = MODULE_PERSONAL_DETAILS_PAGE;

  captchaNeeded = true;
  component = PersonalDetailsStepComponent;
  componentInstance: PersonalDetailsStepComponent;
  progress = 0;
  form: UntypedFormGroup = this.formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    dob: ['', Validators.required],
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private personalDetailsGraphQL: WhitelistedEmployeePersonalDetailsGraphQL,
    private analyticsService: EnterpriseRegistrationAnalyticsService,
    private router: Router,
  ) {
    super();
  }

  canGoBack(): boolean {
    return false;
  }

  initComponent(component: PersonalDetailsStepComponent, _enterpriseRegistration: EnterpriseRegistration) {
    this.componentInstance = component;
    component.form = this.form;
    component.login.subscribe((fromModal: boolean) => {
      if (fromModal) {
        this.analyticsService.alreadyRegisteredModalLoginClicked();
      }
      this.router.navigate(['/login']);
    });
    component.alreadyRegisteredModalViewed.subscribe(() => {
      this.analyticsService.alreadyRegisteredModalViewed();
    });
    component.alreadyRegisteredModalClosed.subscribe(() => {
      this.analyticsService.alreadyRegisteredModalClosed();
    });
  }

  trackPageVisit(state: EnterpriseRegistration, analytics: EnterpriseRegistrationAnalyticsService) {
    analytics.pageViewed({ module: this.MODULE });
  }

  submit(state: EnterpriseRegistration, captcha: any): Observable<any> {
    if (this.form.valid && !this.componentInstance.dob.errorMessage) {
      let { firstName, lastName } = this.form.value;
      const { dob } = this.form.value;

      firstName = firstName.trim();
      lastName = lastName.trim();

      state.patchParams({ firstName, lastName, dob: this.formattedDob(dob) });
      state.patient.firstName = firstName;
      state.patient.lastName = lastName;
      state.patient.dob = this.formattedDob(dob);

      return captcha.getToken().pipe(
        switchMap((reCaptchaToken: string) =>
          this.personalDetailsGraphQL.fetch({
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            dob,
            reCaptchaToken,
          }),
        ),
        switchMap((result: ApolloQueryResult<WhitelistedEmployeePersonalDetails>) => {
          const employee = result.data.whitelistedEmployee;
          let nextStep = StepName.workEmail;

          if (employee) {
            state.b2bCompany = employee.b2bCompany;
            state.details.b2bCompanyId = +employee.b2bCompany.id;
            state.details.activationCode = null;
            state.details.whitelistedEmployeeId = +employee.id;
            state.details.employeeId = employee.employeeId;
            state.details.workEmail = employee.workEmail;

            if (employee.registered) {
              return observableThrowError(new AlreadyRegisteredError());
            }

            if (this.isPeds(dob)) {
              state.details.membershipType = MembershipType.KIDS;
              this.trackSubmission(true);
              this.router.navigate(['/registration/pediatric'], {
                queryParams: { fromEnterpriseRegistration: 'true' },
              });
              return observableOf(false);
            }

            const membershipType = employee.isDependent ? MembershipType.SPOUSE : MembershipType.PERSONAL;
            state.patchParams({ membershipType });
            state.details.membershipType = membershipType;
            nextStep = StepName.accountSetUp;
          }

          this.trackSubmission(state.isWhitelisted);
          state.setCurrentStep(nextStep);
          return observableOf(true);
        }),
        catchError(err => {
          if ((err as AlreadyRegisteredError).alreadyRegistered) {
            this.trackError(state.isWhitelisted, 'alreadyRegistered');
          } else {
            this.componentInstance.hasGenericError = true;
          }
          return observableThrowError(err);
        }),
      );
    }
    this.trackError(state.isWhitelisted, 'Personal Details form invalid');
    return observableThrowError(new Error('Invalid Personal Details'));
  }

  private isPeds(dob: string): boolean {
    return differenceInYears(new Date(), this.parsedDob(dob)) < 18;
  }

  private trackSubmission(isWhitelist: boolean, module = this.MODULE) {
    this.analyticsService.regInputSubmitted({ isWhitelist, module });
  }

  private trackError(isWhitelist: boolean, error: string, module = this.MODULE) {
    const formField = 'Personal Details Validation';
    this.analyticsService.regInputErrored({ error, isWhitelist, module, formField });
  }

  private formattedDob(dob: string): string {
    return format(this.parsedDob(dob), 'yyyy-MM-dd') + ' 00:00:00';
  }

  private parsedDob(dob: string): Date {
    return parse(dob, 'MM/dd/yyyy', new Date());
  }
}
