import { animate, state, style, transition, trigger } from '@angular/animations';

import { PageState } from '@app/pipes/page-state/page-state';

export const enterAnimation = trigger('enter', [
  transition(':enter', [style({ opacity: 0 }), animate(200, style({ opacity: 1 }))]),
]);

export const leaveAnimation = trigger('leave', [
  transition(':leave', [style({ opacity: 1 }), animate(200, style({ opacity: 0, height: 0 }))]),
]);

export const enterAnimationFirst = trigger('enterFirst', [
  transition(':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))]),
]);

export const enterAnimationSecond = trigger('enterSecond', [
  transition(':enter', [style({ opacity: 0 }), animate('200ms 400ms', style({ opacity: 1 }))]),
]);

export const enterAnimationThird = trigger('enterThird', [
  transition(':enter', [style({ opacity: 0 }), animate('200ms 600ms', style({ opacity: 1 }))]),
]);

export const cardContentAnimation = trigger('cardContent', [
  state(
    PageState.LOADING,
    style({
      height: '200px',
    }),
  ),
  state(
    PageState.SUCCESS,
    style({
      height: '*',
    }),
  ),
]);

export const expandCollapseAnimation = trigger('expandCollapse', [
  transition(':enter', [style({ height: 0, overflow: 'hidden' }), animate(200, style({ height: '*' }))]),
  transition(':leave', [style({ height: '*', overflow: 'hidden' }), animate(200, style({ height: '0' }))]),
]);
