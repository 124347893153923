import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

import {
  CreateHealthInformationReleaseAuthorization as Authorization,
  CreateHealthInformationReleaseAuthorizationVariables as Variables,
} from './__generated__/CreateHealthInformationReleaseAuthorization';

@Injectable()
export class CreateHealthInformationReleaseAuthorizationGraphqlService extends Mutation<Authorization, Variables> {
  document = gql`
    mutation CreateHealthInformationReleaseAuthorization($input: CreateHealthInformationReleaseAuthorizationInput!) {
      createHealthInformationReleaseAuthorization(input: $input) {
        success
        errors
      }
    }
  `;
}
