import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { UserService } from '@app/core/user.service';
import { ServiceArea } from '@app/shared/service-area';
import { ServiceAreaGraphQL } from '@app/shared/service-area-graphql.service';

import { RoutingStep } from '../__generated__/routing-step-graphql.service.types';
import { AppointmentBookingStateService } from '../appointment-booking-state-service/appointment-booking-state.service';
import { AppointmentType } from '../appointment-type';
import { RoutingStepGraphQL } from '../routing-step-graphql.service';
import { AppointmentRoutingState } from './appointment-routing-state';
import { AppointmentRoutingStepName, getStepRoute } from './appointment-routing-steps';

@Injectable({
  providedIn: 'root',
})
export class AppointmentRoutingStateService {
  appointmentRoutingState: AppointmentRoutingState;
  errorData: any;
  private startingStepName: AppointmentRoutingStepName;
  topicBasedGuidanceEnabled: boolean;

  constructor(
    private bookingStateService: AppointmentBookingStateService,
    private featureFlagSelector: FeatureFlagSelectors,
    private location: Location,
    private router: Router,
    private routingStepGraphQL: RoutingStepGraphQL,
    private serviceAreaGraphQL: ServiceAreaGraphQL,
    private userService: UserService,
  ) {
    this.appointmentRoutingState = new AppointmentRoutingState();
    this.featureFlagSelector
      .getFeatureFlag(FeatureFlags.TOPIC_BASED_GUIDANCE, false)
      .pipe(take(1))
      .subscribe((topicBasedGuidanceEnabled: boolean) => {
        this.topicBasedGuidanceEnabled = topicBasedGuidanceEnabled;
      });
  }

  resetAppointmentRoutingState(): void {
    this.appointmentRoutingState = new AppointmentRoutingState();
  }

  start$(navigationExtras?: NavigationExtras): Observable<string> {
    const nextStepRoute = this.getStartRoute$().pipe(tap(route => this.navigate(route, navigationExtras)));
    nextStepRoute.subscribe();
    return nextStepRoute;
  }

  getStartRoute$(): Observable<string> {
    this.appointmentRoutingState.routingSessionId = null;
    this.appointmentRoutingState.stepName = AppointmentRoutingStepName.Start;

    return this.nextRoutingStepName(AppointmentRoutingStepName.Start).pipe(
      tap(stepName => (this.startingStepName = stepName)),
      map(stepName => this.getRoute(stepName)),
    );
  }

  advance$(): Observable<string> {
    const nextStepRoute = this.nextRoutingStepName(this.appointmentRoutingState.stepName).pipe(
      map(nextStepName => this.getRoute(nextStepName)),
      tap(route => this.navigate(route)),
    );
    nextStepRoute.subscribe();
    return nextStepRoute;
  }

  navigateToStep(stepName: AppointmentRoutingStepName, source?: string): void {
    const navigationExtras = source ? { queryParams: { source } } : undefined;
    this.navigate(this.getRoute(stepName), navigationExtras);
  }

  getSelectedServiceArea(): Observable<ServiceArea> {
    const selectedServiceAreaId = this.appointmentRoutingState.serviceAreaId;
    if (!selectedServiceAreaId) {
      return observableOf(null);
    } else {
      return this.serviceAreaGraphQL
        .fetch({ id: this.appointmentRoutingState.serviceAreaId })
        .pipe(map(result => ServiceArea.fromGraphQL(result.data.serviceArea)));
    }
  }

  advanceAvailable(): boolean {
    return !this.appointmentRoutingState.final;
  }

  resetAllAppointmentBookingStates() {
    this.bookingStateService.resetAppointmentBookingState();
    this.resetAppointmentRoutingState();

    this.userService // caution: assumes that this observable should fire in time
      .getUser()
      .pipe(take(1))
      .subscribe(({ serviceArea }) => {
        this.appointmentRoutingState.serviceAreaId = serviceArea.id;
      });
  }

  private navigate(route: string, navigationExtras?: NavigationExtras): void {
    const isRouteActive = this.router.isActive(route, {
      paths: 'exact',
      queryParams: 'exact',
      fragment: 'ignored',
      matrixParams: 'ignored',
    });

    if (!isRouteActive) {
      this.router.navigate([route], navigationExtras);
    }
  }

  private nextRoutingStepName(stepName: AppointmentRoutingStepName): Observable<AppointmentRoutingStepName> {
    return this.routingStepGraphQL
      .fetch(
        {
          ...this.appointmentRoutingState.toGraphQL(),
          stepName,
          topicBasedGuidanceEnabled: this.topicBasedGuidanceEnabled,
        },
        { fetchPolicy: 'network-only' },
      )
      .pipe(
        take(1),
        tap(({ data }) => {
          this.errorData = {};
          this.setInitialBookingAppointmentType(data.routingStep.stepContext?.appointmentType);
        }),
        map(({ data }) => {
          this.appointmentRoutingState.fromGraphQL(data); // sets appointmentRoutingState.stepName
          return this.appointmentRoutingState.stepName;
        }),
      );
  }

  private setInitialBookingAppointmentType(
    appointmentType?: RoutingStep['routingStep']['stepContext']['appointmentType'],
  ) {
    const bookingState = this.bookingStateService.getAppointmentBookingState();
    if (appointmentType && !bookingState.appointmentType) {
      bookingState.setSelectedAppointmentType(AppointmentType.fromGraphQL(appointmentType));
    }
  }

  private getRoute(stepName: AppointmentRoutingStepName): string {
    const baseRoute = getStepRoute(stepName);
    const { surveySystemName } = this.appointmentRoutingState;
    return stepName === AppointmentRoutingStepName.Survey ? `${baseRoute}${surveySystemName}` : baseRoute;
  }
}
