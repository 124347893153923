import { Injectable } from '@angular/core';
import { createFeatureSelector, createSelector, select, Store } from '@ngrx/store';
import { defer, from, iif, Observable, of as observableOf } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { FeatureFlagActions } from '@app/core/feature-flags/feature-flag.actions';
import { FeatureFlagState } from '@app/core/feature-flags/feature-flag.reducer';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';

import { FeatureFlags } from './feature-flags';

export const selectFeatureFlagState = createFeatureSelector<FeatureFlagState>('featureFlags');

export const getFeatureFlag = (props: { flag: string }) =>
  createSelector(selectFeatureFlagState, (state: FeatureFlagState) => state.flags[props.flag]);

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagSelectors {
  constructor(
    private store: Store<FeatureFlagState>,
    private featureFlagActions: FeatureFlagActions,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  getFeatureFlag<T>(flag: FeatureFlags, defaultValue?: T): Observable<T> {
    return this.store.pipe(
      select(getFeatureFlag({ flag })),
      tap(flagValue => {
        if (flagValue === undefined) {
          this.featureFlagActions.loadFeatureFlag(flag, defaultValue);
        }
      }),
      filter(flagValue => flagValue !== undefined),
    );
  }

  getMixpanelConfiguredFeatureFlagForAnonymousUser(flag: FeatureFlags): Observable<boolean> {
    const flagEvaluation$: Observable<boolean> = this.getFeatureFlag<boolean | number>(flag, false).pipe(
      map(flagValue => (typeof flagValue === 'boolean' ? flagValue : flagValue > 0)),
    );

    const setAnonymousUuid$ = defer(() => from(this.launchDarklyService.setAnonymousUserUUID()));
    return iif(() => this.launchDarklyService.isAnonymousUnkeyedUser(), setAnonymousUuid$, observableOf(null)).pipe(
      switchMap(() => flagEvaluation$),
    );
  }
}
