import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { of as observableOf } from 'rxjs';

import { MfaStepComponent } from '@app/registration/enterprise/mfa/mfa-step.component';
import { StepName } from '@app/registration/enterprise/registration-step-name';

import { EnterpriseRegistration } from '../enterprise-registration';
import { RegistrationStep } from '../registration-step';

@Injectable()
export class MfaConfig extends RegistrationStep {
  GA_LABEL = 'Mfa_Step';

  component = MfaStepComponent;
  componentInstance: MfaStepComponent;
  progress = 80;
  form: FormGroup = this.formBuilder.group({
    skip: false,
  });

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  canGoBack(): boolean {
    return false;
  }

  initComponent(component: MfaStepComponent) {
    this.componentInstance = component;
    component.form = this.form;
  }

  trackPageVisit() {
    // No-op; we handle this in the MFARegStepComponent
    return;
  }

  submit(state: EnterpriseRegistration) {
    state.setCurrentStep(StepName.emailVerification);
    return observableOf(true);
  }
}
