import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OfficePermalink_offices } from '@app/shared/__generated__/OfficePermalink';
import { OfficePermalinkGraphqlService } from '@app/shared/office-permalink-graphql.service';

import { ApiService } from '../core/api.service';
import { Office } from './office';

@Injectable()
export class OfficeService {
  readonly _offices$ = new BehaviorSubject<Array<Office>>([]);
  readonly offices$ = this._offices$.asObservable();

  constructor(private apiService: ApiService, private officeGraphQL: OfficePermalinkGraphqlService) {}

  getOffice(id: number): Observable<Office> {
    return this.apiService
      .get(`/api/v2/public/offices/${id}.json`)
      .pipe(map(officeResponse => Office.fromApiV2(officeResponse)));
  }

  getRoundedOfficeCount(): Observable<number> {
    return this.officeGraphQL.fetch({ excludeVirtual: true }).pipe(
      map(response => response.data.offices),
      map((offices: OfficePermalink_offices[]) => {
        const rawCount = offices.filter(o => !o.permalink.match(/remote|testing/g) && !o.isHidden).length;
        return Math.floor(rawCount / 10) * 10;
      }),
    );
  }
}
