import { ElementRef, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { ResizeObserverEntry } from './detect-resize.type';

@Injectable()
export class DetectResizeService {
  getResizeEvents$(el: ElementRef | HTMLElement, delay = 100) {
    return this.createResizeObserver$(el).pipe(
      debounceTime(delay),
      distinctUntilChanged(),
      map(entries => (entries && entries[0]?.contentRect) ?? null),
    );
  }

  private createResizeObserver$(el: ElementRef | HTMLElement): Observable<ResizeObserverEntry[]> {
    return new Observable(observer => {
      const callback = (entries: ResizeObserverEntry[]) => observer.next(entries);
      const resizeObserver = new ResizeObserver(callback);
      resizeObserver.observe((<ElementRef>el)?.nativeElement ?? el);
      return () => resizeObserver.disconnect();
    });
  }
}
