import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ApolloLink, from, InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
// @ts-ignore Type libraries for extract-files don't work. They're outdated. These suggestions also didn't work: https://stackoverflow.com/questions/41292559/could-not-find-a-declaration-file-for-module-module-name-path-to-module-nam
import extractFiles from 'extract-files/extractFiles.mjs';
// @ts-ignore Type libraries for extract-files don't work. They're outdated. These suggestions also didn't work: https://stackoverflow.com/questions/41292559/could-not-find-a-declaration-file-for-module-module-name-path-to-module-nam
import isExtractableFile from 'extract-files/isExtractableFile.mjs';

import { ConfigService } from '@app/core/config.service';
import { CoreModule } from '@app/core/core.module';
import { application } from '@app/core/feature-flags/launch-darkly-user-property-mapper';
import { Lrmo } from '@app/core/lrmo';
import { TargetUserService } from '@app/core/target-user.service';

import { AttemptedPathService } from './core/attempted-path.service';
import { AuthService } from './core/auth.service';
import { WindowService } from './utils/window.service';

export function createApollo(
  httpLink: HttpLink,
  targetUserService: TargetUserService,
  configService: ConfigService,
  windowService: WindowService,
  attemptedPathService: AttemptedPathService,
  authService: AuthService,
) {
  const uri = `${configService.json.apiServer}/api/graphql`;
  const http = httpLink.create({
    uri,
    extractFiles: body => extractFiles(body, isExtractableFile),
  });

  return {
    link: from([
      apolloLinkMiddleware(targetUserService),
      apolloAuthErrorLinkMiddleware(windowService, attemptedPathService, authService),
      http,
    ]),
    cache: new InMemoryCache({
      possibleTypes: {
        PatientTasks: [
          'VisitFollowUpOrderTask',
          'GenericFollowUpOrderTask',
          'PatientTaskDefault',
          'BasicFollowUpOrderTask',
          'SurveyOrderTask',
          'LabOrderTask',
          'VaccineOrderTask',
          'ConsultOrderTask',
          'ProcedureOrderTask',
        ],
      },
      typePolicies: {
        Patient: {
          fields: {
            patientSurveys: relayStylePagination(),
            electronicHealthInformationExports: relayStylePagination(),
          },
        },
      },
    }),
  };
}

export function apolloLinkMiddleware(targetUserService: TargetUserService) {
  return new ApolloLink((operation, forward) => {
    let headers = new HttpHeaders({
      'App-Platform-Name': application,
    });

    const lrmoId = new Lrmo(localStorage).getId();
    if (lrmoId) {
      headers = headers.append('Lrmo-Id', lrmoId);
    }

    const targetUser = targetUserService.getTargetUser();
    if (targetUser) {
      headers = headers.append('X-Target-User', targetUser.fhirIdentifier);
    }

    operation.setContext({ headers });

    return forward(operation);
  });
}

export function apolloAuthErrorLinkMiddleware(
  windowService: WindowService,
  attemptedPathService: AttemptedPathService,
  authService: AuthService,
) {
  return onError(({ networkError }) => {
    if (networkError instanceof HttpErrorResponse && networkError.status === 401) {
      const currentPath = windowService.getLocationHrefWithoutOrigin();

      attemptedPathService.setAttemptedPath(currentPath);
      authService.logout(currentPath);
    }
  });
}

@NgModule({
  imports: [CoreModule, ApolloModule],
  exports: [],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, TargetUserService, ConfigService, WindowService, AttemptedPathService, AuthService],
    },
  ],
})
export class GraphQLModule {}
