import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SwiperModule } from 'swiper/angular';

import { OmguiButtonModule } from '@omgui/omgui-button/omgui-button.component';

import { OmguiActionModule } from '../omgui-action/omgui-action.component';
import {
  FeatureCardContentComponent,
  FeatureCardPrimaryActionDirective,
  FeatureCardSecondaryActionDirective,
} from './feature-card-content/feature-card-content.component';
import {
  FeatureCardItemDirective,
  OmguiFeatureCardCollectionComponent,
} from './omgui-feature-card-collection/omgui-feature-card-collection.component';

@Component({
  selector: 'omgui-feature-card',
  templateUrl: './omgui-feature-card.component.html',
  styleUrls: ['./omgui-feature-card.component.scss'],
})
export class OmguiFeatureCardComponent {}

@NgModule({
  declarations: [
    OmguiFeatureCardComponent,
    OmguiFeatureCardCollectionComponent,
    FeatureCardContentComponent,
    FeatureCardItemDirective,
    FeatureCardPrimaryActionDirective,
    FeatureCardSecondaryActionDirective,
  ],
  imports: [CommonModule, RouterModule, OmguiActionModule, SwiperModule, LayoutModule, OmguiButtonModule],
  exports: [
    OmguiFeatureCardComponent,
    OmguiFeatureCardCollectionComponent,
    FeatureCardContentComponent,
    FeatureCardItemDirective,
    FeatureCardPrimaryActionDirective,
    FeatureCardSecondaryActionDirective,
  ],
})
export class OmguiFeatureCardModule {}
