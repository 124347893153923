import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Query, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { ValidTargetUsers, TargetUser } from './__generated__/valid-target-users-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class ValidTargetUsersGraphQL extends Query<ValidTargetUsers> {
  document = gql`
    query ValidTargetUsers {
      validTargetUsers {
        nodes {
          ...TargetUser
        }
      }
    }

    fragment TargetUser on Patient {
      id
      fhirIdentifier
      firstName
      lastName
      preferredName
      profileImageUrl
    }
  `;

  targetUsers$(): Observable<(TargetUser | null)[] | null> {
    return this.fetch().pipe(
      take(1),
      map((result: ApolloQueryResult<ValidTargetUsers>) => result.data.validTargetUsers.nodes),
    );
  }
}
