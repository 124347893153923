import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import throttle from 'lodash-es/throttle';

import { MarkdownService } from '../../core/markdown.service';

@Component({
  selector: 'om-truncated-markdown',
  providers: [],
  templateUrl: './truncated-markdown.component.html',
  styleUrls: ['./truncated-markdown.component.scss'],
})
export class TruncatedMarkdown implements AfterContentInit, AfterViewInit, OnChanges {
  static COLLAPSE_THRESHOLD = 100; // at least $line-height-computed * 4;
  html: SafeHtml;
  collapsed: boolean;
  throttledUpdateCollapsedState: Function;

  @Input() text: string;
  @Input() seeMoreText = 'See More';
  @Input() forceExpanded = false;
  @Input() headings = false;
  @ViewChild('content', { static: true }) content: ElementRef;

  constructor(private markdownService: MarkdownService, private changeDetectionRef: ChangeDetectorRef) {
    this.throttledUpdateCollapsedState = throttle(this.updateCollapsedState, 500);
  }

  ngAfterContentInit() {
    this.initHtml();
  }

  initHtml() {
    if (this.headings) {
      this.html = this.markdownService.convertWithHeadings(this.text);
    } else {
      this.html = this.markdownService.convert(this.text);
    }

    if (this.forceExpanded) {
      this.collapsed = false;
    }
  }

  ngAfterViewInit() {
    this.updateCollapsedState();
    this.changeDetectionRef.detectChanges();
  }

  ngOnChanges() {
    this.initHtml();
  }

  expand(event: MouseEvent) {
    event.preventDefault();
    this.collapsed = false;
  }

  onResize(_event: Event) {
    this.throttledUpdateCollapsedState();
  }

  updateCollapsedState() {
    if (this.collapsed === false) {
      return;
    }
    this.collapsed = this.content.nativeElement.scrollHeight >= TruncatedMarkdown.COLLAPSE_THRESHOLD;
  }
}
