<account-set-up-value-prop [name]="welcomeName"></account-set-up-value-prop>
<div class="col-12 col-lg-8 offset-lg-2">
  <h3 class="mb-4 text-center">Confirm your information to start using One Medical</h3>
  <form class="col-12 col-sm-8 offset-sm-2 p-0" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-12 col-lg-6 mb-2">
        <om-label-wrapper class="" label="Legal First Name">
          <input
            #firstName
            type="text"
            class="form-control text-field"
            placeholder="Enter first name"
            formControlName="firstName"
            required="true"
            autofocus
          />
        </om-label-wrapper>
      </div>
      <div class="col-12 col-lg-6 mb-2">
        <om-label-wrapper class="" label="Legal Last Name">
          <input
            type="text"
            class="form-control text-field"
            placeholder="Enter last name"
            required="true"
            formControlName="lastName"
          />
        </om-label-wrapper>
      </div>
      <a
        class="col-12 mb-3"
        *ngIf="!showPreferredName"
        href=""
        (click)="$event.preventDefault(); showPreferredName = true"
      >
        Add a preferred name (optional)
      </a>
      <div *ngIf="showPreferredName" class="col-12 col-lg-6 mb-3">
        <om-label-wrapper class="" label="Preferred name">
          <input
            type="text"
            class="form-control text-field"
            placeholder="Enter preferred name"
            formControlName="preferredName"
          />
        </om-label-wrapper>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-3"
        ><om-address-input #address [address]="form.get('address')" [prefilledRegistration]="true"></om-address-input
      ></div>
    </div>
    <div class="row">
      <div class="col-12">
        <om-label-wrapper class="" label="Phone Number">
          <om-phone-number-input #phoneNumber formControlName="phoneNumber"></om-phone-number-input>
        </om-label-wrapper>
      </div>
    </div>
    <div class="row pt-1 mb-5">
      <div class="col-12 d-flex">
        <div>
          <om-checkbox formControlName="textAppToPhone"></om-checkbox>
        </div>
        <div>
          <p class="mb-0 fw-light">Text me a link to download the app</p>
          <small class="text-muted">Get 24/7 care over video chat and messages at no cost</small>
        </div>
      </div>
    </div>
    <div><om-submit-button class="col-12" label="Next" [submitting]="submitting"></om-submit-button></div>
  </form>
</div>
