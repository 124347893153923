import { Injectable } from '@angular/core';
import { gql } from '@apollo/client';
import { Query } from 'apollo-angular';

import { States } from '@app/shared/__generated__/States';

@Injectable({
  providedIn: 'root',
})
export class StatesGraphQLService extends Query<States> {
  document = gql`
    query States {
      states {
        id
        name
        shortName
      }
    }
  `;
}
