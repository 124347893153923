<om-label-wrapper class="" [label]="label" [labelClass]="labelClass" [hasError]="hasError">
  <form>
    <input
      #addressSearch
      id="address"
      type="text"
      class="form-control"
      autocomplete="off"
      [placeholder]="placeholder"
      [attr.autofocus]="enableAutoFocus ? true : null"
      [formControl]="addressQuery"
      (blur)="predictAddress()"
      data-cy="address-autocomplete-input"
    />
    <div #placesService></div>
  </form>
</om-label-wrapper>
