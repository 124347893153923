import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { ArrowBaseComponent } from '../arrow-base/arrow-base.component';

@Component({
  selector: 'om-arrow-left',
  templateUrl: './arrow-left.component.html',
  styleUrls: ['./arrow-left.component.scss', '../arrow-base/arrow-base.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class ArrowLeftComponent extends ArrowBaseComponent {
  @Input() currentColor = false;
  @Input() inline = false;
  @Input() size = 'small';
  @Input() disabled: boolean;
  @Input() ariaLabel = 'arrow left';
}
