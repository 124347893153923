import { CalendarEvent, google, ics, outlook, yahoo } from 'calendar-link';

export class CalendarEventUrl {
  private event: CalendarEvent;

  constructor(title: string, description: string, location: string, start: any, end: any) {
    this.event = {
      title,
      description,
      location,
      start,
      end,
    };
  }

  googleCalendarUrl(): string {
    return google(this.event);
  }

  yahooCalendarUrl(): string {
    return yahoo(this.event);
  }

  outlookCalendarUrl(): string {
    return outlook(this.event);
  }

  icsFileUrl(): string {
    return ics(this.event);
  }
}
