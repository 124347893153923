import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FlashService, MessageType } from '@app/shared';

import { Appointment } from './appointment';
import { AppointmentService } from './appointment.service';

@Injectable({
  providedIn: 'root',
})
export class ActiveAppointmentGuardService implements CanActivate {
  constructor(
    private appointmentService: AppointmentService,
    private flashService: FlashService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const appointmentId = route.params.id;
    return this.appointmentService.getAppointment(appointmentId).pipe(
      map((appointment: Appointment) => {
        if (appointment.isSeenOrTerminal) {
          this.flashService.addFlashMessage(
            "No longer available: We're past the visit start time or this visit was previously cancelled.",
            MessageType.ERROR,
          );
          return this.router.parseUrl('/');
        } else {
          return true;
        }
      }),
    );
  }
}
