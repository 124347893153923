import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';

import { MODULE_CHILD_MEMBERSHIP_CONFIRMATION_PAGE } from '@app/core/mixpanel.constants';
import { StepName } from '@app/registration/enterprise/registration-step-name';

import { EnterpriseRegistration } from '../enterprise-registration';
import { RegistrationStep } from '../registration-step';
import { PediatricDirectSignupSuccessStepComponent } from './pediatric-direct-signup-success-step.component';

@Injectable({
  providedIn: 'root',
})
export class PediatricDirectSignupSuccessConfig extends RegistrationStep implements OnDestroy {
  private selectedStep$ = new ReplaySubject<StepName>(1);
  private destroy$ = new Subject<void>();

  readonly MODULE = MODULE_CHILD_MEMBERSHIP_CONFIRMATION_PAGE;
  readonly component = PediatricDirectSignupSuccessStepComponent;
  readonly progress = 100;
  readonly form: UntypedFormGroup;

  initComponent(component: PediatricDirectSignupSuccessStepComponent, state: EnterpriseRegistration): void {
    component.nextStepSelected.asObservable().pipe(takeUntil(this.destroy$)).subscribe(this.selectedStep$);
    component.module = this.MODULE;
    component.isWhitelisted = state.isWhitelisted;
  }

  submit(state: EnterpriseRegistration): Observable<boolean> {
    return this.selectedStep$.pipe(
      take(1),
      tap(step => state.setCurrentStep(step)),
      map(() => true),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  canGoBack(): boolean {
    return false;
  }
}
