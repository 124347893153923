import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-svg-vitals',
  template: `
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 12.7339H9.27343L10.2694 15L12.8957 9L14.6177 12.7339H18" [attr.stroke]="color" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.7559 4.53014L11.9999 6.22428L10.2441 4.53014C8.12928 2.48995 4.70058 2.48995 2.58609 4.53014C0.471304 6.57033 0.471304 9.87781 2.58609 11.918L4.34188 13.6121L11.9999 21L19.6578 13.6121L21.4139 11.918C23.5287 9.87781 23.5287 6.57033 21.4139 4.53014C19.2991 2.48995 15.8707 2.48995 13.7559 4.53014Z"
        [attr.stroke]="color"
      />
    </svg>
  `,
})
export class SvgVitalsComponent {
  @Input() color = 'currentColor';

  constructor() {}
}
