import { Component, Input } from '@angular/core';

export enum OmguiBadgeVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  INFO = 'info',
  WARNING = 'warning',
  PROMO = 'promo',
  RECOMMENDED = 'recommended',
}

export enum OmguiBadgeSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

@Component({
  selector: 'omgui-badge',
  templateUrl: './omgui-badge.component.html',
  styleUrls: ['./omgui-badge.component.scss'],
})
export class OmguiBadgeComponent {
  @Input() text: string;
  @Input() variant: OmguiBadgeVariant = OmguiBadgeVariant.PRIMARY;
  @Input() size: OmguiBadgeSize = OmguiBadgeSize.SMALL;
}
