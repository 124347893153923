import { ValidationFn } from 'ngrx-forms';

export interface NoRepetitiveCharactersValidationErrorExtension {
  noRepetitiveCharacters?: {
    value: string;
    repetitiveCharacters: string;
  };
}

// Extends the ValidationErrors interface from ngrx-forms. Tells Typescript that ValidationErrors#noRepetitiveCharacters is possible.
declare module 'ngrx-forms/src/state' {
  export interface ValidationErrors extends NoRepetitiveCharactersValidationErrorExtension {}
}

export function noRepetitiveCharacters(minimumRepetitiveLength: number): ValidationFn<string | null> {
  if (!Number.isInteger(minimumRepetitiveLength) || minimumRepetitiveLength < 1) {
    throw new Error(`Invalid argument: cannot check for a repetitive length of: ${minimumRepetitiveLength}!`);
  }
  return (value: string): NoRepetitiveCharactersValidationErrorExtension => {
    const pattern = new RegExp('(.)\\1{' + minimumRepetitiveLength + '}', 'i');
    if (!value || value.length <= 0) {
      return {};
    }
    const test = value.match(pattern);
    return test ? { noRepetitiveCharacters: { value, repetitiveCharacters: test[0] } } : {};
  };
}
