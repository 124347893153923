<ng-container *ngFor="let option of options">
  <input
    [id]="option.value"
    class="btn-check"
    autofocus
    type="radio"
    [value]="option.value"
    [formControl]="formControl"
    (focus)="focus()"
    (blur)="blur()"
  />
  <label
    [for]="option.value"
    class="btn btn-outline-primary"
    [class.active]="isSelected(option.value)"
    [class.focus]="isFocused(option.value)"
    [attr.data-cy]="option.value"
  >
    {{ option.label }}
  </label>
</ng-container>
