import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';

import { AddressAutocompleteComponent } from '@app/shared';
import { MapsApiLoaderService } from '@app/shared/maps-api-loader.service';

@Component({
  selector: 'om-clearable-address-autocomplete',
  templateUrl: './clearable-address-autocomplete.component.html',
  styleUrls: ['../../form-input.scss', 'clearable-address-autocomplete.component.scss'],
})
export class ClearableAddressAutocompleteComponent extends AddressAutocompleteComponent {
  @Output() inputCleared = new EventEmitter<void>();

  constructor(mapsAPILoader: MapsApiLoaderService, changeDetectorRef: ChangeDetectorRef) {
    super(mapsAPILoader, changeDetectorRef);
  }

  clearInput() {
    this.hasEnteredAddress = false;
    this.existingAddress = '';
    this.hasPrediction = false;
    this.addressQuery.reset();
    this.inputCleared.emit();
  }
}
