import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LinksService } from '@app/core/links.service';

@Injectable()
export class MaintenanceModeGuardService implements CanActivate {
  constructor(private featureFlagSelectors: FeatureFlagSelectors, private linksService: LinksService) {}

  canActivate(): Observable<boolean> {
    return this.featureFlagSelectors.getFeatureFlag(FeatureFlags.PATIENT_UI_MAINTENANCE_MODE, false).pipe(
      switchMap(maintenance_mode => {
        if (maintenance_mode) {
          window.location.href = this.linksService.SERVICES;
        }

        return observableOf(!maintenance_mode);
      }),
    );
  }
}
