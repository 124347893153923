import { Injectable } from '@angular/core';

import { AnalyticsService } from '@app/core/analytics.service';
import { FLOW_HOME_PAGE_NAVIGATION, MODULE_HOME_PAGE } from '@app/core/mixpanel.constants';

@Injectable()
export class FooterAnalyticsService extends AnalyticsService {
  readonly submodule = 'Footer';
  flow = '';
  module = '';

  setFlowAndModule(homePage: boolean) {
    this.flow = homePage ? FLOW_HOME_PAGE_NAVIGATION : '';
    this.module = homePage ? MODULE_HOME_PAGE : '';
  }

  trackFooterLink(linkClicked: string) {
    return this.trackWithDefaultProperties('Footer Link Clicked', {
      flow: this.flow,
      module: this.module,
      submodule: this.submodule,
      link_clicked: linkClicked,
    });
  }
}
