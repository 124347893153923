<om-navbar-header
  [canGoBack]="false"
  [showLoginLink]="false"
  [showProgressBar]="!!percentComplete"
  [percentComplete]="percentComplete"
>
</om-navbar-header>
<om-flash></om-flash>

<ng-container *rxLet="documentSignerAndBeneficiary$; let documentSignerAndBeneficiary">
  <om-terms-of-service
    [beneficiaryLoggedIn]="!!documentSignerAndBeneficiary[0]"
    [signOnBehalf]="!!documentSignerAndBeneficiary[0]"
    [signer]="documentSignerAndBeneficiary[0]"
    [beneficiary]="documentSignerAndBeneficiary[1]"
    (nextAction)="continue()"
  >
  </om-terms-of-service>
</ng-container>
