import { Component, Input } from '@angular/core';

@Component({
  selector: 'account-set-up-value-prop',
  template: `
    <div class="row p-0 mx-0 mb-4 mb-md-5 value-prop">
      <div class="col-12 col-md-6 p-0 order-1 order-md-0">
        <div class="col-12 col-md-11 col-xl-10 float-end">
          <h1 class="mb-3 mt-4 mt-lg-5 mb-lg-4" data-cy="welcome-name">{{ welcomeMessage() }}</h1>
          <p>We're a modern approach to primary care - and your company is sponsoring your membership fee.</p>
          <div class="benefits-list mb-4">
            <div class="mb-2">
              <om-svg-checkmark class="me-2" color="#005450"></om-svg-checkmark>
              <span>Book same or next-day appointments via app</span>
            </div>
            <div class="mb-2">
              <om-svg-checkmark class="me-2" color="#005450"></om-svg-checkmark>
              <span>24/7 on-demand virtual care with Video Chat and more</span>
            </div>
            <div class="mb-2">
              <om-svg-checkmark class="me-2" color="#005450"></om-svg-checkmark>
              <span>Digitally request prescription renewals</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12 p-0 m-0 order-0 order-md-1">
        <img class="office-img w-100" src="/assets/images/patient-front-desk.png" alt="patient at the front desk" />
      </div>
    </div>
  `,
  styleUrls: ['./account-set-up-value-prop.component.scss'],
})
export class AccountSetUpValuePropComponent {
  @Input() name: string;

  welcomeMessage() {
    return this.name ? `Welcome, ${this.name}!` : 'Welcome!';
  }
}
