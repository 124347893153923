export interface EmailVerificationStatus {
  errorMessage?: string;
  accountLocked: boolean;
  codeSent: boolean;
  emailVerified: boolean;
}

abstract class BaseEmailVerificationStatus implements EmailVerificationStatus {
  accountLocked = false;
  codeSent = false;
  emailVerified = false;
}

export class VerifiedEmailStatus extends BaseEmailVerificationStatus {
  readonly emailVerified = true;
}

export class CodeSentStatus extends BaseEmailVerificationStatus {
  readonly codeSent = true;
}

export class LockedOutStatus extends BaseEmailVerificationStatus {
  readonly errorMessage =
    'Unable to verify your email due to multiple failed attempts. Please contact <a href="mailto:techsupport@onemedical.com">techsupport@onemedical.com</a>';

  readonly accountLocked = true;
}

export class UnverifiedEmailStatus extends BaseEmailVerificationStatus {
  readonly errorMessage = 'Incorrect code. Please try again.';
}

export class CodeRevokedStatus extends BaseEmailVerificationStatus {
  readonly errorMessage = 'Incorrect code. Resend email for a new one.';
}
