import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'om-calendar',
  templateUrl: './calendar.component.html',
})
export class CalendarComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
