<div class="mt-4" [ngClass]="containerClass">
  <div class="row">
    <div [ngClass]="contentWidthClass">
      <div *ngIf="documentsLoaded$ | async">
        <div class="text-center">
          <omgui-banner *ngIf="redirectError" [type]="BannerType.ErrorLight">{{ redirectError }}</omgui-banner>

          <h1 data-cy="section-title">Terms of Service, Privacy Policy, and HIPAA Notice</h1>
          <p *ngIf="signOnBehalf; else selfSign" class="mb-5" data-cy="section-subtitle">
            To complete your child's registration, you'll need to agree to our terms of service and acknowledge receipt
            of our privacy practices.
          </p>
          <ng-template #selfSign>
            <p class="mb-5" data-cy="section-subtitle">
              In order to join One Medical, you must agree to the terms below.
            </p>
          </ng-template>
        </div>

        <div *ngIf="signOnBehalf" class="mb-4">
          <p class="fw-normal mb-2">Name and email of parent accepting on behalf of the child</p>
          <p>
            {{ signer.firstName }} {{ signer.lastName }} <br />
            {{ signer.email }}
          </p>

          <om-legal-agreement [agree]="legalGuardianSet" (agreed)="setLegalGuardian()"
            >I am the legal parent or guardian of the child</om-legal-agreement
          >
          <om-legal-agreement [agree]="agreedToSignOnBehalf" (agreed)="setAgreedToSignOnBehalf()"
            >I accept these terms of service on behalf of the child</om-legal-agreement
          >
        </div>
        <div *ngIf="(documentsToAgreeTo$ | async).length" class="mb-4">
          <p class="fw-normal mb-2">I am at least 18 years of age and I have read and accept:</p>
          <om-legal-doc
            *ngFor="let doc of documentsToAgreeTo$ | async"
            [enabled]="documentsEnabled"
            [signOnBehalf]="signOnBehalf"
            [signer]="signer"
            [beneficiaryLoggedIn]="beneficiaryLoggedIn"
            [beneficiary]="beneficiary"
            [doc]="doc"
            (docSigned)="docSigned(doc)"
          ></om-legal-doc>
        </div>
        <div *ngIf="(documentsToAcknowledge$ | async).length">
          <p class="fw-normal mb-2">I acknowledge receipt of the following:</p>
          <om-legal-doc
            *ngFor="let doc of documentsToAcknowledge$ | async"
            [enabled]="documentsEnabled"
            [signOnBehalf]="signOnBehalf"
            [signer]="signer"
            [beneficiaryLoggedIn]="beneficiaryLoggedIn"
            [beneficiary]="beneficiary"
            [doc]="doc"
            (docSigned)="docSigned(doc)"
          ></om-legal-doc>
        </div>
        <div class="text-center mt-5 row mx-0">
          <div [ngClass]="nextContainerClass">
            <button
              class="btn btn-block btn-primary"
              type="submit"
              aria-label="submit"
              [attr.aria-label]="nextActionButtonText ? nextActionButtonText : 'submit'"
              [disabled]="!(allTermsAccepted$ | async) || redirecting"
              (click)="continue()"
              data-cy="submit-terms-of-service"
            >
              <om-logo-spinner *ngIf="redirecting" size="30"></om-logo-spinner>
              <span *ngIf="!redirecting">{{ nextActionButtonText }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
