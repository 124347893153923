<om-label-wrapper [label]="label" [labelClass]="labelClass" [hasError]="hasError">
  <form>
    <span class="address-input">
      <input
        #addressSearch
        id="address"
        type="text"
        class="form-control clearable-address"
        autocomplete="off"
        [placeholder]="placeholder"
        [attr.autofocus]="enableAutoFocus ? true : null"
        [formControl]="addressQuery"
        (blur)="predictAddress()"
        data-cy="clearable-address-autocomplete-input"
      />
      <button
        class="close close-button"
        aria-label="Clear input field"
        type="button"
        *ngIf="addressQuery?.value"
        (click)="clearInput()"
      >
        <om-x-with-circle circleFillColor="#918F8D" widthPx="24"></om-x-with-circle>
      </button>
    </span>
    <div #placesService></div>
  </form>
</om-label-wrapper>
