import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RegistrationEdge } from '@app/registration/graph/graph-navigation.service';
import { TermsOfServiceNode } from '@app/registration/graph/nodes/terms-of-service.node';
import { UnsignedLegalDocsGraphqlService } from '@app/registration/unsigned-legal-docs-graphql.service';

@Injectable({
  providedIn: 'root',
})
export class StartToTermsOfServiceEdge implements RegistrationEdge {
  constructor(private graphQLService: UnsignedLegalDocsGraphqlService) {}

  nextNode = TermsOfServiceNode;

  shouldNavigate$(): Observable<boolean> {
    return this.graphQLService.fetch().pipe(map(result => !!result.data?.patient?.hasUnsignedLegalDocuments));
  }
}
