import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  Input,
  NgModule,
  QueryList,
  TemplateRef,
} from '@angular/core';

import { isSuccessState, PageState } from '@app/pipes/page-state/page-state';
import { PipesModule } from '@app/pipes/pipes.module';

import { cardContentAnimation, enterAnimation } from '../animations';

@Directive({
  selector: '[om-description-list-card-value]',
})
export class DescriptionListCardValueDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: '[om-description-list-card-key]',
})
export class DescriptionListCardKeyDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: 'om-description-list-card-row',
})
export class DescriptionListCardRowDirective {
  @ContentChild(DescriptionListCardKeyDirective) key: DescriptionListCardKeyDirective;
  @ContentChild(DescriptionListCardValueDirective) value: DescriptionListCardValueDirective;
}

@Directive({
  selector: '[om-description-list-card-header]',
})
export class DescriptionListCardHeaderDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
  selector: 'om-description-list-card',
  templateUrl: './description-list-card.component.html',
  styleUrls: ['../card.scss'],
  animations: [enterAnimation, cardContentAnimation],
})
export class DescriptionListCardComponent {
  @ContentChild(DescriptionListCardHeaderDirective) header: DescriptionListCardHeaderDirective;
  @ContentChildren(DescriptionListCardRowDirective) rows: QueryList<DescriptionListCardRowDirective>;
  @Input() pageState: PageState = PageState.SUCCESS;
  isSuccessState = isSuccessState;
}

@NgModule({
  declarations: [
    DescriptionListCardComponent,
    DescriptionListCardHeaderDirective,
    DescriptionListCardRowDirective,
    DescriptionListCardKeyDirective,
    DescriptionListCardValueDirective,
  ],
  imports: [CommonModule, PipesModule],
  exports: [
    DescriptionListCardComponent,
    DescriptionListCardHeaderDirective,
    DescriptionListCardRowDirective,
    DescriptionListCardKeyDirective,
    DescriptionListCardValueDirective,
  ],
})
export class DescriptionListCardModule {}
