import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'om-svg-caret-down',
  template: `
    <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.77" d="M14.5 0.5L7.5 7.5L0.5 0.5" [attr.stroke]="stroke" />
    </svg>
  `,
  styleUrls: [],
})
export class SvgCaretDownComponent implements OnInit {
  @Input() stroke = '#1A1A1A';

  constructor() {}

  ngOnInit() {}
}
