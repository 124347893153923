<div>
  <header class="doc-title p-3 d-flex justify-content-between">
    <span class="agreement-title me-1" [id]="legalAgreementId"><ng-content></ng-content></span>
    <button
      class="checkbox-lookalike"
      [class.checked]="agree"
      role="checkbox"
      [attr.aria-checked]="agree"
      [attr.aria-readonly]="agree"
      [attr.aria-labelledby]="legalAgreementId"
      (click)="agreeSelected($event)"
      data-cy="sign-legal-agreement"
    >
      <span class="checkmark" *ngIf="!processing" [class.disabled]="agree"></span>
      <om-logo-spinner class="spinner" *ngIf="processing" size="28" color="#068466"></om-logo-spinner>
    </button>
  </header>
</div>
