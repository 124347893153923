import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'om-svg-facebook-link',
  template: `
    <a href="https://www.facebook.com/onemedical" target="_blank" aria-label="One Medical Facebook Page">
      <svg [attr.fill]="fill" height="18px" viewBox="0 0 10 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" stroke-width="1" fill-rule="evenodd">
          <g transform="translate(-1146.000000, -173.000000)" fill-rule="nonzero">
            <g transform="translate(1141.000000, 174.000000)">
              <g>
                <path
                  d="M7.85741506,18.3749943 L7.85741506,9.87696772 L5,9.87696772 L5,6.50002265 L7.85741506,6.50002265 L7.85741506,3.82815402 C7.85741506,2.41800113 8.25324764,1.32327718 9.04491223,0.543982163 C9.83657682,-0.235312854 10.888007,-0.624960363 12.1992016,-0.624960363 C13.2630013,-0.624960363 14.1288845,-0.575481503 14.7968516,-0.476523217 L14.7968516,2.52932899 L13.0156059,2.52932899 C12.3476387,2.52932899 11.8899577,2.67776614 11.6425623,2.97464043 C11.4446463,3.22203586 11.345688,3.61786787 11.345688,4.1621376 L11.345688,6.50002265 L14.4999774,6.50002265 L14.0546659,9.87696772 L11.345688,9.87696772 L11.345688,18.3749943 L7.85741506,18.3749943 Z"
                  id="Facebook"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </a>
  `,
  styles: ['a { color: currentColor; text-decoration: none; }'],
})
export class SvgFacebookLinkComponent implements OnInit {
  @Input() fill = 'white';

  constructor() {}

  ngOnInit() {}
}
