import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-checkmark-list',
  templateUrl: './checkmark-list.component.html',
  styleUrls: ['./checkmark-list.component.scss'],
})
export class CheckmarkListComponent {
  @Input() heading: string;
  @Input() listClass: string;
  @Input() listItems: string[];
}
