import { createReducer, on } from '@ngrx/store';
import cloneDeep from 'lodash-es/cloneDeep';

import { upsertSurvey } from '@app/core/surveys.actions';

export interface Survey {
  systemName: string;
  completed: boolean;
}

const initialState: Survey[] = [];

export const reducer = createReducer(
  initialState,
  on(upsertSurvey, (state, { survey }) => {
    const newState = cloneDeep(state);
    const existingSurveyIndex = newState.findIndex(item => item.systemName === survey.systemName);
    if (existingSurveyIndex < 0) {
      newState.push({ ...survey });
    } else {
      newState[existingSurveyIndex] = { ...survey };
    }
    return newState;
  }),
);
