import { intervalToDuration } from 'date-fns';

class InvalidDateOfBirthError extends Error {
  constructor(dob: string | Date) {
    super(`Invalid date of birth: ${dob}`);
  }
}

export class DateUtils {
  static ageInYears(dob: string | Date): number {
    const foo = intervalToDuration({ start: new Date(dob), end: new Date() });
    const age = foo.years;

    if (!age && age !== 0) {
      throw new InvalidDateOfBirthError(dob);
    }

    return age;
  }
}
