/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/** @deprecated - Use the generated types in graphql.types.ts */
export enum AddressKind {
  billing = 'billing',
  home = 'home',
  other = 'other',
  work = 'work',
}

/** @deprecated - Use the generated types in graphql.types.ts */
export enum ContentBlockMetadataStyles {
  Dark = 'Dark',
  ErrorLight = 'ErrorLight',
  InfoLight = 'InfoLight',
  Light = 'Light',
  SuccessLight = 'SuccessLight',
  WarningLight = 'WarningLight',
}

/** @deprecated - Use the generated types in graphql.types.ts */
export enum EmailVerificationStatus {
  locked_out = 'locked_out',
  unverified = 'unverified',
  verified = 'verified',
}

/** @deprecated - Use the generated types in graphql.types.ts */
export enum OnboardingTaskName {
  health_information_exchange_page = 'health_information_exchange_page',
  welcome_modal = 'welcome_modal',
}

/** @deprecated - Use the generated types in graphql.types.ts */
export enum OnboardingTaskStatus {
  complete = 'complete',
  incomplete = 'incomplete',
}

/** @deprecated - Use the generated types in graphql.types.ts */
export enum PatientPhoneNumberKind {
  home = 'home',
  mobile = 'mobile',
  other = 'other',
  work = 'work',
}

/**
 * Enum for PatientTaskType order types
 */
/** @deprecated - Use the generated types in graphql.types.ts */
export enum PatientTaskOrderType {
  BasicFollowUpOrder = 'BasicFollowUpOrder',
  ConsultOrder = 'ConsultOrder',
  GenericFollowUpOrder = 'GenericFollowUpOrder',
  LabOrder = 'LabOrder',
  ProcedureOrder = 'ProcedureOrder',
  SurveyOrder = 'SurveyOrder',
  VaccineOrder = 'VaccineOrder',
  VisitFollowUpOrder = 'VisitFollowUpOrder',
}

/**
 * Enum for PatientTaskType states
 */
/** @deprecated - Use the generated types in graphql.types.ts */
export enum PatientTaskState {
  assigned = 'assigned',
  cancelled = 'cancelled',
  completed = 'completed',
  declined = 'declined',
  expired = 'expired',
}

/** @deprecated - Use the generated types in graphql.types.ts */
export enum PhoneNumberKind {
  back_office = 'back_office',
  fax = 'fax',
  home = 'home',
  mobile = 'mobile',
  other = 'other',
  pager = 'pager',
  patient_assistance = 'patient_assistance',
  work = 'work',
}

/** @deprecated - Use the generated types in graphql.types.ts */
export enum SendOrReceive {
  RECEIVE = 'RECEIVE',
  SEND = 'SEND',
}

/** @deprecated - Use the generated types in graphql.types.ts */
export enum SurveyAnswerCheckboxCustomBehavior {
  default_selected = 'default_selected',
  select_all = 'select_all',
  select_all_excluded = 'select_all_excluded',
  select_none = 'select_none',
}

/** @deprecated - Use the generated types in graphql.types.ts */
export enum SurveyAnswerDatePickerCustomBehavior {
  future = 'future',
  month_year_only = 'month_year_only',
  past = 'past',
}

/** @deprecated - Use the generated types in graphql.types.ts */
export enum SurveyAnswerKind {
  binary_radio = 'binary_radio',
  boolean = 'boolean',
  checkbox = 'checkbox',
  date_picker = 'date_picker',
  date_range = 'date_range',
  date_string = 'date_string',
  free_text = 'free_text',
  fsa_hsa_grid_input = 'fsa_hsa_grid_input',
  horizontal_radio = 'horizontal_radio',
  multi_select = 'multi_select',
  radio = 'radio',
  scale = 'scale',
  select = 'select',
}

/** @deprecated - Use the generated types in graphql.types.ts */
export enum TimelinePostSort {
  RecentActivity = 'RecentActivity',
}

/**
 * Autogenerated input type of AcceptFamilyAccountInvitation
 */
/** @deprecated - Use the generated types in graphql.types.ts */
export interface AcceptFamilyAccountInvitationInput {
  id: string;
  clientMutationId?: string | null;
}

/** @deprecated - Use the generated types in graphql.types.ts */
export interface AddressAttributes {
  id?: string | null;
  kind?: AddressKind | null;
  address1?: string | null;
  address2?: string | null;
  crossStreet?: string | null;
  cross_street?: string | null;
  city?: string | null;
  stateCode?: string | null;
  state_code?: string | null;
  zip?: string | null;
  _destroy?: (boolean | null)[] | null;
}

/** @deprecated - Use the generated types in graphql.types.ts */
export interface BookAppointmentAttributes {
  appointmentTypeId: string;
  inventoryId: string;
  sendSms?: boolean | null;
  smsNumber?: string | null;
  reason?: string | null;
}

/**
 * Autogenerated input type of BookAppointment
 */
/** @deprecated - Use the generated types in graphql.types.ts */
export interface BookAppointmentInput {
  appointmentAttributes: BookAppointmentAttributes;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CancelAppointment
 */
/** @deprecated - Use the generated types in graphql.types.ts */
export interface CancelAppointmentInput {
  id: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CancelPrescriptionRenewalRequests
 */
/** @deprecated - Use the generated types in graphql.types.ts */
export interface CancelPrescriptionRenewalRequestsInput {
  patientMedicationIds: string[];
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of ChangePharmacyForPrescriptionRenewalRequests
 */
/** @deprecated - Use the generated types in graphql.types.ts */
export interface ChangePharmacyForPrescriptionRenewalRequestsInput {
  patientPharmacyId: string;
  patientMedicationIds: string[];
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CreateCustomPharmacy
 */
/** @deprecated - Use the generated types in graphql.types.ts */
export interface CreateCustomPharmacyInput {
  isMailOrder?: boolean | null;
  name: string;
  phoneNumbersAttributes: PhoneNumberAttributes;
  addressAttributes: AddressAttributes;
  customerCode?: string | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CreateFamilyAccountInvitation
 */
/** @deprecated - Use the generated types in graphql.types.ts */
export interface CreateFamilyAccountInvitationInput {
  fullName: string;
  email: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CreateHealthInformationReleaseAuthorization
 */
/** @deprecated - Use the generated types in graphql.types.ts */
export interface CreateHealthInformationReleaseAuthorizationInput {
  recipientTelephone: string;
  documentVersion: string;
  expiresAt?: any | null;
  signatureText: string;
  signedAt: any;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CreatePatientPharmacy
 */
/** @deprecated - Use the generated types in graphql.types.ts */
export interface CreatePatientPharmacyInput {
  pharmacyId: string;
  pharmacy_id?: string | null;
  customerCode?: string | null;
  clientMutationId?: string | null;
}

/** @deprecated - Use the generated types in graphql.types.ts */
export interface CreatePrescriptionRenewalRequestAttributes {
  patientMedicationId: string;
  comment?: string | null;
}

/**
 * Autogenerated input type of CreatePrescriptionRenewalRequests
 */
/** @deprecated - Use the generated types in graphql.types.ts */
export interface CreatePrescriptionRenewalRequestsInput {
  patientPharmacyId: string;
  createPrescriptionRenewalRequestAttributes: CreatePrescriptionRenewalRequestAttributes[];
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of DeclineFamilyAccountInvitation
 */
/** @deprecated - Use the generated types in graphql.types.ts */
export interface DeclineFamilyAccountInvitationInput {
  id: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of DeleteTimelineCommentDraft
 */
/** @deprecated - Use the generated types in graphql.types.ts */
export interface DeleteTimelineCommentDraftInput {
  id: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of DeleteTimelinePostDraft
 */
/** @deprecated - Use the generated types in graphql.types.ts */
export interface DeleteTimelinePostDraftInput {
  id: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of MarkAllUserNotificationsAsRead
 */
/** @deprecated - Use the generated types in graphql.types.ts */
export interface MarkAllUserNotificationsAsReadInput {
  postId?: string | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of MarkAsLateToAppointment
 */
/** @deprecated - Use the generated types in graphql.types.ts */
export interface MarkAsLateToAppointmentInput {
  id: string;
  clientMutationId?: string | null;
}

/** @deprecated - Use the generated types in graphql.types.ts */
export interface PatientAddressInputObject {
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  id?: string | null;
  isPreferred?: boolean | null;
  state?: string | null;
  zip?: string | null;
  destroy?: boolean | null;
}

/** @deprecated - Use the generated types in graphql.types.ts */
export interface PatientPhoneNumberInputObject {
  id?: string | null;
  ext?: string | null;
  isPreferred?: boolean | null;
  kind?: PatientPhoneNumberKind | null;
  number?: string | null;
  _destroy?: boolean | null;
}

/** @deprecated - Use the generated types in graphql.types.ts */
export interface PatientSurveyAnswer {
  answerId?: string | null;
  answerText?: string | null;
  kind?: SurveyAnswerKind | null;
  surveyQuestionId?: string | null;
}

/** @deprecated - Use the generated types in graphql.types.ts */
export interface PhoneNumberAttributes {
  id?: string | null;
  kind?: PhoneNumberKind | null;
  number?: string | null;
  ext?: string | null;
  _destroy?: (boolean | null)[] | null;
}

/** @deprecated - Use the generated types in graphql.types.ts */
export interface RescheduleAppointmentAttributes {
  appointmentTypeId: string;
  sendSms?: boolean | null;
  smsNumber?: string | null;
  inventoryId: string;
}

/**
 * Autogenerated input type of RescheduleAppointment
 */
/** @deprecated - Use the generated types in graphql.types.ts */
export interface RescheduleAppointmentInput {
  fromAppointmentId: string;
  newAppointmentAttributes: RescheduleAppointmentAttributes;
  appointmentCancellationReasonId?: string | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of SendAppLink
 */
/** @deprecated - Use the generated types in graphql.types.ts */
export interface SendAppLinkInput {
  phoneNumber: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of UnlinkFamilyAccountMember
 */
/** @deprecated - Use the generated types in graphql.types.ts */
export interface UnlinkFamilyAccountMemberInput {
  familyAccountMemberId: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of UpdateRegistrationWaitlistEnrollment
 */
/** @deprecated - Use the generated types in graphql.types.ts */
export interface UpdateRegistrationWaitlistEnrollmentInput {
  patientId: string;
  serviceAreaCode: string;
  clientMutationId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
