<div class="card hide-border-bottom" @enter [@cardContent]="pageState">
  <ng-container *ngIf="isSuccessState(pageState)">
    <div class="card-header" *ngIf="header" @enter>
      <ng-template [ngTemplateOutlet]="header.templateRef"></ng-template>
    </div>
    <div class="card-body" @enter>
      <div *ngFor="let row of rows" class="card-row">
        <div class="col-4 text-sm-end">
          <strong> <ng-template [ngTemplateOutlet]="row.key.templateRef"></ng-template> </strong>
        </div>
        <div class="col"> <ng-template [ngTemplateOutlet]="row.value.templateRef"></ng-template> </div>
      </div>
    </div>
  </ng-container>
</div>
