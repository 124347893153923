import { Component } from '@angular/core';

@Component({
  selector: 'om-svg-profile',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15 9C15 10.6569 13.6569 12 12 12C10.3431 12 9 10.6569 9 9C9 7.34315 10.3431 6 12 6C13.6569 6 15 7.34315 15 9ZM14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9Z"
      />
      <path
        fill="currentColor"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 12C22 14.8208 20.832 17.3689 18.9533 19.1869L18.8994 19.2387C18.615 19.5098 18.3147 19.7643 18 20.0007C16.3287 21.2561 14.2512 22 12 22C9.7132 22 7.60567 21.2324 5.92103 19.9408C5.61517 19.7063 5.32325 19.4545 5.04672 19.1869C3.16798 17.3689 2 14.8209 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 21C14.2487 21 16.3048 20.1753 17.8824 18.8118C17.331 16.0672 14.9069 14 12 14C9.09309 14 6.66901 16.0672 6.11765 18.8118C7.69516 20.1753 9.75127 21 12 21ZM12 13C15.1705 13 17.8486 15.1078 18.7097 17.9985C20.134 16.4064 21 14.3043 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 14.3043 3.86602 16.4064 5.29034 17.9985C6.15137 15.1078 8.82954 13 12 13Z"
      />
    </svg>
  `,
})
export class SvgProfileComponent {}
