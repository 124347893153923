export enum ProviderType {
  primaryCareTeam,
  anyAvailableProvider,
  specificProvider,
  specialtyServices,
}

export class ProviderTypeUtil {
  private static appointmentCategoryNameMap: Map<ProviderType, string> = new Map([
    [ProviderType.specificProvider, 'primary'],
    [ProviderType.primaryCareTeam, 'primary'],
    [ProviderType.anyAvailableProvider, 'urgent'],
    [ProviderType.specialtyServices, 'specialty'],
  ]);

  private static appointmentCategoryDescriptionMap: Map<ProviderType, string> = new Map([
    [ProviderType.specificProvider, 'My Primary Care Team'],
    [ProviderType.primaryCareTeam, 'My Primary Care Team'],
    [ProviderType.anyAvailableProvider, 'Any Available Provider'],
    [ProviderType.specialtyServices, 'A Specialty Services Provider'],
  ]);

  static getTypeCategory(providerType: ProviderType): string {
    return ProviderTypeUtil.appointmentCategoryNameMap.get(providerType);
  }

  static getTypeDescription(providerType: ProviderType): string {
    return ProviderTypeUtil.appointmentCategoryDescriptionMap.get(providerType);
  }
}
