import { Component, HostBinding, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';

export interface ToggleOption {
  label: string;
  value: any;
}

@Component({
  selector: 'om-toggle',
  templateUrl: './toggle.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ToggleComponent,
      multi: true,
    },
  ],
})
export class ToggleComponent implements ControlValueAccessor {
  @Input() classes = '';
  @HostBinding('class') get hostClasses() {
    return this.classes.concat(' btn-group');
  }

  @Input() options: ToggleOption[];
  @Input() focusOnInit = false;
  formControl = new UntypedFormControl('');
  focused = this.focusOnInit;
  onTouched = () => {};

  isSelected(value: ToggleOption['value']) {
    return this.formControl.value === value;
  }

  isFocused(value: ToggleOption['value']) {
    return this.formControl.value === value && this.focused;
  }

  focus() {
    this.focused = true;
  }

  blur() {
    this.focused = false;
  }

  writeValue(value: ToggleOption['value']) {
    this.formControl.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (value: ToggleOption['value']) => void) {
    this.formControl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  markAsTouchedAndDirty() {
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
  }

  get valid() {
    return this.formControl.value !== '';
  }
}
