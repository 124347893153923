import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

import {
  AssignMembershipGroupSeat,
  AssignMembershipGroupSeatVariables,
} from '@app/registration/login-with-amazon/__generated__/assign-membership-group-seat-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class AssignMembershipGroupSeatGraphqlService extends Mutation<
  AssignMembershipGroupSeat,
  AssignMembershipGroupSeatVariables
> {
  document = gql`
    mutation AssignMembershipGroupSeat($input: AssignMembershipGroupSeatInput!) {
      assignMembershipGroupSeat(input: $input) {
        success
        mutationErrors {
          messages
          path
        }
      }
    }
  `;
}
