<div class="legal-document">
  <header class="doc-title d-flex position-relative justify-content-between" [class.signed]="signed">
    <button
      [id]="docToggleId"
      class="leading-chevron btn btn-link p-0 me-1"
      [class.rotated]="!collapsed"
      [attr.aria-expanded]="!collapsed"
      [attr.aria-controls]="docContentId"
      (keyup.space)="toggleDoc($event)"
      (keyup.spacebar)="toggleDoc($event)"
      (click)="toggleDoc($event)"
      data-cy="accordion-toggle"
    >
      <span>{{ doc.detailHint }}</span>
    </button>
    <button
      class="checkbox-lookalike"
      [class.checked]="signed"
      role="checkbox"
      [disabled]="!enabled"
      [attr.aria-checked]="signed"
      [attr.aria-readonly]="signed"
      [attr.aria-label]="'Sign ' + doc.detailHint"
      (click)="signDoc($event)"
      data-cy="sign-document"
    >
      <span class="disabled-unselected" *ngIf="!enabled" [class.disabled]="signed"></span>
      <span class="checkmark" *ngIf="!processing && enabled" [class.disabled]="signed"></span>
      <om-logo-spinner class="spinner" *ngIf="processing" size="28" color="#068466"></om-logo-spinner>
    </button>
  </header>
  <section
    [id]="docContentId"
    class="doc-content"
    [class.collapsed]="collapsed"
    [attr.aria-labelledby]="docToggleId"
    tabindex="0"
    data-cy="doc-content"
  >
    <div class="p-3" [innerHTML]="trustedText"></div>
  </section>
</div>
