<div class="container g-0 p-0" data-cy="provider-inventory-block">
  <div class="row">
    <div
      class="col-md-2 p-0 d-flex flex-column align-items-center mb-4 mb-md-0"
      [class.col-md-3]="size == 'big'"
      [class.mx-4]="size == 'big'"
      [class.my-5]="size == 'big'"
      [class.justify-content-center]="size == 'big'"
    >
      <omgui-profile-bubble
        [profile]="provider"
        [size]="photoSize"
        [status]="profileBubbleState"
        data-cy="remote-inventory-provider-photo"
      ></omgui-profile-bubble>
    </div>
    <div class="d-md-none">
      <omgui-badge [text]="badgeText" [size]="badgeSize"></omgui-badge>
    </div>
    <div data-cy="remote-provider-detail" class="col-md-8 d-flex flex-column justify-content-center">
      <omgui-badge [text]="badgeText" [size]="badgeSize" class="d-none d-md-block"> </omgui-badge>
      <h4 class="align-self-baseline fw-bold m-0">{{ provider.displayName }}</h4>
      <p *ngIf="office?.name" class="small-font">Practices at {{ office.name }}</p>
      <p *ngIf="!seeMoreBiographySelected && biography" class="small-font">
        {{ biography | excerpt: 110 }}
        <button
          class="btn btn-link border-top-width: 0 px-0 pt-0 inline-button underline-link small-font"
          (click)="displayFullBiography()"
        >
          Read more
        </button>
      </p>
      <p *ngIf="seeMoreBiographySelected && biography" class="small-font">
        {{ biography }}
        <button
          class="btn btn-link border-top-width: 0 px-0 pt-0 inline-button underline-link small-font"
          (click)="displayLessBiography()"
        >
          Read less
        </button>
      </p>
      <h6 class="p-0">{{ firstAvailableDayText }} &bull; Book a Remote Visit</h6>
      <p class="small-font mb-0">Scheduled remote visits are billed just like in-office appointments</p>
      <div class="d-flex flex-row flex-wrap flex-md-nowrap flex-grow-0">
        <ng-container *ngFor="let slot of firstDaysFirstFiveInventorySlots">
          <button
            class="btn btn-primary btn-sm inventory-btn me-2 mt-2 flex-shrink-0"
            data-cy="remote-inventory-button"
            (click)="selectAppointment(slot)"
            [attr.aria-label]="slot.start_time | dateFormat: 'EEEE MM dd h mm a':slot.timezone"
          >
            {{ slot.start_time | dateFormat: 'h:mmaaa':slot.timezone }}
          </button>
        </ng-container>
        <button
          class="btn btn-primary btn-sm inventory-btn me-2 mt-2 flex-shrink-0"
          data-cy="remote-inventory-more-button"
          (click)="remoteSearch()"
        >
          More
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #appointmentConfirmationModal let-dismiss="dismiss">
  <om-appointment-confirmation-modal
    *ngIf="selectedInventory"
    [selectedInventory]="selectedInventory"
    [provider]="provider"
    [office]="office"
    [analyticsService]="analyticsService"
    (modalClosed)="deselectAppointment()"
  >
  </om-appointment-confirmation-modal>
</ng-template>
