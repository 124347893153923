import { Component, ContentChild, Directive, Input, TemplateRef } from '@angular/core';

import { isSuccessState, PageState } from '@app/pipes/page-state/page-state';

import { cardContentAnimation, enterAnimation } from '../animations';

@Directive({
  selector: '[om-info-card-header]',
})
export class InfoCardHeaderDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: '[om-info-card-body]',
})
export class InfoCardBodyDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
  selector: 'om-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['../../shared/card.scss'],
  animations: [enterAnimation, cardContentAnimation],
})
export class InfoCardComponent {
  @ContentChild(InfoCardHeaderDirective) header: InfoCardHeaderDirective;
  @ContentChild(InfoCardBodyDirective) body: InfoCardBodyDirective;
  @Input() pageState: PageState = PageState.SUCCESS;
  isSuccessState = isSuccessState;
}
