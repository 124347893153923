import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

import {
  SignLegalDocument,
  SignLegalDocumentVariables,
} from '@app/core/legal-documents/__generated__/sign-legal-doc-graphql.service.types';

/**
 * GraphQL mutation that signs a legal document ({TermsOfService}).
 */

@Injectable()
export class SignLegalDocGraphQL extends Mutation<SignLegalDocument, SignLegalDocumentVariables> {
  document = gql`
    mutation SignLegalDocument($type: String!) {
      signLegalDocument(input: { type: $type }) {
        success
      }
    }
  `;
}
