<div class="container h-100">
  <div class="col-12 my-3 p-0">
    <img class="w-100" src="/assets/images/peds-service-area-alt.png" role="presentation" />
  </div>
  <h2 class="mb-4 mobile-h2">
    Your family’s
    <br />
    membership is covered
  </h2>
  <ul class="checkmark-bullet-list col-12 ps-4 mb-3">
    <li class="ps-2"> Your benefit includes memberships for your family — at no cost to you. </li>
    <li class="ps-2 mt-3"> Sign them up so they’ll have access to exceptional care, 24/7. </li>
  </ul>
  <div class="col-12 fixed-bottom mb-3">
    <a
      [routerLink]="linksService.referrals"
      class="btn btn-block btn-primary"
      data-cy="add-family"
      aria-label="signup family"
    >
      Add family
    </a>
  </div>
</div>
