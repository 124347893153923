import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { LinksService } from '@app/core/links.service';
import { MODULE_ENTERPRISE_REGISTRATION_CONFIRMATION_PAGE } from '@app/core/mixpanel.constants';

import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { StepName } from '../registration-step-name';
import { RegistrationStepDirective } from '../registration-step.directive';

@Component({
  selector: 'om-success-step',
  templateUrl: './success-step.component.html',
})
export class SuccessStepComponent extends RegistrationStepDirective {
  @Input() isNewPatient = true;
  @Input() isWhitelisted: boolean;
  @Input() module: string;
  @Input() patientFirstName: string;
  @Input() patientEmail: string;
  @Input() showDirectSignup = true;
  @Input() shouldRedirectToConsentForm: boolean;
  @Input() isOMNow: boolean;
  @Output() nextStepSelected = new EventEmitter<StepName>();

  constructor(
    private links: LinksService,
    private router: Router,
    private analytics: EnterpriseRegistrationAnalyticsService,
  ) {
    super();
  }

  redirectToHomePage() {
    this.analytics.redirectToHomeClicked({ module: this.module, isWhitelist: this.isWhitelisted });
    window.location.href = `${this.links.home}`;
  }

  redirectToStandaloneDirectSignupPage() {
    this.analytics.directSignupStartedPostRegistration();
    this.router.navigateByUrl(this.links.referrals);
  }

  trackConsentFormClicked = () =>
    this.analytics.redirectToConsentFormClicked({
      module: this.module,
      isWhitelist: this.isWhitelisted,
    });

  goToDirectSignup() {
    if (this.isOMNow) {
      return this.redirectToStandaloneDirectSignupPage();
    }
    return this.goToStep(StepName.directSignup);
  }

  skipDirectSignup() {
    if (this.isOMNow) {
      this.redirectToHomePage();
    } else {
      window.location.href = `${this.links.onboarding}?source=${MODULE_ENTERPRISE_REGISTRATION_CONFIRMATION_PAGE}`;
    }
  }

  private goToStep(step: StepName) {
    this.nextStepSelected.emit(step);
    this.submit.emit();
  }
}
