import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import noop from 'lodash-es/noop';
import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, filter, map, take, tap } from 'rxjs/operators';

import { ApiService } from '@app/core/api.service';
import { User } from '@app/shared/user';

import { Coupon } from './coupon';

export interface ApiV2Coupon {
  amount_off: number;
  coupon_id: string;
  message: string;
  percent_off: number;
  valid: boolean;
  trial_days: number;
  skip_cc: boolean;
  deactivate_at_trial_end: boolean;
  type?: string;
}

interface ApiV2CreateResponse {
  message: string;
  coupon: ApiV2Coupon;
}

@Injectable()
export class CouponService {
  private _currentCoupon$ = new BehaviorSubject<Coupon>(null);
  readonly currentCoupon$ = this._currentCoupon$.asObservable().pipe(filter(coupon => coupon !== null));

  constructor(private apiService: ApiService) {}

  getCurrentCoupon(force = false): Observable<any> {
    if (!(force || this._currentCoupon$.getValue() === null)) {
      return this._currentCoupon$.pipe(take(1));
    }

    const request = this.apiService.get('/api/v2/patient/memberships/coupon').pipe(
      map((response: ApiV2Coupon | {}) => Coupon.fromApiV2(response)),
      tap((coupon: Coupon) => this._currentCoupon$.next(coupon)),
    );

    request.subscribe({ error: noop });

    return request;
  }

  setCoupon(params: Record<string, any>): Observable<any> {
    const request = this.apiService.post('/api/v2/patient/memberships/coupon', params).pipe(
      map((response: ApiV2CreateResponse) => Coupon.fromApiV2(response.coupon)),
      tap((coupon: Coupon) => this._currentCoupon$.next(coupon)),
      catchError((response: HttpErrorResponse) => {
        let message = 'Error finding coupon code.';
        if (response.status === 422 && response.error.message.length) {
          message = response.error.message;
        }

        return observableThrowError(message);
      }),
    );

    request.subscribe({ error: noop });

    return request;
  }

  validateCoupon(coupon: string, user?: User): Observable<any> {
    return this.apiService
      .get('/api/v2/stripe_coupons/validate_coupon', true, {
        coupon_id: coupon,
        async: false,
        patient_id: user ? user.id : '',
      })
      .pipe(
        map((response: any) => Coupon.fromApiV2(response)),
        catchError((response: HttpErrorResponse) => {
          let message = 'Error finding coupon code.';
          if (response.status === 422 && response.error && response.error.valid === false && response.error.message) {
            message = response.error.message;
          }
          return observableThrowError(message);
        }),
      );
  }
}
