import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NavigatorService {
  get locationEnabled(): boolean {
    return !!navigator.geolocation;
  }

  getCurrentPosition(
    successCallback: PositionCallback,
    errorCallback?: PositionErrorCallback,
    options?: PositionOptions,
  ): void {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options);
  }

  // If not using while tab active, check for permission first - https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText
  writeToClipboard(text: string): Promise<void> {
    return navigator.clipboard.writeText(text);
  }
}
