import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-logo-spinner',
  providers: [],
  template: `
    <div class="mx-auto my-0" [ngStyle]="{ height: size + 'px', width: size + 'px' }">
      <div class="om-logo-spinner">
        <i *ngFor="let x of ' '.repeat(5).split('')" [ngStyle]="{ background: color }"></i>
      </div>
    </div>
  `,
  styleUrls: ['./logo-spinner.component.scss'],
})
export class LogoSpinner {
  @Input() size = '50';
  @Input() color = '#fff';

  constructor() {}
}
