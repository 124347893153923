import isNil from 'lodash-es/isNil';

function sanitizeParams(params: Record<string, string>): Record<string, string> {
  const sanitizedParams: Record<string, string> = {};

  Object.keys(params).map(key => {
    if (params[key].length > 0) {
      sanitizedParams[key] = params[key];
    }
  });

  return sanitizedParams;
}

function constructQueryParams(params: string): string {
  if (!params || params === '') {
    return '';
  }

  return `?${params}`;
}

export function appendQueryParamsToPath(path?: string | null, options: Record<string, string> = {}): string | null {
  if (isNil(path)) {
    return null;
  }

  const paramsIndex = path.indexOf('?');
  const newParams = new URLSearchParams(sanitizeParams(options));

  if (paramsIndex <= 0) {
    return path + constructQueryParams(newParams.toString());
  }

  const currentParams = new URLSearchParams(path.substring(paramsIndex));
  const mergedParams = new URLSearchParams({
    ...Object.fromEntries(currentParams),
    ...Object.fromEntries(newParams),
  }).toString();

  const pathWithoutQueryParams = path.substring(0, paramsIndex);

  return pathWithoutQueryParams + constructQueryParams(mergedParams);
}
