import { Params } from '@angular/router';
import * as fromRouter from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import * as fromAuth from '@app/auth/auth.reducer';
import * as fromAnalytics from '@app/core/analytics.reducer';
import * as fromFeatureFlag from '@app/core/feature-flags/feature-flag.reducer';
import * as fromSurveys from '@app/core/surveys.reducer';
import * as fromOnboardingPrompts from '@app/onboarding/onboarding.reducer';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

export interface State {
  analytics: fromAnalytics.AnalyticsState;
  auth: fromAuth.IAuthState;
  router: fromRouter.RouterReducerState<RouterStateUrl>;
  featureFlags: fromFeatureFlag.FeatureFlagState;
  surveys: fromSurveys.Survey[];
  onboardingPrompts: fromOnboardingPrompts.OnboardingPromptsState;
}

export const reducers: ActionReducerMap<State> = {
  analytics: fromAnalytics.reducer,
  auth: fromAuth.reducer,
  router: fromRouter.routerReducer,
  featureFlags: fromFeatureFlag.reducer,
  surveys: fromSurveys.reducer,
  onboardingPrompts: fromOnboardingPrompts.reducer,
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({ keys: ['auth', 'expedited', 'register', 'directSignUpComplete'], rehydrate: true })(
    reducer,
  );
}

export const metaReducers: MetaReducer<State>[] = [];

export const selectRouter = createFeatureSelector<fromRouter.RouterReducerState<any>>('router');
