import { Injector, NgModule } from '@angular/core';

@NgModule({})
export class InjectorModule {
  static injector: Injector;

  // So we can, if necessary, directly access an injectable
  constructor(injector: Injector) {
    InjectorModule.injector = injector;
  }
}
