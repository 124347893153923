import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';

import { MapsApiLoaderService } from '../maps-api-loader.service';
import { MapStyles } from './map-styles';

@Component({
  selector: 'om-styled-map',
  templateUrl: './styled-map.component.html',
})
export class StyledMapComponent implements OnInit, OnChanges {
  @Input() latitude: number;
  @Input() longitude: number;
  @Input() zoom: number;
  @Input() draggable: Boolean;

  styles = MapStyles.MARKETING_MAP_STYLE;
  icon = '/assets/images/icons/pin-icon.svg';
  apiLoaded$: Observable<boolean>;
  googleMapOptions: google.maps.MapOptions;

  constructor(private googleMapLoader: MapsApiLoaderService) {}

  ngOnInit() {
    this.apiLoaded$ = this.googleMapLoader.load$();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.zoom || changes.latitude || changes.longitude || changes.draggable) {
      // 'draggable' is deprecated in google maps' API
      // https://jira.onemedical.com/browse/NS-1100
      const draggable = changes.draggable?.currentValue || this.draggable;
      const gestureHandling: google.maps.MapOptions['gestureHandling'] = draggable ? 'cooperative' : 'none';

      this.googleMapOptions = {
        styles: MapStyles.MARKETING_MAP_STYLE,
        streetViewControl: false,
        disableDefaultUI: true,
        zoom: changes.zoom.currentValue || this.zoom,
        center: {
          lat: changes.latitude.currentValue || this.latitude,
          lng: changes.longitude.currentValue || this.longitude,
        },
        gestureHandling,
      };
    }
  }
}
