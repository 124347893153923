<ng-template #cardContent>
  <div class="container">
    <div class="row">
      <div class="card text-center py-lg-4 py-2 d-flex col" [class.row-reverse]="text">
        <div class="svg-container" [class.needs-padding]="!text"><ng-template om-card-svg></ng-template></div>

        <div class="card-body">
          <p class="card-title m-0">{{ title }}</p>
          <p class="mt-lg-2 mt-1 mb-0" data-cy="card-text" *ngIf="text">{{ text }}</p>
          <p class="card-details" *ngIf="details">{{ details }}</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="routerLink; then routerLinkWrapper; else hrefWrapper"></ng-container>

<ng-template #routerLinkWrapper>
  <a class="om-card-link" [routerLink]="routerLink" [omTrackLink]="trackLink">
    <ng-container *ngTemplateOutlet="cardContent"></ng-container>
  </a>
</ng-template>

<ng-template #hrefWrapper>
  <a class="om-card-link" [href]="link" [omTrackLink]="trackLink">
    <ng-container *ngTemplateOutlet="cardContent"></ng-container>
  </a>
</ng-template>
