import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

import { MembershipUpdateToPrepaidConfirmationEdge } from '@app/registration/graph/edges/__generated__/membership-update-to-prepaid-confirmation-edge-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class MembershipUpdateToPrepaidConfirmationEdgeGraphqlService extends Query<
  MembershipUpdateToPrepaidConfirmationEdge
> {
  document = gql`
    query MembershipUpdateToPrepaidConfirmationEdge {
      membership {
        id
        planType
      }
    }
  `;
}
