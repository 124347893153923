import { Component, OnInit } from '@angular/core';

import { FlashService, MessageType } from '@app/shared/flash.service';
import { BannerType } from '@omgui/omgui-banner/omgui-banner.component';

@Component({
  selector: 'om-flash',
  templateUrl: './flash.component.html',
  styleUrls: ['./flash.component.scss'],
})
export class FlashComponent implements OnInit {
  errors: string[] = [];
  alerts: string[] = [];
  notices: string[] = [];
  successes: string[] = [];

  readonly BannerType = BannerType;

  constructor(private flashService: FlashService) {}

  ngOnInit() {
    this.flashService.messages$.subscribe(data => {
      this.errors = data[MessageType.ERROR];
      this.alerts = data[MessageType.ALERT];
      this.notices = data[MessageType.NOTICE];
      this.successes = data[MessageType.SUCCESS];
    });
  }

  removeError(message: string) {
    this.errors.splice(this.errors.indexOf(message), 1);
  }

  removeAlert(message: string) {
    this.alerts.splice(this.alerts.indexOf(message), 1);
  }

  removeNotice(message: string) {
    this.notices.splice(this.notices.indexOf(message), 1);
  }

  removeSuccess(message: string) {
    this.successes.splice(this.successes.indexOf(message), 1);
  }
}
