import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
})
export class ConfirmationModalComponent {
  @Input() title: string;
  @Input() body: string;
  @Input() close: (confirmed: boolean) => void;
}
