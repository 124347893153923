import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

import { PatientUiStartToServiceAreEdge } from '@app/registration/graph/edges/__generated__/start-to-service-area-edge-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class StartToServiceAreaEdgeGraphqlService extends Query<PatientUiStartToServiceAreEdge> {
  document = gql`
    query PatientUIStartToServiceAreEdge {
      patient {
        id
        serviceArea {
          id
        }
      }
    }
  `;
}
