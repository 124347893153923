import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Directive()
export abstract class RegistrationStepDirective {
  @Input() form: UntypedFormGroup;
  @Input() isLoggedIn = false;
  @Output() login: EventEmitter<any> = new EventEmitter<any>();
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  submitting = false;

  onError(_err: Error) {
    this.submitting = false;
  }
}
