import { Boxed, unbox, ValidationFn } from 'ngrx-forms';

export interface NoSequentialCharactersValidationErrorExtension {
  noSequentialCharacters?: {
    value: string;
    detectedSequence: string;
  };
}

// Extends the ValidationErrors interface from ngrx-forms. Tells Typescript that ValidationErrors#noSequentialCharacters is possible.
declare module 'ngrx-forms/src/state' {
  export interface ValidationErrors extends NoSequentialCharactersValidationErrorExtension {}
}

const minimumDetectableSequenceLength = 2;
const alphanumericPattern = /[A-Za-z0-9]/;

/**
 * A validation function that invalidates input if the number of sequential
 * characters is equal to or greater than `disallowedSequenceLength`
 * (e.g. disallowedSequenceLength = 3 and 'abc' or '123' as well as reverse direction - '321' or 'cba') are detected.
 *
 * The validation error returned by this validation function has the following shape:
 *
 ```typescript
 {
   noSequentialCharacters: {
     value: string;
     detectedSequence: string; // The shortest and first sequence found
   };
 }
 ```
 *
 * Usually you would use this validation function in conjunction with the `validate`
 * update function to perform synchronous validation in your reducer:
 *
 ```typescript
 updateGroup<MyFormValue>({
   name: validate(noSequentialCharacters(3)),
 })
 ```
 */
export function noSequentialCharacters(minimumInvalidSequenceLength: number): ValidationFn<string | Boxed<string>> {
  if (
    !Number.isInteger(minimumInvalidSequenceLength) ||
    minimumInvalidSequenceLength < minimumDetectableSequenceLength
  ) {
    throw new Error(`Invalid argument: cannot check for a sequence with length: ${minimumInvalidSequenceLength}!`);
  }

  return (input: string | Boxed<string>): NoSequentialCharactersValidationErrorExtension => {
    const value = unbox(input) || '';
    if (value.length < minimumInvalidSequenceLength) {
      return {};
    }

    const detectedSequence =
      findIncrementingSequence(value, minimumInvalidSequenceLength) ||
      findDecrementingSequence(value, minimumInvalidSequenceLength);

    return detectedSequence ? { noSequentialCharacters: { value: value, detectedSequence } } : {};
  };
}

function findIncrementingSequence(value: string, minimumInvalidSequenceLength: number): string {
  const startingIndex = value.search(alphanumericPattern);
  let sequence = value.charAt(startingIndex);

  for (let i = startingIndex + 1; i < value.length; i++) {
    const nextCharacter = value.charAt(i);
    sequence += nextCharacter;

    if (!hasAlphanumeric(nextCharacter) || !isLastTwoCharactersIncrementingSequence(sequence)) {
      sequence = nextCharacter;
    } else if (sequence.length === minimumInvalidSequenceLength) {
      break;
    }
  }

  return sequence.length === minimumInvalidSequenceLength ? sequence : '';
}

function findDecrementingSequence(value: string, minimumInvalidSequenceLength: number): string {
  const detectedSequence = findIncrementingSequence(reverseString(value), minimumInvalidSequenceLength);
  return reverseString(detectedSequence);
}

function isLastTwoCharactersIncrementingSequence(value: string): boolean {
  const secondToLastCharacterCode = value.charCodeAt(value.length - 2);
  const lastCharacterCode = value.charCodeAt(value.length - 1);
  return secondToLastCharacterCode + 1 === lastCharacterCode;
}

function hasAlphanumeric(character: string): boolean {
  const matches = character.match(alphanumericPattern) || [];
  return matches.length > 0;
}

function reverseString(value: string) {
  return value.split('').reverse().join('');
}
