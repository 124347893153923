export class Discount {
  constructor(public amountOff: number, public percentOff: number) {}

  getDiscountDollarAmount(): any {
    return isNaN(this.amountOff) ? null : this.amountOff;
  }

  getDiscountPercentAmount(): any {
    return isNaN(this.percentOff) ? null : this.percentOff;
  }

  getDiscountString(): string {
    if (this.amountOff) {
      return `$${this.amountOff.toFixed()}`;
    }
    if (this.percentOff) {
      return `${(this.percentOff * 100).toFixed()}%`;
    }
  }
}
