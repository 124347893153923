import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import { combineLatest, Observable, of as observableOf } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import type { MembershipResult, B2bPlan } from '@app/core/__generated__/membership-graphql.service.types';
import { AuthService } from '@app/core/auth.service';
import { MembershipPlan } from '@app/core/membership';
import { MembershipGraphQL } from '@app/core/membership-graphql.service';
import { UserService } from '@app/core/user.service';
import { PrepaidEnrollmentMembershipService } from '@app/membership/prepaid-enrollment-membership.service';
import { EnterpriseRegistration } from '@app/registration/enterprise';
import { CLAIM_CODE_PARAM_KEY } from '@app/shared/hornbill-params';
import { User } from '@app/shared/user';

import { PrepaidEnrollmentService } from '../prepaid-enrollment.service';

export enum PediatricRegistrationTypes {
  Enterprise = 'Enterprise',
  Consumer = 'Consumer',
  PrepaidEnrollment = 'PrepaidEnrollment',
}

export enum PediatricMembershipTypes {
  Enterprise = 'Enterprise',
  Consumer = 'Consumer',
}

@Injectable()
export class PediatricMembershipTypeService {
  constructor(
    private enterpriseRegistration: EnterpriseRegistration,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private membershipGraphQL: MembershipGraphQL,
    private userService: UserService,
    private prepaidEnrollmentService: PrepaidEnrollmentService,
    private prepaidEnrollmentMembershipService: PrepaidEnrollmentMembershipService,
  ) {}

  static isCorePlusEnterprise(membership: MembershipResult['membership']): boolean {
    return !!(
      membership?.planType === MembershipPlan.B2B &&
      membership.plan?.__typename === MembershipPlan.B2B &&
      membership.plan.company?.includesDependent
    );
  }

  get isEnterprise$(): Observable<boolean> {
    if (this.authService.isLoggedIn()) {
      return this.isMembershipCorePlusEnterprise();
    } else if (this.enterpriseRegistration.b2bCompanyId) {
      return this.b2bCompanyIncludesDependent();
    } else {
      return observableOf(false);
    }
  }

  // Returns true if the child enrollment has been prepaid according to the following criteria:
  // - Feature flag is ON
  // - Logged out user with valid claim code OR Logged In user with ability to invite members to group subscription
  get isPrepaidEnrollment$(): Observable<boolean> {
    if (this.authService.isLoggedIn()) {
      return this.prepaidEnrollmentMembershipService.canInviteMembers$();
    } else {
      const claimCode: string = this.activatedRoute.snapshot.queryParams[CLAIM_CODE_PARAM_KEY];
      return !!claimCode
        ? this.prepaidEnrollmentService.isValid$(claimCode).pipe(
            map(data => data),
            catchError(() => observableOf(false)),
          )
        : observableOf(false);
    }
  }

  get pediatricMembershipRegType$(): Observable<PediatricRegistrationTypes> {
    return combineLatest([this.isEnterprise$, this.isPrepaidEnrollment$]).pipe(
      take(1),
      map(([isEnterprise, isPrepaidEnrollment]) => {
        // TODO: error state if both are true
        if (isEnterprise) {
          return PediatricRegistrationTypes.Enterprise;
        } else if (isPrepaidEnrollment) {
          return PediatricRegistrationTypes.PrepaidEnrollment;
        } else {
          return PediatricRegistrationTypes.Consumer;
        }
      }),
    );
  }

  private b2bCompanyIncludesDependent(): Observable<boolean> {
    const { b2bCompany } = this.enterpriseRegistration;
    return observableOf(b2bCompany && b2bCompany.includesDependent && b2bCompany.enterprisePedsRegistrationEnabled);
  }

  private isMembershipCorePlusEnterprise(): Observable<boolean> {
    this.userService.getUser();
    const user$ = this.userService.user$;

    return combineLatest([user$, this.membershipGraphQL.fetch()]).pipe(
      take(1),
      map(([user, membershipResponse]: [User, ApolloQueryResult<MembershipResult>]) => {
        const { membership } = membershipResponse.data;
        const { whitelistedEmployee } = user;
        const pediatricFeatureEnabled =
          (membership?.plan as B2bPlan)?.company?.enterprisePedsRegistrationEnabled || false;

        if (whitelistedEmployee) {
          return PediatricMembershipTypeService.isCorePlusEnterprise(membership);
        }

        return (
          pediatricFeatureEnabled &&
          PediatricMembershipTypeService.isCorePlusEnterprise(membership) &&
          (membership?.plan as B2bPlan)?.hasActiveDiscountTypes
        );
      }),
    );
  }
}
