import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-svg-lab-result',
  template: `
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6601 3V8.65714L17.5679 17.3573C18.333 19.0606 17.1132 21 15.277 21H9.52297C7.68677 21 6.46701 19.0606 7.23213 17.3573L11.1399 8.65714V3"
        [attr.stroke]="color"
      />
      <path d="M11.5 15.6H16.9" [attr.stroke]="color" stroke-width="1.2" />
      <path d="M11.5 13.8H13.3" [attr.stroke]="color" stroke-width="1.2" />
      <path d="M11.5 17.4H13.3" [attr.stroke]="color" stroke-width="1.2" />
      <rect x="9.925" y="3.225" width="1.35" height="0.45" fill="#7A726A" [attr.stroke]="color" stroke-width="0.45" />
      <rect
        x="13.525"
        y="3.225"
        width="1.35"
        height="0.45"
        [attr.fill]="color"
        [attr.stroke]="color"
        stroke-width="0.45"
      />
    </svg>
  `,
})
export class SvgLabResultComponent {
  @Input() color = 'currentColor';

  constructor() {}
}
