export const MENTAL_HEALTH_APPOINTMENT_BOOKED = 'Mental Health Appointment Booked';
export const NOT_COVID_OR_FLU_APPOINTMENT_BOOKED = 'Not Covid or Flu Appointment Booked';
export const MENTAL_HEALTH_ONLY_TOPICS_APPOINTMENT_BOOKED =
  'Mental Health Only Types from BHX Topic Page Appointment Booked';

// Appointment Types as of 10/31/22
export const fluAndCovidAppointmentTypeIds = [
  213,
  214,
  221,
  222,
  230,
  233,
  235,
  249,
  250,
  251,
  256,
  259,
  260,
  262,
  266,
  269,
  274,
  275,
  276,
  277,
  281,
  286,
  297,
  302,
  306,
  315,
  316,
  325,
  327,
  328,
  329,
  330,
  332,
  334,
  335,
  338,
  354,
  356,
  358,
  359,
  360,
  361,
  373,
  377,
  378,
];

// Appointment Types as of 10/31/22
export const onlyMentalHealthTopicAppointmentTypesFromBhxExperiment = [244, 287, 288, 192, 303, 301];

// Mental Health Types as of 10/31/22
export const mentalHealthAppointmentTypes = [126, 192, 244, 287, 288, 290, 291, 301, 303, 311, 322, 370];
