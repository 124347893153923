<om-inline-form
  [inlineForm]="inlineForm"
  [formGroup]="formGroup"
  (formSubmit)="formSubmit.emit($event)"
  (formDelete)="showDeleteConfirmationModal()"
>
  <ng-content></ng-content>
</om-inline-form>

<ng-template #deleteConfirmationModal let-modal>
  <om-confirmation-modal [title]="modalTitle" [body]="modalBody" [close]="modal.close"></om-confirmation-modal>
</ng-template>
