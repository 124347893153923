import { createAction, props } from '@ngrx/store';

export enum OnboardingPromptsActions {
  ShowOnboardingPrompts = '[Onboarding] Show Onboarding Prompts',
  HideOnboardingPrompts = '[Onboarding] Hide Onboarding Prompts',
  OnboardingPromptSelected = '[Onboarding] Onboarding Prompt Selected',
  ClickOnboardingPromptAction = '[Onboarding] Onboarding Action Clicked',
  DismissOnboardingPrompt = '[Onboarding] Onboarding Prompt Dismissed',
  AssignOnboardingPromptExperimentVariation = '[Onboarding] Onboarding Prompt Experiment Variation Assigned',
}

export const assignOnboardingPromptExperimentVariation = createAction(
  OnboardingPromptsActions.AssignOnboardingPromptExperimentVariation,
  props<{ experiment_name: string; variation_name: string }>(),
);

export const showOnboardingPrompts = createAction(OnboardingPromptsActions.ShowOnboardingPrompts);

export const hideOnboardingPrompts = createAction(OnboardingPromptsActions.HideOnboardingPrompts);

export const selectOnboardingPrompt = createAction(
  OnboardingPromptsActions.OnboardingPromptSelected,
  props<{ onboarding_prompt_selected: string }>(),
);

export const clickOnboardingPromptAction = createAction(
  OnboardingPromptsActions.ClickOnboardingPromptAction,
  props<{ action_selected: string; flow: string }>(),
);

export const dismissOnboardingAction = createAction(OnboardingPromptsActions.DismissOnboardingPrompt);
