import { CommonModule } from '@angular/common';
import { Component, ContentChild, Directive, Input, NgModule, TemplateRef } from '@angular/core';

import { PageState } from '@app/pipes/page-state/page-state';
import { PipesModule } from '@app/pipes/pipes.module';
import { OmguiActionComponent, OmguiActionModule } from '@omgui/omgui-action/omgui-action.component';

import { expandCollapseAnimation } from '../../animations';
import { SvgCaretRightModule } from '../../svg';

@Directive({
  selector: '[omgui-summary-row-expandable-content]',
})
export class OmguiSummaryRowExpandableContentDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: '[omgui-summary-row-primary-text]',
})
export class OmguiSummaryRowPrimaryTextDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: '[omgui-summary-row-secondary-text]',
})
export class OmguiSummaryRowSecondaryTextDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: '[omgui-summary-row-tertiary-text]',
})
export class OmguiSummaryRowTertiaryTextDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: '[omgui-summary-row-icon]',
})
export class OmguiSummaryRowIconDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

interface ComponentState {
  linkCaret: boolean;
  hasAction: boolean;
}

@Component({
  selector: 'omgui-summary-row',
  templateUrl: './omgui-summary-row.component.html',
  styleUrls: ['./omgui-summary-row.component.scss'],
  animations: [expandCollapseAnimation],
})
export class OmguiSummaryRowComponent {
  /**
   * @ignore
   */
  private _action: OmguiActionComponent;
  /**
   * @ignore
   */
  private _linkCaret = false;
  @ContentChild(OmguiSummaryRowPrimaryTextDirective) primaryText: OmguiSummaryRowPrimaryTextDirective;
  @ContentChild(OmguiSummaryRowSecondaryTextDirective) secondaryText: OmguiSummaryRowSecondaryTextDirective;
  @ContentChild(OmguiSummaryRowTertiaryTextDirective) tertiaryText: OmguiSummaryRowTertiaryTextDirective;
  @ContentChild(OmguiSummaryRowIconDirective) icon: OmguiSummaryRowIconDirective;
  @ContentChild(OmguiSummaryRowExpandableContentDirective) expandableContent: OmguiSummaryRowExpandableContentDirective;
  @ContentChild(OmguiActionComponent) set action(value: OmguiActionComponent) {
    if (
      this.validateComponentState({
        ...this.getComponentState(),
        hasAction: !!value,
      })
    ) {
      this._action = value;
    }
  }

  get action(): OmguiActionComponent {
    return this._action;
  }

  /** Determines whether to show the loading state or card content **/
  @Input() pageState = PageState.SUCCESS;

  /** Whether or not to show the caret. */
  @Input() set linkCaret(value: boolean) {
    if (
      this.validateComponentState({
        ...this.getComponentState(),
        linkCaret: value,
      })
    ) {
      this._linkCaret = value;
    }
  }

  get linkCaret(): boolean {
    return this._linkCaret;
  }

  /** Whether or not expandable content is showing. */
  @Input() expanded = false;

  constructor() {}

  /**
   * @ignore
   */
  private validateComponentState(state: ComponentState): boolean {
    if (!!state.linkCaret && state.hasAction) {
      console.error('Both link and action button are not supported.');
      return false;
    }

    return true;
  }

  /**
   * @ignore
   */
  private getComponentState(): ComponentState {
    return {
      linkCaret: this.linkCaret,
      hasAction: !!this.action,
    };
  }
}

@NgModule({
  declarations: [
    OmguiSummaryRowPrimaryTextDirective,
    OmguiSummaryRowSecondaryTextDirective,
    OmguiSummaryRowTertiaryTextDirective,
    OmguiSummaryRowIconDirective,
    OmguiSummaryRowExpandableContentDirective,
    OmguiSummaryRowComponent,
  ],
  imports: [SvgCaretRightModule, CommonModule, OmguiActionModule, PipesModule],
  exports: [
    OmguiSummaryRowPrimaryTextDirective,
    OmguiSummaryRowSecondaryTextDirective,
    OmguiSummaryRowTertiaryTextDirective,
    OmguiSummaryRowIconDirective,
    OmguiSummaryRowExpandableContentDirective,
    OmguiSummaryRowComponent,
  ],
})
export class OmguiSummaryRowModule {}
