<div class="modal-wrapper">
  <button type="button" class="close fw-light" aria-label="Close" (click)="modalClose()">
    <omgui-close-x aria-hidden="true"></omgui-close-x>
  </button>
  <img src="/assets/images/patients_lobby.png" class="patients-lobby" aria-hidden="true" />
  <h2>Please try your search again.</h2> <div class="error-message">{{ errorMessage }}</div>
  <div class="actions">
    <button type="button" class="modal-button btn btn-primary fw-light" (click)="modalClose()"> OK </button>
  </div>
</div>
