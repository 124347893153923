import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { merge, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { NativeAppService } from '@app/shared/native-app.service';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  constructor(private route: ActivatedRoute, private router: Router, private nativeAppService: NativeAppService) {}

  shouldHideFooter$(): Observable<boolean> {
    const initialRouteData$ = this.getLeafRoute().data;
    const navigationEndRouteData$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      switchMap(() => this.getLeafRoute().data),
    );

    return merge(initialRouteData$, navigationEndRouteData$).pipe(
      map(routeData => !!routeData.hideFooter || this.nativeAppService.isWebView(this.route)),
    );
  }

  private getLeafRoute(): ActivatedRoute {
    let currentRoute = this.route;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }

    return currentRoute;
  }
}
