<div [ngClass]="{ 'border rounded': !borderless }">
  <h6 class="pt-3 title" *ngIf="title">{{ title }}</h6>

  <ng-container *ngIf="variant === listCardVariants.Responsive">
    <ul class="list-group list-group-flush rounded d-none d-sm-block">
      <li class="list-group-item d-flex align-items-center" data-cy="list-group-item" *ngFor="let item of items">
        <ng-container *ngTemplateOutlet="content; context: { $implicit: item }"></ng-container>
        <ng-container *ngIf="item.data.action?.route">
          <omgui-button
            class="col-3 px-2"
            [variant]="OmguiButtonVariant.secondary"
            [trackLink]="item.data.action.trackingFunction"
            [internalLink]="item.data.action.route"
            [queryParams]="item.data.action.queryParams"
            [size]="OmguiButtonSize.fillParent"
            >{{ item.data.action.label }}</omgui-button
          >
        </ng-container>
        <ng-container *ngIf="item.data.action?.href">
          <omgui-button
            class="col-3 px-2"
            [variant]="OmguiButtonVariant.secondary"
            [trackLink]="item.data.action.trackingFunction"
            [externalLink]="item.path"
            [size]="OmguiButtonSize.fillParent"
            >{{ item.data.action.label }}</omgui-button
          >
        </ng-container>
        <ng-container *ngIf="!item.data.action?.route && !item.data.action?.href && item.data.action?.label">
          <omgui-button
            class="col-3 px-2"
            [variant]="OmguiButtonVariant.secondary"
            (buttonClick)="item.buttonClick.emit($event)"
            [size]="OmguiButtonSize.fillParent"
            >{{ item.data.action.label }}</omgui-button
          >
        </ng-container>
      </li>
    </ul>
  </ng-container>

  <div class="list-group list-group-flush rounded" [ngClass]="{ 'd-sm-none': variant === listCardVariants.Responsive }">
    <ng-container *ngFor="let item of items">
      <ng-container [ngSwitch]="true">
        <ng-container *ngSwitchCase="!!item.data.action?.route">
          <a
            class="list-group-item d-flex align-items-center list-group-item-action"
            [omTrackLink]="item.data.action.trackingFunction"
            [routerLink]="item.data.action.route"
            [queryParams]="item.data.action.queryParams"
            data-cy="list-card-item-compact"
          >
            <ng-container *ngTemplateOutlet="content; context: { $implicit: item }"></ng-container>
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="!!item.data.action?.href">
          <a
            class="list-group-item d-flex align-items-center list-group-item-action"
            [omTrackLink]="item.data.action.trackingFunction"
            [href]="item.path"
            data-cy="list-card-item-compact"
          >
            <ng-container *ngTemplateOutlet="content; context: { $implicit: item }"></ng-container>
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="!item.data.action?.route && !item.data.action?.href && !!item.data.action?.label">
          <button
            class="list-group-item d-flex align-items-center list-group-item-action"
            (click)="item.buttonClick.emit($event)"
            data-cy="list-card-item-compact"
          >
            <ng-container *ngTemplateOutlet="content; context: { $implicit: item }"></ng-container>
          </button>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <div
            class="list-group-item d-flex align-items-center"
            data-cy="list-card-item-compact"
            (click)="item.buttonClick.emit($event)"
          >
            <ng-container *ngTemplateOutlet="content; context: { $implicit: item }"></ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #itemText let-item>
  <div class="flex-grow-1">
    <h6 class="m-0" data-cy="item-label" [class.text-secondary]="item.secondary">{{ item.label }}</h6>
    <span *ngIf="item.sublabel" [ngClass]="item.sublabelClass || bootstrapClass.Muted" data-cy="list-item-sublabel">
      {{ item.sublabel }}
    </span>
  </div>
</ng-template>

<ng-template #content let-item>
  <ng-container *ngTemplateOutlet="item.icon.templateRef"></ng-container>
  <ng-container *ngTemplateOutlet="itemText; context: { $implicit: item.data }"></ng-container>
</ng-template>
