import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ConfigService } from '@app/core/config.service';
import { WindowService } from '@app/utils/window.service';

import { StorageService } from './storage.service';

const PATH_KEY = 'attempted_path';

@Injectable()
export class AttemptedPathService {
  static PATH_KEY = PATH_KEY;

  constructor(
    public config: ConfigService,
    private storageService: StorageService,
    private windowService: WindowService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  setAttemptedPath(path: string) {
    if (path === '/') {
      return;
    }

    this.storageService.setItem(PATH_KEY, encodeURIComponent(path));
  }

  navigateToAttemptedPath() {
    let redirect;
    const path = this.getAttemptedPathData();
    this.deleteAttemptedPath();

    if (path) {
      redirect = path;
    } else if (this.route.snapshot && this.route.snapshot.queryParams.redirect_to) {
      // Please Use setAttempted Path internally, and avoid using this query parmeter for consistent handling
      // this does not play nicely with auth0 and is a second code path to achieve the same results as the login-guard clause already does.
      // TODO: Remove this logic branch when we confirm it is no longer used
      redirect = this.route.snapshot.queryParams.redirect_to;
    } else {
      redirect = '/';
    }

    this.router.navigateByUrl(redirect, { replaceUrl: true });
  }

  hasAttemptedPath() {
    return !!this.storageService.getItem(PATH_KEY);
  }

  private getAttemptedPathData() {
    const item = this.storageService.getItem(PATH_KEY);

    let data;
    try {
      data = item && decodeURIComponent(item);
    } catch (error) {
      console.error('AttemptedPathService Error parsing encoded json in the ' + PATH_KEY);
      this.deleteAttemptedPath();
      data = null;
    }
    return data;
  }

  deleteAttemptedPath() {
    this.storageService.removeItem(PATH_KEY);
  }
}
