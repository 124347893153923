<div [class]="containerClass" #containerRef>
  <div class="row flex-column-reverse flex-sm-row">
    <div class="col-sm">
      <h1 [class]="titleClass" data-cy="error-state-title">{{ title }}</h1>
      <div *ngIf="bodyContentTemplate" [class]="bodyContentClass" data-cy="error-state-body-content">
        <ng-container *ngTemplateOutlet="bodyContentTemplate.templateRef"></ng-container>
      </div>
      <div *ngIf="bodyContent" [class]="bodyContentClass" data-cy="error-state-body-content">
        <p>{{ bodyContent }}</p>
      </div>
      <div *ngIf="primaryButtonText || secondaryButtonText" [class]="buttonContainerClass">
        <div *ngIf="primaryButtonText" [class]="primaryButtonClass">
          <omgui-button
            #primaryButtonRef
            [variant]="ButtonVariant.primary"
            [size]="ButtonSize.fillParent"
            [type]="ButtonType.button"
            (buttonClick)="primaryButtonClick.emit($event)"
          >
            {{ primaryButtonText }}
          </omgui-button>
        </div>
        <div *ngIf="secondaryButtonText" [class]="secondaryButtonClass">
          <omgui-button
            [variant]="ButtonVariant.link"
            [size]="ButtonSize.fillParent"
            [type]="ButtonType.button"
            (buttonClick)="secondaryButtonClick.emit($event)"
          >
            {{ secondaryButtonText }}
          </omgui-button>
        </div>
      </div>
    </div>
    <div *ngIf="hasImageSource" class="col-sm">
      <img [src]="imageSource" [class]="imageClass" alt="" />
    </div>
  </div>
</div>
