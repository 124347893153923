import { Component, Input } from '@angular/core';

import { ComponentSize } from '@omgui/component-size';

@Component({
  selector: 'om-loading-spinner',
  templateUrl: './loading-spinner.component.html',
})
export class LoadingSpinnerComponent {
  @Input() message: string;

  readonly componentSize = ComponentSize;
}
