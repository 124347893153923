import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

import { SwitchToRemote, SwitchToRemoteVariables } from './__generated__/switch-to-remote-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class SwitchToRemoteGraphQLService extends Mutation<SwitchToRemote, SwitchToRemoteVariables> {
  document = gql`
    mutation SwitchToRemote($input: SwitchToRemoteAppointmentInput!) {
      switchToRemoteAppointment(input: $input) {
        success
        mutationErrors {
          path
          messages
          __typename
        }
        appointment {
          id
          appointmentType {
            id
          }
        }
      }
    }
  `;
}
