interface ApiV2FromAppointment {
  id: number;
  start_time: string;
  office_name: string;
  provider_name: string;
}

export class FromAppointment {
  id: number;
  startTime: string;
  officeName: string;
  providerName: string;
  displayTimezone: string;

  static fromGraphQL({
    id,
    startTime,
    officeName,
    providerName,
    timezoneTzinfo,
  }: Record<string, any>): FromAppointment {
    const fromAppointment = new FromAppointment();
    fromAppointment.id = id;
    fromAppointment.startTime = startTime;
    fromAppointment.officeName = officeName;
    fromAppointment.providerName = providerName;
    fromAppointment.displayTimezone = timezoneTzinfo;

    return fromAppointment;
  }

  toApiV2(): ApiV2FromAppointment {
    return {
      id: this.id,
      start_time: this.startTime,
      office_name: this.officeName,
      provider_name: this.providerName,
    };
  }
}
