<div class="container">
  <div class="col-md-8 offset-md-2 col-12">
    <div class="mb-4 mb-sm-2rem text-center">
      <h1 class="mb-2 mb-sm-3">Secure your account</h1>
    </div>
    <p class="mx-3">
      To help protect your health information, we require members to log in using Multi-factor Authentication.
    </p>

    <!-- error message from auth0 -->
    <div *ngIf="mfaError" class="my-2 mx-3">
      <omgui-banner [type]="BannerType.ErrorLight" data-cy="mfa-generic-error">
        Something went wrong on our end. We'll ask you to set up Multi-factor Authentication the next time you log in.
      </omgui-banner>
    </div>

    <div class="mx-3 d-flex flex-column">
      <ng-container *ngIf="isLoading">
        <om-loading-spinner data-cy="enable-mfa-form-loading-spinner" class="mt-4"></om-loading-spinner>
      </ng-container>
      <ng-container *ngIf="!isLoading">
        <form [formGroup]="formGroup">
          <om-label-wrapper
            class="col-12 mb-2"
            label="Phone Number"
            labelClass="fw-bold"
            errorMessage="Please enter a valid 10-digit phone number"
            [errorMessageClass]="inputValidationClass"
            [hasError]="phoneNumberFormErrorMessage"
          >
            <om-phone-number-input
              class="float-left my-3"
              [overrideDisplayError]="true"
              [formControlName]="phoneNumberFormControlName"
              (errorMessageEmitter)="setErrorMessage($event)"
            ></om-phone-number-input>
          </om-label-wrapper>
          <small class="text-black-50"> Message and data rates will apply </small>

          <div class="mt-4">
            <om-label-wrapper
              class="col-12 mb-2"
              label="Email"
              labelClass="fw-bold"
              errorMessage="Please enter a valid email address"
              [errorMessageClass]="inputValidationClass"
            >
              <om-email-input
                [formControlName]="emailFormControlName"
                placeholder="Enter email"
                required="true"
                data-cy="registration-mfa-email"
                updateOn="change"
              >
              </om-email-input>
            </om-label-wrapper>
          </div>

          <div class="mt-4">
            <p class="fw-bold">Choose how you would like to receive your verification code when logging in</p>
            <om-radio-group [form]="formGroup" [options]="mfaChannelOptions" [submitting]="false"></om-radio-group>
          </div>

          <div class="mt-4">
            <omgui-button
              [customButtonClass]="{ 'rounded-pill': false, 'w-100': true }"
              [type]="'button'"
              data-cy="enable-mfa-button"
              label="Set Up Security"
              [disabled]="phoneNumberFormErrorMessage || isInvalidEmail()"
              (buttonClick)="mfaError ? onSubmit() : enableMfaAutomatically()"
              >{{ mfaError ? 'Continue' : 'Set Up Security' }}</omgui-button
            >
          </div>
        </form>
      </ng-container>
    </div>
    <div class="row mx-3 mt-4 bg-cream rounded">
      <div class="col-2 text-center align-self-center">
        <om-svg-security-filled-cross></om-svg-security-filled-cross>
      </div>
      <div class="col-10 p-3 px-0 lh-1">
        <div class="fw-bold">
          <small>Can't verify with your phone?</small>
        </div>
        <div>
          <small>
            Email
            <a class="fw-bolder text-decoration-underline" href="mailto:mfasupport@onemedical.com"
              >mfasupport@onemedical.com</a
            >
            for help logging into your account
          </small>
        </div>
      </div>
    </div>
    <div class="text-center mx-3 mt-4">
      <a class="fw-bolder text-decoration-underline" href="https://www.onemedical.com/privacy/" target="_blank"
        >Privacy Policy</a
      >
      <span class="px-2">|</span>
      <a
        class="fw-bolder text-decoration-underline"
        href="https://www.onemedical.com/sms-terms/mfa-terms/"
        target="_blank"
        >SMS Terms of Service</a
      >
    </div>
  </div>
</div>
