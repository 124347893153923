<div class="email-sent-confirmation-container container-fluid h-100">
  <div class="row text-center mb-4">
    <div class="col-10 offset-1 col-md-6 offset-md-3">
      <a [href]="links.home">
        <om-logo class="mx-auto mt-5"></om-logo>
      </a>
      <h1 class="mt-5">Email Sent</h1>
      <p class="body-small p-0 mb-3">A link to reset your password has been sent to your email.</p>
      <p class="body-small">
        If you do not receive an email within 30 minutes, please email
        <a data-cy="contact-support-email" href="mailto:{{ contactEmail }}">{{ contactEmail }}</a
        >.
      </p>
    </div>
  </div>
</div>
