import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BasicAddress } from './../address';

export enum AddressDisplayStyle {
  DEFAULT,
  INPUT,
}

@Component({
  selector: 'om-address-display',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './address-display.component.html',
})
export class AddressDisplayComponent {
  addressDisplayStyle = AddressDisplayStyle;
  @Input() address: BasicAddress;
  @Input() style: AddressDisplayStyle = AddressDisplayStyle.DEFAULT;

  get fullAddress(): string {
    const { address1, city, state, zip } = this.address;
    return `${address1}, ${city}, ${state}, ${zip}`;
  }
}
