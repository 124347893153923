import find from 'lodash-es/find';
import isEmpty from 'lodash-es/isEmpty';
import trim from 'lodash-es/trim';

import { StorageDuration } from '@app/core/storage.service';

import { environment } from '../../environments/environment';

abstract class Storage {
  abstract getItem(key: string): string;

  abstract setItem(key: string, value: string, storageDuration?: StorageDuration): void;

  abstract removeItem(key: string): void;
}

export class CookieStorage extends Storage {
  private readonly topLevelDomainKeyVal: string;
  private readonly legacyDomainKeyVal: string;

  constructor() {
    super();
    const tld = environment.topLevelDomain;
    this.topLevelDomainKeyVal = isEmpty(tld) ? '' : `domain=${tld}`;
    // TODO: [GC-1330] Remove this once we are confident that all cookies under the old top-level domain have been cleared - DZ
    this.legacyDomainKeyVal = isEmpty(tld) ? '' : `domain=${tld.substring(tld.indexOf('.'))}`;
  }

  getItem(key: string): string | null {
    const keyVals = document.cookie.split(';');
    const keyVal = find(keyVals, val => {
      const [currentKey, _currentVal] = val.split('=');
      if (trim(currentKey) === key) {
        return true;
      }
    });
    if (keyVal) {
      const [_currentKey, currentVal] = keyVal.split(/=([^]+)/, 2);
      return currentVal;
    }
    return null;
  }

  setItem(key: string, value: string, storageDuration?: StorageDuration) {
    document.cookie = this.buildItem(key, value, { storageDuration });
  }

  setTldItem(key: string, value: string, storageDuration?: StorageDuration) {
    document.cookie = this.buildItem(key, value, { setTld: true, storageDuration });
  }

  removeItem(key: string) {
    document.cookie = `${key}=;path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC`;
  }

  removeTldItem(key: string) {
    document.cookie = `${key}=;path=/;${this.topLevelDomainKeyVal};expires=Thu, 01 Jan 1970 00:00:00 UTC`;
    // TODO: [GC-1330] Remove this once we are confident that all cookies under the old top-level domain have been cleared - DZ
    document.cookie = `${key}=;path=/;${this.legacyDomainKeyVal};expires=Thu, 01 Jan 1970 00:00:00 UTC`;
  }

  private buildItem(
    key: string,
    value: string,
    { storageDuration, setTld }: { storageDuration?: StorageDuration; setTld?: boolean },
  ): string {
    let cookie = `${key}=${value};path=/`;

    if (environment.enableProdMode) {
      cookie = `${cookie};secure;SameSite=strict`;
    }

    if (storageDuration) {
      if (storageDuration.infinite) {
        cookie = `${cookie};expires=Thu, 18 Dec 3000 12:00:00 UTC`;
      } else if (storageDuration.duration) {
        cookie = `${cookie};expires=${storageDuration.duration.toUTCString()}`;
      }
    }

    if (setTld) {
      cookie = `${cookie};${this.topLevelDomainKeyVal}`;
    }

    return cookie;
  }
}
