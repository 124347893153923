import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { OmguiButtonModule } from '@app/omgui/omgui-button/omgui-button.component';

import { ArrowLeftComponent, LogoComponent } from '../svg';
import { NavbarArrowLeftComponent } from './navbar-arrow/navbar-arrow-left.component';
import { NavbarProgressBarComponent } from './navbar-progress/navbar-progress-bar.component';

@Component({
  selector: 'om-navbar-header',
  templateUrl: './navbar-header.component.html',
  styleUrls: ['./navbar-header.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    OmguiButtonModule,
    NgbModule,
    ArrowLeftComponent,
    LogoComponent,
    NavbarProgressBarComponent,
    NavbarArrowLeftComponent,
  ],
})
export class NavbarHeaderComponent {
  @Input() canGoBack = true;
  @Input() percentComplete = 0;
  @Input() showLoginLink = true;
  @Input() showProgressBar = true;
  @Output() login: EventEmitter<any> = new EventEmitter<any>();
  @Output() back: EventEmitter<any> = new EventEmitter<any>();
  progressBarHeight = '0.75rem';

  onBack(e: Event) {
    e.preventDefault();
    this.back.emit();
  }

  onLogIn(e: Event) {
    e.preventDefault();
    this.login.emit();
  }
}
