import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'om-confirmation-error-modal',
  templateUrl: './confirmation-error-modal.component.html',
  styleUrls: ['./confirmation-error-modal.component.scss'],
})
export class ConfirmationErrorModalComponent {
  @Input() errorMessage: string;
  @Output() modalClosed = new EventEmitter();

  modalClose() {
    this.modalClosed.emit();
  }
}
