<div [formGroup]="emailForm">
  <input
    #input
    type="text"
    id="email"
    data-cy="email"
    [attr.aria-invalid]="emailForm.controls.email.invalid"
    aria-describedby="email-error"
    aria-label="email"
    class="form-control text-field mb-2"
    name="email"
    [placeholder]="placeholder"
    required="true"
    formControlName="email"
  />

  <div id="email-error">
    <span *ngIf="invalidEmail" class="error-message"> Please enter a valid email </span>
    <span *ngIf="serverError" class="error-message"> {{ serverError }} </span>
  </div>
</div>
