import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-svg-membership-and-billing',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.4855 18.0163H4.93947C3.86791 18.0163 3 17.189 3 16.1676V7.84866C3 6.82728 3.86791 6 4.93947 6H19.4855C20.5571 6 21.425 6.82728 21.425 7.84866V16.1676C21.425 17.189 20.5571 18.0163 19.4855 18.0163Z"
        [attr.stroke]="color"
      />
      <path d="M3 9.22437H21.425" [attr.stroke]="color" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.1018 14.1711C19.1018 15.0555 18.384 15.7733 17.4996 15.7733C16.6152 15.7733 15.8975 15.0555 15.8975 14.1711C15.8975 13.2867 16.6152 12.5689 17.4996 12.5689C18.384 12.5689 19.1018 13.2867 19.1018 14.1711Z"
        [attr.stroke]="color"
      />
    </svg>
  `,
})
export class SvgMembershipAndBillingComponent {
  @Input() color = 'currentColor';

  constructor() {}
}
