import { Component, Input, ViewChild } from '@angular/core';

import { LinksService } from '@app/core/links.service';
import { EmailInputComponent, PasswordInputComponent } from '@app/shared';

import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { RegistrationStepDirective } from '../registration-step.directive';

@Component({
  selector: 'om-create-account-step',
  styleUrls: ['../../../shared/form-input.scss'],
  templateUrl: './create-account-step.component.html',
})
export class CreateAccountStepComponent extends RegistrationStepDirective {
  @Input() emailError: string;
  @Input() passwordError: string;
  @Input() isWhitelist: boolean;
  @Input() companyId: number;
  @Input() serviceArea: string;
  @Input() module: string;
  @ViewChild('logInEmail', { static: true }) logInEmail: EmailInputComponent;
  @ViewChild('password', { static: true }) password: PasswordInputComponent;

  constructor(
    readonly links: LinksService,
    private enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService,
  ) {
    super();
  }

  onSubmit() {
    this.submitting = true;
    this.logInEmail.markAsTouchedAndDirty();
    this.password.markAsTouchedAndDirty();
    this.submit.emit();
  }

  onError() {
    this.submitting = false;
  }

  trackResetPasswordClicked = () =>
    this.enterpriseRegistrationAnalyticsService.resetPasswordClicked({
      module: this.module,
      isWhitelist: this.isWhitelist,
      companyId: this.companyId,
      serviceArea: this.serviceArea,
    });

  trackEmailAdminClicked = () =>
    this.enterpriseRegistrationAnalyticsService.emailAdminClicked({
      module: this.module,
      isWhitelist: this.isWhitelist,
      companyId: this.companyId,
      serviceArea: this.serviceArea,
    });
}
