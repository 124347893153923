import { Injectable } from '@angular/core';
import debounce from 'lodash-es/debounce';
import { Observable, Subject } from 'rxjs';

import { DiscountType, DiscountTypeGraphQL } from '@app/registration/discount-type-graphql.service';

/**
 * Service that loads DiscountType information using GraphQL. Publishes a stream
 * of {DiscountType} objects that implementers can query to retrieve loaded
 * discount type information when it becomes available.
 */

@Injectable({ providedIn: 'root' })
export class DiscountTypeService {
  /**
   * A stream of loaded DiscountType objects. Each time {#loadDiscountType} is
   * called, and a new discount type is loaded from the backend, it is placed
   * in this stream.
   */

  private discountType = new Subject<DiscountType>();
  discountType$: Observable<DiscountType> = this.discountType.asObservable();

  private readonly _debouncedGetDiscountType: (code: string) => void;

  constructor(private discountTypeGraphQL: DiscountTypeGraphQL) {
    this._debouncedGetDiscountType = debounce(this._getDiscountType.bind(this), 250, { leading: true });
  }

  /**
   * Loads data on a discount type using GraphQL. When the data is loaded, a
   * {DiscountType} object is created and placed in the {discountType$} stream.
   *
   * @param code The discount code.
   * @param force If `true`, does not debounce.
   */

  getDiscountType(code: string, force = false) {
    if (force) {
      this._getDiscountType(code);
    } else {
      this._debouncedGetDiscountType(code);
    }
  }

  private async _getDiscountType(code: string) {
    this.discountTypeGraphQL.fetch({ code }).subscribe(result => {
      this.discountType.next(result.data.discountType);
    });
  }
}
