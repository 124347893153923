import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'omg-svg-action-item',
  template: `
    <svg width="24" height="27" viewBox="0 0 14 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.20893 24.3118C3.09056 24.2641 2.22831 23.4554 2.22831 22.3994C2.22831 21.4755 2.85034 20.5455 4.16041 20.5631M5.72518 26.8078V5.30376V26.8078ZM8.11973 3.21434C8.11973 4.46848 7.06659 5.48531 5.76765 5.48531C4.46951 5.48531 3.41637 4.46848 3.41637 3.21434C3.41637 1.96019 4.46951 0.943359 5.76765 0.943359C7.06659 0.943359 8.11973 1.96019 8.11973 3.21434ZM11.6078 8.49741C11.7072 8.90368 11.218 9.36294 10.5148 9.52346C9.81089 9.68397 9.16023 9.48506 9.06081 9.07878C8.96138 8.67251 9.45136 8.21325 10.1545 8.05274C10.8577 7.89222 11.5083 8.09114 11.6078 8.49741ZM4.16781 8.46807C3.5251 8.31908 2.97387 8.20465 2.61831 8.20004C1.41642 8.18391 0.637695 9.05559 0.637695 10.1116C0.637695 11.1676 1.39972 12.0239 2.61831 12.0239C3.83611 12.0239 7.16657 11.2398 8.18631 11.272C9.20526 11.3035 10.1661 12.1276 10.1661 13.1836C10.1661 14.2396 9.20366 15.1504 8.18631 15.0959C7.73849 15.0721 7.34077 14.9999 7.34077 14.9999L4.16781 8.46807ZM8.90689 9.11972C8.45986 9.21649 7.88636 9.19575 7.26354 9.11434L8.90689 9.11972ZM7.2415 21.2158C8.53407 21.384 9.37086 20.3841 9.37086 19.3281C9.37086 18.2721 8.50941 17.352 7.39025 17.4157C6.27187 17.4795 4.53227 18.216 3.4139 18.1676C2.29473 18.12 1.43328 17.3121 1.43328 16.2561C1.43328 15.2001 2.24542 14.1356 3.96831 14.4881L7.2415 21.2158Z"
        [attr.stroke]="stroke"
      />
    </svg>
  `,
  styleUrls: [],
})
export class SvgActionItemComponent implements OnInit {
  @Input() stroke = 'currentColor';

  constructor() {}

  ngOnInit() {}
}
