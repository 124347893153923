import { Component, OnInit } from '@angular/core';

import { GraphNavigationService } from '@app/registration/graph/graph-navigation.service';

import { NavbarHeaderComponent } from '../../shared/navbar-header/navbar-header.component';
import { SharedModule } from '../../shared/shared.module';

@Component({
  selector: 'om-trial-video-visit-onboarding',
  templateUrl: './trial-video-visit-onboarding.component.html',
  standalone: true,
  imports: [NavbarHeaderComponent, SharedModule],
})
export class TrialVideoVisitOnboardingComponenent implements OnInit {
  constructor(private graphNavigationService: GraphNavigationService) {}

  ngOnInit(): void {
    this.graphNavigationService.navigate();
  }
}
