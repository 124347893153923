import { Injectable } from '@angular/core';
import { Query, gql, Apollo } from 'apollo-angular';

import {
  WhitelistedEmployeePersonalDetails,
  WhitelistedEmployeePersonalDetailsVariables,
} from './__generated__/WhitelistedEmployeePersonalDetails';
import { whitelistedEmployeeBase } from './whitelisted-employee-graphql.service.fragment';

@Injectable()
export class WhitelistedEmployeePersonalDetailsGraphQL extends Query<
  WhitelistedEmployeePersonalDetails,
  WhitelistedEmployeePersonalDetailsVariables
> {
  constructor(apollo: Apollo) {
    super(apollo);
  }

  document = gql`
    query WhitelistedEmployeePersonalDetails(
      $firstName: String
      $lastName: String
      $dob: String
      $reCaptchaToken: String
    ) {
      whitelistedEmployee(firstName: $firstName, lastName: $lastName, dob: $dob, reCaptchaToken: $reCaptchaToken) {
        ...whitelistedEmployeeBase
        isDependent
        b2bCompany {
          id
          b2bEmailDomains
          includesDependent
          verifyDependentInfo
          activationCodeRetrievableViaEmail
          enterprisePedsRegistrationEnabled
        }
      }
    }
    ${whitelistedEmployeeBase}
  `;
}
