import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { DocumentSigner, LegalDocumentsService, TermsOfService } from '@app/core/legal-documents';
import { FlashService, MessageType } from '@app/shared/flash.service';

import { User } from '../user';

export interface BeneficiaryForLegalDoc extends Pick<User, 'id'> {}

@Component({
  selector: 'om-legal-doc',
  templateUrl: './legal-doc.component.html',
  styleUrls: ['./legal-doc.component.scss', './legal-doc-shared.scss'],
})
export class LegalDocComponent implements OnInit {
  @Input() set doc(doc: TermsOfService) {
    this._doc = doc;
    this.docToggleId = `doc-toggle-${doc.type}`;
    this.docContentId = `doc-content-${doc.type}`;
    this.trustedText = this.domSanitizer.bypassSecurityTrustHtml(doc.text);
  }

  @Input() beneficiary: BeneficiaryForLegalDoc;
  @Input() signer: DocumentSigner;
  @Input() beneficiaryLoggedIn: boolean;
  @Input() signOnBehalf: boolean;
  @Input() enabled: boolean;
  @Output() docSigned = new EventEmitter<TermsOfService>();

  private _doc: TermsOfService;
  protected docToggleId: string;
  protected docContentId: string;

  protected trustedText: SafeHtml;
  protected collapsed = true;
  protected signed = false;
  protected processing = false;

  constructor(
    private legalDocumentsService: LegalDocumentsService,
    public flashService: FlashService,
    private domSanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.signed = this.doc.signedOrSignedOnBehalf;
  }

  get doc() {
    return this._doc;
  }

  protected toggleDoc($event: KeyboardEvent | MouseEvent) {
    $event.preventDefault();
    this.collapsed = !this.collapsed;
  }

  protected signDoc($event: MouseEvent) {
    $event.preventDefault();

    if (this.signed || this.processing) {
      return;
    }

    this.processing = true;

    this.getSigningOperation()
      .pipe(finalize(() => (this.processing = false)))
      .subscribe(success => {
        if (success) {
          this.signed = true;
          this.docSigned.emit(this.doc);
        } else {
          this.flashService.addFlashMessage(
            "We've encountered an issue submitting your request. Please log out and try again.",
            MessageType.ERROR,
          );
        }
      });
  }

  private getSigningOperation(): Observable<boolean> {
    if (this.signOnBehalf) {
      if (this.beneficiaryLoggedIn) {
        return this.legalDocumentsService.signForBeneficiaryWhenLoggedInAsBeneficiary(
          this.doc,
          this.beneficiary,
          this.signer,
        );
      } else {
        return this.legalDocumentsService.signForBeneficiaryWhenLoggedInAsAdministrator(this.doc, this.beneficiary);
      }
    } else {
      return this.legalDocumentsService.signForSelf(this.doc);
    }
  }
}
