import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

import { RoutingStep } from './__generated__/routing-step-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class RoutingStepGraphQL extends Query<RoutingStep> {
  document = gql`
    query RoutingStep(
      $appointmentTypeId: ID
      $appointmentTypeLocked: Boolean
      $careOptionId: String
      $careOptionLocked: Boolean
      $careOptionName: String
      $reasonForVisit: String
      $routingSessionId: String
      $serviceAreaId: ID
      $stepName: String!
      $surveySystemName: String
      $topicBasedGuidanceEnabled: Boolean
      $topicName: String
    ) {
      routingStep(
        input: {
          appointmentTypeId: $appointmentTypeId
          appointmentTypeLocked: $appointmentTypeLocked
          careOptionId: $careOptionId
          careOptionLocked: $careOptionLocked
          careOptionName: $careOptionName
          serviceAreaId: $serviceAreaId
          surveySystemName: $surveySystemName
          text: $reasonForVisit
          topicBasedGuidanceEnabled: $topicBasedGuidanceEnabled
          topicName: $topicName
        }
        routingSessionId: $routingSessionId
        stepName: $stepName
      ) {
        final
        routingSessionId
        stepContext {
          appointmentType {
            id
            displayName
            displayedDuration
            isSpecialty
            name
            remote
          }
          rfvRequired
          survey {
            systemName
          }
        }
        stepName
        topicName
      }
    }
  `;
}
