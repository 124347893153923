export enum ComponentSize {
  extraExtraSmall = 'extra-extra-small',
  extraSmall = 'extra-small',
  small = 'small',
  mediumSmall = 'medium-small',
  medium = 'medium',
  large = 'large',
  extraLarge = 'extra-large',
  extraExtraLarge = 'extra-extra-large',
}
