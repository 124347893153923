import {
  FetchAppointmentBookingStateCache_patient_appointmentBookingSession,
  FetchAppointmentBookingStateCache_patient_appointmentBookingSession_appointmentBookingStateCache,
} from './__generated__/FetchAppointmentBookingStateCache';

export class AppointmentBookingSession {
  id: string;
  appointmentBookingStateCache: FetchAppointmentBookingStateCache_patient_appointmentBookingSession_appointmentBookingStateCache;

  static fromGraphQL({
    appointmentBookingStateCache,
    id,
  }: FetchAppointmentBookingStateCache_patient_appointmentBookingSession) {
    const abs = new AppointmentBookingSession();
    abs.id = id;
    abs.appointmentBookingStateCache = appointmentBookingStateCache;

    return abs;
  }
}
