import { Injectable } from '@angular/core';
import { Query, gql, Apollo } from 'apollo-angular';

import {
  WhitelistedEmployeeDependent,
  WhitelistedEmployeeDependentVariables,
} from './__generated__/WhitelistedEmployeeDependent';

@Injectable()
export class WhitelistedEmployeeDependentGraphqlService extends Query<
  WhitelistedEmployeeDependent,
  WhitelistedEmployeeDependentVariables
> {
  constructor(apollo: Apollo) {
    super(apollo);
  }

  document = gql`
    query WhitelistedEmployeeDependent(
      $firstName: String!
      $lastName: String!
      $dateOfBirth: String!
      $parentId: String!
      $b2bCompanyId: String!
      $reCaptchaToken: String
    ) {
      whitelistedEmployeeDependent(
        firstName: $firstName
        lastName: $lastName
        dateOfBirth: $dateOfBirth
        parentId: $parentId
        b2bCompanyId: $b2bCompanyId
        reCaptchaToken: $reCaptchaToken
      ) {
        id
        registered
      }
    }
  `;
}
