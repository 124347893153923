<om-program-banner
  [programName]="title"
  [programImage]="programBanner.programImage"
  data-cy="hub-banner-section"
></om-program-banner>
<div class="container flex-column mb-5">
  <div class="row flex-column-reverse flex-lg-row">
    <div class="col-lg-8">
      <section class="d-flex flex-column mb-4 mb-lg-5" data-cy="hub-care-option-section">
        <h2>{{ careOptionsSection.title }}</h2>
        <div
          class="row g-0"
          *ngFor="let subSection of careOptionsSection.content; let isLastSubSection = last"
          [ngClass]="{ 'mb-3 mb-sm-4': !isLastSubSection }"
        >
          <ng-container *ngIf="subSection.content.length === 1 && subSection.content[0].type === 'card-lg'">
            <om-program-feature-card
              [data]="subSection.content[0]"
              (secondaryAction)="goToSecondaryUrl(subSection.content[0], 'secondary')"
            ></om-program-feature-card>
          </ng-container>
          <ng-container *ngIf="subSection.content.length > 1">
            <ng-container *ngTemplateOutlet="careOptionDeck; context: { $implicit: subSection }"></ng-container>
          </ng-container>
        </div>
      </section>
      <section class="d-flex flex-column mb-4 mb-lg-5" data-cy="hub-appointments-section">
        <h2>{{ appointmentsSection.title }}</h2>
        <om-program-appointments class="mb-3 mb-sm-4"></om-program-appointments>
      </section>
      <section class="d-flex flex-column mb-4 mb-lg-5" data-cy="hub-faq-section">
        <h2>FAQ</h2>
        <om-program-faq [data]="programFaqs"></om-program-faq>
      </section>
    </div>
    <div class="col-lg-4 mb-4 mb-lg-0">
      <!--Adds some space above the sidebar content to align with the main content area-->
      <div class="h2 d-none d-lg-block">&nbsp;</div>
      <section class="d-flex flex-column">
        <div class="omgui-card card bg-light border-0 mb-4" data-cy="hub-program-overview-section">
          <div class="card-body">
            <h5>Program Overview</h5>
            <div data-cy="program-description" [innerHTML]="programDescription"></div>
          </div>
        </div>
        <div class="omgui-card omgui-card-lg card border-1 mb-4" data-cy="hub-care-team-section">
          <div class="card-body">
            <h5>Your Care Team</h5>
            <om-care-team-sidebar [careGivers]="careTeam"> </om-care-team-sidebar>
          </div>
        </div>
        <div class="omgui-card omgui-card-lg card border-1 mb-4" data-cy="hub-immediate-care-section">
          <div class="card-body">
            <h5>Immediate Help</h5>
            <om-program-immediate-care [data]="immediateCare"></om-program-immediate-care>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>

<ng-template #careOptionModalContent let-modal>
  <div class="modal-header text-center p-0">
    <img class="modal-header-image" [src]="modalData.imageUrl" [alt]="modalData.imageAltText" />
    <omgui-close-x
      role="button"
      stroke="#fff"
      backgroundOpacity=".5"
      backgroundFill="#6c757d"
      fill="#6c757d"
      class="btn modal-close fw-light text-end"
      aria-label="Close"
      data-cy="modal-close-button"
      (click)="modal.close()"
    ></omgui-close-x>
  </div>
  <div class="modal-body">
    <h3 class="text-center" data-cy="hub-modal-title">{{ modalData.title }}</h3>
    <p class="col col-sm-10 offset-sm-1 justify-content-center mb-5" data-cy="hub-modal-content">
      {{ modalData.description }}
    </p>
    <div class="col d-flex justify-content-center mb-3">
      <ng-container *ngIf="modalData.primaryActionType === 'external'">
        <a
          class="btn btn-primary"
          ngbAutofocus
          [href]="modalData.primaryActionUrl"
          target="_blank"
          rel="noopener noreferrer"
          [omTrackLink]="trackProgramModalClicked(modalData.moduleName)"
          data-cy="hub-modal-action"
          >{{ modalData.primaryActionLabel
          }}<omgui-external-link-icon class="d-inline-flex ms-2"></omgui-external-link-icon
        ></a>
      </ng-container>
      <ng-container *ngIf="modalData.primaryActionType === 'internal'">
        <a
          class="btn btn-primary"
          ngbAutofocus
          [href]="modalData.primaryActionUrl"
          [omTrackLink]="trackProgramModalClicked(modalData.moduleName)"
          data-cy="hub-modal-action"
          >{{ modalData.primaryActionLabel }}</a
        >
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #careOptionDeck let-subSection>
  <div class="card-deck">
    <ng-container *ngFor="let item of subSection.content">
      <ng-container *ngTemplateOutlet="careOptionCard; context: { $implicit: item }"></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #careOptionCard let-careOptionItem>
  <button
    class="omgui-card-hover-state care-option-card omgui-card card text-start p-0"
    (click)="openModal(careOptionItem)"
    data-cy="hub-care-option-item"
  >
    <img
      class="card-img-top"
      *ngIf="careOptionItem.imageUrl"
      [src]="careOptionItem.imageUrl"
      [alt]="careOptionItem.imageAltText"
    />
    <div class="card-body">
      <h3 class="card-title h4">{{ careOptionItem.title }}</h3>
      <p class="card-text text-muted">{{ careOptionItem.description }}</p>
    </div>
  </button>
</ng-template>
