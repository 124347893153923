import { Injectable } from '@angular/core';

import { AnalyticsService } from '@app/core/analytics.service';
import { EMAIL_VERIFICATION_OUTREACH_EMAIL, FLOW_LOGIN, LOGIN_STARTED } from '@app/core/mixpanel.constants';

@Injectable({
  providedIn: 'root',
})
export class Auth0AnalyticsService extends AnalyticsService {
  trackLoginStarted({ source }: { source?: string } = {}) {
    return this.trackWithDefaultProperties(LOGIN_STARTED, {
      flow: FLOW_LOGIN,
      module: 'Auth0 Redirect',
      source,
    });
  }

  trackLoginStartedFromEmailVerification() {
    return this.trackLoginStarted({ source: EMAIL_VERIFICATION_OUTREACH_EMAIL });
  }

  trackLoginCompleted() {
    return this.trackWithDefaultProperties('Login Completed', {
      flow: FLOW_LOGIN,
      module: 'Auth0 Returned',
    });
  }
}
