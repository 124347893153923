import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

import { DocumentSigner } from '@app/core/__generated__/DocumentSigner';

@Injectable()
export class DocumentSignerGraphQLService extends Query<DocumentSigner> {
  document = gql`
    query DocumentSigner {
      patient {
        id
        documentSigner {
          id
          type
          firstName
          lastName
          email
        }
      }
    }
  `;
}
