import { Component } from '@angular/core';

import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { RegistrationStepDirective } from '../registration-step.directive';

@Component({
  selector: 'om-adult-direct-signup-step',
  templateUrl: 'adult-direct-signup-step.component.html',
})
export class AdultDirectSignupStepComponent extends RegistrationStepDirective {
  constructor(private analytics: EnterpriseRegistrationAnalyticsService) {
    super();
  }

  returnToDirectSignup() {
    this.analytics.trackAnotherDependentClicked();
    this.submit.emit();
  }
}
