import { Component, Input, OnChanges } from '@angular/core';
import kebabCase from 'lodash-es/kebabCase';

import { CreditCardBrand, CreditCardBrandName } from '@app/core/membership';

@Component({
  selector: 'om-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss'],
})
export class CreditCardComponent implements OnChanges {
  constructor() {}
  static readonly IMAGE_PATH = '/assets/images/icons/credit-card';
  @Input() brand: CreditCardBrandName;
  imageSource: string;

  ngOnChanges() {
    switch (this.brand) {
      case CreditCardBrand.AMEX:
      case CreditCardBrand.DISCOVER:
      case CreditCardBrand.MASTERCARD:
      case CreditCardBrand.VISA:
        this.imageSource = `${CreditCardComponent.IMAGE_PATH}/${kebabCase(this.brand)}.png`;
        break;
      default:
        this.imageSource = null;
    }
  }
}
