import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

import {
  LoginWithAmazonRegistration,
  LoginWithAmazonRegistrationVariables,
} from '@app/registration/__generated__/login-with-amazon-registration-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class LoginWithAmazonRegistrationGraphqlService extends Query<
  LoginWithAmazonRegistration,
  LoginWithAmazonRegistrationVariables
> {
  document = gql`
    query LoginWithAmazonRegistration {
      patient {
        id
        isLwaRegUser
      }
    }
  `;
}
