import { v4 as uuidv4 } from 'uuid';
export class Lrmo {
  localStorage: Storage | null;

  constructor(localStorage: Storage | null) {
    this.localStorage = localStorage;
  }

  getId() {
    if (!this.iSLocalStorageSupported()) {
      return null;
    }

    const lrmoId = this.localStorage.getItem('lrmoId');
    if (lrmoId) {
      return lrmoId;
    }
    return this.setId();
  }

  setId() {
    if (!this.iSLocalStorageSupported()) {
      return null;
    }

    const lrmoId = uuidv4();
    this.localStorage.setItem('lrmoId', lrmoId);
    return lrmoId;
  }

  private iSLocalStorageSupported() {
    return this.localStorage ? true : false;
  }
}
