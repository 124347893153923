import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isExists, isValid } from 'date-fns';

import { DateUtils } from '@app/utils/date.utils';

@Component({
  selector: 'om-omgui-date-input',
  templateUrl: './omgui-date-input.component.html',
  styleUrls: ['./omgui-date-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OmguiDateInputComponent),
      multi: true,
    },
  ],
})
export class OmguiDateInputComponent implements ControlValueAccessor {
  /** A unique id for the input. Will get translated to an id attribute that can be targeted with a label. */
  @Input() inputId: string;

  /** Optional placeholder text (aka clue/suggestion for user input) */
  @Input() placeholder? = '';

  /** Optional label for use as aria-label, when input is used without label element. */
  @Input() label?: string;

  /** Optional label to sit above the input element */
  @Input() inputLabel?: null;

  /** Optional helper text to sit below the input element */
  @Input() helperText?: string;

  /** Optional ability to pass in disabled state */
  @Input() isDisabled?: boolean;

  _inputText: string;
  errorMessage: string;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  INVALID_DATE_ERROR = 'Please enter a valid date in the format MM/DD/YYYY';

  private onChange: (value: string) => void = () => {};

  private onTouch: () => void = () => {};

  maskDate(input: { value: string | null }) {
    if (input.value === null) {
      return;
    }
    const groups = input.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
    input.value = !groups[2]
      ? groups[1]
      : !groups[3]
      ? groups[1] + '/' + groups[2]
      : groups[1] + '/' + groups[2] + '/' + groups[3];
  }

  validateDate(input: { value: string | null }) {
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
    const enteredDate = new Date(input.value);
    const dateValues = input.value.split('/');

    if (!isValid(enteredDate) || !isExists(Number(dateValues[2]), Number(dateValues[0]) - 1, Number(dateValues[1]))) {
      return (this.errorMessage = this.INVALID_DATE_ERROR);
    }
    const ageInYears = DateUtils.ageInYears(enteredDate);
    if (input.value.length === 0 || !dateFormat.test(input.value) || ageInYears > 122) {
      // existence of error message will trigger error state style
      return (this.errorMessage = this.INVALID_DATE_ERROR);
    } else {
      // removing error message will remove any error state style
      return (this.errorMessage = '');
    }
  }

  checkForErrors(_value: string) {}

  registerOnChange(fn: (value: string) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouch = fn;
  }

  writeValue(value: string): void {
    this._inputText = value;
    this.changeDetectorRef.detectChanges();
  }

  handleChange(value: string) {
    this.onChange(value);
  }

  handleTouched() {
    this.onTouch();
  }
}
