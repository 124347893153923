import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LinksService } from '@app/core/links.service';

@Component({
  selector: 'email-sent-confirmation-component',
  templateUrl: './email-sent-confirmation.component.html',
  styleUrls: ['./email-sent-confirmation.component.scss'],
})
export class EmailSentConfirmation implements OnInit {
  contactEmail = '';

  constructor(readonly links: LinksService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.contactEmail = this.mfaVerification() ? 'mfasupport@onemedical.com' : 'techsupport@onemedical.com';
  }

  private mfaVerification(): boolean {
    const queryParams = new URLSearchParams(this.route.snapshot.queryParams);
    return queryParams.get('mfaVerification') === 'true';
  }
}
