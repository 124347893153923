<form [formGroup]="form" [ngClass]="{ 'd-flex justify-content-start': inline }">
  <div
    class="my-2 d-flex"
    [class.submitting]="submitting"
    [class.me-5]="inline && !last"
    *ngFor="let option of options; let last = last"
  >
    <om-radio-input-ngrx
      [id]="option.elementId"
      [label]="option.label"
      [value]="option.value"
      [formControlName]="option.formControlName"
      data-cy="reschedule-reason"
      name="radio-config"
      [checked]="option.checked()"
      [disabled]="option.disabled"
      (click)="onClick(option)"
    ></om-radio-input-ngrx>
  </div>
</form>
