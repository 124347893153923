import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

import {
  AppointmentAnalyticsTypeInfo,
  AppointmentAnalyticsTypeInfoVariables,
} from './__generated__/appointment-analytics-type-info-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class AppointmentAnalyticsTypeInfoGraphQLService extends Query<
  AppointmentAnalyticsTypeInfo,
  AppointmentAnalyticsTypeInfoVariables
> {
  document = gql`
    query AppointmentAnalyticsTypeInfo($id: ID!) {
      appointment(id: $id) {
        id
        appointmentType {
          id
          displayName
        }
      }
    }
  `;
}
