import { CommonModule } from '@angular/common';
import { AfterContentInit, Component, ContentChildren, Input, NgModule, QueryList } from '@angular/core';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';

import { OmguiCollapseStateIconModule } from '../omgui-collapse-state-icon/omgui-collapse-state-icon.component';
import { OmguiListCardItemDirective, OMGUI_LIST_CARD_DIRECTIVES } from '../omgui-list-card/omgui-list-card.component';

@Component({
  selector: 'omgui-collapsible-list-card',
  templateUrl: './omgui-collapsible-list-card.component.html',
  styleUrls: ['./omgui-collapsible-list-card.component.scss'],
})
export class OmguiCollapsibleListCardComponent implements AfterContentInit {
  /** @ignore */
  @ContentChildren(OmguiListCardItemDirective) items: QueryList<OmguiListCardItemDirective>;

  /**
   * Controls the number of list items to always be displayed even if the list is collapsed.
   * If a value less than 1 is given, 1 will be used. Hiding all items is not supported.
   * This value is evaluated only upon the first rendering. Reactivity is not supported.
   * */
  @Input() numberToAlwaysShow = 3;

  /** @ignore */
  isCollapsed = true;
  /** @ignore */
  alwaysShownItems$: Observable<OmguiListCardItemDirective[]>;
  /** @ignore */
  hideableItems$: Observable<OmguiListCardItemDirective[]>;
  /** @ignore */
  readonly MINIMUM_ITEMS_ALWAYS_SHOWN = 1;

  /** @ignore */
  ngAfterContentInit(): void {
    const numberAlwaysShown = Math.max(this.MINIMUM_ITEMS_ALWAYS_SHOWN, this.numberToAlwaysShow);

    const itemsArray$ = this.items.changes.pipe(
      map(() => this.items.toArray()),
      startWith(this.items.toArray()),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.alwaysShownItems$ = itemsArray$.pipe(map(array => array.slice(0, numberAlwaysShown)));
    this.hideableItems$ = itemsArray$.pipe(map(array => array.slice(numberAlwaysShown)));
  }

  /** @ignore */
  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
}

@NgModule({
  declarations: [OmguiCollapsibleListCardComponent],
  imports: [CommonModule, NgbCollapseModule, ...OMGUI_LIST_CARD_DIRECTIVES, OmguiCollapseStateIconModule],
  exports: [OmguiCollapsibleListCardComponent, ...OMGUI_LIST_CARD_DIRECTIVES],
})
export class OmguiCollapsibleListCardModule {}
