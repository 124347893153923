import { Component } from '@angular/core';

@Component({
  selector: 'om-svg-add-family-member',
  template: `
    <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.32421 12.6104L2.51251 22.1392H17.1382L16.329 12.6405C16.297 12.2644 16.0815 11.9287 15.7411 11.7656C14.6981 11.2656 12.2712 10.2559 9.68194 10.2559C7.09994 10.2559 4.84082 11.2599 3.87357 11.7614C3.55346 11.9273 3.35482 12.2511 3.32421 12.6104Z"
        fill="white"
        stroke="#004D49"
        stroke-width="1.25"
        stroke-linecap="round"
      />
      <circle cx="9.82517" cy="3.85661" r="3.65641" fill="#004D49" />
      <path d="M1.20001 22.2695H18.7269" stroke="#004D49" stroke-width="1.25" stroke-linecap="round" />
      <path
        d="M3.32421 12.6104L2.51251 22.1392H17.1382L16.329 12.6405C16.297 12.2644 16.0815 11.9287 15.7411 11.7656C14.6981 11.2656 12.2712 10.2559 9.68194 10.2559C7.09994 10.2559 4.84082 11.2599 3.87357 11.7614C3.55346 11.9273 3.35482 12.2511 3.32421 12.6104Z"
        fill="white"
        stroke="#004D49"
        stroke-width="1.25"
        stroke-linecap="round"
      />
      <circle cx="9.82517" cy="3.85661" r="3.65641" fill="#004D49" />
      <path d="M1.20001 22.2695H18.7269" stroke="#004D49" stroke-width="1.25" stroke-linecap="round" />
      <circle cx="18.2492" cy="12.1229" r="5.575" fill="white" stroke="#004D49" stroke-width="1.25" />
      <path
        d="M18.25 14.435V10.5494"
        stroke="#004D49"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.3008 12.4926L20.1977 12.4926"
        stroke="#004D49"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
})
export class SvgAddFamilyMemberComponent {
  constructor() {}
}
