import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import camelCase from 'lodash-es/camelCase';
import debounce from 'lodash-es/debounce';
import isEmpty from 'lodash-es/isEmpty';
import noop from 'lodash-es/noop';
import {
  BehaviorSubject,
  switchMap,
  Observable,
  of as observableOf,
  pipe,
  throwError as observableThrowError,
  UnaryFunction,
} from 'rxjs';
import { catchError, filter, map, take, tap } from 'rxjs/operators';

import { AmazonUserProfileEncryptedPayload } from '@app/registration/amazon-user-profile.service';
import { ExpeditedRegistrationErrors } from '@app/registration/expedited/expedited-registration.reducer';
import { ParamValueMap } from '@app/shared';
import { Address } from '@app/shared/address';

import { EnterpriseRegistrationDetails, User } from '../shared/user';
import { AnalyticsService } from './analytics.service';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { FeatureFlags } from './feature-flags/feature-flags';
import { LaunchDarklyService } from './feature-flags/launchdarkly.service';

interface PatientApiV2Response {
  token: string;
  patient: SerializedApiV2Patient;
}

export class SerializedApiV2EnterpriseRegistrationDetails {
  private static readonly MAPPING_EXCEPTIONS: Record<string, string> = {
    discount_code: 'activationCode',
    b2b_company_id: 'b2bCompanyId',
  };

  b2b_company_id: number = null;
  discount_code: string = null;
  work_email: string = null;
  membership_type: string = null;
  whitelisted_employee_id: number = null;
  service_area_code: string = null;

  static fromEnterpriseRegistrationDetails(
    details?: EnterpriseRegistrationDetails,
  ): SerializedApiV2EnterpriseRegistrationDetails | undefined {
    if (!details) {
      return undefined;
    }

    const serializedDetails = new SerializedApiV2EnterpriseRegistrationDetails();

    Object.keys(serializedDetails).forEach(attr => {
      const detailKey = this.MAPPING_EXCEPTIONS[attr] || camelCase(attr);
      (<Record<string, any>>serializedDetails)[attr] = (<Record<string, any>>details)[detailKey] || null;
    });

    return serializedDetails;
  }
}

export class SerializedApiV2Address {
  private static readonly MAPPING_EXCEPTIONS: Record<string, string> = {
    state_code: 'state',
    is_preferred: 'preferred',
  };

  id: number = null;
  address1: string = null;
  address2: string = null;
  city: string = null;
  zip: string = null;
  state_id: number = null;
  state_code: string = null;
  is_preferred: boolean = null;

  static fromAddress(address?: Address): SerializedApiV2Address | undefined {
    if (!address || isEmpty(address)) {
      return undefined;
    }

    const serializedAddress = new SerializedApiV2Address();

    Object.keys(serializedAddress).forEach(attr => {
      const key: string = this.MAPPING_EXCEPTIONS[attr] || camelCase(attr);
      (<Record<string, any>>serializedAddress)[attr] = (<Record<string, any>>address)[key] || null;
    });

    return serializedAddress;
  }
}

export class SerializedApiV2EncryptedPayload {
  external_id: string = null;
  iv: string = null;
  tag: string = null;

  static fromPayload(payload?: AmazonUserProfileEncryptedPayload): SerializedApiV2EncryptedPayload | undefined {
    if (!payload || isEmpty(payload)) {
      return undefined;
    }

    const serializedPayload = new SerializedApiV2EncryptedPayload();

    Object.keys(serializedPayload).forEach(attr => {
      const key: string = camelCase(attr);
      (<Record<string, any>>serializedPayload)[attr] = (<Record<string, any>>payload)[key] || null;
    });

    return serializedPayload;
  }
}

export class SerializedApiV2Patient {
  private static readonly MAPPING_EXCEPTIONS: Record<string, string> = {
    member_since_at: 'memberSince',
    date_of_birth: 'dob',
    sex: 'gender',
    emp_name: 'employerName',
  };

  // Initialize these values to null so they can be looped through and set at runtime using Object.keys
  id: number = null;
  accessToken: string = null;
  first_name: string = null;
  last_name: string = null;
  preferred_name: string = null;
  nickname: string = null;
  gender_details: string = null;
  date_of_birth: string = null;
  phone_number: string = null;
  email: string = null;
  sex: string = null;
  preferred_email: string = null;
  password: string = null;
  service_area_id: number = null;
  terms_of_service_accepted: boolean = null;
  hearabout_id: number = null;
  hearabout_other: string = null;
  profile_image_url: string = null;
  type: string = null;
  member_since_at: string = null;
  office_id: number = null;
  emp_name: string = null;
  has_access_to_onsite: boolean = null;
  is_direct_signup_eligible: boolean = null;
  referral_link_web_social: string = null;
  age_in_years: number = null;
  has_unregistered_dependents: boolean = null;
  whitelisted_employee: boolean = null;
  membership_id: number = null;
  same_address: boolean = null;
  'g-recaptcha-response': string;
  reg_flow_version: string = null;

  address?: SerializedApiV2Address;
  enterprise_registration?: SerializedApiV2EnterpriseRegistrationDetails;
  encrypted_payload?: SerializedApiV2EncryptedPayload;
  claim_code?: string;

  static fromPatient(patient: User, options: CreateUserOptions = {}): SerializedApiV2Patient {
    const serializedPatient = new SerializedApiV2Patient();

    Object.keys(serializedPatient).forEach(attr => {
      const key = this.MAPPING_EXCEPTIONS[attr] || camelCase(attr);
      (<Record<string, any>>serializedPatient)[attr] = (<Record<string, any>>patient)[key] || null;
    });

    serializedPatient.service_area_id = patient.serviceArea && patient.serviceArea.id;
    serializedPatient.address = SerializedApiV2Address.fromAddress(patient.address);
    serializedPatient.enterprise_registration = SerializedApiV2EnterpriseRegistrationDetails.fromEnterpriseRegistrationDetails(
      patient.enterpriseRegistrationDetails,
    );
    serializedPatient.encrypted_payload = SerializedApiV2EncryptedPayload.fromPayload(options?.encryptedPayload);

    if (options?.recaptchaToken) {
      serializedPatient['g-recaptcha-response'] = options.recaptchaToken;
    }

    if (options?.claimCode) {
      serializedPatient.claim_code = options.claimCode;
    }

    return serializedPatient;
  }
}

export interface CreateUserOptions {
  recaptchaToken?: string;
  encryptedPayload?: AmazonUserProfileEncryptedPayload;
  claimCode?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user$ = new BehaviorSubject<User>(null);

  /** @deprecated There's no guarantee that user$ will emit something, which can result in subscribers not
   * receiving data at all. Subscribe to getUser() instead, which makes an API call for user data if
   * needed, so subscribers are guaranteed to receive something. Note that forcing a user data
   * refresh and wanting a continuous stream versus a single output of user data requires 2 calls, getUser(true)
   * then getUser().
   * */
  readonly user$: Observable<User>;

  _debouncedGetUser: () => Observable<User>;
  _debouncedCreatePediatricUserForNonmember: (user: User, discountCode: string) => Observable<User>;
  _debouncedCreatePediatricUserForEnterpriseNonmember: (
    user: User,
    enterpriseRegistrationDetails: EnterpriseRegistrationDetails,
  ) => Observable<User>;

  _debouncedCreatePediatricUserForConsumerNonmember: (user: User) => Observable<User>;
  _debouncedCreatePediatricUserForPrepaidNonmember: (user: User, claimCode: string) => Observable<User>;
  _debouncedCreatePediatricUserForPatient: (user: User, planId: number, claimCode: string) => Observable<User>;

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    private launchDarklyService: LaunchDarklyService,
  ) {
    this.user$ = this._user$.asObservable().pipe(
      filter(user => user != null),
      tap((user: User) => this.analyticsService.identifyAndUpdateUser(user)),
    );

    this._debouncedGetUser = debounce<() => Observable<User>>(this._getUser.bind(this), 1000, { leading: true });
    this._debouncedCreatePediatricUserForNonmember = debounce(this._createPediatricUserForNonmember.bind(this), 1000, {
      leading: true,
    });
    this._debouncedCreatePediatricUserForEnterpriseNonmember = debounce(
      this._createPediatricUserForEnterpriseNonmember.bind(this),
      1000,
      {
        leading: true,
      },
    );
    this._debouncedCreatePediatricUserForPatient = debounce(this._createPediatricUserForPatient.bind(this), 1000, {
      leading: true,
    });

    this._debouncedCreatePediatricUserForConsumerNonmember = debounce(
      this._createPediatricUserForConsumerNonmember.bind(this),
      1000,
      {
        leading: true,
      },
    );

    this._debouncedCreatePediatricUserForPrepaidNonmember = debounce(
      this._createPediatricUserForPrepaidNonmember.bind(this),
      1000,
      {
        leading: true,
      },
    );
  }

  getUser(force = false): Observable<User> {
    let req: Observable<User>;

    if (force) {
      req = this._getUser(); // Only returns 1 value, not a continuous stream of user data!
    } else if (this._user$.getValue() == null) {
      req = this._debouncedGetUser();
    } else {
      req = this.user$;
    }

    return req;
  }

  createUser(patient: User, options: CreateUserOptions = {}): Observable<User> {
    // Many of the calls in this service add a lot of logic in order to handle debouncing. It was unclear why
    // this was needed, so we don't handle it here. Investigate if we need to add it back.

    return this.apiService.post('/api/v2/public/patients', SerializedApiV2Patient.fromPatient(patient, options)).pipe(
      tap((response: PatientApiV2Response) => this.authService.setToken(response.token)),
      map((response: PatientApiV2Response) => {
        response.patient.accessToken = response.token;
        return response;
      }),
      map((response: PatientApiV2Response) => User.fromApiV2(response.patient)),
      catchError(() => this.handleUserCreationError(patient)),
    );
  }

  createExpeditedUser(patient: User, options: CreateUserOptions = {}): Observable<User> {
    return this.apiService
      .post('/api/v2/public/expedited_enrollments', SerializedApiV2Patient.fromPatient(patient, options))
      .pipe(
        tap((response: PatientApiV2Response) => this.authService.setToken(response.token)),
        map((response: PatientApiV2Response) => {
          response.patient.accessToken = response.token;
          return response;
        }),
        map((response: PatientApiV2Response) => User.fromApiV2(response.patient)),
        catchError((errorResponse: HttpErrorResponse) => {
          const { status, error } = errorResponse;
          if (status === 422 && error?.error?.match(/Insufficient data received/gi) != null) {
            return observableThrowError(ExpeditedRegistrationErrors.ValidationError);
          }
          return observableThrowError(ExpeditedRegistrationErrors.InternalServerError);
        }),
      );
  }

  createDirectSignupUser(patient: User): Observable<User> {
    return this.apiService
      .post('/api/v2/public/patients/create_direct_signup', SerializedApiV2Patient.fromPatient(patient))
      .pipe(
        map((response: PatientApiV2Response) => User.fromApiV2(response.patient)),
        catchError(this.handleDirectSignupError),
      );
  }

  submitEnterpriseConversion(patient: User, reCaptchaToken: string): Observable<any> {
    const requestBody = {
      'g-recaptcha-response': reCaptchaToken,
      enterprise_registration: SerializedApiV2EnterpriseRegistrationDetails.fromEnterpriseRegistrationDetails(
        patient.enterpriseRegistrationDetails,
      ),
    };

    return this.apiService.post('/api/v2/public/patients/enterprise_conversion', requestBody);
  }

  createPediatricUserForConsumerNonmember(user: User, force = false): Observable<User> {
    if (force) {
      return this._createPediatricUserForConsumerNonmember(user);
    } else {
      return this._debouncedCreatePediatricUserForConsumerNonmember(user);
    }
  }

  createPediatricUserForPrepaidNonmember(user: User, claimCode: string, force = false): Observable<User> {
    if (force) {
      return this._createPediatricUserForPrepaidNonmember(user, claimCode);
    } else {
      return this._debouncedCreatePediatricUserForPrepaidNonmember(user, claimCode);
    }
  }

  createPediatricUserForEnterpriseNonmember(
    user: User,
    enterpriseRegistrationDetails: EnterpriseRegistrationDetails,
    force = false,
  ): Observable<User> {
    if (force) {
      return this._createPediatricUserForEnterpriseNonmember(user, enterpriseRegistrationDetails);
    } else {
      return this._debouncedCreatePediatricUserForEnterpriseNonmember(user, enterpriseRegistrationDetails);
    }
  }

  createPediatricUserForPatient(user: User, planId?: number, claimCode?: string, force = false): Observable<User> {
    if (force) {
      return this._createPediatricUserForPatient(user, planId, claimCode);
    } else {
      return this._debouncedCreatePediatricUserForPatient(user, planId, claimCode);
    }
  }

  updateUserProfile(data: Record<string, any>) {
    return this.apiService.patch('/api/v2/patient/profile', data).pipe(catchError(this.handleUserUpdateError));
  }

  updateUser(params: ParamValueMap) {
    const request = this.apiService.patch('/api/v2/user', params).pipe(
      map(response => User.fromApiV2(response)),
      tap((user: User) => this._user$.next(user)),
    );

    request.subscribe({ error: noop });

    return request;
  }

  updateRegistration(params: ParamValueMap) {
    const request = this.apiService.post('/api/v2/user/update_registration', params).pipe(
      map(response => User.fromApiV2(response)),
      tap((user: User) => this._user$.next(user)),
    );

    request.subscribe({ error: noop });

    return request;
  }

  mergeUserWithGraphQLResponse(mergeResponse: (user: User) => void): void {
    const user = this._user$.getValue();

    mergeResponse(user);

    this._user$.next(user);
  }

  displayReferralCode(): Observable<Boolean> {
    return this.launchDarklyService.featureFlag$<boolean>(FeatureFlags.HORNBILL_DISABLE_REFERRAL_CODE, false).pipe(
      switchMap(featureEnabled => {
        if (featureEnabled) {
          return this.getUser().pipe(map(user => !user.isAmazonPlanType()));
        }
        return observableOf(true);
      }),
    );
  }

  private _getUser() {
    const req = this.apiService.get('/api/v2/user.json').pipe(map(user => User.fromApiV2(user)));
    req.subscribe(user => {
      this._user$.next(user);
    });
    return req;
  }

  private handleUserCreationError(patient: User) {
    let message =
      "We've encountered an issue creating your account. Please try again. If this issue persists, please email us at admin@onemedical.com.";
    if (patient.enterpriseRegistrationDetails) {
      message =
        "We encountered an issue creating your account. If you have an email on file with us, we've sent you a message. Contact techsupport@onemedical.com for additional help.";
    }
    return observableThrowError(message);
  }

  private handleDirectSignupError(response: { error: any }) {
    if (response.error) {
      return observableThrowError(response.error.error);
    } else {
      return observableThrowError(
        "We've encountered an issue creating the account. Please try again. If this issue persists, please email us at admin@onemedical.com",
      );
    }
  }

  private handlePediatricCreationError(response: { error: any }) {
    const message =
      "We've encountered an issue creating your account. Please try again. If this issue persists, please email us at admin@onemedical.com";

    return observableThrowError({ message: message, error: response.error });
  }

  private handleUserUpdateError() {
    const message =
      'We seem to have run into an issue saving your information. Please try again. If this issue persists, please email us at admin@onemedical.com';

    return observableThrowError(message);
  }

  private _createPediatricUserForNonmember(patient: User): Observable<User> {
    const params = {
      patient: User.forPediatricApiV2(patient, null),
    };

    const postCall = this.apiService.post<{ token: string }>('/api/v2/public/pediatric/patients', params);

    this.setToken(postCall);

    return postCall.pipe(this.mapRestResponseToUser());
  }

  private _createPediatricUserForConsumerNonmember(patient: User): Observable<User> {
    const postCall = this.apiService.post<{ token: string }>('/api/v2/public/pediatric/patients', {
      patient: User.forPediatricApiV2(patient, null),
    });

    this.setToken(postCall);

    return postCall.pipe(this.mapRestResponseToUser());
  }

  private _createPediatricUserForPrepaidNonmember(patient: User, claimCode: string): Observable<User> {
    const params = {
      patient: User.forPediatricApiV2(patient, null),
      claim_code: claimCode,
    };

    const postCall = this.apiService.post<{ token: string }>('/api/v2/public/pediatric/patients', params);

    this.setToken(postCall);

    return postCall.pipe(this.mapRestResponseToUser());
  }

  private _createPediatricUserForEnterpriseNonmember(
    patient: User,
    enterpriseRegistrationDetails: EnterpriseRegistrationDetails,
  ): Observable<User> {
    const params = {
      patient: User.forPediatricApiV2(patient, null),
      enterprise_registration: SerializedApiV2EnterpriseRegistrationDetails.fromEnterpriseRegistrationDetails(
        enterpriseRegistrationDetails,
      ),
    };

    const postCall = this.apiService.post<{ token: string }>('/api/v2/public/pediatric/patients', params);

    this.setToken(postCall);

    return postCall.pipe(this.mapRestResponseToUser());
  }

  private _createPediatricUserForPatient(patient: User, planId: number, claimCode: string): Observable<User> {
    const params = {
      patient: User.forPediatricApiV2(patient, null),
      ...(planId && { b2b_plan_id: planId }),
      ...(claimCode && { claim_code: claimCode }),
    };

    const postCall = this.apiService.post('/api/v2/patient/pediatric/patients', params);
    return postCall.pipe(this.mapRestResponseToUser());
  }

  private mapRestResponseToUser(): UnaryFunction<Observable<object>, Observable<User>> {
    return pipe(
      catchError(this.handlePediatricCreationError),
      map((response: object) => User.fromApiV2(response)),
    );
  }

  private setToken(postCall: Observable<{ token: string }>) {
    postCall.pipe(take(1)).subscribe({
      next: response => {
        this.authService.setToken(response.token);
      },
      error: _error => {},
    });
  }
}
