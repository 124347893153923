import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppointmentBookingStateService } from '@app/appointment/appointment-booking-state-service';
import { AppointmentRoutingStateService } from '@app/appointment/appointment-routing-state-service/appointment-routing-state.service';

import { BaseConfirmationModalActionComponent } from '../base-confirmation-modal-action/base-confirmation-modal-action.component';
import { BookConfirmationModalActionService } from './book-confirmation-modal-action.service';

@Component({
  selector: 'om-book-confirmation-modal-action',
  templateUrl: '../base-confirmation-modal-action/base-confirmation-modal-action.component.html',
  styleUrls: ['../base-confirmation-modal-action/base-confirmation-modal-action.component.scss'],
})
export class BookConfirmationModalActionComponent extends BaseConfirmationModalActionComponent implements OnInit {
  constructor(
    bookingStateService: AppointmentBookingStateService,
    router: Router,
    routingStateService: AppointmentRoutingStateService,
    bookActionService: BookConfirmationModalActionService,
  ) {
    super(bookingStateService, router, routingStateService, bookActionService);
  }

  submit() {
    this.analyticsService.bookAppointmentClicked(this.selectedInventory);
    super.submit();
  }
}
