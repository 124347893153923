import { Injectable } from '@angular/core';
import { addDays, isBefore, subDays } from 'date-fns';

import { AppointmentAnalyticsService } from '../appointment-analytics.service';
import { AppointmentBookingState } from '../appointment-booking-state-service/appointment-booking-state';
import { AppointmentSearchState } from './appointment-search-state';

@Injectable()
export class AppointmentSearchStateService {
  numDisplayDays: number;

  private searchState: AppointmentSearchState;

  constructor(private analyticsService: AppointmentAnalyticsService) {}

  initSearchState(bookingState: AppointmentBookingState, numDisplayDays: number) {
    const searchState = new AppointmentSearchState(bookingState);
    searchState.dateStart = new Date();
    this.searchState = searchState;
    this.analyticsService.setSearchState(searchState);
    this.setNumDisplayDays(numDisplayDays);
  }

  setNumDisplayDays(numDisplayDays: number): void {
    this.numDisplayDays = numDisplayDays;
    this.searchState.dateEnd = addDays(this.searchState.dateStart, this.dayDiff);
  }

  getSearchState(): AppointmentSearchState {
    if (!this.searchState) {
      console.warn('Appointment Search State not set');
    }
    return this.searchState;
  }

  previousPage(): void {
    let newDateEnd: Date = subDays(this.searchState.dateEnd, this.numDisplayDays);
    let newDateStart: Date = subDays(newDateEnd, this.dayDiff);
    const today: Date = new Date();
    if (isBefore(newDateStart, today)) {
      newDateStart = today;
      newDateEnd = addDays(today, this.dayDiff);
    }

    this.searchState.dateStart = newDateStart;
    this.searchState.dateEnd = newDateEnd;
  }

  nextPage(): void {
    const newDateStart = addDays(this.searchState.dateStart, this.numDisplayDays);
    this.searchState.dateStart = newDateStart;
    this.searchState.dateEnd = addDays(newDateStart, this.dayDiff);
  }

  private get dayDiff(): number {
    return this.numDisplayDays - 1;
  }
}
