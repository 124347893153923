import { Component } from '@angular/core';

@Component({
  selector: 'om-svg-parent-and-child-icon',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.23431 12.6975C5.0903 12.2626 6.96688 11.4583 9.07999 11.4583C11.205 11.4583 13.2291 12.2708 14.1626 12.7098C14.259 12.7551 14.3386 12.844 14.3757 12.9646C14.7528 12.8428 15.1634 12.7429 15.5954 12.6879C15.4776 12.2175 15.1656 11.8001 14.6946 11.5786C13.7052 11.1133 11.481 10.2083 9.07999 10.2083C6.68015 10.2083 4.59634 11.1115 3.66819 11.583C3.13353 11.8546 2.82433 12.3818 2.77696 12.9344L2.05813 21.3206L1.99999 21.9989H2.68085H11.6048L11.6918 20.7489H3.36171L4.02239 13.0412C4.03677 12.8735 4.1244 12.7533 4.23431 12.6975Z"
        fill="currentColor"
      />
      <path
        d="M12.272 5.22938C12.272 6.73383 11.0439 7.95875 9.5224 7.95875C8.00091 7.95875 6.77283 6.73383 6.77283 5.22938C6.77283 3.72492 8.00091 2.5 9.5224 2.5C11.0439 2.5 12.272 3.72492 12.272 5.22938Z"
        stroke="currentColor"
      />
      <path
        d="M12.1528 14.8046L11.5895 21.3765H22L21.4393 14.8347C21.407 14.4577 21.1911 14.1215 20.847 13.9644C20.0359 13.594 18.4148 12.9705 16.6927 12.9705C14.9764 12.9705 13.4605 13.5899 12.7068 13.9607C12.3824 14.1203 12.1837 14.4444 12.1528 14.8046Z"
        stroke="currentColor"
        stroke-width="1.25"
        stroke-linecap="round"
      />
      <path
        d="M18.8975 8.44413C18.8975 9.59352 17.9591 10.5306 16.7949 10.5306C15.6307 10.5306 14.6923 9.59352 14.6923 8.44413C14.6923 7.29474 15.6307 6.35767 16.7949 6.35767C17.9591 6.35767 18.8975 7.29474 18.8975 8.44413Z"
        stroke="currentColor"
      />
    </svg>
  `,
})
export class SvgParentAndChildIconComponent {}
