import { Component, Input } from '@angular/core';
import { map } from 'rxjs/operators';

import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { ComponentSize } from '@omgui/component-size';

import { Office } from '../../../shared/office';
import { Provider } from '../../../shared/provider';

export enum BiosInBookingExperiment {
  Off = 'OFF',
  Control = 'ON CONTROL',
  WithSummary = 'ON WITH SUMMARY',
}

@Component({
  selector: 'om-provider-detail',
  templateUrl: './provider-detail.component.html',
  styleUrls: ['./provider-detail.component.scss'],
})
export class ProviderDetailComponent {
  @Input() provider: Provider;
  @Input() office: Office;
  @Input() remote: boolean;

  protected readonly componentSize = ComponentSize;
  protected readonly displayProviderBios$ = this.launchDarklyService
    .featureFlag$(FeatureFlags.BHX_PROVIDER_BIOS_IN_BOOKING_EXPERIMENT, BiosInBookingExperiment.Off)
    .pipe(map(variant => BiosInBookingExperiment.WithSummary === variant));

  constructor(private launchDarklyService: LaunchDarklyService) {}
}
