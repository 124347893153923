<div class="container mt-5 pt-5 d-flex">
  <div class="col-12 col-xl-5">
    <h1 *ngIf="isNewPatient">Your sponsored membership is now active</h1>
    <h1 *ngIf="!isNewPatient">Your sponsored membership is updated</h1>
    <p class="lh-175" *ngIf="!shouldRedirectToConsentForm">
      We sent a confirmation email to {{ patientEmail }}. You now have access to exceptional primary care, in-person and
      virtually, to help you meet your health needs and goals.
      <span *ngIf="showDirectSignup">Sign up your family so they can experience the same.</span>
    </p>
    <p class="lh-175" *ngIf="shouldRedirectToConsentForm">
      Just one more step! Your sponsored organization requires that you complete the consent form before booking an
      appointment.
    </p>
    <div *ngIf="!shouldRedirectToConsentForm">
      <div *ngIf="showDirectSignup">
        <a class="btn btn-primary btn-block mt4" data-cy="direct-signup-link" (click)="goToDirectSignup()">
          Register a spouse or dependent
        </a>
      </div>
      <div class="d-flex justify-content-center">
        <a
          data-cy="home-link"
          [ngClass]="showDirectSignup ? 'btn btn-link' : 'btn btn-primary btn-block mt-4'"
          (click)="skipDirectSignup()"
        >
          {{ showDirectSignup ? 'Skip for now' : 'Continue' }}
        </a>
      </div>
    </div>
    <div *ngIf="shouldRedirectToConsentForm">
      <a
        class="btn btn-primary btn-block mt-4"
        data-cy="consent-link"
        href="/health-information-release/employer-release"
        [omTrackLink]="trackConsentFormClicked"
      >
        Complete consent form
      </a>
    </div>
  </div>
  <div class="col-xl-7 d-none d-xl-flex justify-content-center">
    <img class="col-10" src="/assets/images/office.svg" role="presentation" />
  </div>
</div>
