import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import some from 'lodash-es/some';
import { Subject } from 'rxjs';
import { takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { ConfigService } from '@app/core/config.service';

import { BasicAddress } from './../address';
import { StateService } from './../state.service';
import { AddressAutocompleteComponent } from './address-autocomplete/address-autocomplete.component';

@Component({
  selector: 'om-address-input',
  templateUrl: './address-option-input.component.html',
  styleUrls: ['./address-option-input.component.scss', '../form-input.scss'],
})
export class AddressOptionInputComponent implements OnInit, OnDestroy {
  hasAutocompleteError: boolean;
  private destroy$ = new Subject<void>();

  @Input() address: UntypedFormGroup;
  @Input() enableAutoFocus: Boolean = true;
  @Input() enableAutoComplete: Boolean = true;
  @Input() prefilledRegistration: Boolean = false;
  @Output() fullAddressFormShown = new EventEmitter<void>();
  @ViewChild('autocomplete') autocomplete: AddressAutocompleteComponent;

  fullAddressFormVisible = false;
  address2Visible = false;
  existingAddress = '';

  constructor(
    public config: ConfigService,
    public formBuilder: UntypedFormBuilder,
    private stateService: StateService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    if (!this.config.json.googlePlacesKey) {
      this.fullAddressFormVisible = true;
    }
  }

  ngOnInit() {
    this.autoUpdateStateId();
    const existingAddressAttributes = some(Object.values(this.address.value), value => !!value);

    if (this.address.valid) {
      const address = <BasicAddress>this.address.value;
      this.existingAddress = `${address.address1}, ${address.city}, ${address.state}, ${address.zip}`;
    } else if (existingAddressAttributes && this.prefilledRegistration) {
      this.markControlKeysTouchedAndDirty();
      this.enableAutoComplete = false;
    }
    if (!this.enableAutoComplete) {
      this.fullAddressFormVisible = true;
    }
  }

  autoUpdateStateId() {
    this.address.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        withLatestFrom(this.stateService.states$),
        tap(([address, states]) => {
          const state = states.find(s => s.shortName === address.state);
          this.address.patchValue({ stateId: state ? state.id : '' }, { emitEvent: false });
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addressSelected(address: BasicAddress) {
    const address2 = this.address.value.address2;
    this.address.patchValue(address);
    // Preserve address2 value for prefilled registration
    if (address2 !== '' && this.prefilledRegistration) {
      this.address.patchValue({ address2: address2 });
    }
    this.markControlKeysTouchedAndDirty();
    this.hasAutocompleteError = !this.address.valid;
    this.changeDetectorRef.detectChanges();
  }

  showFullAddressForm(event: Event) {
    this.hasAutocompleteError = false;
    this.fullAddressFormVisible = true;
    this.detectChangesAndStopEvent(event);

    this.fullAddressFormShown.emit();
  }

  get address2Shown() {
    return this.address2Visible || this.address2Present();
  }

  address2Present() {
    return this.address.value.address2 && this.address.value.address2 !== '';
  }

  markAsTouchedAndDirty(): void {
    if (this.autocomplete) {
      this.autocomplete.markAsTouchedAndDirty();
    }
  }

  private detectChangesAndStopEvent(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.changeDetectorRef.detectChanges();
  }

  private markControlKeysTouchedAndDirty() {
    Object.keys(this.address.controls).forEach(controlKey => {
      this.address.controls[controlKey].markAsDirty();
    });
  }
}
