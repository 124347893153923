import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';

import { emailValidator } from '@app/shared/email-validator';

type UpdateOnInput = 'blur' | 'change' | 'submit';

@Component({
  selector: 'om-email-input',
  templateUrl: './email-input.component.html',
  styleUrls: ['../form-input.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailInputComponent),
      multi: true,
    },

    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmailInputComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
})
export class EmailInputComponent implements AfterViewInit, OnInit, ControlValueAccessor, Validator {
  @Input() autofocus = false;
  @Input() placeholder = 'Email';
  @Input() updateOn = 'blur' as UpdateOnInput;
  @ViewChild('input', { static: true }) input: ElementRef;

  emailForm: UntypedFormGroup;
  invalidEmail: boolean;
  serverError: string;

  constructor(public formBuilder: UntypedFormBuilder) {}

  ngAfterViewInit() {
    if (this.autofocus) {
      this.focusAtNextCycle();
    }
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.emailForm = this.formBuilder.group({
      email: this.formBuilder.control('', {
        validators: [Validators.required, emailValidator],
        updateOn: this.updateOn,
      }),
    });
  }

  onTouched: () => void = () => {};

  writeValue(val: any): void {
    if (val) {
      this.emailForm.setValue({ email: val }, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.emailForm.valueChanges.subscribe(formData => {
      fn(formData.email);
    });
  }

  private setErrorMessages() {
    this.serverError =
      this.email.errors && this.email.errors.serverError
        ? this.setupErrorMessage(this.email.errors.serverError.messages)
        : null;
    this.invalidEmail = !!(this.emailForm.dirty && this.email.errors && this.email.errors.invalidEmail);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(c: AbstractControl): ValidationErrors | null {
    if (c.errors && c.errors.serverError) {
      this.emailForm.controls.email.setErrors({
        ...this.emailForm.controls.email.errors,
        serverError: c.errors.serverError,
      });
    }
    this.setErrorMessages();
    return this.emailForm.valid ? null : { email: { valid: false, errors: this.emailForm.controls.email.errors } };
  }

  get email() {
    return this.emailForm.controls.email;
  }

  setupErrorMessage(messages: string[]) {
    let message = '';
    if (messages.length > 1) {
      const lastError = messages.pop();
      message = `This email ${messages.join(', ')} and ${lastError}`;
    } else if (messages) {
      message = `This email ${messages[0]}`;
    }

    return message;
  }

  markAsDirty() {
    this.emailForm.controls.email.markAsDirty();
  }

  markAsTouched() {
    this.emailForm.controls.email.markAsTouched();
  }

  markAsTouchedAndDirty() {
    this.emailForm.controls.email.markAsTouched();
    this.emailForm.controls.email.markAsDirty();
  }

  focus(): void {
    this.input.nativeElement.focus();
  }

  blur(): void {
    this.input.nativeElement.blur();
  }

  private focusAtNextCycle() {
    /*
      Fixes a bug where the whole page is pushed up in the iOS (v17)
      webview, causing the input to be out of view. By delaying
      focusing to the next cycle, the webview can properly determine
      how much to scroll the view by to center the field when
      displaying the soft keyboard. See Jira ticket QATRIAGE-100.
     */
    setTimeout(() => this.focus());
  }
}
