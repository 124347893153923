<div ngbDropdown container="body">
  <omgui-button
    ngbDropdownToggle
    class="no-arrow-dropdown"
    customButtonClass="btn-alternate dropdown-toggle inline-icon-with-text"
    [variant]="ButtonVariant.secondary"
    [disabled]="disabled"
    data-cy="appointment-actions"
  >
    <span class="text-primary p">{{ name }}</span>
  </omgui-button>
  <div ngbDropdownMenu>
    <button
      type="button"
      ngbDropdownItem
      [routerLink]="detailsLink"
      [queryParams]="{ source: trackingSource }"
      (click)="viewDetailsClicked.emit()"
      data-cy="view-details"
    >
      {{ viewDetailsButtonLabel }}
    </button>
    <button type="button" ngbDropdownItem (click)="startAppointmentCancellation()" data-cy="cancel">
      {{ cancelButtonLabel }}
    </button>
    <button type="button" ngbDropdownItem (click)="startAppointmentReschedule()" data-cy="reschedule">
      {{ rescheduleButtonLabel }}
    </button>
    <button type="button" ngbDropdownItem (click)="showRunningLateConfirmation()" data-cy="running-late">
      {{ runningLateButtonLabel }}
    </button>
  </div>
</div>

<ng-template #confirmationModal let-modal>
  <om-confirmation-modal [title]="modalTitle" [body]="modalBody" [close]="modal.close"></om-confirmation-modal>
</ng-template>
