import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

import { BreakpointService } from '@app/omgui/breakpoint.service';
import {
  OmguiButtonComponent,
  OmguiButtonModule,
  OmguiButtonSize,
  OmguiButtonType,
  OmguiButtonVariant,
} from '@app/omgui/omgui-button/omgui-button.component';

import { ErrorStateBodyContentDirective, ErrorStateComponentBase } from './error-state-base.component';

/**
 * Class representing the responsive layout for error states. It consists of a title,
 * subcopy, optional illustration, primary action, and optional secondary action.
 */
@Component({
  selector: 'om-error-state',
  templateUrl: './error-state.component.html',
  styleUrls: ['./error-state.component.scss'],
  standalone: true,
  imports: [CommonModule, OmguiButtonModule],
  providers: [BreakpointService],
})
export class ErrorStateComponent
  extends ErrorStateComponentBase
  implements AfterViewInit, OnChanges, OnDestroy, OnInit {
  /**
   * Toggles whether or not the primary call-to-action button receives
   * focus after content is rendered.
   */
  @Input() autofocusPrimaryButton = true;
  /**
   * Action fired when the primary call-to-action button is clicked.
   */
  @Output() primaryButtonClick: EventEmitter<void> = new EventEmitter();
  /**
   * Action fired when the secondary call-to-action button is clicked.
   */
  @Output() secondaryButtonClick: EventEmitter<void> = new EventEmitter();

  /**
   * @ignore - Storybook
   */
  @ViewChild('primaryButtonRef') private primaryButtonRef: OmguiButtonComponent;

  /**
   * @ignore - Storybook
   */
  protected readonly ButtonVariant = OmguiButtonVariant;
  /**
   * @ignore - Storybook
   */
  protected readonly ButtonSize = OmguiButtonSize;
  /**
   * @ignore - Storybook
   */
  protected readonly ButtonType = OmguiButtonType;
  /**
   * @ignore - Storybook
   */
  protected containerClass = 'container';
  /**
   * @ignore - Storybook
   */
  protected titleClass = 'title';
  /**
   * @ignore - Storybook
   */
  protected bodyContentClass = 'body-content';
  /**
   * @ignore - Storybook
   */
  protected buttonContainerClass = 'button-container';
  /**
   * @ignore - Storybook
   */
  protected primaryButtonClass = 'primary-button';
  /**
   * @ignore - Storybook
   */
  protected secondaryButtonClass = 'secondary-button';
  /**
   * @ignore - Storybook
   */
  protected imageClass = 'image';

  /**
   * @ignore - Storybook
   */
  private isCollapsed = false;
  /**
   * @ignore - Storybook
   */
  protected hasImageSource = false;
  /**
   * @ignore - Storybook
   */
  private destroy$ = new Subject<void>();

  constructor(private breakpointService: BreakpointService) {
    super();
  }

  /**
   * @ignore - Storybook
   */
  ngOnChanges(): void {
    this.hasImageSource = this.imageSource && this.imageSource.length > 0;
    this.modifyClasses();
  }

  /**
   * @ignore - Storybook
   */
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * @ignore - Storybook
   */
  ngOnInit(): void {
    this.breakpointService
      .isXs$()
      .pipe(startWith(null), takeUntil(this.destroy$))
      .subscribe((result: boolean) => {
        this.isCollapsed = !!result;
        this.modifyClasses();
      });
  }

  /**
   * @ignore - Storybook
   */
  ngAfterViewInit(): void {
    if (this.autofocusPrimaryButton) {
      this.primaryButtonRef?.focus();
    }
  }

  /**
   * @ignore - Storybook
   */
  private modifyClasses(): void {
    this.containerClass = this.modifyClass('container');
    this.titleClass = this.modifyClass('title');
    this.bodyContentClass = this.modifyClass('body-content');
    this.buttonContainerClass = this.modifyClass('button-container');
    this.primaryButtonClass = this.modifyClass('primary-button');
    this.secondaryButtonClass = this.modifyClass('secondary-button');
    this.imageClass = this.modifyClass('image');
  }

  /**
   * @ignore - Storybook
   */
  private modifyClass(clazz: string): string {
    let modifiedClass = clazz;

    if (!this.hasImageSource) {
      modifiedClass += ` ${clazz}--no-image`;
    }

    if (this.isCollapsed) {
      modifiedClass += ` ${clazz}--collapsed`;
    }

    return modifiedClass;
  }
}

// When importing to another module or standalone component, use this array of dependencies instead.
// ErrorStateBodyContentDirective is required when using a `<ng-template om-error-state-body-content>` for body content
// See https://angular.io/guide/standalone-components#advanced-topics
export { ErrorStateBodyContentDirective } from './error-state-base.component';
export const ERROR_STATE_DIRECTIVES = [ErrorStateComponent, ErrorStateBodyContentDirective] as const;
