<ng-container *ngIf="renderLabelAddOn; else hideLabelAddOn">
  <div class="d-flex justify-content-between">
    <label class="form-label" [ngClass]="labelClass" [attr.for]="labelFor">{{ this.label }}</label>
    <ng-content select="[slot=label-addon]"></ng-content>
  </div>
</ng-container>
<div [ngClass]="inputWrapperClass"><ng-content></ng-content></div>
<small
  *ngIf="hasError && errorMessage"
  data-cy="error-message"
  [ngClass]="errorMessageClass"
  [innerHTML]="errorMessage"
></small>

<ng-template #hideLabelAddOn>
  <label class="form-label" [ngClass]="labelClass" [attr.for]="labelFor">{{ this.label }}</label>
</ng-template>
