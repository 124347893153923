import { Component, Input, NgModule } from '@angular/core';

@Component({
  selector: 'om-svg-caret-right',
  template: `
    <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.77" d="M0.5 0.5L7.5 7.5L0.500001 14.5" [attr.stroke]="stroke" />
    </svg>
  `,
})
export class SvgCaretRightComponent {
  @Input() stroke = '#1A1A1A';
}

@NgModule({
  declarations: [SvgCaretRightComponent],
  exports: [SvgCaretRightComponent],
})
export class SvgCaretRightModule {}
