import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { RedirectPromptModalComponent } from '../redirect-prompt-modal.component';

@Component({
  selector: 'om-internal-redirect-prompt-modal',
  templateUrl: '../redirect-prompt-modal.component.html',
  styleUrls: ['../redirect-prompt-modal.component.scss'],
})
export class InternalRedirectPromptModalComponent extends RedirectPromptModalComponent {
  constructor(protected modal: NgbActiveModal, private router: Router) {
    super(modal);
  }

  redirect(): void {
    this.router.navigateByUrl(this.redirectUrl);
    super.redirect();
  }
}
