import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

import { FetchAppointmentCancellationReasons } from './__generated__/FetchAppointmentCancellationReasons';

@Injectable({
  providedIn: 'root',
})
export class AppointmentCancellationReasonsGraphQL extends Query<FetchAppointmentCancellationReasons, {}> {
  document = gql`
    query FetchAppointmentCancellationReasons {
      appointmentCancellationReasons {
        id
        displayReason
      }
    }
  `;
}
