<ng-template #brand>
  <a
    routerLink="/"
    [omTrackLink]="trackHomeLink"
    class="navbar-brand home"
    [class.disabled]="inactiveMembership"
    aria-label="One Medical"
    data-cy="om-logo-nav"
  >
    <div>
      <om-logo class="d-block d-md-none d-lg-block"></om-logo>
      <om-logo-sm class="d-none d-md-block d-lg-none"></om-logo-sm>
    </div>
  </a>
</ng-template>

<nav
  aria-label="Main Nav"
  data-cy="main-nav"
  class="navbar full d-flex navbar-expand-md justify-content-between bg-white fixed-top"
  [class.main-nav-scroll]="(isScrolledToTop$ | async) === false"
>
  <ng-container *ngTemplateOutlet="brand"></ng-container>

  <ul class="navbar-nav d-flex align-items-center">
    <li class="nav-item p-2">
      <div class="nav-link d-flex flex-column align-items-center">
        <a
          [routerLink]="navbarRoutes.Home"
          [omTrackLink]="trackHomeLink"
          data-cy="home-nav"
          class="text-link home"
          [class.disabled]="inactiveMembership"
        >
          Home
        </a>
        <div class="active-section bg-primary" [class.invisible]="!routeInfo[navbarRoutes.Home].isActive"></div>
      </div>
    </li>
    <li class="nav-item">
      <div class="nav-link p-2 d-flex flex-column align-items-center">
        <a
          [routerLink]="navbarRoutes.HealthRecord"
          [omTrackLink]="trackHealthRecordLink"
          data-cy="health-record-nav"
          class="text-link health-record"
        >
          Health Record
        </a>
        <div class="active-section bg-primary" [class.invisible]="!routeInfo[navbarRoutes.HealthRecord].isActive"></div>
      </div>
    </li>
    <ng-container *ngTemplateOutlet="carePlanItem"></ng-container>
    <ng-container *ngTemplateOutlet="messagesItem"></ng-container>
    <li class="nav-item">
      <omgui-button
        [variant]="(isScrolledToTop$ | async) === false ? OmguiButtonVariant.primary : OmguiButtonVariant.secondary"
        [size]="OmguiButtonSize.small"
        [internalLink]="links.newAppointment"
        [trackLink]="trackBookVisitLink"
        data-cy="book-visit-nav"
        class="nav-link book-visit"
        [disabled]="!canBookVisit"
      >
        Schedule Visit
      </omgui-button>
    </li>
    <li ngbDropdown class="nav-item" display="dynamic" placement="bottom-right" (openChange)="setDropdownOpen($event)">
      <button
        aria-label="Profile Menu Toggle"
        class="nav-link p-2 d-inline-flex align-items-center navbar-menu rounded profile-menu-button remove-dropdown-caret"
        id="profile-menu-label"
        data-cy="profile-menu-toggle"
        ngbDropdownToggle
      >
        <omgui-profile-bubble
          [size]="ComponentSize.mediumSmall"
          aria-hidden="true"
          [profile]="profile"
          class="navbar-menu"
          data-cy="profile-menu-nav"
        ></omgui-profile-bubble>
        <div class="px-2">
          <ng-container *ngIf="dropdownOpen; then caretUp; else caretDown"></ng-container>
        </div>
      </button>
      <div
        class="dropdown-menu py-2"
        ngbDropdownMenu
        aria-label="Profile Menu"
        aria-labelledby="profile-menu-label"
        data-cy="profile-menu"
      >
        <om-navbar-dropdown
          class="navbar-dropdown-redesign"
          data-cy="dropdown-redesign"
          [profile]="profile"
          [canRegisterKid]="canRegisterKid"
          [directSignupEligible]="directSignupEligible"
          [enrolledInMindsetPlus]="enrolledInMindsetPlus"
          [inviteCta]="inviteCta"
          [displayInviteCta]="displayInviteCta"
          [virtual]="virtual"
          [currentUser]="currentUser"
          [principalUser]="principalUser"
          [targetUsers]="targetUsers"
          (principalUserClicked)="resetTargetUser()"
          (targetUserClicked)="setTargetUser($event)"
        ></om-navbar-dropdown>
      </div>
    </li>
  </ul>
</nav>

<ng-template #messagesItem>
  <li class="nav-item d-flex p-2 position-relative">
    <div class="nav-link d-flex flex-column align-items-center">
      <a
        [routerLink]="navbarRoutes.Messages"
        [omTrackLink]="trackMessagesLink"
        data-cy="messages-nav"
        class="text-link messages"
      >
        Messages
      </a>
      <div class="active-section bg-primary" [class.invisible]="!routeInfo[navbarRoutes.Messages].isActive"></div>
    </div>
    <ng-container *rxLet="hasUnreadMessages$ as hasUnreadMessages">
      <div
        data-cy="messages-badge"
        class="om-badge position-absolute bg-danger rounded-circle"
        [class.invisible]="!hasUnreadMessages"
      ></div>
    </ng-container>
  </li>
</ng-template>

<ng-template #carePlanItem>
  <li class="nav-item d-flex p-2 position-relative">
    <div class="nav-link d-flex flex-column align-items-center">
      <a
        [routerLink]="navbarRoutes.CarePlan"
        [omTrackLink]="trackTasksLink"
        data-cy="care-plan-nav"
        class="text-link tasks"
      >
        Care Plan
      </a>
      <div class="active-section bg-primary" [class.invisible]="!routeInfo[navbarRoutes.CarePlan].isActive"></div>
    </div>
    <ng-container *rxLet="hasActiveTasks$ as hasActiveTasks">
      <div
        data-cy="care-plan-badge"
        class="om-badge position-absolute bg-danger rounded-circle"
        [class.invisible]="!hasActiveTasks"
      ></div>
    </ng-container>
  </li>
</ng-template>

<ng-template #caretUp>
  <om-svg-caret-up data-cy="caret-up"></om-svg-caret-up>
</ng-template>

<ng-template #caretDown>
  <om-svg-caret-down data-cy="caret-down"></om-svg-caret-down>
</ng-template>
