import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of as observableOf, switchMap } from 'rxjs';

import { TrialVideoVisitOnboardingGraphQL } from '@app/membership/gcn-confirmation/trial-video-visit-onboarding-graphql.service';
import { amazonFlowStatus } from '@app/registration/routing/constants';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class TrialVideoVisitGuardService implements CanActivate {
  constructor(
    private authService: AuthService,
    private trialVideoVisitOnboardingGraphQLService: TrialVideoVisitOnboardingGraphQL,
    private router: Router,
  ) {}

  canActivate(_route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.authService.isLoggedIn$().pipe(
      switchMap(isLoggedIn => {
        if (!isLoggedIn) {
          return observableOf(false);
        }
        return this.trialVideoVisitOnboardingGraphQLService.fetch({}, { fetchPolicy: 'network-only' }).pipe(
          switchMap(response => {
            const patient = response.data?.patient;

            if (
              patient?.hasAmazonPrimeTrial &&
              !patient?.isRegComplete &&
              patient?.completedAmazonFlowStatus === amazonFlowStatus.gcn_trial
            ) {
              return observableOf(this.router.parseUrl('/trial-video-visit-onboarding'));
            }
            return observableOf(true);
          }),
        );
      }),
    );
  }
}
