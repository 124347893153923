import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { captureException } from '@sentry/angular-ivy';

import { MyOneAppModule } from '@app/app.module';
import { initializeSentry } from '@app/core/errors/sentry';

import { environment } from './environments/environment';

initializeSentry();

if (environment.enableProdMode) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(MyOneAppModule, { preserveWhitespaces: true })
  .catch(error => captureException(error));
