<div class="expanding-container" [class.lined]="lined">
  <pre aria-hidden="true" class="mirror">
    {{value}}<br><br *ngIf="!lined">
  </pre>
  <textarea
    #textarea
    class="message-entry"
    (focus)="handleFocus($event)"
    [disabled]="disabled"
    [(ngModel)]="value"
    (ngModelChange)="handleValueUpdate()"
    [placeholder]="placeholder || ''"
    [attr.title]="placeholder"
  >
  </textarea>
</div>
