<div class="container mt-4 mb-5">
  <div class="row justify-content-center">
    <div class="col col-lg-8">
      <div *ngIf="pageState | isLoadingState" class="d-flex">
        <omgui-spinner class="align-self-center m-auto"></omgui-spinner>
      </div>

      <ng-container *ngIf="pageState | isSuccessState">
        <h1>Follow the directions below to join your upcoming visit</h1>

        <hr />

        <p>
          <strong>For Web and iPhone users:</strong>
          <br /><br />
          Install the Zoom app and click below to join.
        </p>
        <div class="d-flex align-items-center">
          <div class="inline-icon-with-text">
            <img src="/assets/images/zoom.png" alt="Zoom" class="icon me-3" />
            <strong>Zoom Video Conference</strong>
          </div>
          <a
            class="btn btn-sm btn-outline-primary ms-auto"
            role="button"
            [href]="zoomUrl"
            data-cy="zoom-deep-link-button"
            >Open</a
          >
        </div>

        <hr />

        <p>
          <strong>For Android users:</strong>
          <br /><br />
          Install, log in, and join your visit from the One Medical app.
        </p>
        <div class="d-flex align-items-center">
          <div class="inline-icon-with-text">
            <div class="bordered icon me-3 d-flex align-items-center justify-content-center">
              <om-logo-sm></om-logo-sm>
            </div>
            <strong>One Medical</strong>
          </div>
          <a class="btn btn-sm btn-outline-primary ms-auto" role="button" [href]="appStoreUrl" target="_blank">Open</a>
        </div>
      </ng-container>

      <ng-container *ngIf="pageState | isErrorState">
        <div class="container mt-4 mb-5">
          <div class="row justify-content-center">
            <div class="d-flex flex-column justify-content-center text-center">
              <om-svg-calendar-outline class="my-3 d-block d-flex justify-content-center"></om-svg-calendar-outline>
              <h3>Sorry, we were unable to connect your visit</h3>
              <p>
                If you are still attempting to connect with a provider, please
                <a [href]="configService.json.contactSupportLink">contact our support team</a>.
              </p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
