import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

import { Provider } from '@app/account/my-provider/__generated__/Provider';

export const GET_PROVIDER_QUERY = gql`
  query Provider {
    patient {
      id
      careTeam {
        pcp {
          id
          displayName
          fullImageUrl
          firstName
          lastName
        }
      }
      homeOffice {
        address {
          address1
          address2
          city
          state
          zip
        }
        phone
        hours
        labHours
        name
      }
    }
  }
`;

@Injectable()
export class ProviderService extends Query<Provider> {
  document = GET_PROVIDER_QUERY;
}
