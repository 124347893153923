import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PrepaidEnrollmentMembershipService } from '@app/membership/prepaid-enrollment-membership.service';
import { RegistrationEdge } from '@app/registration/graph/graph-navigation.service';
import { PrepaidInviteNode } from '@app/registration/graph/nodes/prepaid-invite.node';

@Injectable({
  providedIn: 'root',
})
export class MembershipUpdateToPrepaidInviteEdge implements RegistrationEdge {
  nextNode = PrepaidInviteNode;

  constructor(private prepaidEnrollmentMembershipService: PrepaidEnrollmentMembershipService) {}

  shouldNavigate$(): Observable<boolean> {
    return this.prepaidEnrollmentMembershipService.canInviteMembers$();
  }
}
