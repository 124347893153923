import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppointmentBookingStateService } from '@app/appointment/appointment-booking-state-service';
import { BookAppointmentGraphQLService } from '@app/appointment/book-appointment-graphql.service';
import { AppointmentInventory } from '@app/appointment/provider-inventories';

import {
  AbstractConfirmationModalActionService,
  SubmissionResults,
} from '../base-confirmation-modal-action/abstract-confirmation-modal-action.service';

@Injectable({
  providedIn: 'root',
})
export class BookConfirmationModalActionService implements AbstractConfirmationModalActionService {
  constructor(
    private bookingStateService: AppointmentBookingStateService,
    private bookAppointmentGraphQLService: BookAppointmentGraphQLService,
  ) {}

  getConfirmationCTA(): string {
    return 'Confirm Appointment';
  }

  executeAppointmentAction$(selectedInventory: AppointmentInventory): Observable<SubmissionResults> {
    const bookingState = this.bookingStateService.getAppointmentBookingState();
    return this.bookAppointmentGraphQLService
      .mutate({
        input: {
          appointmentAttributes: {
            appointmentTypeId: bookingState.getAppointmentTypeId().toString(),
            inventoryId: selectedInventory.id.toString(),
            reason: bookingState.reason,
            sendSms: bookingState.sendSms,
            smsNumber: bookingState.sendSms ? bookingState.phoneNumber : null,
          },
        },
      })
      .pipe(map(result => result.data.bookAppointment));
  }
}
