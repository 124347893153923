import { AfterViewInit, Component, Input, Type, ViewChild } from '@angular/core';

import { ComponentType } from '@app/component-type';
import { SmallCardSvgDirective } from '@app/shared/small-card/small-card-svg.directive';
import { RouterLinkCommand } from '@app/utils/track-link.directive';

export interface SmallCard {
  svgComponentClass: Type<ComponentType>;
  title: string;
  link?: string;
  routerLink?: RouterLinkCommand;
  trackLink?: Function;
  text?: string;
  details?: string;
}

@Component({
  selector: 'om-small-card',
  templateUrl: './small-card.component.html',
  styleUrls: ['../card/default-styling.scss', './small-card.component.scss'],
})
export class SmallCardComponent implements AfterViewInit, SmallCard {
  @Input() dataCy: string;
  @Input() svgComponentClass: Type<ComponentType>;
  @Input() title: string;
  @Input() link: string;
  @Input() routerLink: RouterLinkCommand;
  @Input() trackLink: Function;
  @Input() text: string;
  @Input() details: string;
  @ViewChild(SmallCardSvgDirective) cardSvg: SmallCardSvgDirective;

  ngAfterViewInit() {
    const viewContainerRef = this.cardSvg.viewContainerRef;
    viewContainerRef.clear();
    viewContainerRef.createComponent(this.svgComponentClass);
  }
}
