import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiService } from '@app/core/api.service';
import {
  CodeRevokedStatus,
  CodeSentStatus,
  EmailVerificationStatus,
  LockedOutStatus,
  UnverifiedEmailStatus,
  VerifiedEmailStatus,
} from '@app/shared/email-verification-status';

export interface EmailVerificationResponse {
  status: EmailVerificationStatusName;
}

export enum EmailVerificationSource {
  pediatricRegistration = 'pediatric_registration',
  registration = 'registration',
  settings = 'settings',
  settingsEmailUpdate = 'settings_email_update',
}

export enum EmailVerificationStatusName {
  codeCreated = 'code_created',
  lockedOut = 'locked_out',
  verified = 'verified',
  unverified = 'unverified',
  revoked = 'revoked',
}

export enum EmailVerificationAction {
  continue = 'Continue',
  skip = 'Skip for now',
  resend = 'Resend Email',
  techSupport = 'techsupport@onemedical.com',
  exit = 'Exit',
}

class UnrecognizedEmailVerificationStatus extends Error {
  message =
    'Unable to verify your email at this time. Please contact <a href="mailto:techsupport@onemedical.com">techsupport@onemedical.com</a>';
}

@Injectable({
  providedIn: 'root',
})
export class EmailVerificationService {
  protected readonly EMAIL_VERIFICATIONS_API_PATH: string = '/api/v2/patient/email_verifications';
  private readonly status$ = new ReplaySubject<EmailVerificationStatus>(1);
  private readonly statusMapping: Record<string, any> = {
    [EmailVerificationStatusName.unverified]: UnverifiedEmailStatus,
    [EmailVerificationStatusName.lockedOut]: LockedOutStatus,
    [EmailVerificationStatusName.revoked]: CodeRevokedStatus,
    [EmailVerificationStatusName.verified]: VerifiedEmailStatus,
    [EmailVerificationStatusName.codeCreated]: CodeSentStatus,
  };

  private statusRequested = false;

  constructor(private apiService: ApiService) {}

  refreshVerificationStatus(): void {
    this.apiService
      .get(this.EMAIL_VERIFICATIONS_API_PATH)
      .pipe(map((response: EmailVerificationResponse) => this.mapToStatus(response)))
      .subscribe({
        next: (status: EmailVerificationStatus) => this.status$.next(status),
        error: error => this.status$.error(error),
      });
  }

  getVerificationStatus$(): Observable<EmailVerificationStatus> {
    if (!this.statusRequested) {
      this.statusRequested = true;
      this.refreshVerificationStatus();
    }

    return this.status$.asObservable();
  }

  createVerificationCode(
    source: EmailVerificationSource,
    additionalParams: Record<string, any> = {},
  ): Observable<EmailVerificationStatus> {
    return this.apiService.post(this.EMAIL_VERIFICATIONS_API_PATH, { source, ...additionalParams }).pipe(
      map((response: EmailVerificationResponse) => this.mapToStatus(response)),
      tap(status => this.status$.next(status)),
    );
  }

  submitVerificationCode(code: string, additionalParams = {}): Observable<EmailVerificationStatus> {
    return this.apiService
      .patch(this.EMAIL_VERIFICATIONS_API_PATH, { verification_code: code, ...additionalParams })
      .pipe(
        map((response: EmailVerificationResponse) => this.mapToStatus(response)),
        tap(status => this.status$.next(status)),
      );
  }

  private mapToStatus(response: EmailVerificationResponse): EmailVerificationStatus {
    const emailVerificationStatusClass = this.statusMapping[response.status];
    if (!emailVerificationStatusClass) {
      throw new UnrecognizedEmailVerificationStatus();
    }

    return new emailVerificationStatusClass();
  }
}
