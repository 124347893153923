export enum ExpeditedRegistrationSteps {
  CreateAccount = 0,
  ServiceArea = 1,
  TermsOfService = 2,
  MFAEnrollment = 3,
  Restart = 4,
}

export const EXPEDITED_REGISTRATION_STEPS_ORDER: ExpeditedRegistrationSteps[] = [
  ExpeditedRegistrationSteps.CreateAccount,
  ExpeditedRegistrationSteps.ServiceArea,
  ExpeditedRegistrationSteps.TermsOfService,
  ExpeditedRegistrationSteps.MFAEnrollment,
];

export class ClaimCodeInfo {
  isMembershipManager: boolean;
  seatsAvailable: number | null;
  subscriptionId?: string | null;
  billingCycle?: string | null;
  trial?: boolean | null;
}
