export const APPOINTMENT_BOOKING_VIRTUAL_CARE_ROUTE = '/appointments/virtual-care';

export enum AppointmentRoutingStepName {
  Start = 'Start',
  ReasonForVisit = 'ReasonForVisit',
  Survey = 'Survey',
  AppointmentTypeSelection = 'AppointmentTypeSelection',
  InventoryResults = 'InventoryResults',
  ServiceAreaSelection = 'ServiceAreaSelection',
  CareOptions = 'CareOptions',
}

export function getStepRoute(stepName: AppointmentRoutingStepName): string {
  switch (stepName) {
    case AppointmentRoutingStepName.ReasonForVisit:
      return '/appointments/reason';
    case AppointmentRoutingStepName.Survey:
      return '/appointments/survey/';
    case AppointmentRoutingStepName.AppointmentTypeSelection:
      return '/appointments/select-type';
    case AppointmentRoutingStepName.InventoryResults:
      return '/appointments/search';
    case AppointmentRoutingStepName.ServiceAreaSelection:
      return '/appointments/select-service-area';
    case AppointmentRoutingStepName.CareOptions:
      return '/appointments/care-options';
    default:
      throw new Error(`Unknown routing step ${stepName}`);
  }
}
