import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ArrowLeftComponent, LogoComponent } from '../svg';

@Component({
  selector: 'om-simple-navbar',
  templateUrl: './simple-navbar.component.html',
  standalone: true,
  imports: [CommonModule, LogoComponent, ArrowLeftComponent],
})
export class SimpleNavbarComponent {
  @Input() canGoBack = false;
  @Output() back: EventEmitter<void> = new EventEmitter<any>();

  onBack(e: Event) {
    e.preventDefault();
    this.back.emit();
  }
}
