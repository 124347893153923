import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import pick from 'lodash-es/pick';

import { MarkdownService } from '@app/core/markdown.service';
import { BehavioralHealthCareTeamGraphQL } from '@app/shared/behavioral-health-care-team-graphql.service';
import { WindowService } from '@app/utils/window.service';

import {
  HubCareOptionItem,
  HubContentItem,
  HubModalData,
  HUMANEST_MODULE,
  MEDICATION_MODULE,
  ProgramBanner,
  ProgramCareTeam,
  ProgramFaqs,
  ProgramImmediateCare,
  SKILLS_MODULE,
  THERAPY_MODULE,
  WARMLINE_MODULE,
} from '../program-types';
import { ProgramHubAnalyticsService } from './program-hub-analytics.service';

/**
 * One approach we discussed for the "Program Hub" page was the idea of generating a "manifest" on the backend
 * That would put together a payload of program-related content, this page would fetch and consume the manifest,
 * rendering the content and layout based on the manifest's configuration.
 *
 * We aren't quite there yet, but we're starting by defining each section as a partial manifest and we can put it all
 * together when we have a better idea of what the layout and content for other Program Hubs may require
 */
@Component({
  selector: 'om-program-hub',
  templateUrl: './program-hub.component.html',
  styleUrls: ['./program-hub.component.scss'],
})
export class ProgramHubComponent implements OnInit {
  static readonly MINDSET_PROGRAM_TITLE = 'Mindset+';

  @ViewChild('careOptionModalContent', { static: true }) careOptionModalContent: TemplateRef<any>;
  careTeam: ProgramCareTeam[];
  programDescription: SafeHtml;

  modalData: HubModalData = {
    moduleName: '',
    title: '',
    description: '',
    imageUrl: '',
    imageAltText: '',
    primaryActionLabel: '',
    primaryActionType: null,
    primaryActionUrl: '',
  };

  readonly title = ProgramHubComponent.MINDSET_PROGRAM_TITLE;

  readonly programBanner: ProgramBanner = {
    programImage: 'assets/images/img_mindset_flourish.png',
  };

  readonly careOptionsSection: HubContentItem = {
    title: 'Care Options',
    content: [
      {
        content: [
          {
            moduleName: HUMANEST_MODULE,
            title: 'An online community for advice and support',
            description:
              "Whatever you're going through, you don't have to face it alone.\n\nConnect with a therapist in a moderated messaging board and chat with others like you.",
            type: 'card-lg',
            imageUrl: '/assets/images/person-looking-at-phone.jpg',
            imageAltText: 'Person with glasses looking at phone',

            primaryActionLabel: 'Go to Humanest',
            primaryActionUrl: 'https://members.humanestcare.com/sign_up',

            secondaryActionLabel: 'Learn More',
            secondaryActionUrl: 'https://members.humanestcare.com/plans/200912',
          },
        ],
      },
      {
        content: [
          {
            moduleName: THERAPY_MODULE,
            title: 'Skills Training',
            description: 'Skills-based coaching tailored for you',
            imageUrl: '/assets/images/person-sitting-with-laptop.jpg',
            imageAltText: 'Person with eyes closed seated in front of laptop',
            modalContent: {
              description:
                'Connect with your care manager to get goal-focused, skill-building training to tackle personal and professional challenges.',
              primaryActionLabel: 'Message Care Manager',
              primaryActionType: 'internal',
              primaryActionUrl: '/messages',
            },
          },
          {
            moduleName: MEDICATION_MODULE,
            title: 'Therapy Sessions',
            description: 'Sessions with a licensed therapist, as needed',
            imageUrl: '/assets/images/provider-and-patient-talking.jpg',
            imageAltText: 'Provider and a patient talking',
            modalContent: {
              description:
                'Your licensed therapist might suggest therapy sessions as part of your care plan to help you develop emotional and cognitive coping skills. Talk to your care manager about this option.',
              primaryActionLabel: 'Message Care Manager',
              primaryActionType: 'internal',
              primaryActionUrl: '/messages',
            },
          },
          {
            moduleName: SKILLS_MODULE,
            title: 'Medication Management',
            description: 'Requests and renewals',
            imageUrl: '/assets/images/person-viewing-mobile-app.jpg',
            imageAltText: 'Person looking at One Medical App on mobile device',
            modalContent: {
              description:
                'If prescribed a medication, we will work with you to consistently measure progress and make any necessary adjustments in your treatment. Talk to your care manager if you have any side effects or are looking for a prescription.',
              primaryActionLabel: 'Message Care Manager',
              primaryActionType: 'internal',
              primaryActionUrl: '/messages',
            },
          },
        ],
      },
      {
        content: [{ title: 'See Upcoming and Past Appointments' }],
      },
    ],
  };

  readonly appointmentsSection: HubContentItem = {
    title: 'Appointments',
    content: [],
  };

  readonly programFaqs: ProgramFaqs = {
    faqs: [
      {
        question: 'How long will I be in the program?',
        answer:
          'The length of the program depends on your care plan and progress. The typical timeframe for being actively in the program is 3-6 months, followed by a less intensive maintenance period for about 6 months. During that time we are available if you need us to ensure that once you’re feeling better you’re staying better.',
      },
      {
        question: 'Can I continue working with my outside therapist?',
        answer:
          'Yes, you can. While your Mindset+ care plan can include a One Medical therapist, it doesn’t have to. Our care managers are equipped to work with you if you’ve got an outside therapist as well!',
      },
      {
        question: 'How is my privacy protected?',
        answer:
          'We take your privacy seriously — and we want to make sure you have the information you need to feel confident getting care with us. \n' +
          '- Everything you tell your care manager and therapist is confidential \n' +
          '- All the messages between you and your care team are secured \n' +
          '- Your treatment plan is protected in your medical records\n' +
          '- All biling information is protected under HIPAA\n' +
          'For more information, see our [Privacy Policy.](https://www.onemedical.com/privacy/)',
      },
      {
        question: 'Who can I reach out to if I have billing questions or concerns?',
        answer:
          "Just reach out to your care manager and they'll connect you to someone from our billing team who can help answer your questions and navigate any billing issues.",
      },
    ],
  };

  readonly immediateCare: ProgramImmediateCare = {
    moduleName: WARMLINE_MODULE,
    programImmediateCare: {
      immediateCareContacts: [
        {
          title: 'One Medical Warm Line',
          contactValue: '18883273422',
          contactDisplay: '+1 (888) 327-3422',
          protocol: 'tel',
          tooltipText:
            'The Warmline provides 24/7 and confidential support for people in suicidal crisis or emotional distress.',
        },
      ],
    },
  };

  private readonly roleDescriptions: Record<string, string> = {
    Therapist:
      'Your licensed therapist creates your care plan and is available to advise you about questions and treatment options.',
    'Care Manager':
      "Your care manager is a mental health professional who provides tools, strategies and education. They'll also keep track of your treatment plan and progress and help answer any questions about billing.",
    PCP:
      'Your primary care provider works closely with your care manager to stay informed about your treatment progress and may write or refill prescriptions for medications.',
  };

  constructor(
    private modal: NgbModal,
    private behavioralHealthCareTeamGraphQL: BehavioralHealthCareTeamGraphQL,
    private markdownService: MarkdownService,
    private programHubAnalyticsService: ProgramHubAnalyticsService,
    private windowService: WindowService,
  ) {}

  ngOnInit(): void {
    this.behavioralHealthCareTeamGraphQL.fetch().subscribe(({ data }) => {
      this.programDescription =
        data.patient?.programs?.nodes?.find(program => program?.displayName === 'Mindset+')?.description || '';

      this.careTeam = data.patient?.careTeam?.edges.map(
        edge =>
          ({
            profile: edge.node,
            role: edge.label || 'Unknown',
            tooltipText: edge.label ? this.roleDescriptions[edge.label] : '',
          } as ProgramCareTeam),
      );

      this.programHubAnalyticsService.trackPageViewed({ programName: ProgramHubComponent.MINDSET_PROGRAM_TITLE });
    });

    this.convertFaqMarkdown();
  }

  openModal(careOption: HubCareOptionItem, ctaType?: string): void {
    this.programHubAnalyticsService.trackProgramModuleClicked({ module: careOption.moduleName, ctaType: ctaType });
    this.modalData = this.buildCareOptionModalData(careOption);
    this.modal.open(this.careOptionModalContent, { size: 'sm', centered: true, modalDialogClass: 'modal-redesign' });
  }

  trackProgramModalClicked(moduleName: string): Function {
    return this.programHubAnalyticsService.trackProgramModalClicked.bind(this.programHubAnalyticsService, {
      module: moduleName,
    });
  }

  goToSecondaryUrl(hubCareOptionItem: HubCareOptionItem, ctaType?: string) {
    this.programHubAnalyticsService.trackProgramModuleClicked({
      module: hubCareOptionItem.moduleName,
      ctaType: ctaType,
    });
    if (!!hubCareOptionItem.secondaryActionUrl) {
      this.windowService.redirect(hubCareOptionItem.secondaryActionUrl, { newTab: true });
    }
  }

  private convertFaqMarkdown() {
    this.programFaqs.faqs.map(faq => {
      faq.htmlSafeAnswer = this.markdownService.convert(faq.answer);
      return faq;
    });
  }

  private buildCareOptionModalData(data: HubCareOptionItem): HubModalData {
    return {
      ...pick(data, [
        'moduleName',
        'title',
        'description',
        'imageUrl',
        'imageAltText',
        'primaryActionLabel',
        'primaryActionUrl',
      ]),
      ...data.modalContent,
    };
  }
}
