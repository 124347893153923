import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, NgModule, OnInit } from '@angular/core';

const rotate180Animation = trigger('rotate180', [
  state(
    'open',
    style({
      transform: 'rotate(180deg)',
    }),
  ),
  transition('open => closed', [animate(200)]),
  transition('closed => open', [animate(200)]),
]);

@Component({
  selector: 'omgui-collapse-state-icon',
  templateUrl: './omgui-collapse-state-icon.component.html',
  animations: [rotate180Animation],
})
export class OmguiCollapseStateIconComponent implements OnInit {
  /** Specifies whether the chevron pointing upwards means the collapsible is open. Defaults to true */
  @Input() upIsOpen = true;

  /** Is true if the collapsible is open. Use this to sync your collapsible with this component */
  @Input() isOpen: boolean;

  /** @ignore */
  flipped: string;
  /** @ignore */
  default: string;
  /** @ignore */
  private possibleStates = ['open', 'closed'];

  /** @ignore */
  ngOnInit(): void {
    if (this.upIsOpen) {
      [this.flipped, this.default] = this.possibleStates;
    } else {
      [this.default, this.flipped] = this.possibleStates;
    }
  }
}

@NgModule({
  declarations: [OmguiCollapseStateIconComponent],
  exports: [OmguiCollapseStateIconComponent],
})
export class OmguiCollapseStateIconModule {}
