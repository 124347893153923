import { Component, NgModule } from '@angular/core';

@Component({
  selector: 'omgui-external-link-icon',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_835_26131)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6 2H3.15C2.51487 2 2 2.51487 2 3.15V12.85C2 13.4851 2.51487 14 3.15 14H12.85C13.4851 14 14 13.4851 14 12.85V10H13V12.85C13 12.9328 12.9328 13 12.85 13H3.15C3.06716 13 3 12.9328 3 12.85V3.15C3 3.06716 3.06716 3 3.15 3H6V2Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.53553 1.97423H13.9853V7.42398H12.9853L12.9853 3.68134L7.82843 8.83819L7.12132 8.13109L12.2782 2.97423L8.53553 2.97423L8.53553 1.97423Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_835_26131">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  `,
})
export class OmguiExternalLinkIconComponent {}

@NgModule({
  declarations: [OmguiExternalLinkIconComponent],
  exports: [OmguiExternalLinkIconComponent],
})
export class OmguiExternalLinkIconModule {}
