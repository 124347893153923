import { AbstractControl } from '@angular/forms';

const USA_FULL_PHONE_NUMBER_LENGTH = 10;

export function phoneNumberValidator({ value }: AbstractControl): Record<string, boolean> | null {
  const errors: Record<string, boolean> = {};
  const controlValue = value ? value.replace(/[^0-9]/g, '') : '';

  if (controlValue.length !== USA_FULL_PHONE_NUMBER_LENGTH) {
    errors.invalidLength = true;
  }

  return Object.keys(errors).length > 0 ? errors : null;
}
