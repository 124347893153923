import { Injectable } from '@angular/core';
import { captureException, captureMessage } from '@sentry/angular-ivy';
import { toDate } from 'date-fns';

import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class RegistrationAccessTokenService {
  private static TOKEN_KEY = 'api_token';

  constructor(private storageService: StorageService) {}

  retrieveToken(): string | null {
    return this.storageService.getItem(RegistrationAccessTokenService.TOKEN_KEY);
  }

  removeToken(): void {
    this.storageService.removeItem(RegistrationAccessTokenService.TOKEN_KEY);
    this.storageService.removeTldItem(RegistrationAccessTokenService.TOKEN_KEY);
  }

  persistToken(token: string) {
    const tokenExpirationDate = this.getExpirationDateFromToken(token);
    if (tokenExpirationDate) {
      this.storageService.setTldItem(RegistrationAccessTokenService.TOKEN_KEY, token, {
        duration: tokenExpirationDate,
      });
    } else {
      this.storageService.setTldItem(RegistrationAccessTokenService.TOKEN_KEY, token);
      captureMessage('Expiration date not set in auth cookie.', 'warning');
    }
  }

  private getExpirationDateFromToken(token: string) {
    const tokens = token.split('.');

    try {
      const expirationTimeEpoch = JSON.parse(atob(tokens[1])).exp;
      if (expirationTimeEpoch) {
        return toDate(expirationTimeEpoch * 1000);
      }
    } catch (e) {
      captureException(e);
    }
  }
}
