import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

export enum MessageType {
  ALERT = 'alert',
  ERROR = 'error',
  NOTICE = 'notice',
  SUCCESS = 'success',
}

interface Messages {
  [MessageType.ALERT]: string[];
  [MessageType.ERROR]: string[];
  [MessageType.NOTICE]: string[];
  [MessageType.SUCCESS]: string[];
}

@Injectable({
  providedIn: 'root',
})
export class FlashService {
  private messages: Messages;
  private _messages$ = new ReplaySubject<Messages>(1);
  messages$: Observable<Messages> = this._messages$.asObservable();

  constructor() {
    this.clearAll();
  }

  addFlashMessage(message: string, type: MessageType) {
    this.messages[type].push(message);
    this._messages$.next(this.messages);
  }

  clearMessages(type: MessageType) {
    this.messages[type] = [];
    this._messages$.next(this.messages);
  }

  clearAll() {
    this.messages = {
      [MessageType.ALERT]: [],
      [MessageType.ERROR]: [],
      [MessageType.NOTICE]: [],
      [MessageType.SUCCESS]: [],
    };
    this._messages$.next(this.messages);
  }
}
