import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-svg-checkmark',
  templateUrl: './svg-checkmark.component.html',
})
export class SvgCheckmarkComponent {
  @Input() color = '#5CB85C';
  @Input() width = 15;
  @Input() height = 12;
}
