import { GraphQLAppointment } from './appointment';

export const DATE_FORMAT = 'EEE MM/dd/yy';
export const DATE_FORMAT_ALT = 'EEE, MMMM d, yyyy';
export const TIME_FORMAT = 'h:mm aaa zz';
export const TIME_FORMAT_ALT = 'h:mm a zz';
export const APPOINTMENT_BOOKING = 'Appointment Booking';

type RequiredAppointmentValues = Pick<GraphQLAppointment, 'isRemote' | 'timezoneTzinfo'>;

export function chooseDisplayTimezone(patientTimezone: string, appointment: RequiredAppointmentValues): string {
  return appointment.isRemote ? patientTimezone : appointment.timezoneTzinfo;
}
