import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ServiceAreaToTermsOfServiceEdge } from '@app/registration/graph/edges/service-area-to-terms-of-service.edge';
import { NodeUrlMappingService } from '@app/registration/graph/node-url-mapping.service';
import { RegistrationNode } from '@app/registration/graph/nodes/registration.node';

import { ServiceAreaToTrialVideoVisitConfirmationEdge } from '../edges/service-area-to-trial-video-visit-confirmation.edge';

@Injectable({
  providedIn: 'root',
})
export class ServiceAreaNode extends RegistrationNode {
  constructor(
    nodeUrlMappingService: NodeUrlMappingService,
    router: Router,
    private serviceAreaToTermsOfServiceEdge: ServiceAreaToTermsOfServiceEdge,
    private serviceAreaToTrialVideoVisitConfirmationEdge: ServiceAreaToTrialVideoVisitConfirmationEdge,
  ) {
    super(nodeUrlMappingService, router);
    this.edges = [serviceAreaToTrialVideoVisitConfirmationEdge, serviceAreaToTermsOfServiceEdge];
  }
}
