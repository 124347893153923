import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-svg-medication',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.12548 9.56203L13.5678 4.08803C15.0101 2.63732 17.3701 2.63732 18.8117 4.08803C20.2534 5.53875 20.2534 7.9117 18.8117 9.36241L8.32452 19.9125C6.88288 21.3625 4.52288 21.3625 3.08123 19.9125C1.63959 18.4617 1.63959 16.0875 3.08123 14.6374L6.93219 10.7907"
        [attr.stroke]="color"
      />
      <path d="M8.19952 9.18204L13.5121 14.5808" [attr.stroke]="color" />
      <path d="M3.05048 14.3854L6.66948 18.0258" [attr.stroke]="color" />
      <path d="M4.77411 12.7548L8.38081 16.3045" [attr.stroke]="color" />
      <path d="M6.53229 10.848L10.685 15.0486" [attr.stroke]="color" />
    </svg>
  `,
})
export class SvgMedicationComponent {
  @Input() color = 'currentColor';

  constructor() {}
}
