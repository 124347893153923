import { Component, NgModule } from '@angular/core';

@Component({
  selector: 'omgui-action',
  template: `
    <ng-content select="omgui-spinner"></ng-content>
    <ng-content select="omgui-checkbox"></ng-content>
    <ng-content select="button.btn"></ng-content>
    <ng-content select="a.btn"></ng-content>
    <ng-content select="omgui-button"></ng-content>
    <ng-content select="[ngbDropdown]"></ng-content>
    <ng-content select="om-appointment-more-options"></ng-content>
  `,
})
export class OmguiActionComponent {}

@NgModule({
  declarations: [OmguiActionComponent],
  exports: [OmguiActionComponent],
})
export class OmguiActionModule {}
