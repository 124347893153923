import { Component, Input, NgModule, OnInit } from '@angular/core';

import { ComponentSize } from '@omgui/component-size';

@Component({
  selector: 'omgui-camera',
  templateUrl: './omgui-camera.component.html',
})
export class OmguiCameraComponent implements OnInit {
  @Input() color = '#49AB94';
  @Input() width = 29;
  @Input() height = 29;
  @Input() size: ComponentSize;

  ngOnInit() {
    if (this.size === ComponentSize.extraExtraLarge) {
      this.width = 60;
      this.height = 60;
    }
  }
}

@NgModule({
  declarations: [OmguiCameraComponent],
  exports: [OmguiCameraComponent],
})
export class OmguiCameraModule {}
