<div class="value-prop-container" [class.value-prop-container-FreeTrial]="registerFlows.FreeTrial == regFlow">
  <div [ngSwitch]="regFlow">
    <div class="free-trial-tos" *ngSwitchCase="registerFlows.FreeTrial">
      <div>
        <h2 class="free-trial-amf">$0</h2>
        <span *ngIf="coupon && coupon.skipCreditCard" class="text-start"
          ><h5>Temporary Membership</h5>
          <div>
            <span>Valid Until {{ planRenewsOn }}</span></div
          ></span
        >
        <span *ngIf="coupon && !coupon.skipCreditCard" class="text-start"
          ><h5>{{ trialDays }}-Day Free Trial</h5>
          <div>
            <span>Valid Until {{ planRenewsOn }}</span></div
          ></span
        >
      </div>
      <hr />

      <small *ngIf="coupon && (coupon.skipCreditCard || coupon.deactivateAtTrialEnd)"
        >Your membership will deactivate at the end of the temporary membership period. New members only. One Medical
        reserves the right to cancel or limit the promotion in its sole discretion at any time. Void where prohibited.
      </small>

      <small *ngIf="coupon && !coupon.skipCreditCard && !coupon.deactivateAtTrialEnd"
        >We require your payment information to initiate the free trial, but you won’t be charged until after the
        {{ trialDays }}-Day Free Trial period. We’ll remind you prior to charging your card and your One Medical
        Membership will renew automatically at ${{ amf }}/year until you cancel. Cancel anytime. New members only. One
        Medical reserves the right to cancel or limit the promotion in its sole discretion at any time. Void where
        prohibited.
        <span *ngIf="trialAutoRenew"
          >Free trial offer void in Washington D.C. Washington D.C. residents not eligible for free trial offer.<br /></span
      ></small>
    </div>
    <div *ngSwitchDefault>
      <h5 class="text-center">Annual Membership Fee</h5>
      <div *ngIf="coupon" class="text-center mb-4">
        <span class="discount-amf">${{ amf }}</span
        ><h2 class="d-inline">${{ getTotal() }}</h2>
      </div>
      <div *ngIf="!coupon" class="text-center mb-4">
        <h2 class="d-inline">${{ getTotal() }}</h2
        ><span>/year</span>
      </div>

      <om-checkmark-list listClass="mb-5 mx-2" [listItems]="value_props"></om-checkmark-list>
    </div>
  </div>

  <om-submit-button
    buttonClass="limited-width"
    class=""
    [label]="buttonLabel"
    [disabled]="disabled"
    [submitting]="submitting"
  ></om-submit-button>
</div>
