import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

import { AppointmentToReschedule, AppointmentToRescheduleVariables } from './__generated__/AppointmentToReschedule';

@Injectable({
  providedIn: 'root',
})
export class AppointmentToRescheduleGraphQLService extends Query<
  AppointmentToReschedule,
  AppointmentToRescheduleVariables
> {
  document = gql`
    query AppointmentToReschedule($id: ID!) {
      appointment(id: $id) {
        id
        office {
          id
          name
        }
      }
    }
  `;
}
