import { Injectable } from '@angular/core';
import { Query, gql, Apollo } from 'apollo-angular';

import { LegalDocumentsForBeneficiary } from '@app/core/legal-documents/__generated__/LegalDocumentsForBeneficiary';

export interface BeneficiaryQueryVariables {
  beneficiaryId: number;
}

/**
 * Apollo query for loading legal documents when signing as a beneficiary, when
 * logged in as an administrator for that beneficiary.
 */

@Injectable()
export class BeneficiaryLegalDocGraphQL extends Query<LegalDocumentsForBeneficiary, BeneficiaryQueryVariables> {
  constructor(apollo: Apollo) {
    super(apollo);
  }

  document = gql`
    query LegalDocumentsForBeneficiary($beneficiaryId: ID!) {
      legalDocumentsForBeneficiary(beneficiaryId: $beneficiaryId) {
        type
        version
        text
        detailHint
        buttonHint
        signedOnBehalfOf(id: $beneficiaryId)
      }
    }
  `;
}
