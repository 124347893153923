import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';
import { map } from 'rxjs';

import { BehavioralHealthCareTeam } from './__generated__/behavioral-health-care-team-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class BehavioralHealthCareTeamGraphQL extends Query<BehavioralHealthCareTeam> {
  document = gql`
    query BehavioralHealthCareTeam {
      patient {
        programs {
          nodes {
            id
            displayName
            description
          }
        }
        id
        careTeam(type: BEHAVIORAL_HEALTH) {
          edges {
            ...CareTeamEdgeFragment
          }
        }
      }
    }

    fragment CareTeamEdgeFragment on CareTeamEdge {
      label
      node {
        ... on InternalUserPublic {
          ...InternalUserPublicFragment
        }
      }
    }

    fragment InternalUserPublicFragment on InternalUserPublic {
      id
      displayName
      profileImageUrl
    }
  `;

  enrolledInProgram$(displayName: string) {
    return this.fetch().pipe(
      map(({ data }) =>
        data.patient?.programs?.nodes?.some(program => program.displayName.toLowerCase() === displayName.toLowerCase()),
      ),
    );
  }
}
