import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TrialVideoVisitOnboardingGraphQL } from '@app/membership/gcn-confirmation/trial-video-visit-onboarding-graphql.service';
import { RegistrationEdge } from '@app/registration/graph/graph-navigation.service';
import { amazonFlowStatus } from '@app/registration/routing/constants';

import { TrialVideoVisitConfirmationNode } from '../nodes/trial-video-visit-confirmation.node';

@Injectable({
  providedIn: 'root',
})
export class ServiceAreaToTrialVideoVisitConfirmationEdge implements RegistrationEdge {
  constructor(private trialVideoVisitOnboardingGraphQLService: TrialVideoVisitOnboardingGraphQL) {}

  nextNode = TrialVideoVisitConfirmationNode;

  shouldNavigate$(): Observable<boolean> {
    return this.trialVideoVisitOnboardingGraphQLService
      .fetch()
      .pipe(
        map(
          response =>
            response.data?.patient?.hasAmazonPrimeTrial &&
            response.data.patient?.completedAmazonFlowStatus === amazonFlowStatus.gcn_trial,
        ),
      );
  }
}
