<div class="footer d-flex">
  <om-logo-sm fill="currentColor" class="me-4 mb-4 mb-lg-0"></om-logo-sm>

  <div class="d-flex flex-wrap w-100">
    <div class="flex-grow-1 d-flex flex-column">
      <div class="links-section mb-lg-2 primary-links">
        <a href="https://www.onemedical.com/blog/" [omTrackLink]="trackFooterLink('Blog')" data-cy="blog">Blog</a>
        <span class="dot"></span>
        <a
          href="https://www.onemedical.com/mediacenter/"
          [omTrackLink]="trackFooterLink('Media Center')"
          data-cy="media-center"
          >Media center</a
        >
        <span class="dot"></span>
        <a href="https://www.onemedical.com/about-us/" [omTrackLink]="trackFooterLink('About')" data-cy="about"
          >About</a
        >
        <span class="dot"></span>
        <a
          href="https://www.onemedical.com/practice-integration/"
          [omTrackLink]="trackFooterLink('Practice Integration')"
          data-cy="practice-integration"
          >Practice integration</a
        >
      </div>
      <div class="links-section primary-links">
        <a
          href="https://www.onemedical.com/sponsored-membership/"
          [omTrackLink]="trackFooterLink('Sponsored Membership')"
          data-cy="sponsored-membership"
          >Sponsored membership</a
        >
        <span class="dot"></span>
        <a href="https://www.onemedical.com/careers/" [omTrackLink]="trackFooterLink('Careers')" data-cy="careers"
          >Careers</a
        >
        <span class="dot"></span>
        <a
          [href]="configService.json.contactSupportLink"
          [omTrackLink]="trackFooterLink('Contact Us')"
          data-cy="contact-us"
          >Contact us</a
        >
        <span class="dot"></span>
        <a
          class="no-margin"
          href="https://www.onemedical.com/faq/"
          [omTrackLink]="trackFooterLink('FAQs')"
          data-cy="faqs"
          >FAQs</a
        >
      </div>
    </div>

    <div class="my-4 my-lg-0">
      <om-svg-facebook-link
        class="social-media-link"
        fill="currentColor"
        [omTrackLink]="trackFooterLink('Facebook')"
        data-cy="facebook"
      ></om-svg-facebook-link>
      <om-svg-twitter-link
        class="social-media-link"
        fill="currentColor"
        [omTrackLink]="trackFooterLink('Twitter')"
        data-cy="twitter"
      ></om-svg-twitter-link>
      <om-svg-youtube-link
        class="social-media-link"
        fill="currentColor"
        [omTrackLink]="trackFooterLink('Youtube')"
        data-cy="youtube"
      ></om-svg-youtube-link>
      <om-svg-instagram-link
        class="social-media-link"
        fill="currentColor"
        [omTrackLink]="trackFooterLink('Instagram')"
        data-cy="instagram"
      ></om-svg-instagram-link>
      <om-svg-linked-in-link
        class="social-media-link"
        fill="currentColor"
        [omTrackLink]="trackFooterLink('LinkedIn')"
        data-cy="linkedIn"
      ></om-svg-linked-in-link>
    </div>

    <div class="break d-none d-lg-inline-block"></div>

    <div class="links-section flex-grow-1 secondary-links mb-3 mb-lg-0">
      <a
        class="small-font-md"
        href="https://www.onemedical.com/membership-terms/"
        [omTrackLink]="trackFooterLink('Membership Terms of Service')"
        data-cy="membership-tos"
        >Membership terms of service</a
      >
      <span class="dot"></span>
      <a
        class="small-font-md"
        href="https://www.onemedical.com/medical-terms-service/"
        [omTrackLink]="trackFooterLink('Medical Terms of Service')"
        data-cy="medical-tos"
        >Medical terms of service</a
      >
      <span class="dot"></span>
      <a
        class="small-font-md"
        href="https://www.onemedical.com/privacy/"
        [omTrackLink]="trackFooterLink('Privacy Policy')"
        data-cy="privacy-policy"
        >Privacy policy</a
      >
      <span class="dot"></span>
      <a
        class="small-font-md"
        href="https://www.onemedical.com/hipaa/"
        [omTrackLink]="trackFooterLink('HIPAA NPP')"
        data-cy="hipaa"
        >HIPAA NPP</a
      >
    </div>

    <span class="small-font-md" data-cy="copyright"
      >&copy; {{ currentYear }} 1Life Healthcare, Inc. All rights reserved.</span
    >
  </div>
</div>
