<div class="registration-wizard vh-100 d-flex flex-column">
  <om-progress-bar
    [canGoBack]="canGoBack()"
    [percentComplete]="percentComplete()"
    [showLoginLink]="showLoginLink && !isLoggedIn"
    (back)="onBack()"
    (login)="handleLogIn()"
  >
  </om-progress-bar>
  <div [hidden]="fastForwarding" class="flex-fill d-flex flex-column"><ng-template #container></ng-template></div>
  <div class="mt-10" [hidden]="!fastForwarding"> <om-logo-spinner color="#005450" size="100"></om-logo-spinner> </div>
  <om-recaptcha #captcha></om-recaptcha>
  <om-toast data-cy="toast"></om-toast>
</div>
