import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { Membership } from '@app/core/membership';
import { MembershipService } from '@app/core/membership.service';

@Injectable({
  providedIn: 'root',
})
export class PrepaidEnrollmentMembershipService {
  constructor(private launchDarklyService: LaunchDarklyService, private membershipService: MembershipService) {}

  private featureEnabled$(): Observable<boolean> {
    return this.launchDarklyService.featureFlag$(FeatureFlags.HORNBILL_MEMBERSHIP_MANAGEMENT_P0_ENABLED, false);
  }

  isMembershipManager$(): Observable<boolean> {
    return this.featureEnabled$().pipe(
      switchMap((flagEnabled: boolean) => {
        if (!flagEnabled) {
          return observableOf(false);
        }
        return this.membershipService
          .getMembershipWithRequest()
          .pipe(map((membership: Membership) => membership.isGroupMembershipManager()));
      }),
    );
  }

  isSeatHolder$(): Observable<boolean> {
    return this.featureEnabled$().pipe(
      switchMap((flagEnabled: boolean) => {
        if (!flagEnabled) {
          return observableOf(false);
        }
        return this.membershipService
          .getMembershipWithRequest()
          .pipe(map((membership: Membership) => membership.isGroupSeatholder()));
      }),
    );
  }

  canInviteMembers$(): Observable<boolean> {
    return this.featureEnabled$().pipe(
      switchMap((flagEnabled: boolean) => {
        if (!flagEnabled) {
          return observableOf(false);
        }
        return this.membershipService
          .getMembershipWithRequest()
          .pipe(
            map(
              (membership: Membership) =>
                membership.isGroupMembershipManager() && membership.hasGroupSubscriptionSeatsAvailable(),
            ),
          );
      }),
    );
  }

  isMemberManagerWithMultipleSeats$(): Observable<boolean> {
    return this.featureEnabled$().pipe(
      switchMap((flagEnabled: boolean) => {
        if (!flagEnabled) {
          return observableOf(false);
        }
        return this.membershipService
          .getMembershipWithRequest()
          .pipe(
            map((membership: Membership) => membership.isGroupMembershipManager() && membership.hasMultipleSeats()),
          );
      }),
    );
  }
}
