<div class="modal-wrapper">
  <button type="button" class="close fw-light" aria-label="Close" (click)="modalClose()">
    <omgui-close-x aria-hidden="true"></omgui-close-x>
  </button>

  <h1 data-cy="appointment-confirmation-heading">{{ confirmationHeading }}</h1>
  <h2 data-cy="appointment-confirmation-datetime">{{ confirmationTimePrefix }} {{ formattedStartDate }}</h2>

  <om-profile-bubble-with-details [provider]="provider"></om-profile-bubble-with-details>
  <div class="d-flex justify-content-between flex-wrap">
    <om-office-details *ngIf="!bookingState.appointmentType.remote" [office]="office"></om-office-details>
    <div class="fw-light">
      <div>
        Tel: <a href="tel:{{ office.phone }}">{{ office.phone }}</a>
      </div>
      <div>
        Email: <a href="mailto:{{ office.email }}">{{ office.email }}</a>
      </div>
    </div>
  </div>

  <om-styled-map
    *ngIf="!bookingState.appointmentType.remote"
    [latitude]="office.address.latitude"
    [longitude]="office.address.longitude"
    [zoom]="13"
    [draggable]="true"
  >
  </om-styled-map>

  <div class="upper-divider mt-3 pt-3">
    <h5 data-cy="appointment-duration-message">
      {{ bookingState.appointmentType.displayName }}
      <span *ngIf="bookingState.appointmentType.displayedDuration">
        - typically {{ bookingState.appointmentType.displayedDuration }} minutes
      </span>
    </h5>
    <div class="d-flex justify-content-start mt-2">
      <div class="d-flex align-items-start me-3">
        <img alt="appointment icon" class="icon-size" src="/assets/images/icons/icn_appointment.svg" />
      </div>
      <div>
        <h6 class="mb-0">Reason for visit</h6>
        <p class="reason-for-visit mb-1">{{ bookingState.reason }}</p>
      </div>
    </div>

    <div *ngIf="afterHoursBillingDisclosure">
      <div class="d-flex justify-content-start mt-2">
        <div class="d-flex align-items-start me-3">
          <img alt="membership icon" class="icon-size" src="/assets/images/icons/membership.svg" />
        </div>
        <div>
          <h6 class="mb-0">Billing and Insurance Details</h6>
          <p
            *ngIf="afterHoursBillingDisclosureContentBlock$ | async as afterHoursBillingDisclosureContentBlock"
            data-cy="after-hours-billing-disclosure"
            class="reason-for-visit mb-1"
            [innerText]="afterHoursBillingDisclosureContentBlock"
          ></p>
        </div>
      </div>
    </div>
  </div>

  <ng-content select="[additionalConfirmationDetails]"></ng-content>

  <div *ngIf="locationAttestation" class="upper-divider mt-3 pt-3">
    <h5>Notice of Service</h5>
    <p
      *ngIf="remoteVisitBillingContentBlock$ | async as remoteVisitBillingContentBlock"
      data-cy="rbvContentBlock"
      [innerText]="remoteVisitBillingContentBlock"
    ></p>
    <p data-cy="locationAttestation" [innerText]="locationAttestation"></p>
  </div>

  <div class="upper-divider mt-3 pt-3">
    <h5>Send a text message before appointment</h5>
    <div class="mt-2 mb-4 mt-md-3 mb-md-5" [class.has-error]="displayError" [formGroup]="phoneNumberForm">
      <div class="d-flex">
        <om-checkbox
          class="mt-2 me-1"
          [isChecked]="bookingState.sendSms"
          (checked)="bookingState.sendSms = $event"
        ></om-checkbox>
        <div>
          <input
            type="text"
            class="form-control"
            id="phone-number"
            aria-label="Phone Number"
            placeholder="(xxx) xxx-xxxx"
            autofocus
            formControlName="phoneNumber"
          />
          <div class="text-danger" *ngIf="displayError">Please enter a valid phone number</div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isSwitchToRemoteEnabled; then refactoredModalActions; else existingActions"></ng-container>

  <ng-template #existingActions>
    <div class="d-flex justify-content-between flex-wrap actions">
      <button
        type="button"
        class="modal-button btn btn-primary fw-light"
        data-cy="appointment-confirmation"
        [disabled]="confirming"
        (click)="confirmAppointment()"
      >
        <span *ngIf="confirming; else confirmBlock">Submitting...</span>
        <ng-template #confirmBlock>{{ confirmationCTA }}</ng-template>
      </button>
      <button type="button" class="modal-button btn btn-outline-primary fw-light" (click)="modalClose()">
        Cancel
      </button>
    </div>
  </ng-template>

  <ng-template #refactoredModalActions>
    <ng-container [ngSwitch]="bookingType">
      <om-reschedule-confirmation-modal-action
        *ngSwitchCase="bookingTypeEnumType.Reschedule"
        [selectedInventory]="selectedInventory"
        [analyticsService]="analyticsService"
        [canSubmit]="!bookingState.sendSms || phoneNumberForm.valid"
        (modalClosed)="modalClose()"
        (showErrorModal)="showErrorModal($event)"
        (displayError)="setDisplayError()"
      >
      </om-reschedule-confirmation-modal-action>
      <om-switch-to-remote-confirmation-modal-action
        *ngSwitchCase="bookingTypeEnumType.SwitchToRemote"
        [selectedInventory]="selectedInventory"
        [analyticsService]="analyticsService"
        [canSubmit]="!bookingState.sendSms || phoneNumberForm.valid"
        (modalClosed)="modalClose()"
        (showErrorModal)="showErrorModal($event)"
        (displayError)="setDisplayError()"
      >
      </om-switch-to-remote-confirmation-modal-action>
      <om-book-confirmation-modal-action
        *ngSwitchDefault
        [selectedInventory]="selectedInventory"
        [analyticsService]="analyticsService"
        [canSubmit]="!bookingState.sendSms || phoneNumberForm.valid"
        (modalClosed)="modalClose()"
        (showErrorModal)="showErrorModal($event)"
        (displayError)="setDisplayError()"
      >
      </om-book-confirmation-modal-action>
    </ng-container>
  </ng-template>
</div>
