import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { captureException } from '@sentry/angular-ivy';
import { addYears } from 'date-fns';
import { EMPTY as empty, Subject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Membership } from '@app/core/membership';
import { MembershipService } from '@app/core/membership.service';
import { UpdateCreditCardService } from '@app/core/update-credit-card.service';
import { UserService } from '@app/core/user.service';
import { Coupon } from '@app/shared/coupon';
import { CouponService } from '@app/shared/coupon.service';
import { formatDate } from '@app/shared/date-format.pipe';
import { FlashService, MessageType } from '@app/shared/flash.service';
import { PaymentModalAnalyticsService } from '@app/shared/payment-modal/payment-modal-analytics.service';
import { StripeCreditCardComponent } from '@app/shared/stripe-credit-card/stripe-credit-card.component';

const SUBMIT_CC_ERROR =
  'An error occurred processing this information, please confirm that your payment info is correct';
const SUBMIT_DISCOUNT_ERROR =
  'An error occurred processing your coupon code, please confirm a coupon has not already been applied.';
const SUBMIT_CC_EXCEPTION_PREFIX = 'An error occurred processing this form';

@Component({
  selector: 'om-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss'],
  animations: [trigger('expand', [transition(':enter', [style({ height: 0 }), animate(200, style({ height: '*' }))])])],
})
export class PaymentModalComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    private analyticsService: PaymentModalAnalyticsService,
    private activatedRoute: ActivatedRoute,
    private couponService: CouponService,
    private flashService: FlashService,
    private formBuilder: UntypedFormBuilder,
    private modal: NgbActiveModal,
    private router: Router,
    private userService: UserService,
    public membershipService: MembershipService,
    public updateCreditCardService: UpdateCreditCardService,
  ) {}

  validStripePaymentInfo = false;
  membershipCost: number;
  fullMembershipCost: number;
  validUntil: string;
  firstName: string;
  form: UntypedFormGroup;

  @ViewChild(StripeCreditCardComponent)
  stripeComponent: StripeCreditCardComponent;

  formErrors = {
    creditCard: <string>null,
    submitError: <string>null,
    discountCode: <string>null,
  };

  submitting = false;
  membership: Membership = null;
  showSuccess = false;
  isRenew = false;
  isShowingDiscount = false;
  isApplyingDiscount = false;
  discountCodeButtonText = 'Add discount code';
  couponCode = '';
  coupon: Coupon;
  chargeDateText = '';
  inFreeTrial: boolean;
  modalTitle = '';

  // ignoring ts errors on processingTimeoutId assignment, because setting to null
  // triggers TS7008 noImplicitAny error. Setting it to 0 to let ts know it will be a
  // number (because setTimeout returns timeout id as per docs - https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#return_value)
  // yields a different error - TS2322: Type 'Timeout' is not assignable to type 'number', even though we know per docs return value would be number.
  // @ts-ignore
  private processingTimeoutId = null;
  private subscription = {
    next: () => {
      clearTimeout(this.processingTimeoutId);

      this.couponService.getCurrentCoupon(true);
      this.membershipService.getMembershipWithRequest().subscribe(membership => {
        this.membership = membership;
        if (this.inFreeTrial) {
          this.handleTrialConversionCCUpdate();
        } else {
          this.handlePaymentSuccess();
        }
      });
    },
    error: (errorMessage?: Error | string) => {
      clearTimeout(this.processingTimeoutId);

      this.formErrors.submitError = SUBMIT_CC_ERROR;
      if (errorMessage?.toString().includes('coupon')) {
        this.formErrors.submitError = SUBMIT_DISCOUNT_ERROR;
        this.analyticsService.trackDiscountFailure(this.membership.omMembershipType);
      }
      this.submitting = false;
    },
  };

  ngOnInit() {
    const discountCode = this.activatedRoute.snapshot.queryParams.discount_code || '';
    this.form = this.formBuilder.group({
      creditCard: ['', [Validators.required]],
      couponCode: [discountCode],
    });
    this.form.valueChanges.subscribe(() => {
      this.couponCode = this.form.value.couponCode;
    });

    this.initializeMembership();
    if (discountCode) {
      this.couponCode = discountCode;
      this.validateDiscount();
      this.isShowingDiscount = true;
    }

    if (this.inFreeTrial) {
      this.updateCreditCardService.listenToSubscriptionEvents().subscribe(this.subscription);
    }
  }

  ngOnDestroy() {
    clearTimeout(this.processingTimeoutId);
  }

  ngAfterViewInit() {
    this.stripeComponent.registerOnComplete(e => {
      if (e.complete) {
        this.validStripePaymentInfo = true;
        this.formErrors.creditCard = null;
      }
    });

    this.stripeComponent.registerOnError(e => {
      if (e.error) {
        this.validStripePaymentInfo = false;
        this.formErrors.creditCard = e.error.message;
      }
    });
  }

  closeModal() {
    this.modal.close();
  }

  navigateHome() {
    this.router.navigate(['/'], { replaceUrl: true });
    this.modal.close();
  }

  showDiscountField(event: Event) {
    event.preventDefault();
    this.isShowingDiscount = true;
  }

  validateDiscount() {
    this.isApplyingDiscount = true;
    this.coupon = null;
    this.formErrors.discountCode = null;
    this.couponService
      .validateCoupon(this.couponCode)
      .pipe(
        catchError(error => {
          this.formErrors.discountCode = error;
          this.isApplyingDiscount = false;
          return empty;
        }),
      )
      .subscribe((coupon: Coupon) => {
        this.handleCouponSuccess(coupon);
      });
  }

  onSubmit() {
    this.submitting = true;

    // on staging, we have observed pmt modal not closing after payment succeeds.
    // Reloading the page shows correct state. If it ever happens on prod, let's log it
    // to sentry - and reload the page on member's behalf.
    this.processingTimeoutId = setTimeout(() => {
      captureException(new Error('Membership settings: payment modal shown for over 30s'));
      window.location.reload();
    }, 30000);

    try {
      this.stripeComponent
        .generateToken()
        .pipe(switchMap((token: stripe.Token) => this.stripeCallback(token.id)))
        .subscribe(this.subscription);
    } catch (e) {
      clearTimeout(this.processingTimeoutId);
      this.submitting = false;
      this.formErrors.submitError = `${SUBMIT_CC_EXCEPTION_PREFIX}: ${e};`;
    }
  }

  private handleCouponSuccess(coupon: Coupon) {
    this.coupon = coupon;
    this.isApplyingDiscount = false;
    this.isShowingDiscount = false;
    this.discountCodeButtonText = 'Change code';
    this.updateMembershipCost(coupon);
  }

  private updateMembershipCost(coupon: Coupon) {
    if (coupon.discountType === 'AMOUNT') {
      this.membershipCost = this.fullMembershipCost - coupon.amountOff / 100;
    }
    if (coupon.discountType === 'PERCENT') {
      this.membershipCost = this.fullMembershipCost - this.fullMembershipCost * (coupon.percentOff / 100);
    }
  }

  private initializeMembership() {
    if (this.membership === null) {
      this.membershipService.getMembershipWithRequest().subscribe(membership => {
        this.membership = membership;
        this.configureMembershipOptions();
      });
    } else {
      this.configureMembershipOptions();
    }
  }

  private configureMembershipOptions() {
    this.setMembershipPriceAndValidUntil();

    // Auto set `isRenew` to true, if the member has expired
    this.isRenew = this.membership.isExpired();

    // set up vars to distinguish "purchase membership" (from trial) and "convert to personal" (from b2b) views
    this.setTrialOptions(this.membership.inFreeTrial());
  }

  private setTrialOptions(inTrial: boolean) {
    this.inFreeTrial = inTrial;
    this.chargeDateText = inTrial ? ' on ' + formatDate(new Date(this.membership.trialUntil), 'MMMM d, yyyy') : '';
    this.modalTitle = inTrial ? 'Please enter your payment' : 'Convert to Personal Membership';
  }

  private setMembershipPriceAndValidUntil() {
    // to do: for enterprise, beginDate should be their enterprise expiration + 1 day (like trialUntil date below)
    // backend bit for this future subscription is not yet done, so currently using just 1 year out from now
    const beginDate = this.membership.inFreeTrial() ? new Date(this.membership.trialUntil) : new Date();

    this.validUntil = formatDate(addYears(beginDate, 1), 'MMM d, yyyy');

    this.userService.getUser().subscribe(user => {
      const regionCode: string = user.serviceAreaCode();
      this.firstName = user.firstName;
      this.membershipService.getNewMembershipPrice(regionCode).subscribe(membershipPrice => {
        this.membershipCost = this.fullMembershipCost = membershipPrice['amount'];
      });
    });
  }

  private stripeCallback(token: string) {
    const coupon = this.coupon ? this.coupon.id : null;

    if (this.inFreeTrial) {
      const updateCreditCardResponse = new Subject();
      this.updateCreditCardService.updateCreditCard(token, coupon).subscribe({
        error: error => updateCreditCardResponse.error(error),
      });

      return updateCreditCardResponse;
    } else {
      return this.membershipService.createOrRenewConsumerOrAmazonMembership({ stripeTokenId: token, coupon });
    }
  }

  private handlePaymentSuccess() {
    if (this.membership.isCurrent()) {
      this.showSuccess = true;

      // Mixpanel
      this.analyticsService.trackPaymentSuccess(this.membership.omMembershipType);
      if (this.coupon) {
        this.analyticsService.trackDiscountSuccess(this.membership.omMembershipType, this.coupon);
      }
    }
    this.submitting = false;
  }

  private handleTrialConversionCCUpdate() {
    this.membershipService.convertTrialMembership().subscribe(response => {
      if (response.success) {
        this.handleTrialConversionError();
        return;
      }

      this.membershipService.getMembershipWithRequest().subscribe(membership => {
        if (!membership.inFreeTrial() || membership.trialAutoRenew) {
          this.showSuccess = true;
          this.submitting = false;

          // Mixpanel
          this.analyticsService.trackConvertTrialMembershipCompleted();
          if (this.coupon) {
            this.analyticsService.trackConvertTrialMembershipDiscountSuccess(this.coupon);
          }
        } else {
          this.handleTrialConversionError();
        }
      });
    });
  }

  private handleTrialConversionError() {
    this.submitting = false;
    this.modal.close();
    this.flashService.addFlashMessage(
      "We've encountered an issue submitting your request. Please try again later, or call us at 1-888-ONEMED1 (1-888-663-6331)",
      MessageType.ERROR,
    );
  }
}
