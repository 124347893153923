<select
  [class]="'form-control service-area-select ' + additionalClasses"
  [ngClass]="!currentSelection ? 'unset' : ''"
  name="service-area"
  data-cy="service-area"
  required="true"
  aria-label="Select Service Area"
  [ngModel]="currentSelection"
  (ngModelChange)="setSelectedServiceArea($event)"
>
  <option class="placeholder" [ngValue]="undefined" [disabled]="true" [selected]="true">Select location</option>
  <option *ngFor="let serviceArea of serviceAreas$ | async" [ngValue]="serviceArea"> {{ serviceArea.name }} </option>
</select>
