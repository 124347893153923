/* To do: deprecate in favor of SvgEyeOpenComponent (potentially used in combination with SvgEyeClosedComponent) */

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'om-show-password',
  templateUrl: './show-password.component.html',
})
export class ShowPasswordComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
