import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-x-with-circle',
  templateUrl: './x-with-circle.component.html',
})
export class XWithCircleComponent {
  @Input() circleFillColor = '#1B7E74';
  @Input() widthPx = 15;
}
