import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TermsOfService } from '@app/core/legal-documents';

@Component({
  selector: 'om-legal-agreement',
  templateUrl: './legal-agreement.component.html',
  styleUrls: ['../legal-doc-shared.scss'],
})
export class LegalAgreementComponent {
  // FIXME: This is not being used by any parent component, can likely be removed
  /** @ignore - Storybook */
  @Input() processing: boolean;
  @Input() agree = false;
  @Output() agreed = new EventEmitter<TermsOfService>();

  /** @ignore - Storybook */
  protected legalAgreementId = `legal-agreement-${Math.round(Math.random() * 1_000_000)}`;

  agreeSelected($event: MouseEvent) {
    $event.preventDefault();
    if (!this.agree) {
      this.agreed.emit();
    }
  }
}
