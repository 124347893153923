import { Component, NgModule } from '@angular/core';

@Component({
  selector: 'omgui-upward-trend-icon',
  template: `
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 0C1.79086 0 0 1.79086 0 4V10C0 10.2914 0.031154 10.5755 0.0903128 10.8491L4.38134 6.55812C4.72305 6.21641 5.27707 6.21641 5.61878 6.55812L7.00006 7.9394L10.4395 4.5H8.75C8.47386 4.5 8.25 4.27614 8.25 4V3.5C8.25 3.22386 8.47386 3 8.75 3H12C12.5523 3 13 3.44772 13 4V7.25C13 7.52614 12.7761 7.75 12.5 7.75H12C11.7239 7.75 11.5 7.52614 11.5 7.25V5.56077L7.61878 9.442C7.27707 9.78371 6.72305 9.78371 6.38134 9.442L5.00006 8.06072L0.740992 12.3198C1.46623 13.3368 2.65567 14 4 14H12C14.2091 14 16 12.2091 16 10V4C16 1.79086 14.2091 0 12 0H4Z"
        fill="currentColor"
      />
    </svg>
  `,
})
export class OmguiUpwardTrendIconComponent {}

@NgModule({
  declarations: [OmguiUpwardTrendIconComponent],
  exports: [OmguiUpwardTrendIconComponent],
})
export class OmguiUpwardTrendIconModule {}
