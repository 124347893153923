import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppointmentBookingStateService } from '@app/appointment/appointment-booking-state-service';
import { AppointmentInventory } from '@app/appointment/provider-inventories';
import { RescheduleAppointmentGraphQLService } from '@app/appointment/reschedule-appointment-graphql.service';

import {
  AbstractConfirmationModalActionService,
  SubmissionResults,
} from '../base-confirmation-modal-action/abstract-confirmation-modal-action.service';

@Injectable({
  providedIn: 'root',
})
export class RescheduleConfirmationModalActionService implements AbstractConfirmationModalActionService {
  constructor(
    private bookingStateService: AppointmentBookingStateService,
    private rescheduleAppointmentGraphQLService: RescheduleAppointmentGraphQLService,
  ) {}

  getConfirmationCTA(): string {
    return 'Reschedule Appointment';
  }

  executeAppointmentAction$(selectedInventory: AppointmentInventory): Observable<SubmissionResults> {
    const bookingState = this.bookingStateService.getAppointmentBookingState();

    return this.rescheduleAppointmentGraphQLService
      .mutate({
        input: {
          fromAppointmentId: bookingState.getFromAppointmentId().toString(),
          appointmentCancellationReasonId: bookingState.getCancellationReasonId().toString(),
          newAppointmentAttributes: {
            appointmentTypeId: bookingState.getAppointmentTypeId().toString(),
            inventoryId: selectedInventory.id.toString(),
            sendSms: bookingState.sendSms,
            smsNumber: bookingState.sendSms ? bookingState.phoneNumber : null,
          },
        },
      })
      .pipe(map(result => result.data.rescheduleAppointment));
  }
}
