import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';

import { LinksService } from '@app/core/links.service';
import { MODULE_DIRECT_SIGN_UP_SELECTION_PAGE } from '@app/core/mixpanel.constants';

import { EnterpriseRegistration } from '../enterprise-registration';
import { RegistrationStep } from '../registration-step';
import { StepName } from '../registration-step-name';
import { DirectSignupStepComponent } from './direct-signup-step.component';

@Injectable({
  providedIn: 'root',
})
export class DirectSignupConfig extends RegistrationStep implements OnDestroy {
  private selectedStep$ = new ReplaySubject<StepName>(1);
  private destroy$ = new Subject<void>();

  readonly MODULE = MODULE_DIRECT_SIGN_UP_SELECTION_PAGE;
  readonly component = DirectSignupStepComponent;
  readonly progress = 90;
  readonly form: UntypedFormGroup;

  constructor(private linksService: LinksService) {
    super();
  }

  initComponent(component: DirectSignupStepComponent, state: EnterpriseRegistration): void {
    component.nextStepSelected.asObservable().pipe(takeUntil(this.destroy$)).subscribe(this.selectedStep$);
    component.module = this.MODULE;
    component.isWhitelisted = state.isWhitelisted;
  }

  submit(state: EnterpriseRegistration): Observable<boolean> {
    return this.selectedStep$.pipe(
      take(1),
      tap(step => {
        if (step === StepName.adultDirectSignup) {
          state.setCurrentStep(step);
        } else {
          window.location.href = `${this.linksService.onboarding}?source=${MODULE_DIRECT_SIGN_UP_SELECTION_PAGE}`;
        }
      }),
      map(() => true),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
