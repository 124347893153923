import { HealthInformationExchangePatientPreferences } from '../account/health-information-exchange/__generated__/health-information-exchange-graphql.service.types';
import { ApiV2Response } from './patient-preferences.service';

export class PatientPreferences {
  fullTextNotifications: boolean;
  emailNotifications: boolean;
  pushNotifications: boolean;
  avsHideWeight: boolean;

  // GraphQL fields
  healthInformationExchangeOptIn: boolean;
  healthInformationExchangeLegalTextVersion: number;

  constructor() {}

  static fromApiV2(response: ApiV2Response): PatientPreferences {
    const preferences = new PatientPreferences();
    preferences.fullTextNotifications = response.full_text_notifications;
    preferences.emailNotifications = response.accepts_patient_task_emails;
    preferences.pushNotifications = response.accepts_patient_task_push;
    preferences.avsHideWeight = response.avs_hide_weight;
    return preferences;
  }

  static fromGraphQL(response: HealthInformationExchangePatientPreferences): PatientPreferences {
    const preferences = new PatientPreferences();

    preferences.healthInformationExchangeOptIn = response.healthInformationExchangeOptIn;
    preferences.healthInformationExchangeLegalTextVersion = response.healthInformationExchangeLegalTextVersion;

    return preferences;
  }

  toApiV2() {
    return {
      full_text_notifications: this.fullTextNotifications,
      accepts_patient_task_emails: this.emailNotifications,
      accepts_patient_task_push: this.pushNotifications,
      avs_hide_weight: this.avsHideWeight,
    };
  }
}
