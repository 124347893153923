import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';

import { ComponentSize } from '@omgui/component-size';
import { OmguiCameraModule } from '@omgui/omgui-camera/omgui-camera.component';
import { OmguiSpinnerModule } from '@omgui/omgui-spinner/omgui-spinner.component';

export class ProfileBubbleStates {
  static DANGER = 'DANGER';
  static PROCESSING = 'PROCESSING';
  static REMOTE = 'REMOTE';
}

export enum UserType {
  Patient = 'Patient',
  InternalUser = 'InternalUser',
  Role = 'Role',
}

/**
 * To generate the fallback image using the profile holder's initials, Profile requires one of the following:
 *  - preferredName + lastName
 *  - firstName + lastName
 *  - displayName
 */
export interface Profile {
  type: UserType;
  displayName?: string;
  firstName?: string;
  lastName?: string;
  preferredName?: string;
  profileImageUrl?: string | null;
  isForLabService?: boolean;
}

export interface GraphQLProfile extends Omit<Profile, 'type'> {
  __typename: 'Patient' | 'PatientProfileInfo' | 'Provider' | 'InternalUserPublic' | 'PatientLimited' | 'PatientBasic';
}

@Component({
  selector: 'omgui-profile-bubble',
  templateUrl: './omgui-profile-bubble.component.html',
  styleUrls: ['omgui-profile-bubble-sizes.scss', 'omgui-profile-bubble.component.scss'],
})
export class OmguiProfileBubbleComponent {
  @Input() size = ComponentSize.small;
  @Input() status: ProfileBubbleStates;

  @Input() set profile(profile: Profile | GraphQLProfile) {
    if (profile == null) {
      return;
    }

    if (profile.isForLabService) {
      this.source = '/assets/images/icons/icon-labs.svg';
    } else {
      this.source = profile.profileImageUrl;
    }
    this.profileImageStyle = { 'background-image': `url(${this.source})` };
    this.setDefaultProfileImage(profile);

    if ('type' in profile && profile.type) {
      this.userType = profile.type;
    } else if ('__typename' in profile) {
      const patientTypenames = ['PatientProfileInfo', 'Patient', 'PatientBasic'];
      this.userType = patientTypenames.includes(profile.__typename) ? UserType.Patient : UserType.InternalUser;
    } else {
      this.userType = UserType.Patient;
    }
  }

  initials: string;
  profileBubbleStates = ProfileBubbleStates;
  source: string;
  profileImageStyle: object;
  userType: UserType;

  readonly ComponentSize = ComponentSize;
  readonly UserType = UserType;

  private setDefaultProfileImage(profile: Omit<Profile, 'type'>): void {
    if (!!profile.preferredName) {
      this.initials = profile.preferredName[0];

      if (!!profile.lastName) {
        this.initials += profile.lastName[0];
      }
    } else if (!!profile.firstName) {
      this.initials = profile.firstName[0];

      if (!!profile.lastName) {
        this.initials += profile.lastName[0];
      }
    } else if (!!profile.displayName) {
      this.initials = profile.displayName[0];
    }
  }

  fallbackToInitials(_e: ErrorEvent) {
    this.source = null;
  }
}

@NgModule({
  declarations: [OmguiProfileBubbleComponent],
  imports: [CommonModule, OmguiCameraModule, OmguiSpinnerModule],
  exports: [OmguiProfileBubbleComponent],
})
export class OmguiProfileBubbleModule {}
