<div
  class="d-flex flex-row justify-content-start mx-2 flex-fill account-box"
  *ngIf="profile"
  data-cy="dropdown-account-item"
>
  <omgui-profile-bubble aria-hidden="true" [profile]="profile" class="m-2"></omgui-profile-bubble>
  <div class="p-2 account-name d-flex flex-fill align-items-center" data-cy="profile-name">
    {{ profile.preferredName }} {{ profile.lastName }}
  </div>
</div>

<a class="dropdown-item" routerLink="/account/profile" [omTrackLink]="trackAccountClicked" data-cy="account-nav">
  Account Settings
</a>
<a
  class="dropdown-item"
  routerLink="/appointments/list"
  [omTrackLink]="trackAppointmentsClicked"
  data-cy="appointments-nav"
>
  Appointments
</a>
<a
  *ngIf="!virtual"
  class="dropdown-item"
  routerLink="/account/my-provider"
  [omTrackLink]="trackProviderClicked"
  data-cy="provider-nav"
>
  Provider
</a>
<a
  *ngIf="enrolledInMindsetPlus"
  class="dropdown-item"
  routerLink="/programs/mindset+"
  [omTrackLink]="trackMindsetSelected"
  data-cy="program-hub-nav"
>
  Mindset+
</a>
<a class="dropdown-item" [routerLink]="links.help" [omTrackLink]="trackHelpCenterLink" data-cy="help-center-nav">
  Help Center
</a>
<a class="dropdown-item" href="#" (click)="logout($event)" data-cy="log-out-nav">Log Out</a>

<div class="scrollable-list" *ngIf="accountSwitchingOptions.length > 0">
  <ng-container *ngFor="let accountSwitchingOption of accountSwitchingOptions">
    <a
      class="d-flex flex-row justify-content-start flex-fill account-box dropdown-item"
      data-cy="select-target-user-nav"
      routerLink=""
      [id]="'select-target-user-nav-' + accountSwitchingOption.user.id"
      (click)="selectTargetUser(accountSwitchingOption.user)"
    >
      <omgui-profile-bubble
        aria-hidden="true"
        [profile]="accountSwitchingOption.user"
        class="ms-0 me-2 my-2"
      ></omgui-profile-bubble>
      <div class="p-2 account-name d-flex flex-fill align-items-center" data-cy="profile-name">
        {{ accountSwitchingOption.user.preferredName }} {{ accountSwitchingOption.user.lastName }}
      </div>
    </a>
  </ng-container>
</div>

<a
  *ngIf="canRegisterKid"
  class="dropdown-item register-child"
  routerLink="/registration/pediatric"
  [omTrackLink]="trackRegisterChildLink"
  data-cy="consumer-ped-reg-nav"
>
  <om-svg-add-family-member class="pe-3"></om-svg-add-family-member>
  Add Child
</a>
<a
  *ngIf="directSignupEligible"
  class="dropdown-item"
  [routerLink]="links.referrals"
  [omTrackLink]="trackDirectSignupLink"
  data-cy="family-nav"
>
  Register Family Member
</a>
<div class="dropdown-item no-hover" *ngIf="displayInviteCta">
  <omgui-button
    [variant]="OmguiButtonVariant.secondary"
    [size]="OmguiButtonSize.fillParent"
    class="no-wrap-cta-button"
    customButtonClass="inline-icon-with-text justify-content-center"
    (buttonClick)="openInviteModal()"
    data-cy="invite-nav"
  >
    <om-svg-gift-box-icon fill="currentColor" class="me-2"></om-svg-gift-box-icon>
    {{ inviteCta }}
  </omgui-button>
</div>
