import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

import { hideOnboardingPrompts, showOnboardingPrompts } from '@app/onboarding/onboarding.actions';

export const onboardingFeatureKey = 'onboardingPrompts';

export interface OnboardingPromptsState {
  showOnboardingPrompts: boolean;
}

export const initialState: OnboardingPromptsState = {
  showOnboardingPrompts: false,
};

export const reducer = createReducer(
  initialState,
  on(showOnboardingPrompts, state => ({ ...state, ...initialState, showOnboardingPrompts: true })),
  on(hideOnboardingPrompts, state => ({ ...state, ...initialState, showOnboardingPrompts: false })),
);

export const selectOnboardingPromptsState = createFeatureSelector<OnboardingPromptsState>(onboardingFeatureKey);
export const getOnboardingPromptsState = createSelector(
  selectOnboardingPromptsState,
  (state: OnboardingPromptsState) => state.showOnboardingPrompts,
);
