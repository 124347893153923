<div [formGroup]="passwordForm">
  <div
    class="text-field d-flex flex-row align-items-center password-container"
    [class.focused]="inputFocused"
    [class.ng-invalid]="passwordForm.invalid"
    [class.ng-dirty]="passwordForm.dirty && passwordForm.touched"
  >
    <input
      class="hidden flex-grow-1 form-control"
      #input
      [type]="showPassword ? 'text' : 'password'"
      id="password"
      name="password"
      required="true"
      [attr.aria-describedby]="'password-error ' + passwordComplexityErrorsToMessageIds"
      formControlName="password"
      placeholder="Enter password"
      (focus)="focusContainingDiv()"
      (blur)="blurContainingDiv()"
    />
    <div class="show-hide-password show-password p-2" (click)="showPassword = !showPassword">
      <om-show-password aria-hidden="true" class="show-password"></om-show-password>
      {{ showPassword ? 'Hide' : 'Show' }}
    </div>
  </div>
  <ul
    id="password-validations"
    class="form-group row mt-2 password-validations list-unstyled"
    aria-label="password requirements"
  >
    <li class="col-12 col-sm-6" *ngFor="let error of passwordComplexityErrorsToMessages" id="{{ error.key }}">
      <om-svg-checkmark
        aria-hidden="true"
        *ngIf="noError(error.key); else bullet"
        [class.d-none]="!noError(error.key)"
      ></om-svg-checkmark>
      {{ error.message }}
    </li>
    <ng-template #bullet>
      <span class="bullet" aria-hidden="true">•</span>
    </ng-template>
  </ul>
  <!-- screen reader specific announcements for meeting the password requirements -->
  <ul class="sr-only">
    <li *ngFor="let error of passwordComplexityErrorsToMessages" aria-live="polite">
      <span *ngIf="noError(error.key)">{{ error.message }} requirement met.</span>
    </li>
  </ul>
  <span *ngIf="serverError" class="error-message" id="password-error"> {{ serverError }} </span>
</div>
