<button
  [class.disabled]="disabled"
  [disabled]="disabled"
  [class.inline]="inline"
  [class.current-color]="currentColor"
  [attr.aria-label]="ariaLabel"
  *ngIf="clickHandler"
>
  <ng-container [ngSwitch]="size">
    <ng-container *ngSwitchCase="'xsmall'" [ngTemplateOutlet]="xsmallArrow"></ng-container>
    <ng-container *ngSwitchCase="'large'" [ngTemplateOutlet]="largeArrow"></ng-container>
    <ng-container *ngSwitchCase="'small'" [ngTemplateOutlet]="smallArrow"></ng-container>
  </ng-container>
</button>

<div [class.disabled]="disabled" [class.inline]="inline" [class.current-color]="currentColor" *ngIf="!clickHandler">
  <ng-container [ngSwitch]="size">
    <ng-container *ngSwitchCase="'xsmall'" [ngTemplateOutlet]="xsmallArrow"></ng-container>
    <ng-container *ngSwitchCase="'large'" [ngTemplateOutlet]="largeArrow"></ng-container>
    <ng-container *ngSwitchCase="'small'" [ngTemplateOutlet]="smallArrow"></ng-container>
  </ng-container>
</div>

<ng-template #smallArrow>
  <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4967 1.39286C10.4967 1.24219 10.4297 1.10826 10.3292 1.00781L9.49219 0.170758C9.39174 0.0703125 9.24107 0.00334668 9.10714 0.00334668C8.97321 0.00334668 8.82255 0.0703125 8.7221 0.170758L0.920759 7.9721C0.820313 8.07254 0.753348 8.22321 0.753348 8.35714C0.753348 8.49107 0.820313 8.64174 0.920759 8.74219L8.7221 16.5435C8.82255 16.644 8.97321 16.7109 9.10714 16.7109C9.24107 16.7109 9.39174 16.644 9.49219 16.5435L10.3292 15.7065C10.4297 15.606 10.4967 15.4554 10.4967 15.3214C10.4967 15.1875 10.4297 15.0368 10.3292 14.9364L3.75 8.35714L10.3292 1.7779C10.4297 1.67745 10.4967 1.52678 10.4967 1.39286Z"
      fill="green"
    />
  </svg>
</ng-template>

<ng-template #largeArrow>
  <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.8496 2L2.84961 12L12.8496 22" stroke="#068466" stroke-width="4" />
  </svg>
</ng-template>

<ng-template #xsmallArrow>
  <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.99763 4.61L6.96806 7.45517L5.48837 9L0.971736 4.67383L5.45645 -1.34939e-07L6.99997 1.48106L3.99763 4.61Z"
      fill="#0358B2"
    />
  </svg>
</ng-template>
