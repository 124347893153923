import { CurrencyPipe } from '@angular/common';
import { int } from 'aws-sdk/clients/datapipeline';

import { ApiV2Coupon } from '@app/shared/coupon.service';

/** Discount code types. */
export enum DiscountType {
  /** A percentage-based discount. */
  PERCENT = 'PERCENT',

  /**  A fixed dollar amount discount. */
  AMOUNT = 'AMOUNT',
}

/** Discount code types. */
export enum CodeType {
  /** A regular discount. */
  Discount = 'Discount',

  /** A referral discount */
  Referral = 'Referral',

  /**  A trial discount */
  Trial = 'Free Trial',
}

/**
 * A coupon, usually loaded from the back-end via the coupon API. Coupons are
 * associated with referral codes, which are discount types granted to users to
 * refer their friends.
 */

export class Coupon {
  /** The coupon code that the user enters. */
  id: string;

  /** The associated discount type. */
  discountType: DiscountType;

  /** The associated discount code type: Trial, Referral, Discount */
  codeType: CodeType;

  /** A human-readable description of the discount. */
  description: string;

  /** Raw data representing amount off */
  amountOff: int;

  /** Raw data representing percent off */
  percentOff: int;

  /** The number of days granted if this is a free trial membership **/
  trialDays: int;

  /** Option that allows for a user to register without entering a credit card **/
  skipCreditCard: boolean;

  /** Option for when the trial membership will deactivate instead of auto-enrolling at the end of the trial **/
  deactivateAtTrialEnd: boolean;

  constructor() {}

  /**
   * Creates a Coupon from an API v2 response.
   *
   * @param response The response from the coupon API.
   * @return The coupon object.
   */

  static fromApiV2(response: ApiV2Coupon | {}): Coupon {
    const coupon = new Coupon();

    if (!this.isCouponResponse(response)) {
      return coupon;
    }

    coupon.id = response.coupon_id;
    coupon.discountType = Coupon.determineType(response);
    coupon.description = Coupon.buildDescription(coupon.discountType, response);
    coupon.amountOff = response.amount_off;
    coupon.percentOff = response.percent_off;
    coupon.trialDays = response.trial_days;
    coupon.skipCreditCard = response.skip_cc;
    coupon.codeType = CodeType[response.type as keyof typeof CodeType];
    coupon.deactivateAtTrialEnd = response.deactivate_at_trial_end;

    return coupon;
  }

  private static isCouponResponse(response: ApiV2Coupon | {}): response is ApiV2Coupon {
    return (<ApiV2Coupon>response).coupon_id !== undefined;
  }

  private static determineType(response: ApiV2Coupon) {
    if (response.percent_off != null) {
      return DiscountType.PERCENT;
    } else if (response.amount_off != null) {
      return DiscountType.AMOUNT;
    }
    throw new Error('Unknown coupon type');
  }

  private static buildDescription(discountType: DiscountType, response: ApiV2Coupon): string {
    return discountType === DiscountType.PERCENT
      ? Coupon.buildPercentDescription(response)
      : Coupon.buildAmountDescription(response);
  }

  private static buildPercentDescription(response: ApiV2Coupon) {
    return `${response.percent_off}% off your membership.`;
  }

  private static buildAmountDescription(response: ApiV2Coupon) {
    const currencyPipe = new CurrencyPipe('en-US');
    const dollar_amount = currencyPipe.transform(response.amount_off / 100, 'USD', 'symbol', '1.0-0');
    return `${dollar_amount} off your membership.`;
  }
}
