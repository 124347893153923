import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AnalyticsService } from '@app/core/analytics.service';

@Injectable()
export class ProgramHubAnalyticsService extends AnalyticsService {
  trackPageViewed({ programName }: { programName: string }): Observable<any> {
    return this.trackWithDefaultProperties('Page Viewed', {
      module: 'Program Hub',
      program: programName,
    });
  }

  trackProgramModuleClicked({ module, ctaType }: { module: string; ctaType?: string }): Observable<any> {
    return this.trackWithDefaultProperties('Program Module Clicked', {
      module: module,
      cta_type: ctaType,
    });
  }

  trackProgramModalClicked({ module }: { module: string }): Observable<any> {
    return this.trackWithDefaultProperties('Program Modal Clicked', {
      module: module,
    });
  }

  trackCareTeamRolesViewed({ role }: { role: string }): Observable<any> {
    return this.trackWithDefaultProperties('Care Team Roles Viewed', { role });
  }
}
