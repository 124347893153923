import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

@Injectable()
export class PatientMobileDsuEligibleGraphQL extends Query<any> {
  document = gql`
    query PatientMobileDsuEligible {
      patient {
        mobileDirectSignupEligible
      }
    }
  `;
}
