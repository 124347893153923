import { createReducer, on } from '@ngrx/store';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';

import { setFlags } from '@app/core/feature-flags/feature-flag.actions';

export const featureKey = 'featureFlags';

export interface FeatureFlagState {
  flags: LDFlagSet;
}

export const initialState: FeatureFlagState = {
  flags: {},
};

export const reducer = createReducer(
  initialState,
  on(setFlags, (state, action) => ({
    ...state,
    flags: { ...state.flags, ...action.flags },
  })),
);
