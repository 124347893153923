import { Injectable } from '@angular/core';
import { setMonth } from 'date-fns';
import range from 'lodash-es/range';
import take from 'lodash-es/take';

import { formatDate } from '@app/shared';

export class MonthData {
  name: string;
  monthNumber: string;
}

@Injectable()
export class DateService {
  private readonly monthData;
  private readonly currentYear;
  private readonly currentMonth;

  constructor() {
    this.monthData = this.buildMonthData();
    this.currentYear = new Date().getFullYear();
    this.currentMonth = new Date().getMonth() + 1;
  }

  private buildMonthData(): MonthData[] {
    const now = new Date();
    return range(0, 12).map(monthNumber => {
      const date = setMonth(now, monthNumber);
      return { name: formatDate(date, 'MMMM'), monthNumber: formatDate(date, 'MM') };
    });
  }

  get months() {
    return this.monthData;
  }

  lastXYears(number: number): number[] {
    return range(this.currentYear, this.currentYear - number);
  }

  pastCurrentMonthsForYear(year: number): MonthData[] {
    if (year < this.currentYear) {
      return this.months;
    }
    return take(this.monthData, this.currentMonth);
  }
}
