import { AbstractControl } from '@angular/forms';
import { ValidationErrors } from 'ngrx-forms/public_api';
import isEmail from 'validator/es/lib/isEmail';

export function emailValidator({
  value,
}: Pick<AbstractControl<string>, 'value'>): {
  invalidEmail: { value: string };
} | null {
  return isEmail(value) ? null : { invalidEmail: { value } };
}

export function ngrxFormsEmailValidator(email: string): ValidationErrors {
  return emailValidator({ value: email }) || {};
}
