import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';

import { MODULE_REMOTE_VISIT_MODAL } from '@app/core/mixpanel.constants';
import { HomePageAnalyticsService } from '@app/home/home-page-analytics.service';

@Component({
  selector: 'om-billing-clarity-modal',
  templateUrl: './billing-clarity-modal.component.html',
  styleUrls: ['./billing-clarity-modal.component.scss'],
})
export class BillingClarityModalComponent {
  @ViewChild('modalContent') modalContent: TemplateRef<any>;

  @Input() analyticsModule: string;
  @Output() appLinkClicked = new EventEmitter();
  @Output() proceedToBookingFlow = new EventEmitter();

  modalReference: NgbModalRef;

  constructor(private modalService: NgbModal, private homePageAnalyticsService: HomePageAnalyticsService) {}

  open() {
    this.modalReference = this.modalService.open(this.modalContent, {
      centered: true,
      modalDialogClass: 'modal-redesign no-sm-rounded-bottom',
      ariaLabelledBy: 'billing-clarity-modal',
    });

    this.homePageAnalyticsService.trackRemoteVisitBillingClarityModalViewed();
    this.modalReference.dismissed.pipe(take(1)).subscribe(() => this.homePageAnalyticsService.trackModalClosed());
  }

  dismissAndShowAppModal(e: KeyboardEvent) {
    e.preventDefault();
    if (e.type === 'keyup' && e.key !== 'Enter') {
      return;
    }

    this.modalReference.hidden.pipe(take(1)).subscribe(() => {
      this.appLinkClicked.emit();
      this.homePageAnalyticsService.trackDownloadAppClicked('Appointment Booking', MODULE_REMOTE_VISIT_MODAL);
    });

    this.modalReference.dismiss();
  }

  acknowledge() {
    this.proceedToBookingFlow.emit();
    this.modalReference.dismiss();
  }
}
