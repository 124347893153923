<div class="container mt-xl-10 mt-2">
  <div class="row" *ngIf="!noB2bCompanyIdError">
    <form class="col-12 col-xl-6 px-xl-5 col-lg-8 offset-lg-2 offset-xl-0" [formGroup]="form" (ngSubmit)="onSubmit()">
      <h1> Welcome to <span class="text-nowrap">One Medical.</span> </h1>
      <div class="g-0 mb-4">
        <p class="col-12">
          Activate your sponsored membership to get exceptional primary care, same or next day appointments, and 24/7
          virtual care with our app.
        </p>
      </div>
      <div class="mb-3">
        <om-label-wrapper class="" label="Enter your ID number" [hasError]="hasError" [errorMessage]="errorMessage">
          <input
            type="text"
            class="form-control text-field mb-2"
            placeholder="ID Number"
            formControlName="employeeId"
            required="true"
            autofocus
          />
        </om-label-wrapper>
        <small class="d-block text-black-50 mt-2">
          This can include subscriber ID or employee ID. Contact your benefits team if you have any issues.
        </small>
      </div>
      <div>
        <om-submit-button class="col-12" label="Activate Your Membership" [submitting]="submitting"></om-submit-button>
      </div>
      <div *ngIf="!isLoggedIn" class="col-12 text-center">
        <small> Already have an account? <a href="" (click)="onLogIn($event)">Update your membership</a> </small>
      </div>
    </form>
    <div class="col-xl-6 d-none d-xl-flex align-middle"><img src="/assets/images/work-email-image.png" /></div>
  </div>
  <div *ngIf="noB2bCompanyIdError">
    <h1 class="text-center mb-3">Uh oh!</h1>
    <p class="text-center col-10 offset-1 col-xl-8 offset-xl-2">
      It looks like the page can’t be found. Please start at the landing page provided by your organization or contact
      your benefits team for help.
    </p>
  </div>
</div>
