import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

export interface DiscountType {
  name?: string;
  description?: string;
  amount?: number | null;
  percentage?: number | null;
  validForNew?: boolean;
  validForRenewal?: boolean;
  validWithOtherDiscounts?: boolean;
  b2bPlanId?: number | null;
  kind?: string | null;
  referralCode?: {
    name: string;
  } | null;
}

export interface Response {
  discountType: DiscountType;
}

export interface Variables {
  code: string;
}

/**
 * GraphQL query that loads DiscountType records by code.
 */

@Injectable()
export class DiscountTypeGraphQL extends Query<Response, Variables> {
  document = gql`
    query DiscountTypeByCode($code: String!) {
      discountType(code: $code) {
        amount
        b2bPlanId
        description
        kind
        name
        percentage
        validForNew
        validForRenewal
        validWithOtherDiscounts
        referralCode {
          name
        }
      }
    }
  `;
}
