<div class="position-relative {{ size }}">
  <div class="position-absolute loop-container" *ngIf="active">
    <svg class="loop" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="5" y="5" width="90" height="90" rx="50" fill="none" attr.stroke="url(#{{ uuid }})" />
      <defs>
        <linearGradient id="{{ uuid }}" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#ABCC76" />
          <stop offset="1" stop-color="#3BADBC" />
        </linearGradient>
      </defs>
    </svg>
  </div>

  <omgui-profile-bubble [profile]="profile" [size]="size"></omgui-profile-bubble>

  <ng-container [ngSwitch]="notificationType">
    <img
      *ngSwitchCase="NotificationType.inOfficeAppointment"
      class="notification-type-specifier"
      src="/assets/images/icons/in-office-visit.svg"
      aria-hidden="true"
      alt="in office visit"
    />

    <img
      *ngSwitchCase="NotificationType.remoteAppointment"
      class="notification-type-specifier"
      src="/assets/images/icons/remote-visit.svg"
      aria-hidden="true"
      alt="remote visit"
    />

    <img
      *ngSwitchCase="NotificationType.unreadMessage"
      class="notification-type-specifier"
      src="/assets/images/icons/message.svg"
      aria-hidden="true"
      alt="unread message"
    />
  </ng-container>
</div>
