import { Component, Input } from '@angular/core';

import { RegisterFlows } from '@app/registration/consumer/consumer-registration.constants';
import { MEMBERSHIP_BENEFITS } from '@app/registration/consumer/consumer-registration.constants';
import { Coupon } from '@app/shared/coupon';

@Component({
  selector: 'amf-value-props',
  templateUrl: './amf-value-props.component.html',
  styleUrls: ['./amf-value-props.component.scss'],
})
export class AmfValuePropsComponent {
  @Input() amf: string;
  @Input() coupon: Coupon;
  @Input() buttonLabel: string;
  @Input() disabled = true;
  @Input() submitting = false;
  @Input() regFlow: RegisterFlows = RegisterFlows.Paid;
  @Input() discountCode: string;
  @Input() trialDays: number;
  @Input() trialAutoRenew: boolean;
  @Input() planRenewsOn: string;
  @Input() paymentAmount: number;
  registerFlows = RegisterFlows;
  value_props = MEMBERSHIP_BENEFITS;

  getTotal() {
    let total = parseFloat(this.amf);
    if (this.coupon) {
      if (this.coupon.amountOff) {
        total = total - this.coupon.amountOff / 100;
      } else if (this.coupon.percentOff) {
        total = total - total * (this.coupon.percentOff / 100);
      }
    }
    return total % 1 === 0 ? Math.floor(total) : total.toFixed(2);
  }
}
