<div ngbDropdown>
  <a class="btn add-to-calendar" id="addToCalendar" ngbDropdownToggle>
    <om-calendar></om-calendar>
    <span>Add to Calendar</span>
  </a>
  <div ngbDropdownMenu aria-labelledby="addToCalendar">
    <a
      class="dropdown-item"
      (click)="emitAnalyticsEvent()"
      [href]="googleCalendarUrl"
      target="_blank"
      rel="noopener noreferrer"
      data-cy="google-calendar-link"
    >
      Google Calendar
    </a>
    <a
      class="dropdown-item"
      (click)="emitAnalyticsEvent()"
      [href]="yahooCalendarUrl"
      target="_blank"
      rel="noopener noreferrer"
      data-cy="yahoo-calendar-link"
    >
      Yahoo Calendar
    </a>
    <a
      class="dropdown-item"
      (click)="emitAnalyticsEvent()"
      [href]="outlookComCalendarUrl"
      target="_blank"
      rel="noopener noreferrer"
      data-cy="outlook-com-calendar-link"
    >
      Outlook.com Calendar
    </a>
    <a class="dropdown-item" (click)="emitAnalyticsEvent()" [href]="icsFile">Outlook Calendar</a>
    <a class="dropdown-item" (click)="emitAnalyticsEvent()" [href]="icsFile">Apple Calendar</a>
  </div>
</div>
