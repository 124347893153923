import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '@app/core/auth.service';

import { LinksService } from './links.service';

@Injectable({
  providedIn: 'root',
})
export class NotLoggedInGuardService implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private links: LinksService) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.authService.isLoggedIn$().pipe(map(isLoggedIn => !isLoggedIn || this.router.parseUrl(this.links.home)));
  }
}
