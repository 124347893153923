import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, Output } from '@angular/core';

@Component({
  selector: 'omgui-close-x',
  templateUrl: './omgui-close-x.component.html',
  styleUrls: ['./omgui-close-x.component.scss'],
})
export class OmguiCloseXComponent {
  @Input() stroke = '#343434';
  @Input() fill = '#F7F6F4';
  @Input() backgroundFill = 'none';
  @Input() backgroundOpacity: 1;

  @Input() width = 36;
  @Input() height = 36;

  @Output() buttonClick = new EventEmitter();
}

@NgModule({
  declarations: [OmguiCloseXComponent],
  imports: [CommonModule],
  exports: [OmguiCloseXComponent],
})
export class OmguiCloseXModule {}
