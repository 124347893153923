import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';

import { Patient } from './__generated__/trial-video-visit-onboarding-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class TrialVideoVisitOnboardingGraphQL extends Query<Patient> {
  document = gql`
    query TrialVideoVisitOnboardingGraphQL {
      patient {
        id
        hasAmazonPrimeTrial
        isRegComplete
        completedAmazonFlowStatus
      }
    }
  `;
}
