import { Injectable } from '@angular/core';
import { Channel } from 'pusher-js';

import { RealtimeCommunicationService } from './realtime-communication.service';

/**
 * @deprecated Use RealtimeCommunicationService, which does not leak pusher.js concerns
 */
@Injectable()
export class PusherService {
  constructor(private realtimeCommunicationService: RealtimeCommunicationService) {}

  /**
   * @deprecated Use RealtimeCommunicationService#getResponse, which does not leak pusher.js concerns
   */
  subscribeTo(channelName: string): Channel {
    return this.realtimeCommunicationService.subscribe(channelName);
  }
}
