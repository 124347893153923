import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { LinksService } from '@app/core/links.service';
import { NodeUrlMappingService } from '@app/registration/graph/node-url-mapping.service';
import { RegistrationNode } from '@app/registration/graph/nodes/registration.node';
import { HORNBILL_INVITE_SOURCE_FLOW_KEY, PrepaidInviteSourceFlow } from '@app/shared/hornbill-params';

@Injectable({
  providedIn: 'root',
})
export class PrepaidInviteNode extends RegistrationNode {
  constructor(private linksService: LinksService, nodeUrlMappingService: NodeUrlMappingService, router: Router) {
    super(nodeUrlMappingService, router);
    this.edges = [];
  }

  execute(): void {
    this.router.navigate([this.linksService.prepaidInvite], {
      queryParams: { [HORNBILL_INVITE_SOURCE_FLOW_KEY]: PrepaidInviteSourceFlow.Registration },
      queryParamsHandling: 'merge',
    });
  }
}
