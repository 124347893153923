import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'om-navbar-progress-bar',
  styleUrls: ['./navbar-progress-bar.component.scss'],
  templateUrl: './navbar-progress-bar.component.html',
  standalone: true,
  imports: [CommonModule, NgbModule],
})
export class NavbarProgressBarComponent {
  @Input() percentComplete = 0;
  @Input() marginTop = 0;
  progressBarHeight = '0.75rem';
}
