import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { from, Observable, of as observableOf } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ConfigService } from '@app/core/config.service';

@Injectable({
  providedIn: 'root',
})
export class MapsApiLoaderService {
  readonly loader: Loader;

  constructor(private config: ConfigService) {
    this.loader = new Loader({
      apiKey: config.json.googlePlacesKey,
      version: 'weekly',
      libraries: ['places'],
    });
  }

  load$(): Observable<boolean> {
    if (window.google?.maps) {
      return observableOf(true);
    }

    return from(this.loader.load()).pipe(
      map(() => true),
      catchError(() => observableOf(false)),
    );
  }
}
