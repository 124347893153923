<div class="col-12 row p-0 m-0">
  <form
    class="col-12 col-xl-6 col-lg-8 offset-lg-2 offset-xl-0 mt-xl-5 mt-3 pb-3"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
  >
    <div class="col-xl-8 offset-xl-3 col-12 mt-3 mt-xl-3">
      <h1> Welcome to <span class="text-nowrap">One Medical.</span> </h1>
      <div class="g-0 mb-4">
        <p class="col-12">
          Activate your sponsored membership to get exceptional primary care, same or next day appointments, and 24/7
          virtual care with our app.
        </p>
      </div>
      <div>
        <om-label-wrapper
          class=""
          label="What’s your sponsored organization (work, school, etc.) email?"
          [hasError]="hasError"
          [errorMessage]="errorMessage"
        >
          <om-email-input
            #workEmail
            formControlName="workEmail"
            placeholder="Enter email"
            [autofocus]="true"
          ></om-email-input>
          <small class="text-black-50">
            No sponsored organization email? Enter your personal email.<br />
            Are you the spouse or dependent of the sponsored individual? Use the email associated with the sponsorship.
          </small>
        </om-label-wrapper>
      </div>
      <div class="mt-4 pt-3 mb-4">
        <om-submit-button class="col-12" label="Get Started" [submitting]="submitting"></om-submit-button>
      </div>
      <div *ngIf="!isLoggedIn" class="col-12 text-center">
        <small> Already have an account? <a href="" (click)="onLogIn($event)">Update your membership</a> </small>
      </div>
    </div>
  </form>
  <om-landing-page-value-prop></om-landing-page-value-prop>
</div>
<ng-template #membershipNotFound>
  <om-modal-template
    heading="Something isn't right"
    [subheader]="modalSubheader()"
    (close)="closeModal()"
    primaryCallToActionLabel="Close"
    primaryClasses="col-4 offset-4 border-0 justify-content-center pb-3"
    (primaryCallToAction)="closeModal()"
  >
    <div custom class="col-10 offset-1 text-center mb-4">
      If your organization provided an activation code
      <div class="p-2">
        <a href (click)="proceedToActivationCode($event)" data-cy="proceed-to-activation-code">Enter it here</a>
      </div>
    </div>
  </om-modal-template>
</ng-template>
