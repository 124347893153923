<div class="flash">
  <omgui-banner
    *ngFor="let alert of alerts"
    [type]="BannerType.WarningLight"
    dismissible="true"
    (dismissed)="removeAlert(alert)"
    >{{ alert }}</omgui-banner
  >
  <!-- OM "notice" will map to Bootstrap's "info" type-->
  <omgui-banner
    *ngFor="let notice of notices"
    [type]="BannerType.InfoLight"
    dismissible="true"
    (dismissed)="removeNotice(notice)"
    >{{ notice }}</omgui-banner
  >
  <!-- OM "error" will map to Bootstrap's "danger" type-->
  <omgui-banner
    *ngFor="let error of errors"
    [type]="BannerType.ErrorLight"
    dismissible="true"
    (dismissed)="removeError(error)"
    >{{ error }}</omgui-banner
  >
  <!-- OM "success" will map to Bootstrap's "success" type-->
  <omgui-banner
    *ngFor="let success of successes"
    [type]="BannerType.SuccessLight"
    dismissible="true"
    (dismissed)="removeSuccess(success)"
    >{{ success }}</omgui-banner
  >
</div>
