import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

interface SecondaryCtaOptions {
  type: string;
  message: string;
}

@Component({
  selector: 'om-redirect-prompt-modal',
  templateUrl: './redirect-prompt-modal.component.html',
  styleUrls: ['./redirect-prompt-modal.component.scss'],
})
export class RedirectPromptModalComponent {
  constructor(protected modal: NgbActiveModal) {}

  @Input() cta = 'Continue';
  @Input() message: string;
  @Input() redirectUrl: string;
  @Input() title: string;
  // secondary cta - if "type = close", then it's a link to close modal
  // potentially in the future we can add "type = redirect" with additional redirect links
  @Input() secondaryCtaOptions: SecondaryCtaOptions = {
    type: '',
    message: '',
  };

  closeModal() {
    this.modal.close();
  }

  dismissModal() {
    this.modal.dismiss();
  }

  redirect(): void {
    this.closeModal();
  }
}
