import { LDUser } from 'launchdarkly-js-sdk-common';

import { UpdateUserProps } from '@app/core/feature-flags/interfaces';
import { User } from '@app/shared/user';

export const application = 'patient-ui';

export const mapPropsToLDUser = (params: UpdateUserProps): LDUser => {
  const userProperties = mapDefaultUserProperties(params.user);
  mapMembershipProperties(params, userProperties);
  mapCustomAndPrivateAttributes(params, userProperties);

  return userProperties;
};

export const anonymousLDUser = (): LDUser => ({
  key: 'Anonymous',
  anonymous: true,
  custom: {
    application,
  },
});

const mapCustomAndPrivateAttributes = (params: UpdateUserProps, userProperties: LDUser) => {
  if (params.customAttributes && Object.keys(params.customAttributes).length !== 0) {
    userProperties.custom = { ...userProperties.custom, ...params.customAttributes };
  }

  if (params.privateAttributes && Object.keys(params.privateAttributes).length !== 0) {
    userProperties.custom = { ...userProperties.custom, ...params.privateAttributes };
    userProperties.privateAttributeNames = userProperties.privateAttributeNames.concat(
      Object.keys(params.privateAttributes),
    );
  }
};

const mapDefaultUserProperties = (user: User) => {
  const userProperties = {
    key: `${user.id}`,
    anonymous: false,
    custom: {
      email: user.email,
      isPediatric: user.isPediatric(),
      isRegComplete: user.isRegComplete,
      membershipId: user.membershipId,
      officeId: user.officeId,
      serviceAreaId: user.serviceAreaId || user?.serviceArea?.id,
      application,
    },
    privateAttributeNames: ['email', 'membershipId'],
  };
  return userProperties;
};

const mapMembershipProperties = (params: UpdateUserProps, userProperties: LDUser) => {
  if (params.membership) {
    const membership = params.membership;
    const membershipProperties = {
      b2bCompanyId: membership.isB2b() ? membership.b2bCompany?.id : undefined,
      isB2b: membership.isB2b(),
      planId: membership.planId,
      planType: membership.planType,
    };
    userProperties.custom = { ...userProperties.custom, ...membershipProperties };
  }
};
