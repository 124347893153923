/**
 * Different possible verbiages for the consent statement for a legal document.
 */

export enum Consent {
  /** "I acknowledge" the following. */
  I_ACKNOWLEDGE,

  /** "I agree to" the following. */
  I_AGREE,
}

/**
 * A legal document, not necessarily simply a terms of service, but any text
 * the user must agree to for service. These documents are loaded and signed by
 * the {LegalDocumentsService}.
 */

export class TermsOfService {
  signed?: boolean;
  signedOnBehalfOf?: boolean;
  text: string;
  type: string;
  version: string;
  detailHint: string;
  buttonHint: string;

  static fromGraphQL(object: any): TermsOfService {
    const tos = new TermsOfService();
    tos.signed = object.signed;
    if (object.hasOwnProperty('signedOnBehalfOf')) {
      tos.signedOnBehalfOf = object.signedOnBehalfOf;
    }
    tos.text = object.text;
    tos.type = object.type;
    tos.version = object.version;
    tos.detailHint = object.detailHint;
    tos.buttonHint = object.buttonHint;
    return tos;
  }

  clone(): TermsOfService {
    const tos = new TermsOfService();
    tos.signed = this.signed;
    if (this.hasOwnProperty('signedOnBehalfOf')) {
      tos.signedOnBehalfOf = this.signedOnBehalfOf;
    }
    tos.text = this.text;
    tos.type = this.type;
    tos.version = this.version;
    tos.detailHint = this.detailHint;
    tos.buttonHint = this.buttonHint;
    return tos;
  }

  /**
   * The proper verbiage to use when prompting the user to sign.
   */

  get consentType(): Consent {
    if (this.type === 'hipaa_policy') {
      return Consent.I_ACKNOWLEDGE;
    } else {
      return Consent.I_AGREE;
    }
  }

  /**
   * Returns `true` if this document has been signed, either for oneself or
   * on behalf of a beneficiary.
   */

  get signedOrSignedOnBehalf(): boolean {
    return this.hasOwnProperty('signedOnBehalfOf') ? this.signedOnBehalfOf : this.signed;
  }
}
