import { Observable } from 'rxjs';

import { AppointmentInventory } from '@app/appointment/provider-inventories';

export interface SubmissionResults {
  success: boolean;
  appointment?: {
    id: string;
    appointmentType: {
      id: string;
    };
  };

  mutationErrors?: {
    path: string[];
    messages: string[];
  }[];
}

export abstract class AbstractConfirmationModalActionService {
  abstract executeAppointmentAction$(selectedInventory: AppointmentInventory): Observable<SubmissionResults>;
  abstract getConfirmationCTA(): string;
}
