import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'om-omgui-input',
  templateUrl: './omgui-input.component.html',
  styleUrls: ['./omgui-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OmguiInputComponent),
      multi: true,
    },
  ],
})
export class OmguiInputComponent implements ControlValueAccessor {
  /** A unique id for the input. Will get translated to an id attribute that can be targeted with a label. */
  @Input() inputId: string;

  /** Optional placeholder text (aka clue/suggestion for user input) */
  @Input() placeholder? = '';

  /** Optional label for use as aria-label, when input is used without label element. */
  @Input() label?: string;

  /** Optional label to sit above the input element */
  @Input() inputLabel?: string;

  /** Optional helper text to sit below the input element */
  @Input() helperText?: string;

  /** Optional error message to pass in, sits below the input element */
  @Input() errorMessage?: string;

  /** Optional ability to pass in disabled state */
  @Input() isDisabled?: boolean;

  _inputText: string;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  private onChange: (value: string) => void = () => {};

  private onTouch: () => void = () => {};

  registerOnChange(fn: (value: string) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouch = fn;
  }

  writeValue(value: string): void {
    this._inputText = value;
    this.changeDetectorRef.detectChanges();
  }

  handleChange(value: string) {
    this.onChange(value);
  }

  handleTouched() {
    this.onTouch();
  }
}
