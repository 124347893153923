import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Membership } from '@app/core/membership';
import { MembershipService } from '@app/core/membership.service';

@Injectable()
export class AccountActiveGuardService implements CanActivate {
  constructor(private membershipService: MembershipService, private router: Router) {}

  canActivate(): boolean | Observable<boolean> {
    this.membershipService.getMembership();

    return this.membershipService.membership$.pipe(
      map((membership: Membership) => {
        if (membership.isActive) {
          return true;
        } else {
          this.router.navigate(['/membership/settings'], { replaceUrl: true });
          return false;
        }
      }),
    );
  }
}
