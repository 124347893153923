import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiHeaderService } from './api-header.service';
import { FeatureFlags } from './feature-flags/feature-flags';
import { LaunchDarklyService } from './feature-flags/launchdarkly.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private apiHeaderService: ApiHeaderService, private launchDarklyService: LaunchDarklyService) {}

  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return this.launchDarklyService.featureFlag$(FeatureFlags.NO_COOKIE_FOR_YOU, false).pipe(
      switchMap(enabled => {
        const authToken = this.apiHeaderService.token;

        if (enabled || !authToken) {
          return next.handle(request);
        }

        const authenticatedRequest = request.clone({ setHeaders: { Authorization: `Bearer ${authToken}` } });
        return next.handle(authenticatedRequest);
      }),
    );
  }
}
