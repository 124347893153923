import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-svg-vaccines',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.53916 18.4486L3 20.9878" [attr.stroke]="color" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12.8619 14.5512L11.4631 13.1109" [attr.stroke]="color" stroke-linejoin="round" />
      <path d="M11.4632 15.9914L10.0645 14.5511" [attr.stroke]="color" stroke-linejoin="round" />
      <path d="M10.0645 17.4315L8.66577 15.9911" [attr.stroke]="color" stroke-linejoin="round" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.2692 6.96649C16.5905 7.64516 15.4904 7.64516 14.8117 6.96649C14.1331 6.28781 14.1331 5.18768 14.8117 4.50901C15.4904 3.83033 16.5905 3.83033 17.2692 4.50901C17.9479 5.18768 17.9479 6.28781 17.2692 6.96649V6.96649Z"
        [attr.stroke]="color"
        stroke-linejoin="round"
      />
      <path d="M12.2004 10.3799L14.472 12.5976" [attr.stroke]="color" stroke-width="1.2" stroke-linejoin="round" />
      <path d="M17.5759 6.65923L14.8108 9.42432" [attr.stroke]="color" stroke-width="1.2" stroke-linejoin="round" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.21738 18.6267C5.21738 18.6267 5.79351 17.1286 5.21738 14.9397C5.18783 14.8284 12.2839 7.87396 12.2839 7.87396C12.622 7.53593 13.1746 7.53593 13.5127 7.87396L15.9702 10.3314C16.3082 10.6695 16.3082 11.2221 15.9702 11.5602L8.90447 18.6267C6.56169 17.8968 5.21738 18.6267 5.21738 18.6267V18.6267Z"
        [attr.stroke]="color"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.7284 9.42431C19.0498 10.103 17.9496 10.103 17.271 9.42431C16.5923 8.74564 16.5923 7.64551 17.271 6.96683C17.9496 6.28816 19.0498 6.28816 19.7284 6.96683C20.4071 7.64551 20.4071 8.74564 19.7284 9.42431V9.42431Z"
        [attr.stroke]="color"
        stroke-linejoin="round"
      />
    </svg>
  `,
})
export class SvgVaccinesComponent {
  @Input() color = 'currentColor';

  constructor() {}
}
