<div class="referral mb-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 pt-5">
        <h1 class="text-center mobile-h2 mb-3" data-cy="section-title">Add a family member</h1>
        <p class="col-12 col-lg-12 direct-signup-subtitle text-center mb-5" data-cy="section-subtitle">
          Your benefit covers your family. Select an option below to add someone at no cost.
        </p>
        <div class="col-md-10 offset-md-1 p-0">
          <div *ngFor="let option of options$ | async" class="mb-2">
            <omgui-standard-card
              [dataCy]="option.value"
              (clicked)="membershipTypeSelected.emit(option.value)"
              [label]="option.header"
              [sublabel]="option.subheader"
            >
              <ng-template omgui-standard-card-icon>
                <img class="dsu-type-icon" [src]="option.iconUrl" alt="" />
              </ng-template>
            </omgui-standard-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #hasNoUnregisteredDependents>
  <om-modal-template
    heading="Membership not available"
    subheader="The membership type you selected isn't available with your sponsorship. Contact your benefits team for more information."
    primaryCallToActionLabel="Continue to home"
    [showCloseButton]="false"
    (primaryCallToAction)="redirectToHome()"
  >
    <om-svg-exclamation></om-svg-exclamation>
  </om-modal-template>
</ng-template>
