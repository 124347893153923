import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

import {
  SendForgotPasswordEmail,
  SendForgotPasswordEmailVariables,
} from '@app/core/forgot-password/__generated__/send-forgot-password-email-graphql.service.types';

@Injectable()
export class SendForgotPasswordEmailGraphQL extends Mutation<
  SendForgotPasswordEmail,
  SendForgotPasswordEmailVariables
> {
  document = gql`
    mutation SendForgotPasswordEmail($email: String!, $usePreferredEmail: Boolean, $mfaVerification: Boolean) {
      sendForgotPasswordEmail(
        input: { email: $email, usePreferredEmail: $usePreferredEmail, mfaVerification: $mfaVerification }
      ) {
        success
      }
    }
  `;
}
