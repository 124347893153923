import { Component, Input, OnInit } from '@angular/core';

import { Office } from '../../shared/office';

@Component({
  selector: 'om-office-details',
  templateUrl: './office-details.component.html',
  styleUrls: ['./office-details.component.scss'],
})
export class OfficeDetailsComponent implements OnInit {
  @Input() office: Office;

  constructor() {}

  ngOnInit() {}
}
