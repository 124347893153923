import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { ArrowBaseComponent } from '@app/shared/svg/arrow-base/arrow-base.component';

@Component({
  selector: 'om-navbar-arrow-left',
  templateUrl: './navbar-arrow-left.component.html',
  styleUrls: ['./navbar-arrow-left.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class NavbarArrowLeftComponent extends ArrowBaseComponent {
  @Input() disabled: boolean;
  @Input() ariaLabel = 'arrow left short';
}
