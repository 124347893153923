import { Injectable } from '@angular/core';
import noop from 'lodash-es/noop';
import { BehaviorSubject } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

import { ApiService } from '@app/core/api.service';

import { PatientPreferences } from './patient-preferences';

export interface ApiV2Response {
  full_text_notifications: boolean;
  accepts_patient_task_emails: boolean;
  accepts_patient_task_push: boolean;
  avs_hide_weight: boolean;
}

@Injectable()
export class PatientPreferencesService {
  private _patientPreferences$ = new BehaviorSubject<PatientPreferences>(null);
  readonly patientPreferences$ = this._patientPreferences$
    .asObservable()
    .pipe(filter(preferences => preferences !== null));

  constructor(private apiService: ApiService) {}

  getPatientPreferences(force = false) {
    if (!(force || this._patientPreferences$.getValue() === null)) {
      return this._patientPreferences$.pipe(take(1));
    }

    const request = this.apiService.get('/api/v2/patient/patient_preferences').pipe(
      map((response: ApiV2Response) => PatientPreferences.fromApiV2(response)),
      tap((preferences: PatientPreferences) => this._patientPreferences$.next(preferences)),
    );

    request.subscribe({ error: noop });

    return request;
  }

  setPatientPreferences(preferences: PatientPreferences) {
    const request = this.apiService.patch('/api/v2/patient/patient_preferences', preferences.toApiV2()).pipe(
      map((response: ApiV2Response) => PatientPreferences.fromApiV2(response)),
      tap((p: PatientPreferences) => this._patientPreferences$.next(p)),
    );
    request.subscribe({ error: noop });
    return request;
  }
}
