export class UploadData {
  done = false;
  progress = 0;
  key?: string;
  bucket?: string;

  constructor(done: boolean, progress: number, key?: string, bucket?: string) {
    this.done = done;
    this.progress = progress;
    if (key) {
      this.key = key;
    }
    if (bucket) {
      this.bucket = bucket;
    }
  }
}
