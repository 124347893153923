import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { differenceInDays, differenceInYears, isValid, parseISO, startOfDay } from 'date-fns';

type CustomValidator = (now: Date, date: Date) => ValidationErrors | null;

export function dateValidator(control: AbstractControl) {
  const date = convertToDate(control.value);
  return isValid(date) ? null : { date: control.value };
}

export function dateComparisonValidatorCreator(customValidator: CustomValidator): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value?.hasOwnProperty('date') ? control.value.date : control.value;
    const date: Date = convertToDate(value);
    if (isValid(date)) {
      const now = startOfDay(new Date());
      return customValidator(now, date);
    } else {
      return { dateInvalid: { valid: false } };
    }
  };
}

export function getDateInPastValidator(): ValidatorFn {
  const validationFunction = (now: Date, date: Date) => {
    if (differenceInDays(date, now) > 0) {
      return { dateInPast: { valid: false } };
    } else {
      return null;
    }
  };

  return dateComparisonValidatorCreator(validationFunction);
}

export function getDateInFutureValidator(includeNow = false): ValidatorFn {
  const validationFunction = (now: Date, date: Date) => {
    const difference: number = differenceInDays(date, now);
    if (includeNow && difference < 0) {
      return { dateInFuture: { valid: false } };
    } else if (!includeNow && difference <= 0) {
      return { dateInFuture: { valid: false } };
    } else {
      return null;
    }
  };

  return dateComparisonValidatorCreator(validationFunction);
}

export function maximumAge(age: number): ValidatorFn {
  const validationFunction = (now: Date, date: Date) => {
    const yearsAgo = differenceInYears(now, date);
    if (yearsAgo > age) {
      return {
        age: {
          valid: false,
          requiredAge: age,
        },
      };
    } else {
      return null;
    }
  };
  return dateComparisonValidatorCreator(validationFunction);
}

export function minimumAge(minAge: number): ValidatorFn {
  const validationFunction = (now: Date, date: Date) => {
    const age = differenceInYears(now, date);
    if (age < minAge) {
      return {
        age: {
          valid: false,
          requiredAge: minAge,
        },
      };
    } else {
      return null;
    }
  };
  return dateComparisonValidatorCreator(validationFunction);
}

function convertToDate(date: string | Date): Date | undefined {
  if (typeof date === 'string') {
    return parseISO(date);
  } else if (date instanceof Date) {
    return date;
  }
}
