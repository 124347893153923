import { Injectable } from '@angular/core';
import { Query, gql, Apollo } from 'apollo-angular';

import { OfficePermalink, OfficePermalinkVariables } from './__generated__/OfficePermalink';

@Injectable()
export class OfficePermalinkGraphqlService extends Query<OfficePermalink, OfficePermalinkVariables> {
  constructor(apollo: Apollo) {
    super(apollo);
  }

  document = gql`
    query OfficePermalink($excludeVirtual: Boolean) {
      offices(excludeVirtual: $excludeVirtual) {
        permalink
        isHidden
      }
    }
  `;
}
