import { ENVIRONMENT_GLOBAL } from './global';

export const ENVIRONMENT_STAGING = {
  ...ENVIRONMENT_GLOBAL,
  auth0: {
    audience: 'https://onemedical.com',
    clientId: 'TTgbLdU8H2B8jv2A1ChcFhffM2UWPvF7',
    domain: 'login.stage.1life.com',
  },
  appSync: {
    apiUrl: 'https://myll6bcxizb3rl2jnqhnfibxda.appsync-api.us-east-1.amazonaws.com/graphql',
    region: 'us-east-1',
    enabled: true,
  },
  devRoutes: false,
  enableProdMode: true,
  googlePlacesKey: 'AIzaSyDKwqaTYzv_t597rBnoByDo6unUhNQwJGM',
  googleTagManagerId: 'GTM-5PQD',
  hintPortalUrl: 'https://member.staging.hint.com/login/request?practice=onemedicalonelifespractice',
  launchDarklyClientId: '600b3ce23cadb30a6ffcc6ac',
  mixpanelDebug: true,
  mixpanelToken: 'a0a55dfa4ad763cdde7d516ff6a71df3',
  oneMedicalPhone: '415-291-0480',
  pusherKey: '17a53bdb21f690e36d33',
  newRecaptchaSiteKey: '6LfCNswpAAAAAApoCqPX7kKhshvP3un2FXw0HoM0',
  sentry: {
    enabled: true,
    dsn: 'https://94fb2cb964527591835f763ad4493a4f@o4506221115408384.ingest.us.sentry.io/4506855554154496',
    environment: 'staging',
  },
  stripePublishableKey:
    'pk_test_515UoAWCHqmdLFAqvOPwaAT7dUiGs3lI2b4gqOhsggwBanPaSORDWF9vbVv4DDMFjbBkE7VIWTpMjwdrBLcaihBlb00szZpgleb',
  typeaheadTestingMode: true,
};
