import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface ToastMessage {
  content: string;
  action?: {
    text: string;
    callback: () => void;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  static RUNNING_LATE = "You've been marked as running late to your appointment";
  static APPOINTMENT_CANCELED = 'Your appointment has been canceled';

  private _toastMessage$ = new Subject<ToastMessage>();
  toastMessage$ = this._toastMessage$.asObservable();

  push(content: string, action?: ToastMessage['action']) {
    this._toastMessage$.next({ content, action });
  }
}
