import { gql } from 'apollo-angular';

export const latestPatientMedicationRegimen = gql`
  fragment latestPatientMedicationRegimen on PatientMedicationRegimen {
    id
    layInstructionsForUse
    medicationDispensable {
      id
      textDesc
    }
  }
`;

export const pharmacy = gql`
  fragment prescription_pharmacy on Pharmacy {
    id
    displayName
    displayAddressStreet
    workPhoneNumber
    details
    isMailOrder
    __typename
  }
`;

export const patientPharmacy = gql`
  fragment prescription_patientPharmacy on PatientPharmacy {
    id
    isPreferred
    pharmacy {
      ...prescription_pharmacy
    }
  }
  ${pharmacy}
`;

export const pendingRenewalRequest = gql`
  fragment pendingRenewalRequest on RenewalRequest {
    patientPharmacy {
      ...prescription_patientPharmacy
    }
    createdAt
  }
  ${patientPharmacy}
`;

export const patientMedication = gql`
  fragment patientMedication on PatientMedication {
    id
    name
    latestPatientMedicationRegimen {
      ...latestPatientMedicationRegimen
    }
    pendingRenewalRequest {
      ...pendingRenewalRequest
    }
    __typename
  }
  ${latestPatientMedicationRegimen}
  ${pendingRenewalRequest}
`;

export const activeMedication = gql`
  fragment activeMedication on PatientMedication {
    isRenewable
    ...patientMedication
  }
  ${patientMedication}
`;
