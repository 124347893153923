<div class="container mt-xl-10 mt-2">
  <form class="col-12 col-lg-8 offset-lg-2" [formGroup]="form" (ngSubmit)="onSubmit()">
    <h1 class="text-center">Enter your <span class="text-nowrap">activation code</span></h1>
    <div class="mb-4 text-center">
      <p> Enter the code provided by your sponsored organization to activate your membership. </p>
    </div>
    <div class="mb-5 col-md-8 offset-md-2 col-12">
      <om-label-wrapper
        class="mb-3"
        label="What’s your activation code?"
        [hasError]="hasError()"
        [errorMessage]="activationCodeError()"
      >
        <input
          #activationCode
          autofocus
          type="text"
          class="form-control mb-2"
          autocomplete="off"
          placeholder="Enter activation code"
          formControlName="activationCode"
          [class.error]="hasError()"
          (input)="onInput()"
        />
        <small *ngIf="canEmailCode && !codeSent && !hasError()">
          <a href (click)="onResendCode($event)"> Forgot your code? Resend to {{ codeRetrievalEmail }} </a>
        </small>
        <small *ngIf="!canEmailCode" class="text-black-50">
          Don’t have an activation code? Please contact your benefits team for help.
        </small>
        <small *ngIf="codeSent && !hasError()" class="text-black-50">
          Your activation code has been sent to {{ codeRetrievalEmail }}. If you don't see it, check your spam folder.
        </small>
      </om-label-wrapper>
    </div>
    <om-submit-button
      class="col-md-8 offset-md-2 col-12 d-block"
      label="Next"
      [submitting]="submitting"
    ></om-submit-button>
  </form>
</div>
