import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { AppStoreRedirectService } from '@app/shared';
import { WindowService } from '@app/utils/window.service';

@Injectable()
export class MobileAppRedirectGuardService implements CanActivate {
  constructor(private appStoreRedirectService: AppStoreRedirectService, private windowService: WindowService) {}

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
    const mobileRedirectUrl = this.appStoreRedirectService.mobileRedirectUrl;
    if (mobileRedirectUrl) {
      this.windowService.redirect(mobileRedirectUrl);
    }

    return true;
  }
}
