import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AttemptedPathService } from '@app/core/attempted-path.service';

import { AuthService } from './auth.service';

@Injectable()
export class LoginGuardService implements CanActivate {
  constructor(private attemptedPathService: AttemptedPathService, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.authService.isLoggedIn$().pipe(
      map(isLoggedIn => {
        this.setAttemptedPathFromParams(route.queryParams);

        if (isLoggedIn) {
          this.attemptedPathService.navigateToAttemptedPath();
          return false;
        }

        this.authService.goLogin();
        return false;
      }),
    );
  }

  setAttemptedPathFromParams(params: Params) {
    const path = decodeURIComponent(params.returnUrl || '');
    if (path && path.length > 1) {
      this.attemptedPathService.setAttemptedPath(path);
    }
  }
}
