<div class="text-center">
  <om-svg aria-hidden="true" image="welcome_back"></om-svg>
  <h1 class="mb-2 mb-sm-3">Welcome back, {{ firstName }}!</h1>
  <div>
    <p class="mb-4"> Just a few steps left before you can join One Medical. </p>
  </div>
  <div class="text-center mb-4">
    <button data-cy="complete-registration-button" class="btn btn-primary" (click)="continueRegistration()"
      >Complete Registration</button
    >
  </div>
  <div class="text-center m-4 not-member">
    <span
      >Not {{ firstName }}?
      <a href="" data-cy="restart-registration-link" (click)="registerAsNewUser(); $event.preventDefault()"
        >Sign up here</a
      ></span
    >
  </div>
</div>
