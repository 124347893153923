import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-svg-watch',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="5" y="5" width="14" height="14" rx="2" [attr.stroke]="color" />
      <path d="M7 3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V5H7V3Z" [attr.stroke]="color" />
      <path d="M7 19H17V21C17 21.5523 16.5523 22 16 22H8C7.44772 22 7 21.5523 7 21V19Z" [attr.stroke]="color" />
      <path
        d="M12.6385 9.59506L11.9999 10.2539L11.3615 9.59506C10.5925 8.80165 9.34567 8.80165 8.57676 9.59506C7.80775 10.3885 7.80775 11.6747 8.57676 12.4681L9.21523 13.1269L11.2819 15.2592C11.6748 15.6645 12.3251 15.6645 12.718 15.2592L14.7847 13.1269L15.4232 12.4681C16.1923 11.6747 16.1923 10.3885 15.4232 9.59506C14.6542 8.80165 13.4075 8.80165 12.6385 9.59506Z"
        [attr.stroke]="color"
      />
    </svg>
  `,
})
export class SvgWatchComponent {
  @Input() color = 'currentColor';

  constructor() {}
}
