import { Injectable } from '@angular/core';
import { Query, gql, Apollo } from 'apollo-angular';

import { LegalDocumentGraphQLResponse } from '@app/core/legal-documents/legal-document-graphql-response';

/**
 * Apollo query for loading legal documents as when signing for oneself or as
 * a beneficiary, when logged in as that beneficiary.
 */

@Injectable()
export class AdministratorLegalDocGraphQL extends Query<LegalDocumentGraphQLResponse> {
  constructor(apollo: Apollo) {
    super(apollo);
  }

  document = gql`
    query LegalDocumentsForAdministrator {
      legalDocuments {
        type
        version
        text
        detailHint
        buttonHint
        signed
      }
    }
  `;
}
