<div class="container mt-10">
  <om-email-verification-registration
    [form]="form"
    [submitting]="submitting"
    [preferredEmail]="preferredEmail"
    [errorMessage]="errorMessage"
    (submitCode)="onSubmit()"
    (skip)="onSkip()"
    (resendEmail)="onResendEmail()"
    (emailTechSupport)="onEmailTechSupport()"
  >
  </om-email-verification-registration>
</div>
