import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { StateService } from '@app/shared/state.service';

@Component({
  selector: 'om-address-basic-input',
  templateUrl: './address-basic-input.component.html',
  styleUrls: ['../../form-input.scss'],
})
export class AddressBasicInputComponent {
  @Input() address: UntypedFormGroup;

  constructor(public stateService: StateService) {}
}
