import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

import {
  ValidateClaimCodeQuery,
  ValidateClaimCodeQueryVariables,
} from './__generated__/prepaid-enrollment-validate-claim-code-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class PrepaidEnrollmentValidateClaimCodeGraphQL extends Query<
  ValidateClaimCodeQuery,
  ValidateClaimCodeQueryVariables
> {
  document = gql`
    query ValidateClaimCodeQuery($claimCode: String!) {
      validateClaimCode(claimCode: $claimCode) {
        billingCycle
        isMembershipManager
        isValidClaimCode
        seatsAvailable
        subscriptionId
        trial
        error {
          code
          message
        }
      }
    }
  `;
}
