import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

import {
  registerBasicInfo,
  registerBasicInfoSuccess,
  registerLocationSuccess,
  registerReset,
  registerRestart,
  registerTermsOfServiceComplete,
} from '@app/registration/consumer/consumer-registration.actions';
import { Address } from '@app/shared/address';

export interface IAuthState {
  firstName: string;
  lastName: string;
  email: string | null;
  userId: number | null;
  address: Address;
  accessToken: string | null;
  serviceAreaCode: string;
  serviceAreaName: string;
  termsOfServiceAccepted: boolean;
}

export type authProps = keyof IAuthState;

export const initialState: IAuthState = {
  firstName: null,
  lastName: null,
  email: null,
  userId: null,
  address: null,
  accessToken: null,
  serviceAreaCode: null,
  serviceAreaName: null,
  termsOfServiceAccepted: false,
};

export const authReducer = createReducer(
  initialState,
  on(registerBasicInfo, registerReset, _state => ({
    // Clear any stale state
    ...initialState,
  })),
  on(registerBasicInfoSuccess, registerRestart, (state, action) => ({
    ...state,
    firstName: action.user.firstName,
    lastName: action.user.lastName,
    email: action.user.email,
    userId: action.user.id,
    accessToken: action.user.accessToken,
    address: action.user.address,
    serviceAreaCode: action.user.serviceArea ? action.user.serviceArea.code : null,
    serviceAreaName: action.user.serviceArea ? action.user.serviceArea.name : null,
  })),
  on(registerLocationSuccess, (state, action) => ({
    ...state,
    address: action.user.address,
    serviceAreaCode: action.user.serviceArea.code,
    serviceAreaName: action.user.serviceArea.name,
  })),
  on(registerTermsOfServiceComplete, (state, _action) => ({
    ...state,
    termsOfServiceAccepted: true,
  })),
);

export function reducer(state: IAuthState | undefined, action: Action) {
  return authReducer(state, action);
}

export const selectAuthState = createFeatureSelector<IAuthState>('auth');
export const getAccessToken = createSelector(selectAuthState, (state: IAuthState) => state.accessToken);
export const getUserId = createSelector(selectAuthState, (state: IAuthState) => state.userId);
export const getEmail = createSelector(selectAuthState, (state: IAuthState) => state.email);
export const getFirstName = createSelector(selectAuthState, (state: IAuthState) => state.firstName);
export const getLastName = createSelector(selectAuthState, (state: IAuthState) => state.lastName);
export const getFullName = createSelector(
  selectAuthState,
  (state: IAuthState) => `${state.firstName} ${state.lastName}`,
);

export const getServiceAreaCode = createSelector(selectAuthState, (state: IAuthState) => state.serviceAreaCode);
export const getServiceAreaName = createSelector(selectAuthState, (state: IAuthState) => state.serviceAreaName);
export const getAddress = createSelector(selectAuthState, (state: IAuthState) => state.address);
