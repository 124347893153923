import { Component } from '@angular/core';

@Component({
  selector: 'om-svg-security',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 3.00069C14.4176 2.97929 16.8128 3.45923 19.0293 4.40941L19.0371 4.41285C19.3244 4.54188 19.569 4.75037 19.7403 5.01445C19.9113 5.27805 20.0018 5.58545 19.9999 5.8996V11.6505C19.9999 13.1177 19.6698 14.8784 18.6181 16.545C17.5631 18.217 15.8036 19.7595 13.0051 20.8166C12.358 21.0611 11.6419 21.0611 10.9948 20.8166C8.19615 19.7579 6.4363 18.2218 5.38125 16.5531C4.3294 14.8894 4.00003 13.1292 4.00003 11.6505V5.89965C3.99814 5.58545 4.08862 5.27805 4.25967 5.01445C4.43102 4.75037 4.67558 4.54188 4.96287 4.41285L4.97071 4.40941C7.18715 3.45923 9.5824 2.97929 12 3.00069ZM4.50004 5.898H5.00005V11.6505C5.00005 12.9847 5.2972 14.5488 6.2265 16.0187C7.15255 17.4834 8.7287 18.8902 11.3484 19.8812C11.7678 20.0396 12.2324 20.0396 12.6517 19.8811C15.2694 18.8923 16.8457 17.48 17.7724 16.0114C18.7024 14.5375 18.9999 12.972 18.9999 11.6505V5.89435C19.0008 5.77625 18.9669 5.6597 18.9014 5.55875C18.8365 5.4587 18.7429 5.3777 18.6308 5.3266C16.5419 4.43183 14.2839 3.97992 12.0045 4.00069H11.9954C9.71605 3.97992 7.45805 4.43183 5.3692 5.3266C5.25705 5.37775 5.1635 5.4587 5.09855 5.55875C5.03305 5.6597 4.99916 5.77625 5 5.89435L4.50004 5.898Z"
        fill="currentColor"
      />
      <path
        d="M15.008 9.83414H13.3925V8.34714C13.3896 8.25236 13.3493 8.16259 13.2803 8.09753C13.2113 8.03248 13.1193 7.99745 13.0245 8.00014H10.8515C10.7567 7.99745 10.6647 8.03248 10.5957 8.09753C10.5267 8.16259 10.4864 8.25236 10.4835 8.34714V9.83414H8.868C8.77321 9.83145 8.68123 9.86648 8.61223 9.93153C8.54324 9.99659 8.50288 10.0864 8.5 10.1811V12.1896C8.50288 12.2844 8.54324 12.3742 8.61223 12.4393C8.68123 12.5043 8.77321 12.5393 8.868 12.5366H10.4835V14.0221C10.4864 14.1169 10.5267 14.2067 10.5957 14.2718C10.6647 14.3368 10.7567 14.3718 10.8515 14.3691H13.025C13.1198 14.3718 13.2118 14.3368 13.2808 14.2718C13.3498 14.2067 13.3901 14.1169 13.393 14.0221V12.5341H15.008C15.1028 12.5368 15.1948 12.5018 15.2638 12.4368C15.3328 12.3717 15.3731 12.2819 15.376 12.1871V10.1796C15.3727 10.0851 15.3322 9.99573 15.2633 9.931C15.1943 9.86627 15.1025 9.83145 15.008 9.83414ZM14.638 11.8411H13.0245C12.9297 11.8384 12.8377 11.8735 12.7687 11.9385C12.6997 12.0036 12.6594 12.0934 12.6565 12.1881V13.6736H11.219V12.1876C11.2161 12.0929 11.1758 12.0031 11.1068 11.938C11.0378 11.873 10.9458 11.8379 10.851 11.8406H9.238V10.5261H10.8535C10.9483 10.5288 11.0403 10.4938 11.1093 10.4288C11.1783 10.3637 11.2186 10.2739 11.2215 10.1791V8.69414H12.659V10.1796C12.6619 10.2744 12.7022 10.3642 12.7712 10.4293C12.8402 10.4943 12.9322 10.5293 13.027 10.5266H14.638V11.8411Z"
        fill="currentColor"
      />
    </svg>
  `,
})
export class SvgSecurityComponent {}
