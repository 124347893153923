<form [formGroup]="phoneNumberForm">
  <input
    type="text"
    class="form-control text-field"
    id="phone-number"
    aria-label="Phone Number"
    placeholder="(xxx) xxx-xxxx"
    formControlName="formattedPhoneNumber"
    [class.error]="displayError"
    (blur)="handlePhoneBlurValidation()"
    data-cy="phone-number-input"
  />
  <div class="mt-2 text-danger" [ngClass]="'mb-2 small'" *ngIf="displayError && !overrideDisplayError">{{
    errorMessage
  }}</div>
</form>
