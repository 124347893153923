import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { PatientMobileDsuEligibleGraphQL } from '@app/registration/patient-mobile-dsu-eligible-graphql.service';

@Injectable()
export class MobileDsuEligibleService {
  mobileDsuEligible$ = new BehaviorSubject<boolean>(false);

  constructor(private mobileDsuEligibleGraphQL: PatientMobileDsuEligibleGraphQL) {}

  getEligibility() {
    this.mobileDsuEligibleGraphQL.fetch().subscribe(({ data }) => {
      this.mobileDsuEligible$.next(data.patient.mobileDirectSignupEligible);
    });
  }
}
