import { Component } from '@angular/core';

@Component({
  selector: 'om-svg-health-information-exchange',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" />
      <g clip-path="url(#clip0_378:961)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.9998 7.99829C13.6567 7.99829 14.9998 6.65515 14.9998 4.99829C14.9998 3.34144 13.6567 1.99829 11.9998 1.99829C10.343 1.99829 8.99982 3.34144 8.99982 4.99829C8.99982 6.65515 10.343 7.99829 11.9998 7.99829ZM11.9998 6.99829C13.1044 6.99829 13.9998 6.10286 13.9998 4.99829C13.9998 3.89372 13.1044 2.99829 11.9998 2.99829C10.8952 2.99829 9.99982 3.89372 9.99982 4.99829C9.99982 6.10286 10.8952 6.99829 11.9998 6.99829Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16 16.4983C16 18.1551 17.3431 19.4983 19 19.4983C20.6569 19.4983 22 18.1551 22 16.4983C22 14.8414 20.6569 13.4983 19 13.4983C17.3431 13.4983 16 14.8414 16 16.4983ZM17 16.4983C17 17.6029 17.8954 18.4983 19 18.4983C20.1046 18.4983 21 17.6029 21 16.4983C21 15.3937 20.1046 14.4983 19 14.4983C17.8954 14.4983 17 15.3937 17 16.4983Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 16.502C8 14.8451 6.65685 13.502 5 13.502C3.34315 13.502 2 14.8451 2 16.502C2 18.1588 3.34315 19.502 5 19.502C6.65685 19.502 8 18.1588 8 16.502ZM7 16.502C7 15.3974 6.10457 14.502 5 14.502C3.89543 14.502 3 15.3974 3 16.502C3 17.6065 3.89543 18.502 5 18.502C6.10457 18.502 7 17.6065 7 16.502Z"
          fill="currentColor"
        />
        <path
          d="M7.58232 4.65698C4.84688 6.20107 3 9.13485 3 12.5C3 12.5334 3.00018 12.5668 3.00055 12.6002L4.02195 11.9029C4.20634 9.40374 5.53886 7.22387 7.49407 5.88871L7.58232 4.65698Z"
          fill="currentColor"
        />
        <path
          d="M19.9781 11.9029C19.7937 9.40374 18.4612 7.22389 16.506 5.88873L16.4177 4.657C19.1531 6.20109 21 9.13486 21 12.5C21 12.5334 20.9998 12.5668 20.9995 12.6002L19.9781 11.9029Z"
          fill="currentColor"
        />
        <path
          d="M12 20.5C10.7749 20.5 9.61421 20.2246 8.5763 19.7324L7.46471 20.2754C8.79644 21.0539 10.3461 21.5 12 21.5C13.6539 21.5 15.2036 21.0539 16.5353 20.2754L15.4237 19.7324C14.3858 20.2246 13.2251 20.5 12 20.5Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_378:961">
          <rect width="20" height="20" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </svg>
  `,
})
export class SvgHealthInformationExchangeComponent {
  constructor() {}
}
