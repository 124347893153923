<svg
  width="40px"
  height="34px"
  viewBox="0 0 40 34"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <defs><path id="path-1" d="M0,0 L40,0 L40,34 L0,34 L0,0 Z"></path></defs>
  <g id="Page-1" stroke="currentColor" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
    <g sketch:type="MSLayerGroup">
      <g
        class="icon-stroke"
        id="Group-5"
        transform="translate(13.000000, 0.000000)"
        stroke="currentColor"
        stroke-width="2"
        sketch:type="MSShapeGroup"
      >
        <path d="M0,0 L0,10" id="Stroke-1"></path>
        <path d="M14,0 L14,10" id="Stroke-3"></path>
      </g>
      <path d="M8,5 L33,5" id="Stroke-6" stroke="currentColor" stroke-width="2" sketch:type="MSShapeGroup"></path>
      <g id="Group-25">
        <path
          d="M37,5 C38.1,5 39,5.9 39,7 L39,31 C39,32.1 38.1,33 37,33 L3,33 C1.9,33 1,32.1 1,31 L1,7 C1,5.9 1.9,5 3,5 L37,5 L37,5 Z"
          id="Stroke-7"
          stroke="currentColor"
          stroke-width="2"
          sketch:type="MSShapeGroup"
        ></path>
        <mask id="mask-2" sketch:name="Clip 10" fill="currentColor"><use xlink:href="#path-1"></use></mask>
        <g id="Clip-10"></g>
        <path
          d="M16,22 L14,22 L14,20 L16,20 L16,22 Z"
          id="Fill-9"
          fill="#9a928a"
          sketch:type="MSShapeGroup"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M21,22 L19,22 L19,20 L21,20 L21,22 Z"
          id="Fill-11"
          fill="#9a928a"
          sketch:type="MSShapeGroup"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M26,22 L24,22 L24,20 L26,20 L26,22 Z"
          id="Fill-12"
          fill="#9a928a"
          sketch:type="MSShapeGroup"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M31,22 L29,22 L29,20 L31,20 L31,22 Z"
          id="Fill-13"
          fill="#9a928a"
          sketch:type="MSShapeGroup"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M16,27 L14,27 L14,25 L16,25 L16,27 Z"
          id="Fill-14"
          fill="#9a928a"
          sketch:type="MSShapeGroup"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M21,27 L19,27 L19,25 L21,25 L21,27 Z"
          id="Fill-15"
          fill="#9a928a"
          sketch:type="MSShapeGroup"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M26,27 L24,27 L24,25 L26,25 L26,27 Z"
          id="Fill-16"
          fill="#9a928a"
          sketch:type="MSShapeGroup"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M31,27 L29,27 L29,25 L31,25 L31,27 Z"
          id="Fill-17"
          fill="#9a928a"
          sketch:type="MSShapeGroup"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M11,22 L9,22 L9,20 L11,20 L11,22 Z"
          id="Fill-18"
          fill="#9a928a"
          sketch:type="MSShapeGroup"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M11,27 L9,27 L9,25 L11,25 L11,27 Z"
          id="Fill-19"
          fill="#9a928a"
          sketch:type="MSShapeGroup"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M16,17 L14,17 L14,15 L16,15 L16,17 Z"
          id="Fill-20"
          fill="#9a928a"
          sketch:type="MSShapeGroup"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M21,17 L19,17 L19,15 L21,15 L21,17 Z"
          id="Fill-21"
          fill="#9a928a"
          sketch:type="MSShapeGroup"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M26,17 L24,17 L24,15 L26,15 L26,17 Z"
          id="Fill-22"
          fill="#9a928a"
          sketch:type="MSShapeGroup"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M31,17 L29,17 L29,15 L31,15 L31,17 Z"
          id="Fill-23"
          fill="#9a928a"
          sketch:type="MSShapeGroup"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M11,17 L9,17 L9,15 L11,15 L11,17 Z"
          id="Fill-24"
          fill="#9a928a"
          sketch:type="MSShapeGroup"
          mask="url(#mask-2)"
        ></path>
      </g>
    </g>
  </g>
</svg>
