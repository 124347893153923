import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

import { unreadMessagesCount } from '@app/shared/navbar/navbar-desktop/badge-status-graphql.fragments';

import { BadgeStatus } from './__generated__/BadgeStatus';

@Injectable({
  providedIn: 'root',
})
export class BadgeStatusGraphQLService extends Query<BadgeStatus> {
  document = gql`
    query BadgeStatus {
      patient {
        id
        tasks(states: [assigned]) {
          id
        }
        ...unreadMessagesCount
      }
    }
    ${unreadMessagesCount}
  `;
}
