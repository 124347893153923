import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/core/api.service';

export interface ValidateActivationCodesB2bCompany {
  id: number;
  b2b_email_domains: string[];
  includes_dependent: boolean;
  verify_dependent_info: boolean;
  enterprise_peds_registration_enabled: boolean;
}

export interface ValidateActivationCodes {
  b2b_company: ValidateActivationCodesB2bCompany;
}

export interface ValidateTrialCouponCodes {
  valid: boolean;
  trial_days: number;
  auto_renew: boolean;
  skip_credit_card: boolean;
}

export interface ValidateGiftCodes {
  valid: boolean;
  code: string;
}

@Injectable()
export class ActivationCodeService {
  constructor(private apiService: ApiService) {}

  validate(code: string, recaptchaToken: string): Observable<ValidateActivationCodes> {
    return this.apiService.get('/api/v2/public/validate_activation_codes', false, {
      activation_code: code,
      'g-recaptcha-response': recaptchaToken,
    }) as Observable<ValidateActivationCodes>;
  }

  validateTrialCode(code: string): Observable<ValidateTrialCouponCodes> {
    return this.apiService.get('/api/v2/public/validate_trial_coupon_codes', false, {
      trial_code: code,
    }) as Observable<ValidateTrialCouponCodes>;
  }

  validateGiftCode(code: string): Observable<ValidateGiftCodes> {
    return this.apiService.get('/api/v2/public/validate_gift_codes', false, {
      gift_code: code,
    }) as Observable<ValidateGiftCodes>;
  }

  resendCode(workEmail: string) {
    // 'flow' param is used to determine if the user is in the redesigned enterprise flow.
    // Remove when new registration flow is turned on for 100% of users.
    return this.apiService.post('/api/v2/public/b2b_codes/send_to_email', {
      email: workEmail,
      flow: 'redesign',
    });
  }
}
