import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromRoot from '@app/app.reducer';
import * as ConsumerRegistrationActions from '@app/registration/consumer/consumer-registration.actions';
import { RegisterSteps } from '@app/registration/consumer/consumer-registration.constants';

@Component({
  selector: 'om-registration-restart',
  templateUrl: './registration-restart.component.html',
  styleUrls: ['./registration-restart.component.scss'],
})
export class RegistrationRestartComponent implements OnInit {
  @Input() firstName: string;

  @Output() registerReset = new EventEmitter();
  @Output() registerContinue = new EventEmitter();

  constructor(private store: Store<fromRoot.State>) {}

  ngOnInit() {
    // the effect of this action is that Page Viewed event is sent to mixpanel
    this.store.dispatch(ConsumerRegistrationActions.registerGoToStep({ step: RegisterSteps.Restart }));
  }

  continueRegistration() {
    this.registerContinue.emit();
  }

  registerAsNewUser() {
    this.registerReset.emit();
  }
}
