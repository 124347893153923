import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import lodashMap from 'lodash-es/map';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '@app/core/api.service';
import { ServiceArea } from '@app/shared/service-area';

import { VirtualServiceArea } from './service-area.types';

@Injectable({
  providedIn: 'root',
})
export class ServiceAreasDataService extends DefaultDataService<ServiceArea> {
  publicServiceAreaPath = '/api/v2/public/service_areas';

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private apiService: ApiService) {
    super('ServiceArea', http, httpUrlGenerator);
  }

  getAll(): Observable<ServiceArea[]> {
    return this.apiService.get(this.publicServiceAreaPath).pipe(
      map(result => lodashMap(result as any, sa => new ServiceArea(sa))),
      map((allServiceAreas): ServiceArea[] => {
        const allServiceAreaResults: ServiceArea[] = allServiceAreas.map((sa: ServiceArea) =>
          sa.virtual && sa.code === VirtualServiceArea.CODE
            ? ({ ...sa, name: VirtualServiceArea.NAME } as ServiceArea)
            : sa,
        );
        const filteredServiceAreaResults: ServiceArea[] = allServiceAreaResults
          .filter(serviceArea => !serviceArea.registration_hidden)
          // sort alphabetically and place "Virtual" at the bottom of the options
          .sort((a, b) => (a.virtual === b.virtual ? 0 : a.virtual ? 1 : -1) || a.name.localeCompare(b.name));

        return filteredServiceAreaResults;
      }),
    );
  }

  getById(key: number): Observable<ServiceArea> {
    return this.apiService
      .get(`${this.publicServiceAreaPath}/${key}`)
      .pipe(map((result: any) => new ServiceArea(result)));
  }
}
