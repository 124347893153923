<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2 my-2 text-center">
      <h2>Authorization&nbsp;to&nbsp;Release COVID&#x2011;19&nbsp;Health&nbsp;Information</h2>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2 my-2 text-center" *ngIf="description">
      <p>{{ description }}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2 my-2">
      <h4>Patient</h4>
      <div class="row">
        <div class="col-12 col-lg-6 mb-2">
          <om-label-wrapper class="d-block" label="First Name">
            <input
              type="text"
              class="form-control text-field"
              formControlName="consenterFirstName"
              required="true"
              autofocus
              readonly
            />
          </om-label-wrapper>
        </div>
        <div class="col-12 col-lg-6 mb-2">
          <om-label-wrapper class="d-block" label="Last Name">
            <input
              type="text"
              class="form-control text-field"
              formControlName="consenterLastName"
              required="true"
              readonly
            />
          </om-label-wrapper>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-2">
          <om-label-wrapper label="Date of Birth" class="">
            <om-date-input formControlName="consenterDateOfBirth" [readOnly]="true"></om-date-input>
          </om-label-wrapper>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-2">
          <om-address-display
            [address]="form.get('consenterAddress').value"
            [style]="inputDisplayStyle"
          ></om-address-display>
        </div>
      </div>

      <p class>
        I authorize One Medical to disclose my COVID-19-related health information to the Company/Organization named
        below:
      </p>
    </div>
  </div>

  <div class="row">
    <div #recipientSection class="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2 my-2">
      <h4>Third Party (Company/Organization)</h4>
      <div class="row">
        <div class="col-12 col-lg-6 mb-2">
          <om-label-wrapper class="d-block" label="Company Name">
            <input
              type="text"
              class="form-control text-field"
              formControlName="recipientName"
              required="true"
              readonly
            />
          </om-label-wrapper>
        </div>
        <div class="col-12 col-lg-6 mb-2">
          <om-label-wrapper class="d-block" label="Telephone">
            <om-phone-number-input #recipientTelephone formControlName="recipientTelephone"></om-phone-number-input>
          </om-label-wrapper>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div #releaseInformation class="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2 my-2">
      <h5>
        Disclosure is limited to only the following information below for the purpose of tracking and maintaining the
        COVID-19 health information of individuals who may be accessing the facilities of the Company/Organization.
        Information unrelated to COVID-19 will <u>NOT</u> be disclosed to the Company/Organization without additional
        authorization from you:
      </h5>

      <div class="row">
        <div class="col-12 mb-2">
          <br />
          <ul>
            <li
              >COVID-19 test results, testing and vaccination administration information, and information you provide in
              any screening questionnaire.</li
            >
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2 my-2">
      <div class="mb-2">
        <h4>My Rights</h4>
      </div>

      <div class="d-flex mb-2">
        <div>
          <ul>
            <li>I understand that this Authorization is voluntary.</li>
            <br />
            <li
              >I understand that my treatment, payment, enrollment, or eligibility for benefits will not be conditioned
              on whether I sign this form.</li
            >
            <br />
            <li
              >I understand that I may revoke or alter this Authorization at any time, that I must do so in writing and
              submit to One Medical (privacy@onemedical.com). However, I understand that if I revoke this Authorization,
              it will not have any effect on actions One Medical took before they received my written revocation.</li
            >
            <br />
            <li
              >I understand that once this health information is disclosed pursuant to this authorization, how the
              recipient (e.g., my employer, school, etc.) uses or further discloses the information may no longer be
              protected by state or federal laws such as HIPAA.</li
            >
            <br />
            <li
              >I understand the recipient of the information (e.g., my employer, school, etc.) may independently use the
              information to make decisions related to my employment, my ability to attend in-person classes, or my
              ability to enter premises, for example. One Medical has no control over, or responsibility for, the
              decisions or actions made by the recipient.</li
            >
            <br />
            <li>I understand that I am entitled to request and receive a copy of this Authorization.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2 my-2">
      <h4>Expiration of Authorization</h4>
      <p>
        Unless otherwise revoked, this Authorization will expire 24 months from the date of this form. Please contact
        One Medical Privacy (privacy@onemedical.com) if this Authorization needs to be withdrawn or modified before the
        expiration date.
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2 my-2">
      <h4>I have read and understand the information in this Authorization.</h4>
      <div class="row">
        <div class="col-12 col-md-6 mb-2">
          <om-label-wrapper class="d-block mb-3" [label]="signatureLabel">
            <input
              type="text"
              class="form-control text-field"
              formControlName="signatureText"
              required="true"
              autofocus
            />
          </om-label-wrapper>
        </div>
        <div class="col-12 col-md-6 mb-2">
          <om-label-wrapper class="d-block mb-3" label="Date">
            <om-date-input #signedAt class="" formControlName="signedAt"></om-date-input>
          </om-label-wrapper>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2 my-2">
      <div class="text-center">
        <small class="text-danger" *ngIf="submissionError">We've encountered an issue submitting your request.</small>
      </div>
      <om-submit-button label="Submit" [submitting]="submitting"></om-submit-button>
    </div>
  </div>
</form>
