<ng-container *ngIf="apiLoaded$ | async">
  <google-map width="100%" height="100%" [options]="googleMapOptions">
    <map-marker
      #marker="mapMarker"
      *ngFor="let markerConfig of markerConfigs"
      [position]="markerConfig.position"
      [options]="{
        icon: {
          url: selectedServiceArea?.code === markerConfig.serviceArea.code ? MAP_ICON_ACTIVE : MAP_ICON_INACTIVE,
          scaledSize: MARKER_ICON_SIZE
        },
        title: markerConfig.serviceArea.name
      }"
      (mapClick)="handleMarkerClick(marker, markerConfig.serviceArea)"
    >
    </map-marker>
    <map-info-window>
      <div class="info-window">
        <h5>{{ selectedServiceArea?.name }}</h5>
        <p *ngIf="selectedServiceArea?.officesCount >= 0">{{
          selectedServiceArea?.officesCount | i18nPlural: OFFICE_PLURAL_MAPPING
        }}</p>
      </div>
    </map-info-window>
  </google-map>
</ng-container>
