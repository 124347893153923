import { ContentChild, Component, Directive, Input, TemplateRef } from '@angular/core';

/**
 * Class representing a directive used for the content projection
 * of the error state body content.
 */
@Directive({
  selector: '[om-error-state-body-content]',
  standalone: true,
})
export class ErrorStateBodyContentDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * Class containing the standard inputs for the error state component.
 */
@Component({
  template: '',
})
export abstract class ErrorStateComponentBase {
  /**
   * The title or header for the error.
   */
  @Input() title: string;
  /**
   * The subcopy for the error.
   */
  @Input() bodyContent: string;
  /**
   * The path to the illustration to be displayed.
   *
   * @example
   * /assets/images/calendar.png
   */
  @Input() imageSource: string;
  /**
   * The text to be displayed on the primary call-to-action button.
   */
  @Input() primaryButtonText: string;
  /**
   * The text to be displayed on the secondary call-to-action button.
   */
  @Input() secondaryButtonText: string;

  /**
   * Captures the projected body content template, if any.
   */
  @ContentChild(ErrorStateBodyContentDirective) bodyContentTemplate: ErrorStateBodyContentDirective;
}
