import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'om-arrow-base',
  template: '',
  styleUrls: ['./arrow-base.component.scss'],
})
export class ArrowBaseComponent implements OnInit {
  @Input() disabled: boolean;
  @Output() click: EventEmitter<any> = new EventEmitter<any>();

  clickHandler: boolean;

  ngOnInit() {
    this.clickHandler = this.click.observers.length > 0;
  }
}
