import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { add, parseISO } from 'date-fns';

import { AppointmentAnalyticsService } from '@app/appointment/appointment-analytics.service';

import { CalendarEventUrl } from '../calendar-event-url';

@Component({
  selector: 'om-add-to-calendar',
  templateUrl: './add-to-calendar.component.html',
  styleUrls: ['./add-to-calendar.component.scss'],
})
export class AddToCalendarComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() location: string;
  @Input() startTime: string;
  @Input() durationMinutes: number;

  protected googleCalendarUrl: string;
  protected yahooCalendarUrl: string;
  protected outlookComCalendarUrl: string;
  protected icsFile: SafeUrl;

  constructor(private sanitizer: DomSanitizer, private analyticsService: AppointmentAnalyticsService) {}

  ngOnInit() {
    const endTime = add(parseISO(this.startTime), { minutes: this.durationMinutes });
    const calendarEventUrl = new CalendarEventUrl(this.title, this.description, this.location, this.startTime, endTime);

    this.googleCalendarUrl = calendarEventUrl.googleCalendarUrl();
    this.yahooCalendarUrl = calendarEventUrl.yahooCalendarUrl();
    this.outlookComCalendarUrl = calendarEventUrl.outlookCalendarUrl();
    this.icsFile = this.sanitizer.bypassSecurityTrustUrl(calendarEventUrl.icsFileUrl());
  }

  protected emitAnalyticsEvent() {
    this.analyticsService.addToCalendarClicked();
  }
}
