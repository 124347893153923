import { Component, OnInit, ViewChild } from '@angular/core';

import { AddressOptionInputComponent } from '@app/shared/address-option-input/address-option-input.component';
import { PhoneNumberInputComponent } from '@app/shared/phone-number-input/phone-number-input.component';

import { RegistrationStepDirective } from '../registration-step.directive';

@Component({
  selector: 'om-verified-account-set-up-step',
  styleUrls: ['../../../shared/form-input.scss'],
  templateUrl: './verified-account-set-up-step.component.html',
})
export class VerifiedAccountSetUpStepComponent extends RegistrationStepDirective implements OnInit {
  @ViewChild('address', { static: true }) address: AddressOptionInputComponent;
  @ViewChild('phoneNumber', { static: true }) phoneNumber: PhoneNumberInputComponent;

  showPreferredName = false;
  welcomeName = '';

  ngOnInit(): void {
    this.welcomeName = this.form.controls.firstName.value;
  }

  onSubmit() {
    this.submitting = true;
    this.form.controls.firstName.markAsDirty();
    this.form.controls.lastName.markAsDirty();
    this.phoneNumber.markAsTouchedAndDirty();
    this.address.markAsTouchedAndDirty();
    this.submit.emit();
  }
}
