import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';

import { LinksService } from '../core/links.service';

@Injectable({
  providedIn: 'root',
})
export class GetCareDeepLinkGuard implements CanActivate {
  constructor(private links: LinksService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    return route.queryParamMap.get('careOptionName') === 'TreatMeNow' || this.buildAppointmentsGetCareUrl(route);
  }

  buildAppointmentsGetCareUrl(route: ActivatedRouteSnapshot): UrlTree {
    return this.router.createUrlTree([this.links.appointmentsGetCare], {
      queryParams: route.queryParams,
      fragment: route.fragment ?? undefined,
    });
  }
}
