import { Injectable } from '@angular/core';
import { finalize, onErrorResumeNext, share } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics.service';

import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { TargetUserService } from './target-user.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private analyticsService: AnalyticsService,
    private apiService: ApiService,
    private authService: AuthService,
    private targetUserService: TargetUserService,
  ) {}

  logout() {
    // TODO: For Auth0 Launch - investigate moving the logout call to the authservice.logout()
    this.analyticsService.resetMixpanelId();
    this.targetUserService.resetTargetUser();
    const request = this.apiService.delete('/api/v2/patient/logout').pipe(
      onErrorResumeNext(),
      finalize(() => this.authService.logout()),
      share(),
    );
    request.subscribe();
    return request;
  }
}
