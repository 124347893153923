import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { RegistrationStepDirective } from '../registration-step.directive';

@Component({
  selector: 'om-mfa-step',
  styleUrls: ['../../../shared/form-input.scss'],
  templateUrl: './mfa-step.component.html',
})
export class MfaStepComponent extends RegistrationStepDirective {
  @Input() form: FormGroup;
  @Input() errorMessage: string;

  onSubmit() {
    this.submitting = true;
    this.submit.emit();
  }

  onError(err: Error) {
    this.errorMessage = err.message;
    this.submitting = false;
  }
}
