import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../core/api.service';

/**
 * @deprecated Use GraphQL. Here's an example: https://github.com/onemedical/patient-ui/blob/master/src/app/home/content-block-graphql.service.ts
 *
 */
@Injectable()
export class ContentBlockService {
  constructor(private apiService: ApiService) {}

  /**
   * @deprecated Use GraphQL. Here's an example: https://github.com/onemedical/patient-ui/blob/master/src/app/home/content-block-graphql.service.ts
   */
  getContentBlock(
    section: string,
    id: number | string,
    title: string,
    serviceAreaId: number | string,
  ): Observable<{ html: string | null }> {
    return this.apiService.get(
      `/content/guaranteed?section=${section}&page=${id}&title=${title}&service_area_id=${serviceAreaId}`,
    );
  }
}
