<svg [attr.width]="widthPx" [attr.height]="widthPx" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15Z"
    [attr.fill]="circleFillColor"
  />
  <path d="M10.0169 4.83594L4.83594 10.0169" stroke="white" />
  <path d="M10.0169 10.0169L4.83594 4.83594" stroke="white" />
</svg>
