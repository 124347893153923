import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { ServiceArea } from '@app/shared/service-area';
import { ServiceAreaService } from '@app/shared/service-area.service';

import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { RegistrationStepDirective } from '../registration-step.directive';

@Component({
  selector: 'om-service-area-selection-step',
  styleUrls: ['../../../shared/form-input.scss', './service-area-selection-step.component.scss'],
  templateUrl: './service-area-selection-step.component.html',
})
export class ServiceAreaSelectionStepComponent extends RegistrationStepDirective implements OnInit, OnDestroy {
  @HostBinding('className') _flexFill = 'flex-fill d-flex';
  @Input() numberOfOffices: number;
  @Input() b2bCompanyId: number;

  private destroy$ = new Subject<void>();
  private notifyForServiceAreaCodes: String[];
  hasVirtualServiceAreas$: Observable<boolean>;
  selectedServiceArea: ServiceArea;

  @ViewChild('virtualServiceAreaModal', { static: true }) virtualServiceAreaModal: ElementRef;

  constructor(
    public serviceAreaService: ServiceAreaService,
    private modalService: NgbModal,
    private analytics: EnterpriseRegistrationAnalyticsService,
  ) {
    super();
  }

  ngOnInit() {
    this.serviceAreaService.allServiceAreaOptions$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        serviceAreas =>
          (this.notifyForServiceAreaCodes = serviceAreas.filter(area => area.virtual).map(area => area.code)),
      );

    this.hasVirtualServiceAreas$ = this.serviceAreaService.virtualServiceAreas$.pipe(
      takeUntil(this.destroy$),
      map(virtualServiceAreas => virtualServiceAreas.length > 0),
    );

    this.serviceAreaService.getServiceAreas(this.b2bCompanyId);

    this.watchServiceAreaChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showError() {
    const { serviceArea } = this.form.controls;
    return serviceArea.dirty && serviceArea.touched && serviceArea.invalid;
  }

  onMarkerClick(serviceArea: ServiceArea) {
    // Select the service area that was clicked
    this.form.patchValue({ serviceArea: serviceArea.code });
  }

  onSubmit() {
    const { serviceArea } = this.form.controls;
    if (Object.keys(serviceArea.value).length === 0) {
      serviceArea.markAsDirty();
      serviceArea.markAsTouched();
    } else if (this.notifyForServiceAreaCodes.includes(serviceArea.value)) {
      this.notifyForServiceAreaCodes = this.notifyForServiceAreaCodes.filter(code => code !== serviceArea.value);
      this.showVirtualServiceAreaModal();
    } else {
      this.submit.emit(this.form.value);
    }
  }

  continueRegistrationFlow() {
    this.analytics.continueAsAVirtualMemberClicked();
    this.submit.emit(this.form.value);
    this.closeModal();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  private showVirtualServiceAreaModal() {
    this.modalService.open(this.virtualServiceAreaModal, { centered: true });
  }

  // Opens the correct map info window when the form value changes, whether through the select or the map.
  private watchServiceAreaChanges() {
    combineLatest([this.serviceAreaService.serviceAreas$, this.form.valueChanges.pipe(startWith(this.form.value))])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([serviceAreas, value]: [ServiceArea[], any]) => {
        const serviceArea = serviceAreas.find(({ code }: ServiceArea) => code === value.serviceArea);
        this.selectedServiceArea = serviceArea;
      });
  }
}
