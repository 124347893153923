import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'om-checkbox:not([formControlName]):not([formControl])',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit {
  @Input() checkboxValue: any;
  @Input() checkboxLabel: string;
  @Input() displayFlex = false;
  @Input() isChecked: boolean;
  @Input() isDisabled = false;

  @Output() checked = new EventEmitter<boolean>();
  @Output() selectedValue = new EventEmitter<string>();

  ngOnInit() {
    if (this.checkboxLabel == null) {
      this.checkboxLabel = this.checkboxValue;
    }
  }

  handleCheck() {
    if (!this.isDisabled) {
      this.checked.emit(this.isChecked);
      this.selectedValue.emit(this.checkboxValue);
    }
  }

  uncheck() {
    this.isChecked = false;
    this.checked.emit(this.isChecked);
  }
}
