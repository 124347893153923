import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';

import { AuthService } from '@app/core/auth.service';
import { StepName } from '@app/registration/enterprise/registration-step-name';

@Injectable({ providedIn: 'root' })
export class InitialStepNameResolver implements Resolve<string> {
  constructor(private authService: AuthService) {}

  resolve(_route: ActivatedRouteSnapshot): Observable<string> {
    if (this.authService.isLoggedIn()) {
      return observableOf(StepName.workEmail.valueOf());
    }
    return observableOf(StepName.personalDetails.valueOf());
  }
}
