import { ENVIRONMENT_STAGING } from './staging';

export const environment = {
  ...ENVIRONMENT_STAGING,
  apiServer: 'https://members.staging-app.1life.com',
  designSystemUrl: '/',
  marketingSite: 'https://mktg-stage.onemedical.com',
  myoneServer: 'https://members.staging-app.1life.com',
  opsUrl: 'https://staging.patientops.com',
  topLevelDomain: 'staging-app.1life.com',
  typeaheadDataLoggingUrl: 'https://any-ac-ml-staging.app.1life.com/data-logging/member',
  typeaheadSearchUrl: 'https://any-ac-ml-staging.app.1life.com/autocomplete/member',
};
