import { formatRelative, isAfter } from 'date-fns';
import { enUS } from 'date-fns/locale';

import { Membership } from '@app/core/membership';
import { formatDate } from '@app/shared/date-format.pipe';

export class MembershipExpirationHelper {
  constructor(private membership: Membership) {}

  formattedExpiry(): string {
    const date = new Date(this.membership.validUntil);

    const options: Record<string, string> = {
      today: isAfter(date, Date.now()) ? "'expires today'" : "'expired today'",
      tomorrow: "'is expiring tomorrow'",
      nextWeek: "'is expiring on' MMMM d, yyyy",
      yesterday: "'has expired'",
      lastWeek: "'has expired'",
      other: isAfter(date, Date.now()) ? "'is expiring on 'MMMM d, yyyy" : "'has expired'",
    };

    const localeUS: Locale = {
      ...enUS,
      formatRelative: token => options[token],
    };

    return formatRelative(date, Date.now(), { locale: localeUS });
  }

  expirationDateString(): string {
    return formatDate(new Date(this.membership.validUntil), 'MMM dd, yyyy');
  }

  freeTrialExpirationDateString(): string {
    const date = !this.membership.trialUntil ? new Date() : new Date(this.membership.trialUntil);
    return formatDate(date, 'MMM dd, yyyy');
  }

  cancellationDateString(): string {
    const cancelDate = this.membership.inFreeTrial() ? this.membership.trialUntil : this.membership.validUntil;
    return formatDate(new Date(cancelDate), 'M/d/yy');
  }

  hasExplicitExpirationDate(): boolean {
    return this.membership.validUntil !== null;
  }
}
