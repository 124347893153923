import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LinksService } from '@app/core/links.service';
import { Membership } from '@app/core/membership';
import { MembershipService } from '@app/core/membership.service';

@Injectable()
export class GuestGuardService implements CanActivate {
  constructor(private membershipService: MembershipService, private links: LinksService, private router: Router) {}

  canActivate(): boolean | Observable<boolean> {
    this.membershipService.getMembership();

    return this.membershipService.membership$.pipe(
      map((membership: Membership) => {
        if (!membership.isGuest()) {
          return true;
        } else {
          this.router.navigate([this.links.guest]);
          return false;
        }
      }),
    );
  }
}
