import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AnalyticsService } from '@app/core/analytics.service';
import { EXPIRED_RESET_PASSWORD_CLICKED, FLOW_LOGIN } from '@app/core/mixpanel.constants';

@Injectable({
  providedIn: 'root',
})
export class ForgotPasswordAnalyticsService extends AnalyticsService {
  trackResetPasswordPageLoad({ inactiveAccount }: { inactiveAccount: boolean }): Observable<void> {
    return this.trackWithDefaultProperties(EXPIRED_RESET_PASSWORD_CLICKED, {
      flow: FLOW_LOGIN,
      module: inactiveAccount ? 'Inactive Account Reset' : 'Password Reset',
    });
  }
}
