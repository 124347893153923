import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';

import { RegistrationEdge } from '@app/registration/graph/graph-navigation.service';
import { MembershipUpdateNode } from '@app/registration/graph/nodes/membership-update.node';

@Injectable({
  providedIn: 'root',
})
export class TermsOfServiceToMembershipUpdateEdge implements RegistrationEdge {
  nextNode = MembershipUpdateNode;

  shouldNavigate$(): Observable<boolean> {
    return observableOf(true);
  }
}
