import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';

@Component({
  selector: 'omgui-close-x-svg',
  templateUrl: './omgui-close-x-svg.component.html',
})
export class OmguiCloseXSvgComponent {
  @Input() stroke = '#343434';
  @Input() fill = '#F7F6F4';
  @Input() backgroundFill = 'none';
  @Input() backgroundOpacity: 1;

  @Input() width = 36;
  @Input() height = 36;

  @Input() strokeWidth = 1;
  @Input() strokeLinejoin = 'miter';

  constructor() {}
}

@NgModule({
  declarations: [OmguiCloseXSvgComponent],
  imports: [CommonModule],
  exports: [OmguiCloseXSvgComponent],
})
export class OmguiCloseXSvgModule {}
