import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'om-progress-bar',
  styleUrls: ['./progress-bar.component.scss'],
  templateUrl: './progress-bar.component.html',
})
export class ProgressBarComponent {
  @Input() canGoBack = false;
  @Input() percentComplete = 0;
  @Input() showLoginLink = false;
  @Output() login: EventEmitter<any> = new EventEmitter<any>();
  @Output() back: EventEmitter<any> = new EventEmitter<any>();
  progressBarHeight = '0.75rem';

  onBack() {
    this.back.emit();
  }

  onLogIn(e: Event) {
    e.preventDefault();
    this.login.emit();
  }
}
