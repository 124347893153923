import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

import { PatientPhoneNumbers } from '@app/shared/download-app-modal/__generated__/PatientPhoneNumbers';

@Injectable({
  providedIn: 'root',
})
export class PatientPhoneNumbersGraphQLService extends Query<PatientPhoneNumbers> {
  document = gql`
    query PatientPhoneNumbers {
      patient {
        id
        profileInfo {
          phoneNumbers {
            id
            kind
            number
            isPreferred
          }
        }
      }
    }
  `;
}
