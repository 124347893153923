import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LinksService } from '@app/core/links.service';
import { UnsignedLegalDocsGraphqlService } from '@app/registration/unsigned-legal-docs-graphql.service';

@Injectable({
  providedIn: 'root',
})
export class UnsignedLegalDocsGuardService {
  constructor(
    private links: LinksService,
    private router: Router,
    private unsignedLegalDocsGraphqlService: UnsignedLegalDocsGraphqlService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.unsignedLegalDocsGraphqlService
      .fetch({}, { fetchPolicy: 'network-only' })
      .pipe(map(result => !!result.data?.patient?.hasUnsignedLegalDocuments || this.router.parseUrl(this.links.home)));
  }
}
