import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import uniqueId from 'lodash-es/uniqueId';

// TODO: Reimplement using Bootstrap 5: https://getbootstrap.com/docs/5.0/forms/checks-radios/#switches or delete
@Component({
  selector: 'omgui-slide-toggle',
  templateUrl: './omgui-slide-toggle.component.html',
  styleUrls: ['./omgui-slide-toggle.component.scss'],
})
export class OmguiSlideToggleComponent {
  @Input() label: string;
  @Input() disabled = false;
  @Input() checked = false;
  @Input() toggleClass: string;
  @Input() labelClass: string;
  @Input() ariaLabel: string;

  elementId: string;

  constructor() {
    this.elementId = uniqueId('omgui-slide-toggle-');
  }
}

@NgModule({
  declarations: [OmguiSlideToggleComponent],
  imports: [CommonModule],
  exports: [OmguiSlideToggleComponent],
})
export class OmguiSlideToggleModule {}
