import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { FooterService } from '@app/core/footer.service';
import { LinksService } from '@app/core/links.service';
import { NavbarService } from '@app/core/navbar.service';
import { FlashService } from '@app/shared/flash.service';
import { BannerType } from '@omgui/omgui-banner/omgui-banner.component';

import { NativeAppService } from '../native-app.service';
import { ToastService } from '../toast.service';
import { ToastComponent } from '../toast/toast.component';

@Component({
  selector: 'om-logged-in-wrapper',
  templateUrl: './logged-in-wrapper.component.html',
  styleUrls: ['./logged-in-wrapper.component.scss'],
})
export class LoggedInWrapperComponent implements OnInit, OnDestroy {
  @ViewChild(ToastComponent) toastComponent: ToastComponent;

  closed = false;
  shouldHideFooter: boolean;
  onHomePage = false;
  hideFooter$: Observable<boolean>;
  hideNavigation$: Observable<boolean>;
  initialized$: Observable<boolean>;

  private destroy$ = new Subject<void>();

  readonly BannerType = BannerType;

  constructor(
    private nativeAppService: NativeAppService,
    private route: ActivatedRoute,
    private router: Router,
    private flashService: FlashService,
    private toastService: ToastService,
    private navbarService: NavbarService,
    private footerService: FooterService,
    private links: LinksService,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit() {
    this.initToast();

    this.onHomePage = this.router.url === this.links.home;
    this.hideNavigation$ = this.navbarService.shouldHideNavbar$();
    this.hideFooter$ = this.footerService.shouldHideFooter$();
    this.initialized$ = this.launchDarklyService.userIdentified$.pipe(map(() => true));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initToast() {
    this.toastService.toastMessage$.pipe(takeUntil(this.destroy$)).subscribe({
      next: message => this.toastComponent.show(message),
    });
  }
}
