import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { marked } from 'marked';

@Injectable()
export class MarkdownService {
  private readonly rendererWithHeadings: marked.Renderer;
  private readonly rendererNoHeadings: marked.Renderer;

  constructor(private sanitizer: DomSanitizer) {
    this.rendererNoHeadings = this.buildRenderer(false);
    this.rendererWithHeadings = this.buildRenderer(true);

    // default setup
    marked.setOptions({
      breaks: true,
      renderer: this.rendererNoHeadings,
      headerIds: false,
    });
  }

  convert(text: string): SafeHtml {
    marked.setOptions({ renderer: this.rendererNoHeadings });
    return this.escapedMarkedAndSanitized(text, false, false);
  }

  convertWithHeadings(text: string): SafeHtml {
    return this.escapedMarkedAndSanitized(text, true, false);
  }

  convertInline(text: string): SafeHtml {
    marked.setOptions({ renderer: this.rendererNoHeadings });
    return this.escapedMarkedAndSanitized(text, false, true);
  }

  private escapedMarkedAndSanitized(text: string, withHeading = false, inline = false): SafeHtml {
    // default to empty string
    text = text || '';

    if (withHeading) {
      marked.setOptions({ renderer: this.rendererWithHeadings });
    } else {
      marked.setOptions({ renderer: this.rendererNoHeadings });
    }

    const escapedAndMarkedText = inline ? marked.parseInline(escapeHtml(text)) : marked(escapeHtml(text));
    return this.sanitizer.sanitize(SecurityContext.HTML, escapedAndMarkedText);
  }

  private buildRenderer(headings = false): marked.Renderer {
    const renderer = new marked.Renderer();
    renderer.blockquote = noop;
    renderer.code = noop;
    renderer.codespan = noop;
    if (!headings) {
      renderer.heading = noop;
    }
    return renderer;
  }
}

function noop(text: string): string {
  return text;
}

function escapeHtml(unsafe: string): string {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
}
