<div *ngIf="membership">
  <div *ngIf="membership.isSelfPaid() && membership.isExpired() && canDisplayExpiredBanner()">
    <ngb-alert class="text-center expired-banner" type="info" [dismissible]="false">
      Your membership expired on {{ expirationDate }}.
      <a routerLink="/membership/renew/consumer" [omTrackLink]="trackExpiredMembershipBannerClicked">
        Renew Membership</a
      >
    </ngb-alert>
  </div>

  <div
    *ngIf="
      canDisplayAutoRenewBanner() && membership.isSelfPaid() && !membership.isExpired() && membership.canReactivate
    "
  >
    <ngb-alert class="text-center expired-banner" type="info" [dismissible]="false">
      Your membership will end on {{ expirationDate }}. Need to make changes?
      <a routerLink="/membership/settings" [omTrackLink]="trackReactivateMembershipBannerClicked"> Update Account</a>
    </ngb-alert>
  </div>

  <ng-container *ngIf="canDisplayExpiredBanner() && membership.isB2b()">
    <div *ngIf="membership.isExpired()">
      <ngb-alert class="text-center expired-banner" type="info" [dismissible]="false">
        Your sponsored membership {{ formattedExpiryString }}.
        <a routerLink="/membership/renew/enterprise" [omTrackLink]="trackExpiredMembershipBannerClicked">
          Renew Membership</a
        >
      </ngb-alert>
    </div>

    <div *ngIf="membership.isExpiringSoon() && membership.isEnterpriseAndExpiring() && !membership.isExpired()">
      <ngb-alert class="text-center expired-banner" type="info" [dismissible]="false">
        Your sponsored membership {{ formattedExpiryString }}.
        <a routerLink="/membership/settings" [omTrackLink]="trackExpiringB2bMembershipBannerClicked">
          Update Membership.
        </a>
      </ngb-alert>
    </div>
  </ng-container>

  <div
    *ngIf="
      canDisplayExpiredBanner() &&
      membership.creditCard &&
      membership.isConsumer() &&
      membership.creditCard.isExpired &&
      membership.isActive
    "
  >
    <ngb-alert class="text-center expired-banner" type="info" [dismissible]="false">
      Your credit card on file is not valid.
      <a routerLink="/membership/renew/consumer" [omTrackLink]="trackInvalidCCMembershipBannerClicked">
        Update Payment</a
      >
    </ngb-alert>
  </div>
</div>
