import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

import { FetchAppointmentBookingStateCache } from './__generated__/FetchAppointmentBookingStateCache';

@Injectable({
  providedIn: 'root',
})
export class AppointmentBookingSessionGraphQL extends Query<FetchAppointmentBookingStateCache> {
  document = gql`
    query FetchAppointmentBookingStateCache {
      patient {
        appointmentBookingSession {
          id
          appointmentBookingStateCache {
            appointmentCategory
            appointmentDate
            appointmentReason
            appointmentTypeId
            firstAvailable
            providerId
            serviceAreaId
            task
            visitReasonCategories {
              categorizableId
              categorizableType
              matchedText
            }
            appointmentBookingState {
              providerType
              isAppointmentRecommended
              searchDateStart
              searchDateEnd
              appointmentCancellationReason {
                id
                displayReason
              }
              appointmentType {
                id
                description
                displayName
                firstAppointmentSelected
                firstVisitAppointmentTypeId
                isSpecialty
                remote
                position
              }
              fromAppointment {
                id
                startTime
                officeName
                providerName
              }
              provider {
                id
                serviceArea {
                  id
                  name
                }
              }
              selectedReasons {
                text
                visitReasonCategory {
                  categorizableId
                  categorizableType
                  matchedText
                }
              }
              serviceArea {
                id
                name
                defaultOffice {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `;
}
