import { Injectable } from '@angular/core';

import { AnalyticsService } from '@app/core/analytics.service';
import { MP_FLOW_REGISTRATION } from '@app/core/mixpanel.constants';

@Injectable({
  providedIn: 'root',
})
export class MfaAnalyticsService extends AnalyticsService {
  readonly flow = MP_FLOW_REGISTRATION;

  trackMfaEvent(eventName: string, module: string) {
    return this.trackWithDefaultProperties(eventName, {
      flow: this.flow,
      module,
    });
  }
}
