import { updateGroup, validate } from 'ngrx-forms';
import { maxLength, notEqualTo, required } from 'ngrx-forms/validation';

import { ngrxFormsEmailValidator } from '@app/shared/email-validator';
import { passwordValidators } from '@app/shared/ngrx-forms-custom-validators/password';

export interface ExpeditedAccountCreationInfo {
  email: string;
  password: string;
  preferredName: string;
}

export const expeditedAccountCreationInfoFormState: ExpeditedAccountCreationInfo = {
  email: '',
  password: '',
  preferredName: '',
};

export const expeditedAccountCreationInfoFormGroupValidation = updateGroup<ExpeditedAccountCreationInfo>({
  email: validate(required, maxLength(128), ngrxFormsEmailValidator),
  password: (password, form) => validate(password, [...passwordValidators, notEqualTo(form.value.email)]),
});
