import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

import { CurrentUserId } from '@app/core/tos/__generated__/CurrentUserId';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserIdGraphQLService extends Query<CurrentUserId> {
  document = gql`
    query CurrentUserId {
      patient {
        id
      }
    }
  `;
}
