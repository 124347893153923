import { gql } from 'apollo-angular';

export const elistB2bCompany = gql`
  fragment elistB2bCompany on B2bCompany {
    id
    b2bEmailDomains
    includesDependent
    verifyDependentInfo
    activationCodeRetrievableViaEmail
    enterprisePedsRegistrationEnabled
    __typename
  }
`;

export const dependents = gql`
  fragment dependents on ScrubbedWhitelistedEmployee {
    id
    registered
    __typename
  }
`;

export const whitelistedEmployeeBase = gql`
  fragment whitelistedEmployeeBase on WhitelistedEmployee {
    id
    employeeId
    registered
    workEmail
  }
`;
