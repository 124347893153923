import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { NodeUrlMappingService } from '@app/registration/graph/node-url-mapping.service';
import { RegistrationNode } from '@app/registration/graph/nodes/registration.node';

@Injectable({
  providedIn: 'root',
})
export class TrialVideoVisitConfirmationNode extends RegistrationNode {
  constructor(nodeUrlMappingService: NodeUrlMappingService, router: Router) {
    super(nodeUrlMappingService, router);
    this.edges = [];
  }
}
