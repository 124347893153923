import { FetchAppointmentBookingStateCache_patient_appointmentBookingSession_appointmentBookingStateCache_appointmentBookingState_appointmentCancellationReason as GraphQLCancellationReason } from './__generated__/FetchAppointmentBookingStateCache';

export interface ApiV2AppointmentCancellationReason {
  id: number;
  display_reason: string;
}

export class AppointmentCancellationReason {
  id: string;
  displayReason: string;

  static fromGraphQL(response: GraphQLCancellationReason): AppointmentCancellationReason {
    const reason = new AppointmentCancellationReason();
    reason.id = response.id;
    reason.displayReason = response.displayReason;
    return reason;
  }

  toApiV2(): ApiV2AppointmentCancellationReason {
    return {
      id: parseInt(this.id, 10),
      display_reason: this.displayReason,
    };
  }
}
