import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

import {
  RemoteAppointmentType,
  RemoteAppointmentTypeVariables,
} from './__generated__/remote-appointment-type-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class RemoteAppointmentTypeGraphQLService extends Query<RemoteAppointmentType, RemoteAppointmentTypeVariables> {
  document = gql`
    query RemoteAppointmentType($id: ID!) {
      appointment(id: $id) {
        id
        appointmentType {
          id
          remoteAppointmentTypeId
        }
      }
    }
  `;
}
