export class PhoneNumberFormatter {
  get display(): string | null {
    return this.formatPhoneNumber();
  }

  get tenDigitNumber(): string | null {
    return this.extractDigits()?.slice(0, 10) || null;
  }

  constructor(private phoneNumber: string) {}

  private formatPhoneNumber(): string | null {
    const extractedDigits = this.extractDigits();

    if (!extractedDigits || extractedDigits.length <= 0) {
      return null;
    } else if (extractedDigits.length <= 3) {
      return '(' + extractedDigits;
    } else if (extractedDigits.length <= 6) {
      return '(' + extractedDigits.slice(0, 3) + ') ' + extractedDigits.slice(3);
    } else {
      return (
        '(' + extractedDigits.slice(0, 3) + ') ' + extractedDigits.slice(3, 6) + '-' + extractedDigits.slice(6, 10)
      );
    }
  }

  private extractDigits() {
    return this.phoneNumber?.replace(/[^0-9]/g, '');
  }
}
