import { Injectable } from '@angular/core';
import { Query, gql, Apollo } from 'apollo-angular';

import { WhitelistedEmployee, WhitelistedEmployeeVariables } from './__generated__/WhitelistedEmployee';
import { dependents, elistB2bCompany, whitelistedEmployeeBase } from './whitelisted-employee-graphql.service.fragment';

@Injectable()
export class WhitelistedEmployeeGraphQL extends Query<WhitelistedEmployee, WhitelistedEmployeeVariables> {
  constructor(apollo: Apollo) {
    super(apollo);
  }

  document = gql`
    query WhitelistedEmployee($workEmail: String, $b2bCompanyId: String, $employeeId: String, $reCaptchaToken: String) {
      whitelistedEmployee(
        workEmail: $workEmail
        b2bCompanyId: $b2bCompanyId
        employeeId: $employeeId
        reCaptchaToken: $reCaptchaToken
      ) {
        ...whitelistedEmployeeBase
        dependents {
          ...dependents
        }
        b2bCompany {
          ...elistB2bCompany
        }
      }
    }
    ${dependents}
    ${elistB2bCompany}
    ${whitelistedEmployeeBase}
  `;
}
