import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-svg-request-records',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" />
      <path
        d="M6.70001 3.14286C6.70001 2.51167 7.21169 2 7.84287 2H13.2168C13.848 2 14.3596 2.51167 14.3596 3.14286V5.11249C14.3596 5.74367 13.848 6.25535 13.2168 6.25535H7.84287C7.21169 6.25535 6.70001 5.74367 6.70001 5.11249V3.14286Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.84287 2C7.26007 2 6.77915 2.43624 6.70885 3H4.14286C3.51167 3 3 3.51167 3 4.14286V20C3 21.1046 3.89543 22 5 22H15V21H5C4.44772 21 4 20.5523 4 20V4.14286C4 4.06396 4.06396 4 4.14286 4H6.70001V5.11249C6.70001 5.74367 7.21169 6.25535 7.84287 6.25535H13.2168C13.848 6.25535 14.3596 5.74367 14.3596 5.11249V4H16.8571C16.936 4 17 4.06396 17 4.14286V12H18V4.14286C18 3.51167 17.4883 3 16.8571 3H14.3508C14.2806 2.43617 13.7996 2 13.2168 2H7.84287ZM13.2168 3H7.84287C7.76397 3 7.70001 3.06396 7.70001 3.14286V5.11249C7.70001 5.19139 7.76397 5.25535 7.84287 5.25535H13.2168C13.2957 5.25535 13.3596 5.19139 13.3596 5.11249V3.14286C13.3596 3.06396 13.2957 3 13.2168 3Z"
        fill="currentColor"
      />
      <path
        d="M9.85357 16.1465L7.70712 14L15 14V13L7.70712 13L9.85357 10.8536L9.14646 10.1465L5.79291 13.5L9.14646 16.8536L9.85357 16.1465Z"
        fill="currentColor"
      />
      <path
        d="M21.2071 18.5L17.8536 15.1465L17.1465 15.8536L19.2929 18H12V19H19.2929L17.1465 21.1465L17.8536 21.8536L21.2071 18.5Z"
        fill="currentColor"
      />
    </svg>
  `,
})
export class SvgRequestRecordsComponent {
  @Input() color = 'currentColor';

  constructor() {}
}
