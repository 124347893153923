import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LinksService } from '@app/core/links.service';
import { EnterpriseRegistrationAnalyticsService } from '@app/registration/enterprise';

@Component({
  selector: 'om-direct-signup-info',
  templateUrl: 'direct-signup-info.component.html',
  styleUrls: ['direct-signup-info.component.scss'],
})
export class DirectSignupInfoComponent implements OnInit {
  constructor(
    public linksService: LinksService,
    private enterpriseAnalytics: EnterpriseRegistrationAnalyticsService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    const { source } = this.route.snapshot.queryParams;
    this.enterpriseAnalytics.directSignupFamilyPageViewed(source);
  }
}
