<form [formGroup]="address">
  <div class="form-group">
    <label for="address-line-1" class="form-label fw-bolder"> Address Line 1 </label>
    <input
      type="text"
      id="address-line-1"
      class="form-control"
      placeholder="Address Line 1"
      formControlName="address1"
    />
  </div>
  <div class="form-group">
    <label for="address-line-2" class="form-label fw-bolder"> Address Line 2 </label>
    <input
      type="text"
      id="address-line-2"
      class="form-control"
      placeholder="Apt, Suite, Floor"
      formControlName="address2"
    />
  </div>
  <div class="form-group">
    <label for="address-city" class="form-label fw-bolder"> City </label>
    <input type="text" id="address-city" class="form-control" placeholder="City" formControlName="city" />
  </div>
  <div class="row">
    <div class="form-group col-md">
      <label for="address-state" class="form-label fw-bolder"> State </label>
      <select
        type="text"
        id="address-state"
        class="form-select"
        *ngIf="stateService.states$ | async as states"
        formControlName="state"
      >
        <option *ngFor="let state of states" [ngValue]="state.shortName"> {{ state.name }} </option>
      </select>
    </div>
    <div class="form-group col-md">
      <label for="address-zip-code" class="form-label fw-bolder"> Zip </label>
      <input type="text" id="address-zip-code" class="form-control" placeholder="Zip" formControlName="zip" />
    </div>
  </div>
</form>
