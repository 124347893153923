import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AdministratorLegalDocGraphQL } from '@app/core/legal-documents/administrator-legal-doc-graphql.service';
import { BeneficiaryLegalDocGraphQL } from '@app/core/legal-documents/beneficiary-legal-doc-graphql.service';
import { LegalDocumentsService } from '@app/core/legal-documents/legal-documents.service';
import { SignLegalDocGraphQL } from '@app/core/legal-documents/sign-legal-doc-graphql.service';

/**
 * Module containing services, models, and components related to legal documents
 * (a.k.a. TOS or terms of service documents).
 */

@NgModule({
  imports: [CommonModule],
  providers: [AdministratorLegalDocGraphQL, BeneficiaryLegalDocGraphQL, LegalDocumentsService, SignLegalDocGraphQL],
})
export class LegalDocumentsModule {}
