import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import uniqueId from 'lodash-es/uniqueId';

export interface RadioOptionConfig {
  label: string;
  onClick: () => void;
  formControlName: string;
  value: any;
  checked: () => boolean;
  disabled?: boolean; // default to false
  elementId?: string; // this will be set by ngOnChanges below; it need not be defined externally
}

@Component({
  selector: 'om-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
})
export class RadioGroupComponent implements OnChanges {
  @Input() inline = false;
  @Input() options: RadioOptionConfig[] = [];
  @Input() submitting: boolean;
  @Input() form: UntypedFormGroup;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.options.forEach((option: RadioOptionConfig) => {
        option.elementId = uniqueId('radio-config-');
      });
    }
  }

  onClick(option: RadioOptionConfig) {
    if (option.checked()) {
      return;
    }

    option.onClick();
  }
}
