import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FetchAppointmentBookingStateCache } from './__generated__/FetchAppointmentBookingStateCache';
import { AppointmentBookingSession } from './appointment-booking-session';
import { AppointmentBookingSessionGraphQL } from './appointment-booking-session-graphql.service';

@Injectable()
export class AppointmentBookingSessionService {
  constructor(public bookingSessionGraphQL: AppointmentBookingSessionGraphQL) {}

  getAppointmentBookingSession(): Observable<AppointmentBookingSession> {
    return this.bookingSessionGraphQL.fetch().pipe(
      map((result: ApolloQueryResult<FetchAppointmentBookingStateCache>) => {
        const { appointmentBookingSession } = result.data.patient;
        if (!!appointmentBookingSession) {
          return AppointmentBookingSession.fromGraphQL(result.data.patient.appointmentBookingSession);
        }

        return null;
      }),
    );
  }
}
