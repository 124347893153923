import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import each from 'lodash-es/each';
import find from 'lodash-es/find';
import pullAt from 'lodash-es/pullAt';
import remove from 'lodash-es/remove';
import set from 'lodash-es/set';

import { SurveyAnswerKind, PatientSurveyAnswer } from '../../../../__generated__/globalTypes';
import { setStringValue } from '../input-helper';
import { PatientCustomAnswer, SurveyQuestion } from '../survey-models';

export interface PatientAnswerGroup {
  name: string;
  dosage: string;
  quantity: string;
  reason: string;
}

export interface FsaHsaAnswerText {
  group: number;
  field: string;
  value: string;
}

export enum FsaHsaFields {
  name = 'name',
  dosage = 'dosage',
  quantity = 'quantity',
  reason = 'reason',
}

export const maxFreeTextLength = 200;

@Component({
  selector: 'om-fsa-hsa-question',
  templateUrl: './fsa-hsa-question.component.html',
  styleUrls: ['./fsa-hsa-question.component.scss'],
})
export class FsaHsaQuestionComponent implements OnInit {
  @Input() question: SurveyQuestion;
  @Output() answered = new EventEmitter();
  patientAnswers: PatientSurveyAnswer[];
  patientAnswerGroups: PatientAnswerGroup[] = [{ name: '', dosage: '', quantity: '', reason: '' }];
  maxAnswerLength = maxFreeTextLength;

  constructor() {}

  ngOnInit() {
    this.patientAnswers = this.question.patientAnswers;
  }

  updateTextValue(value: string, group: number, field: string) {
    set(this.patientAnswerGroups, `[${group}].${field}`, value);

    const patientInput = find(this.patientAnswers, answer => {
      const answerText: FsaHsaAnswerText = JSON.parse(answer.answerText);
      return answerText.group === group && answerText.field === field;
    });

    setStringValue(
      patientInput as PatientCustomAnswer,
      JSON.stringify({
        group,
        field,
        value: value.length > maxFreeTextLength ? value.substring(0, maxFreeTextLength) : value,
      }),
      () => this.question.clearErrorText(),
    );

    this.answered.emit();
  }

  addAnswerGroup() {
    this.patientAnswerGroups.push({ name: '', dosage: '', quantity: '', reason: '' });

    const answerGroup = this.patientAnswerGroups.length - 1;

    each(Object.values(FsaHsaFields), field => {
      this.patientAnswers.push(
        new PatientCustomAnswer({
          surveyQuestionId: this.question.id,
          kind: SurveyAnswerKind.fsa_hsa_grid_input,
          answerText: JSON.stringify({ group: answerGroup, field, value: '' }),
        }),
      );
    });
  }

  removeAnswerGroup(group: number) {
    remove(this.patientAnswers, patientAnswer => {
      const answerText: FsaHsaAnswerText = JSON.parse(patientAnswer.answerText);
      return answerText.group === group;
    });

    each(this.patientAnswers, patientAnswer => {
      const answerText: FsaHsaAnswerText = JSON.parse(patientAnswer.answerText);
      if (answerText.group > group) {
        answerText.group--;
        patientAnswer.answerText = JSON.stringify(answerText);
      }
    });

    pullAt(this.patientAnswerGroups, [group]);
  }
}
