import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Query, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { PrincipalUserInfo, PrincipalUser } from './__generated__/principal-user-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class PrincipalUserGraphQL extends Query<PrincipalUserInfo> {
  document = gql`
    query PrincipalUserInfo {
      principalUser {
        ...PrincipalUser
      }
    }

    fragment PrincipalUser on Patient {
      id
      fhirIdentifier
      firstName
      lastName
      preferredName
      profileImageUrl
    }
  `;

  principalUser$(): Observable<PrincipalUser> {
    return this.fetch().pipe(
      take(1),
      map((result: ApolloQueryResult<PrincipalUserInfo>) => result.data.principalUser),
    );
  }
}
