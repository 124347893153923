import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MembershipPlan } from '@app/core/membership';
import { MembershipUpdateToPrepaidConfirmationEdgeGraphqlService } from '@app/registration/graph/edges/membership-update-to-prepaid-confirmation-edge-graphql.service';
import { RegistrationEdge } from '@app/registration/graph/graph-navigation.service';
import { PrepaidConfirmationNode } from '@app/registration/graph/nodes/prepaid-confirmation.node';

@Injectable({
  providedIn: 'root',
})
export class MembershipUpdateToPrepaidConfirmationEdge implements RegistrationEdge {
  constructor(private graphQLService: MembershipUpdateToPrepaidConfirmationEdgeGraphqlService) {}

  nextNode = PrepaidConfirmationNode;

  shouldNavigate$(): Observable<boolean> {
    return this.graphQLService
      .fetch()
      .pipe(map(result => result.data?.membership?.planType === MembershipPlan.SELF_PAID_AMAZON));
  }
}
