import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/* Adapted from bootstrap breakpoints */
export const BREAKPOINTS = {
  xs: '(min-width: 0px) and (max-width: 575px)',
  sm: '(min-width: 576px) and (max-width: 767px)',
  md: '(min-width: 768px) and (max-width: 991px)',
  lg: '(min-width: 992px) and (max-width: 1199px)',
  xl: '(min-width: 1200px)',
};

@Injectable({
  providedIn: 'root',
})
export class BreakpointService {
  constructor(private observer: BreakpointObserver) {}

  matches$(mediaQuery: string): Observable<boolean> {
    return this.observer.observe(mediaQuery).pipe(map(({ matches }) => matches));
  }

  isXs$(): Observable<boolean> {
    return this.matches$(BREAKPOINTS.xs);
  }

  isSm$(): Observable<boolean> {
    return this.matches$(BREAKPOINTS.sm);
  }

  isMd$(): Observable<boolean> {
    return this.matches$(BREAKPOINTS.md);
  }

  isLg$(): Observable<boolean> {
    return this.matches$(BREAKPOINTS.lg);
  }

  isXl$(): Observable<boolean> {
    return this.matches$(BREAKPOINTS.xl);
  }
}
