import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

import {
  RetrieveAccessToken,
  RetrieveAccessTokenVariables,
} from './__generated__/retrieve-access-token-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class RetrieveAccessTokenGraphQLService extends Query<RetrieveAccessToken, RetrieveAccessTokenVariables> {
  document = gql`
    query RetrieveAccessToken($nonce: String!) {
      accessToken(nonce: $nonce)
    }
  `;
}
