import { PatientCustomAnswer, PatientPredefinedAnswer } from './survey-models';

export const setValue = (patientAnswer: PatientPredefinedAnswer, value: string, event?: Event) => {
  if (event) {
    event.preventDefault();
  }

  if (patientAnswer.answerId === value) {
    patientAnswer.answerId = undefined;
  } else {
    patientAnswer.answerId = value;
  }
};

export const setStringValue = (patientAnswer: PatientCustomAnswer, value: string, callback: any) => {
  patientAnswer.answerText = value;
  if (value?.length > 0) {
    callback();
  }
};
