import { Type } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { GraphNavigationArgs, RegistrationEdge } from '@app/registration/graph/graph-navigation.service';
import { NodeUrlMappingService } from '@app/registration/graph/node-url-mapping.service';

export class RegistrationNodeError extends Error {
  constructor(message: string | undefined) {
    super(message);
    this.name = 'RegistrationNodeError';
  }
}

export abstract class RegistrationNode {
  protected edges: RegistrationEdge[];
  isAsync = false;

  constructor(private nodeUrlMappingService: NodeUrlMappingService, protected router: Router) {}

  execute(_args?: GraphNavigationArgs): void {
    const url = this.nodeUrlMappingService.getUrlForNode(this.constructor as Type<RegistrationNode>);
    this.router.navigate([url], { queryParamsHandling: 'preserve' });
  }

  execute$(_args?: GraphNavigationArgs): Observable<void> {
    throw new RegistrationNodeError('execute$ not implemented');
  }

  nextNode$(): Observable<Type<RegistrationNode>> {
    const evaluatedEdges$ = this.edges.map(edge =>
      edge.shouldNavigate$().pipe(
        take(1),
        map(shouldNavigate => ({ nextNode: edge.nextNode, shouldNavigate })),
      ),
    );

    return combineLatest(evaluatedEdges$).pipe(
      map(evaluatedEdges => {
        const nextEdge = evaluatedEdges.find(evaluatedEdge => evaluatedEdge.shouldNavigate);

        if (!nextEdge) {
          throw new RegistrationNodeError('No edge found');
        }

        return nextEdge.nextNode;
      }),
    );
  }

  hasNoEdges(): boolean {
    return !this.edges?.length;
  }
}
