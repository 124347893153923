declare const Stripe: stripe.StripeStatic;

/**
 * Handles (lazy) intiialization of the stripe library
 * Because we load the stripe script asynchronously, we only want to initialize the client when we need it
 */
export class StripeClient {
  private _elements: stripe.elements.Elements;
  private _stripe: stripe.Stripe;

  get elements(): stripe.elements.Elements {
    this._elements ??= this.stripe.elements();
    return this._elements;
  }

  get stripe(): stripe.Stripe {
    this._stripe ??= Stripe(this.stripePublishableKey);
    return this._stripe;
  }

  constructor(private stripePublishableKey: string) {
    if (!stripePublishableKey) {
      throw new Error('Stripe key is not defined in this environment');
    }
  }
}
